import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { db } from '../context/firebase';

import AuthContext from '../context/auth/authContext';
import AlertContext from '../context/alert/alertContext';
import AuthFormWithQuote from '../components/Authentication/AuthFormWithQuote';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ClaimAccount = (props) => {
  const queryToken = useQuery().get('token');

  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const { register, error, clearErrors, isAuthenticated, loadUser } =
    authContext;

  const [loading, setLoading] = useState(true);

  const searchUserTemp = async (token) => {
    const UserRecord = db
      .collection('users')
      .where('directToken', '==', token)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length !== 0 && querySnapshot.docs[0].exists) {
          return querySnapshot.docs[0].data();
        }
        return null;
      })
      .catch((err) => {
        return err;
      });
    return UserRecord;
  };

  useEffect(() => {
    loadUser();
    if (isAuthenticated) {
      props.history.push('/dashboard');
    }

    if (!!error) {
      setAlert(error.message, 'danger');
      clearErrors();
    }

    if (!queryToken) {
      props.history.push('/contactadviser');
    } else {
      searchUserTemp(queryToken).then((res) => {
        if (!!res && res.directToken === queryToken) {
          setLoading(false);
          setUser({
            ...user,
            email: res.email,
            name: res.name,
            tenant: res.tenant,
            adviser: res.admin,
            userId: res.id,
          });
        } else {
          props.history.push('/invalidtoken');
        }
      });
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    email: '',
    name: '',
    password: '',
    token: queryToken,
    tenant: '',
    adviser: '',
    userId: '',
  });

  const { email, name, password, userId } = user;

  const onChange = (e) =>
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    register(email, password, name, userId);
  };

  return (
    <AuthFormWithQuote
      customLoading={loading}
      submit={onSubmit}
      change={onChange}
      data={[
        {
          name: 'password',
          type: 'password',
          label: 'Password',
          value: password,
        },
      ]}
      loading={loading}
      buttonLabel='Register'
      title={name + ', welcome to'}
      subtitle='Setup a password for your account'
    />
  );
};

export default ClaimAccount;
