import React from 'react';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  box-shadow: ${(props) => props.theme.boxShadow};
  padding: 2rem;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  border-radius: 15px;
  @media only screen and (max-width: 800px) {
    box-shadow: none;
    margin-top: 2rem;
  }
`;

const AppName = styled.div`
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const Title = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  margin: 2rem 0 0.5rem 0;
`;

const SubTitle = styled.div`
  font-size: 1.4rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.8)`};
  margin: 0rem 0 0.5rem 0;
`;

const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const ExplainerWrapper = styled.div`
  margin: 2rem 0;
  line-height: 1.5rem;
  div {
    margin-bottom: 0.5rem;
  }
`;

const EmailVerifiedNotification = ({
  title,
  subtitle,
  explainer,
  appIcon,
  appName,
}) => {
  return (
    <Wrapper>
      <AppName>
        <Icon
          iconType={appIcon}
          size={2.6}
          margin='0 0.5rem 0 0'
          color='primary'
        />
        {appName}
      </AppName>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <FlexGroup>
        <Icon
          iconType='mailSend'
          size={6}
          color='primary'
          margin='2rem 0rem 0rem 0'
        />
        <ExplainerWrapper>
          {explainer.map((text, index) => (
            <div key={index}>{text}</div>
          ))}
        </ExplainerWrapper>
      </FlexGroup>
    </Wrapper>
  );
};

export default EmailVerifiedNotification;
