import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 4rem 0;
`;

const Title = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.4rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const Content = styled.div`
  display: grid;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 2rem 1rem;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 2rem 1rem 2rem 0;
  }
`;

const Tile = styled.div`
  padding: 0 2rem;
  text-align: center;
  img {
    width: 100px;
    border-radius: 50%;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
    height: 100px;
  }
`;

const TileTitle = styled.div`
  font-weight: bold;
  min-height: 3rem;
  margin: 0.5rem 0;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
`;

const FeaturesWithTitleText = ({ config }) => {
  return (
    <Wrapper>
      <Title>{config.title}</Title>
      <Content>
        {config.content.map((item, index) => (
          <Tile key={index}>
            <img src={item.img} alt={item.title} />
            <TileTitle>{item.title}</TileTitle>
            <p>{item.content}</p>
          </Tile>
        ))}
      </Content>
    </Wrapper>
  );
};

export default FeaturesWithTitleText;
