import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;

const Container = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  max-width: 1100px;
  margin: auto;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

const PostWrapper = styled(Link)`
  :hover {
    cursor: pointer;
    opacity: 0.8;
    background: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 0.02)`};
  }
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
    margin-bottom: 1rem;
  }
  span {
    color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
    margin-left: 0.25rem;
    font-size: 0.9rem;
  }
  p {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 200px;
    border-radius: 5px;
  }
  @media only screen and (max-width: 800px) {
    margin-bottom: 2rem;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  justify-content: flex;
  align-items: center;
  flex-wrap: wrap;
  text-transform: uppercase;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.8)`};
  font-weight: 500;
  margin: 1rem 0;
  font-size: 0.9rem;
  * {
    margin-right: 1rem;
    letter-spacing: 0.07rem;
  }
`;

const Badge = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  background: ${(props) =>
    props.color
      ? `rgba(${props.theme.colors[props.color][0]}, 1)`
      : `rgba(${props.theme.colors.neutral[5]}, 1)`};
  padding: 0 0.25rem;
  border-radius: 3px;
`;

const Tag = styled.div``;

const Author = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
  }
  h4 {
    font-weight: bold;
  }
`;

const BlogWithTileList = ({ config }) => {
  return (
    <Wrapper>
      <Container>
        {config.map((post, postIndex) => (
          <Fragment key={postIndex}>
            {!post.isHidden && (
              <PostWrapper to={`/news/${slugify(post.title)}`}>
                <img src={post.image} alt={post.title} />
                <TagsWrapper>
                  <Badge color={post.typeColor}>{post.type}</Badge>
                  {post.tags.map((tag, tagIndex) => (
                    <Tag key={tagIndex}>{tag}</Tag>
                  ))}
                </TagsWrapper>
                <h2>{post.title}</h2>
                <p>
                  {post.intro.substring(0, 200)}...
                  <span>Read More</span>
                </p>
                <Author>
                  <img src={post.author.image} alt={post.author.name} />
                  <div>
                    <h4>{post.author.name}</h4>
                    <p>{post.author.role}</p>
                  </div>
                </Author>
              </PostWrapper>
            )}
          </Fragment>
        ))}
      </Container>
    </Wrapper>
  );
};

export default BlogWithTileList;
