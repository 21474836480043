import dividendgrpah from './dividend-graph.jpg';
import Rachel from './rachellane.jpg';

const dividends = {
  title: 'Dividends recover in 2021',
  date: '03/08/2020',
  readTime: 3,
  image: dividendgrpah,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Investing', 'Covid-19'],
  intro:
    'Many income-seeking investors would prefer to forget 2020. Global interest rates stayed around zero (or below), while many companies throughout the world cut or suspended their dividend payments. The average fall in global dividends in 2020 was 12.2%, according to one leading international investment manager. However, the global picture is distorted by the resilient performance of North America, which saw a small rise in dividends. Strip out North America from the world data and the picture elsewhere was mostly grim.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'Many income-seeking investors would prefer to forget 2020. Global interest rates stayed around zero (or below), while many companies throughout the world cut or suspended their dividend payments. The average fall in global dividends in 2020 was 12.2%, according to one leading international investment manager. However, the global picture is distorted by the resilient performance of North America, which saw a small rise in dividends. Strip out North America from the world data and the picture elsewhere was mostly grim.',
      },
    ],
    [
      {
        content:
          'Dividend cuts were particularly prevalent in the UK, as the graph shows. Between 2019 and 2020 the total value of dividends (regular and one-off) paid by UK companies fell by 43.1%. Most of that reflected the decline in regular dividends, although proportionately there was a much greater fall in one-off payments. From April 2020 to December 2020, nearly a third of UK companies cancelled their dividends, while close to another quarter cut them.',
      },
    ],
    [
      {
        content:
          'In some instances, the companies had no other option. The main UK banks were effectively ordered by the Bank of England to end dividend payments as a way of preserving their capital. Others, such as airlines, had little choice because their revenue disappeared. There were also businesses that took the opportunity presented by the pandemic to make overdue adjustments to how much profit they passed out to shareholders. Arguably, the ‘rebasing’ of dividends by the UK’s two oil majors, BP and Shell, was an example of this pragmatism.',
      },
    ],
    [{ type: 'heading', content: 'LOOKING UP IN 2021' }],
    [
      {
        content:
          'In 2021, the clouds seem to be lifting. The banks have been allowed to resume dividend payments, although at a lower level than in 2019. Some companies that suspended dividends as a precaution have begun making payments again. Even Shell, which cut its quarterly dividend from 47¢ to 16¢ in June 2020, has started to increase its quarterly payments. ',
      },
    ],
    [
      {
        content:
          'Inevitably, there are companies in sectors such as hospitality and retail that continue to conserve cash, but these should benefit from the bounce back that is emerging in economic activity',
      },
    ],
    [
      {
        content:
        'Link Asset Services, one of the largest share registrars in the UK, has estimated that in its best-case scenario, regular dividend payments will rise 5.6% in 2021. Its worst-case scenario is still positive, with dividends rising by just 0.9%. Link says that “companies are increasingly declaring dividends in line with our best-case scenario as the economy comes back to life and constraints on pay-outs are lifted”',
      },
    ],
    [
      {
        content:
          'If you want to invest in the UK equity income sector and potentially benefit from rising dividends, please ask our advice on the wide range of funds available. You might also consider overseas equity income funds. As 2020 showed, international diversification can be a wise strategy.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investment and the income from it can go down as well as up and you may not get back the full amount you invested. Past performance is not a reliable indicator of future performance.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Investing in shares should be regarded as a long-term investment and should fit in with your overall attitude to risk and financial circumstances. ',
      },
    ],
  ],
};

export default dividends;
