import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import Icon from '../UI/Icon';
import Hamburger from './Hamburger';
import initials from '../../utils/initials';

const MobileNav = styled.div`
  @media only screen and (min-width: 800px) {
    display: none;
  }
  position: fixed;
  width: 100vw;
  height: 4rem;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  border-top: ${(props) =>
    `0.25rem solid rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const AppName = styled(Link)`
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Profile = styled(Link)`
  margin: 0 1rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  background-color: ${(props) =>
    props.active === 'true'
      ? `rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : `rgba(${props.theme.colors.primary[0]}, 1)`};
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
`;

const NotificationsWrapper = styled(Link)`
  display: flex;
`;

const Notifications = styled.div`
  margin-left: -0.75rem;
  margin-top: -0.25rem;
  padding: 0 0 0.1rem 0.075rem;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 15px;
  font-size: 0.6rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  background-color: ${(props) => `rgba(${props.theme.colors.danger[0]}, 1)`};
`;

const MobileAppNavHeader = ({
  handleMobileNavToggle,
  showMobileNav,
  appIcon,
  appName,
  unreadNotifications,
  userData,
}) => {
  const location = useLocation();
  return (
    <MobileNav>
      <div
        onClick={() => {
          handleMobileNavToggle();
        }}
      >
        <Hamburger isActive={showMobileNav} />
      </div>
      <AppName to='/dashboard'>
        <Icon
          iconType={appIcon}
          size={1.6}
          margin='0 0.5rem 0 0'
          color='primary'
        />
        {appName}
      </AppName>
      <ProfileWrapper>
        <NotificationsWrapper to='/notifications'>
          <Icon
            iconType='bell'
            size={1.5}
            color={
              location.pathname === '/notifications' ? 'linkBlue' : 'midGrey'
            }
          />
          {unreadNotifications !== 0 && (
            <Notifications>{unreadNotifications}</Notifications>
          )}
        </NotificationsWrapper>
        <Profile
          to='/profile'
          active={(location.pathname === '/profile').toString()}
        >
          {userData && userData.name ? initials(userData.name) : '--'}
        </Profile>
      </ProfileWrapper>
    </MobileNav>
  );
};

export default MobileAppNavHeader;
