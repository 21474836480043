import logo from './assets/logo.png';

const companyConfig = {
  appName: 'llane',
  appIcon: 'llane',
  logo: logo,
  companyName: 'Laura Lane',
  registeredOffice: ['5C Church Square', 'Market Harborough', 'LE16 7NB'],
  email: 'lauralanemakeup@outlook.com',
  facebook: 'https://www.facebook.com/lauralanemakeup/',
  twitter: 'https://twitter.com/lauralanemakeup',
  instagram: 'https://www.instagram.com/lauralanemakeup/',
  author: 'Laura Lane',
  keywords: 'Stylist, Makeup, Brushes',
  description: 'Laura Lane',
  mailingTitle: 'Join our mailing list',
  subscribeLabel: 'Subscribe',
  loadingLabel: 'Sending...',
  completeLabel: 'Thanks, all signed up.',
};

export default companyConfig;
