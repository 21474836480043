import cover from './unhappywall.JPG';
import Rachel from './rachellane.jpg';

const NoFaultDivorce = {
  title: 'No fault divorce comes to England and Wales',
  date: '20/09/2021',
  readTime: 3,
  image: cover,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['planning', 'settlement'],
  intro:
    'The Divorce, Dissolution and Separation Act 2020 should have been implemented in autumn 2021. However, in June the government pushed back the start date to April 2022. The delay, plus the after-effects of lockdown, could mean a divorce boom in England and Wales next year.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'The Divorce, Dissolution and Separation Act 2020 should have been implemented in autumn 2021. However, in June the government pushed back the start date to April 2022. The delay, plus the after-effects of lockdown, could mean a divorce boom in England and Wales next year.',
      },
    ],
    [
      {
        content:
          'The Act removes the existing requirements for requesting divorce in England and Wales which currently must fulfil one of five ‘facts’:',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'two years\' separation with the consent of the other spouse to divorce; ',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'five years\' separation without consent;',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'unreasonable behaviour;',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'adultery; and',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'desertion',
      },
    ],
    [
      {
        content:
          'In 2019, the most common ground for oppositesex divorce was unreasonable behaviour, covering 35% of petitions from men and 49% from women (who accounted for close to two thirds of divorce applications in 2019).',
      },
    ],
    [
      {
        content:
          'This has consistently been the most common reason given for wives petitioning for divorce since the late 1970s and for husbands since 2006. However, unreasonable behaviour, like adultery and desertion, can be contested, leading to potentially ugly and expensive court battles',
      },
    ],
    [{type: 'heading', content: 'Simplified rules'}],
    [
      {
        content:
          'The Act replaces the five ‘facts’ with a single requirement to provide a statement of irretrievable breakdown, which can be made by one spouse or jointly. If it is made individually, the other spouse will not be able to contest the divorce. The process should normally take about six months from the start of legal proceedings. Many couples contemplating divorce either on separation or other grounds may now be waiting for April 2022.',
      },
    ],
    [
      {
        content:
          'These changes will not affect Scotland, which revised its divorce law in 2006, or Northern Ireland, which has similar five ‘facts’ rules to England.',
      },
    ],
    [{type: 'heading', content: 'Financial settlements'}],
    [
      {
        content:
          'One key aspect of divorce is not changing: the need to reach a financial settlement. This not only involves the family home and investments, but also the pensions of both parties. In later life divorces, these can be the most valuable single asset – a £10,000 a year deferred pension could be worth close to £250,000.',
      },
    ],
    [
      {
        content:
          'Dealing with pensions is a complex area, which needs to be integrated with other, post-divorce financial planning. There is no one-size-fits-all solution, so taking personal financial advice is essential',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investment and any income from it can go down as well as up and you may not get back the full amount you invested.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Occupational pension schemes are regulated by The Pensions Regulator',
      },
    ],
  ],
};

export default NoFaultDivorce;
