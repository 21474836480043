import React, { Fragment, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import slugify from 'slugify';
import { HelmetProvider } from 'react-helmet-async';

import ConfigContext from './context/config/configContext';

import ConfigState from './context/config/ConfigState';
import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';
import MainrecordState from './context/mainrecord/MainrecordState';
import ShoppingcartState from './context/shoppingcart/ShoppingcartState';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import MainrecordRoute from './routes/MainrecordRoute';

import StylesWrapper from './components/Layout/StylesWrapper';

import login from './pages/login';
import register from './pages/register';
import verify from './pages/verify';
import claimaccount from './pages/claimaccount';
import success from './pages/success';

import Profile from './pages/profile';
import Dashboard from './pages/dashboard';

import MainAdminPage from './components/PageShells/MainAdminPage';
import RecordPage from './components/PageShells/RecordPage';
import ComingSoon from './components/Widgets/ComingSoon';

import ScrollToTop from './components/Layout/ScrollToTop';
import MainPublicPage from './components/PageShells/MainPublicPage';
import BlogTemplate from './components/Blogs/BlogTemplate';
import HelmetItems from './components/Layout/HelmetItems';

//todo - refactor app routes to loop over config and pass config to component in the same way as public - remove the need to look up path->config in component
const App = () => {
  return (
    <HelmetProvider>
      <ConfigState>
        <AuthState>
          <ShoppingcartState>
            <MainrecordState>
              <AlertState>
                <StylesWrapper>
                  <Router>
                    <ScrollToTop />
                    <HelmetItems />
                    <Switch>
                      <Route exact path='/login' component={login} />
                      <Route exact path='/register' component={register} />
                      <Route
                        exact
                        path='/claimaccount'
                        component={claimaccount}
                      />
                      <Route exact path='/verify-email' component={verify} />
                      <Route exact path='/success' component={success} />
                      <PrivateRoute
                        path='/dashboard'
                        component={Dashboard}
                        exact
                      />
                      <PrivateRoute path='/profile' component={Profile} exact />
                      <PrivateRoute
                        path='/users'
                        component={MainAdminPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/usersrecord'
                        component={RecordPage}
                        exact
                      />
                      <PrivateRoute
                        path='/contacts'
                        component={MainAdminPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/contactsrecord'
                        component={RecordPage}
                        exact
                      />
                      <PrivateRoute
                        path='/accounts'
                        component={MainAdminPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/accountsrecord'
                        component={RecordPage}
                        exact
                      />
                      <PrivateRoute
                        path='/opportunities'
                        component={MainAdminPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/opportunitiesrecord'
                        component={RecordPage}
                        exact
                      />
                      <PrivateRoute
                        path='/reviews'
                        component={MainAdminPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/reviewsrecord'
                        component={RecordPage}
                        exact
                      />
                      <PrivateRoute
                        path='/tasks'
                        component={MainAdminPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/tasksrecord'
                        component={RecordPage}
                        exact
                      />
                      <PrivateRoute
                        path='/compliance'
                        component={MainAdminPage}
                        exact
                      />
                      <PrivateRoute
                        path='/compliancerecord'
                        component={RecordPage}
                        exact
                      />
                      <PrivateRoute
                        path='/notifications'
                        component={ComingSoon}
                        exact
                      />
                      <PrivateRoute
                        path='/notificationsrecord'
                        component={ComingSoon}
                        exact
                      />
                      <PrivateRoute
                        path='/support'
                        component={ComingSoon}
                        exact
                      />
                      <MainrecordRoute
                        path='/addressrecord'
                        component={RecordPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/connectedpersonrecord'
                        component={RecordPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/employmentrecord'
                        component={RecordPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/incomerecord'
                        component={RecordPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/liabilityrecord'
                        component={RecordPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/schemerecord'
                        component={RecordPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/assetrecord'
                        component={RecordPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/insurancerecord'
                        component={RecordPage}
                        exact
                      />
                      <MainrecordRoute
                        path='/schemesrecord'
                        component={RecordPage}
                        exact
                      />
                      <PublicPages />
                    </Switch>
                  </Router>
                </StylesWrapper>
              </AlertState>
            </MainrecordState>
          </ShoppingcartState>
        </AuthState>
      </ConfigState>
    </HelmetProvider>
  );
};

const PublicPages = () => {
  const configContext = useContext(ConfigContext);
  return (
    <Fragment>
      {!!configContext.config &&
        configContext.config.publicConfig &&
        configContext.config.publicConfig.map((page, index) => (
          <PublicRoute
            path={page.route}
            component={MainPublicPage}
            exact
            key={index}
            config={page}
          />
        ))}
      {!!configContext.config &&
        configContext.config.newsConfig.content &&
        !!configContext.config.newsConfig.content[0].posts &&
        configContext.config.newsConfig.content[0].posts.map((post, index) => (
          <PublicRoute
            path={`/news/${slugify(post.title)}`}
            component={BlogTemplate}
            exact
            key={index}
            config={post}
          />
        ))}
    </Fragment>
  );
};

export default App;
