import React from 'react';
import styled from 'styled-components';

import { Button } from '../Controls';
import Icon from '../UI/Icon';
import Breadcrumbs from './Breadcrumbs';

const Container = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  padding: 2rem;
  padding-bottom: 0;
  @media only screen and (max-width: 800px) {
    padding: 1rem;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    button {
      margin-top: 0.5rem;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: flex-end;
  p {
    margin-left: 0.5rem;
    font-size: 0.9rem;
    padding-bottom: 0.2rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
  h1 {
    font-weight: bold;
    font-size: 1.7rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Tabs = styled.div`
  display: flex;
  overflow-x: auto;
`;

const Tab = styled.div`
  white-space: nowrap;

  margin-right: 1rem;
  margin-top: 1rem;
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 300;
  padding: 0.5rem;
  padding-right: 2rem;
  color: ${(props) =>
    props.active === 'true'
      ? `rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : `rgba(${props.theme.colors.neutral[1]}, 1)`};
  border-bottom: ${(props) =>
    props.active === 'true'
      ? `1px solid rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : 'none'};
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const HeaderWithActionsAndTabs = ({
  title,
  btnLabel,
  count,
  tabs,
  tabSelected,
  setTabSelected,
  name,
  setToggleAdd,
  disableAddRecord,
  breadcrumbConfig,
  currentRecord,
}) => {
  return (
    <Container>
      <TopRow>
        <Title>
          <h1>{title}</h1>
          {!!count && count !== 0 && (
            <p>
              ({!count ? 0 : count} {name}
              {count !== 1 && 's'})
            </p>
          )}
        </Title>
        {setToggleAdd && !disableAddRecord && (
          <Button
            color='linkBlue'
            fontsize={1}
            onClick={() => {
              setToggleAdd(true);
            }}
          >
            <Icon
              iconType='plusSign'
              size={1.2}
              color='light'
              margin='auto 0.5rem auto auto'
            />
            {btnLabel}
          </Button>
        )}
      </TopRow>
      {!!breadcrumbConfig && (
        <Breadcrumbs
          breadcrumbConfig={breadcrumbConfig}
          currentRecord={currentRecord}
        />
      )}
      <Tabs>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            active={(tab === tabSelected).toString()}
            onClick={() => {
              setTabSelected(tab);
            }}
          >
            {tab}
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default HeaderWithActionsAndTabs;
