import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Toggle } from '../Controls';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  label {
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
  input {
    width: 100%;
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
    outline: none;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
    border-radius: 10px;
    &:focus,
    &:active {
      outline: none;
      color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
      border: ${(props) =>
        `1px solid rgba(${props.theme.colors.success[0]},1)`};
    }
  }
  select {
    width: 100%;
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
    outline: none;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
    border-radius: 10px;
    &:focus,
    &:active {
      outline: none;
      color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
      border: ${(props) =>
        `1px solid rgba(${props.theme.colors.success[0]},1)`};
    }
  }
`;

const Mandatory = styled.span`
  margin-left: 0.5rem;
  font-weight: 300;
  font-size: 0.8rem;
  color: ${(props) => `rgba(${props.theme.colors.danger[2]},1)`};
`;

const ToggleGroup = styled.div`
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-right: 1rem;
    }
  }
`;

const ToggleLabel = styled.div`
  margin-right: 2rem;
`;
const StyledTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  font-family: ${(props) => props.theme.fonts.mainFontFamily};
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background: ${(props) =>
    props.important === 'true'
      ? `rgba(${props.theme.colors.danger[0]}, 0.1)`
      : `rgba(${props.theme.colors.neutral[5]}, 1)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
  outline: none;
  border: ${(props) => `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
  border-radius: 10px;
  &:focus,
  &:active {
    outline: none;
    color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
    border: ${(props) => `1px solid rgba(${props.theme.colors.success[0]},1)`};
  }
`;

const ReadOnly = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
`;

const InputGroup = ({
  name,
  dataItemConfig,
  dataItem,
  handleChange,
  handleBlur,
  mandatory,
  handleToggle,
  overrideReadOnly,
}) => {
  return (
    <Wrapper>
      {!!overrideReadOnly || !dataItemConfig.readOnly ? (
        <Fragment>
          {dataItemConfig.type !== 'textArea' &&
            dataItemConfig.type !== 'boolean' && (
              <label>
                {dataItemConfig.label}
                {!!mandatory && dataItem === '' && (
                  <Mandatory>Required</Mandatory>
                )}
                {!!mandatory && dataItem !== '' && (
                  <Icon
                    iconType='checkCircle'
                    size={1}
                    color='success'
                    margin='0  0 0 0.5rem'
                  ></Icon>
                )}
              </label>
            )}
          {(dataItemConfig.type === 'email' ||
            dataItemConfig.type === 'text' ||
            dataItemConfig.type === 'number' ||
            dataItemConfig.type === 'date' ||
            dataItemConfig.type === 'tel') && (
            <input
              important={dataItemConfig.important ? 'true' : 'false'}
              type={dataItemConfig.type}
              name={name}
              value={dataItem}
              onBlur={(e) => {
                !!handleBlur && handleBlur(e);
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          )}
          {dataItemConfig.type === 'textArea' && (
            <>
              {dataItemConfig.includeTitle && (
                <label>{dataItemConfig.label}</label>
              )}
              <StyledTextArea
                important={dataItemConfig.important ? 'true' : 'false'}
                name={name}
                placeholder='Type here...'
                value={dataItem}
                onBlur={(e) => {
                  !!handleBlur && handleBlur(e);
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </>
          )}
          {dataItemConfig.type === 'boolean' && (
            <ToggleGroup>
              <ToggleLabel>{dataItemConfig.label}</ToggleLabel>
              <div>
                <p> {!!dataItem ? 'Yes' : 'No'}</p>
                <Toggle
                  itemRef={name}
                  value={!!dataItem ? dataItem : false}
                  change={handleToggle}
                />
              </div>
            </ToggleGroup>
          )}
          {dataItemConfig.type === 'select' && (
            <>
              <select
                name={name}
                value={dataItem}
                onBlur={(e) => {
                  !!handleBlur && handleBlur(e);
                }}
                onChange={(e) => handleChange(e)}
              >
                <option value='' disabled>
                  Select Option
                </option>
                {dataItemConfig.valueOptions.map((option, index) => (
                  <option
                    key={'opt' + (!!option.value ? option.value : index)}
                    value={!!option.value ? option.value : index}
                  >
                    {!!option.label ? option.label : option}
                  </option>
                ))}
              </select>
            </>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <label>{dataItemConfig.label}</label>
          <ReadOnly>
            <Icon
              iconType='profile'
              size={1.5}
              color='primary'
              margin='0 0.25rem 0 0'
            />
            {(dataItem === '' || dataItem === undefined) && 'No Data'}
            {dataItemConfig.type === 'select' &&
              dataItemConfig.valueOptions[dataItem] &&
              dataItemConfig.valueOptions[dataItem].label &&
              dataItemConfig.valueOptions[dataItem].label}
            {dataItemConfig.type === 'select' &&
              dataItemConfig.valueOptions[dataItem] &&
              !dataItemConfig.valueOptions[dataItem].label &&
              dataItemConfig.valueOptions[dataItem]}
            {dataItem !== '' &&
              dataItem !== undefined &&
              dataItemConfig.type !== 'select' &&
              dataItem}
          </ReadOnly>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default InputGroup;
