import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthContext from '../context/auth/authContext';

import PublicLayout from '../components/Layout/PublicLayout';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading } = authContext;

  return (
    <Route
      {...rest}
      render={(props) =>
        !!isAuthenticated && !loading ? (
          <Redirect to='/dashboard' />
        ) : (
          <PublicLayout>
            <Component {...props} config={rest.config} />
          </PublicLayout>
        )
      }
    />
  );
};

export default PrivateRoute;
