import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  padding: 2rem 1rem;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 2rem 1rem 2rem 0;
  }
`;

const Tile = styled(Link)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.boxShadow};
  margin: 1rem;
  border-radius: 7px;
  padding-bottom: 1rem;
  img {
    width: 100%;
    height: 10rem;
    object-fit: cover;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
  }
  h4 {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 1rem 1rem 0.5rem 1rem;
  }
  p {
    padding: 0 1rem 1rem 1rem;
    font-size: 0.9rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
  }
  div {
    display: flex;
    align-items: center;
    margin: 0 1rem;
    font-size: 0.9rem;
    color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]},1)`};
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
    div {
      svg {
        margin-left: 2rem;
      }
      font-weight: bold;
    }
  }
`;

const TwoByTwoFeaturesPhoto = ({ config }) => {
  return (
    <Wrapper>
      {config.features.map((item, index) => (
        <Tile key={index} to={item.route}>
          <img src={item.img} alt={item.title} />
          <h4>{item.title}</h4>
          <p>{item.text}</p>
          <div>
            More
            <Icon
              iconType='chevron-right'
              size={1}
              color='linkBlue'
              margin='auto'
            />
          </div>
        </Tile>
      ))}
    </Wrapper>
  );
};

export default TwoByTwoFeaturesPhoto;
