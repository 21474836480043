import Image from './image.png';
import Newsletter from './newsletter.pdf';
import Rachel from './rachellane.jpg';

const summernewsletter = {
  title: 'Financial Focus - Summer 2021 Newsletter',
  date: '23/08/2020',
  readTime: 15,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['summer 2021', 'IHT', 'Investing'],
  intro:
    'After the last 18 months, looking ahead is very appealing to many of us. However one critical area of future financial planning is often overlooked. Since the inheritance tax (IHT) threshold is now frozen for the next five years, many more estates will be subject to the tax. Our feature looks at the current rules surrounding IHT, what you can do now to mitigate your liability and possible changes coming in the future. Such forward tax planning can also make a difference in the short term. Ensuring that you and your partner make the most of existing tax allowances and reliefs can make a difference to your family finances as we also explore. And we look at keeping on top of portfolio management - both the importance of active reviews in maintaining the diversity of your investments, and understanding what some of the ‘green’ labelling on increasingly popular sustainable investments really means.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default summernewsletter;
