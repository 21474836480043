import React, { Fragment, useState } from 'react';
import TestimonialWithVideo from '../Features/TestimonialWithVideo';
import AssumptionsFooter from '../Journey/AssumptionsFooter';
import DataCaptureStep from '../Journey/DataCaptureStep';
import DataDisplayStep from '../Journey/DataDisplayStep';

const PublicJourneyShell = ({ config }) => {
  //handle journey steps
  const [currentJourneyStep, setCurrentJourneyStep] = useState(0);
  const handleNextStep = () => {
    setCurrentJourneyStep(currentJourneyStep + 1);
  };

  //handle data
  const [journeyData, setJourneyData] = useState({});

  setJourneyData({});

  return (
    <Fragment>
      {currentJourneyStep === 0 ? (
        <TestimonialWithVideo
          config={config.intro}
          journeyIncrement={handleNextStep}
        />
      ) : config.journey[currentJourneyStep - 1].type === 'data-capture' ? (
        <DataCaptureStep
          config={config.journey[currentJourneyStep - 1]}
          data={journeyData}
        />
      ) : (
        <DataDisplayStep
          config={config.journey[currentJourneyStep - 1]}
          data={journeyData}
        ></DataDisplayStep>
      )}
      <AssumptionsFooter config={config.assumptions} />
    </Fragment>
  );
};

export default PublicJourneyShell;
