import React, { useContext, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import AuthContext from '../context/auth/authContext';
import MainrecordContext from '../context/mainrecord/mainrecordContext';

import AppLayout from '../components/Layout/AppLayout';
import PageLoader from '../components/Feedback/PageLoader';

const MainrecordRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const pageReference = location.pathname.replace(/^\/|\/$/g, '').slice(0, -6);
  const mainrecordContext = useContext(MainrecordContext);
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, user, userData, loadUser, updateUserData } =
    authContext;

  useEffect(() => {
    !userData && loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        (!isAuthenticated || !user) && !loading ? (
          <Redirect to='/login' />
        ) : !!user && !user.emailVerified ? (
          <Redirect to='/verify-email' />
        ) : !mainrecordContext.current ||
          !mainrecordContext.current.recordType ||
          mainrecordContext.current.recordType !== pageReference ? (
          <Redirect to='/dashboard' />
        ) : !!mainrecordContext.current ? (
          <AppLayout userData={userData}>
            <Component
              {...props}
              userData={userData}
              updateUserData={updateUserData}
            />
          </AppLayout>
        ) : (
          <AppLayout userData={userData}>
            <PageLoader />
          </AppLayout>
        )
      }
    />
  );
};

export default MainrecordRoute;
