import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

import HeroWithImageBackground from '../Heros/HeroWithImageBackground';
import HeroWithVideoBackground from '../Heros/HeroWithVideoBackground';
import TwoByTwoFeatures from '../Features/TwoByTwoFeatures';
import TwoByTwoFeaturesPhoto from '../Features/TwoByTwoFeaturesPhoto';
import CenteredTestimonial from '../Testimonials/Centered';
import LeftTextWithBackgroundImg from '../Scrollover/LeftTextWithBackgroundImg';
import TextWithBackgroundImg from '../Scrollover/TextWithBackgroundImg';
import WithImages from '../Tiles/WithImages';
import TeamWithCirclePhoto from '../Team/TeamWithCirclePhoto';
import FeatureWithImage from '../Features/FeatureWithImage';
import FeatureWithBullets from '../Features/FeatureWithBullets';
import PublicVertical from '../Steps/PublicVertical';
import HeroContactForm from '../Heros/HeroContactForm';
import TermsSection from '../TermsConditions/TermsSection';
import HeroNewsPage from '../Heros/HeroNewsPage';
import SimpleSideBySide from '../Tiles/SimpleSideBySide';
import SocialsMailingList from '../Banners/SocialsMailingList';
import FeaturesWithTitleText from '../Features/FeaturesWithTitleText';
import FeaturesImagesTitleOnly from '../Features/FeaturesImagesTitleOnly';
import ProductWithImage from '../ECommerce/ProductWithImage';
import Checkout from '../ECommerce/Checkout';
import PublicJourneyShell from './PublicJourneyShell';

const MainPublicPage = ({ config }) => {
  return (
    !!config && (
      <Fragment>
        <Helmet>
          <title>{config.title}</title>
        </Helmet>
        {config.content.map((item, index) => (
          <Fragment key={index}>
            {item.component === 'hero-with-image-background' && (
              <HeroWithImageBackground config={item} />
            )}
            {item.component === 'hero-with-video-background' && (
              <HeroWithVideoBackground config={item} />
            )}
            {item.component === 'features-two-by-two' && (
              <TwoByTwoFeatures config={item} />
            )}
            {item.component === 'features-two-by-two-photo' && (
              <TwoByTwoFeaturesPhoto config={item} />
            )}
            {item.component === 'testimonial-centered' && (
              <CenteredTestimonial config={item} />
            )}
            {item.component === 'scrollover-text-with-background-img' && (
              <TextWithBackgroundImg config={item} />
            )}
            {item.component === 'scrollover-left-text-with-background-img' && (
              <LeftTextWithBackgroundImg config={item} />
            )}
            {item.component === 'tiles-with-images' && (
              <WithImages config={item} />
            )}
            {item.component === 'team-with-circle-photo' && (
              <TeamWithCirclePhoto config={item} />
            )}
            {item.component === 'features-with-image' && (
              <FeatureWithImage config={item} />
            )}
            {item.component === 'features-with-bullets' && (
              <FeatureWithBullets config={item} />
            )}
            {item.component === 'steps-publicvertical' && (
              <PublicVertical config={item} />
            )}
            {item.component === 'hero-contactform' && (
              <HeroContactForm config={item} />
            )}
            {item.component === 'termsconditions-termssections' && (
              <TermsSection config={item} />
            )}
            {item.component === 'hero-newspage' && (
              <HeroNewsPage config={item} />
            )}
            {item.component === 'tiles-simple-side-by-side' && (
              <SimpleSideBySide config={item} />
            )}
            {item.component === 'banner-socials-with-mailinglist' && (
              <SocialsMailingList config={item} />
            )}
            {item.component === 'features-with-title-text' && (
              <FeaturesWithTitleText config={item} />
            )}
            {item.component === 'features-images-title-only' && (
              <FeaturesImagesTitleOnly config={item} />
            )}
            {item.component === 'product-with-image' && (
              <ProductWithImage config={item} />
            )}
            {item.component === 'ecommerce-checkout' && (
              <Checkout config={item} />
            )}
            {item.component === 'public-journey' && (
              <PublicJourneyShell config={item} />
            )}
          </Fragment>
        ))}
      </Fragment>
    )
  );
};

export default MainPublicPage;
