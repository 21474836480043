import SeminarImg from './seminars.jpg';
import Rachel from './rachellane.jpg';

const portfoliorelabance = {
  title: 'Portfolio Rebalance - Spring 2021',
  date: '28/04/2021',
  readTime: 43,
  image: SeminarImg,
  type: 'Seminar',
  typeColor: 'primary',
  tags: ['Investing', 'Portfolio'],
  intro:
    'Join us with Ed Margot, Investment Strategist for FE Investments, for a market update and a look at how the portfolios have adapted to suit current market conditions. Following the portfolio rebalance the previous week Ed will be talking us through how the funds are being deployed to make the most of the opportunities available. This seminar is ideal for anyone invested in our portfolios or for anyone simply looking to understand more about the current market challenges and potential successes.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'Join us with Ed Margot, Investment Strategist for FE Investments, for a market update and a look at how the portfolios have adapted to suit current market conditions. ',
      },
    ],
    [
      {
        content:
          'Following the portfolio rebalance the previous week Ed will be talking us through how the funds are being deployed to make the most of the opportunities available. This seminar is ideal for anyone invested in our portfolios or for anyone simply looking to understand more about the current market challenges and potential successes.',
      },
    ],
  ],
};

export default portfoliorelabance;
