import CompanyLogo from './assets/logo.png';
import styling from './styling';

import publicNavConfig from './public/publicNavConfig';
import footerConfig from './public/footerConfig';
import homeConfig from './public/homeConfig';
import aboutConfig from './public/aboutConfig';
import companyConfig from './companyConfig';
import approachConfig from './public/approachConfig';
import personalConfig from './public/personalConfig';
import corporateConfig from './public/corporateConfig';
import contactConfig from './public/contactConfig';
import termsConfig from './public/termsConfig';
import newsConfig from './public/news/newsConfig';
import homebuyerConfig from './public/homebuyerConfig';
import homebuyerCalculatorConfig from './public/homebuyerCalculatorConfig';

const baseConfig = {
  tenant: 'teamrapport',
  companyLogo: CompanyLogo,
  companyConfig: companyConfig,
  styling: styling,
  newsConfig: newsConfig,
  publicConfig: [
    homeConfig,
    aboutConfig,
    approachConfig,
    personalConfig,
    corporateConfig,
    contactConfig,
    termsConfig,
    newsConfig,
    homebuyerConfig,
    homebuyerCalculatorConfig,
  ],
  publicNavConfig: publicNavConfig,
  publicFooterConfig: footerConfig,
};

export default baseConfig;
