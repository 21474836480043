import Image from './image.png';
import Newsletter from './autumn_statement_2022.pdf';
import Rachel from './rachellane.jpg';

const summernewsletter = {
  title: 'Autumn Statement - 2022',
  date: '18/11/2022',
  readTime: 15,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['Tax', 'Government', 'Inflation'],
  intro:
    'The message from the Chancellor, Jeremy Hunt, in the days before he rose to the despatch box in the House of Commons to deliver the Autumn Statement was clear; he would be outlining billions of pounds of tax rises and spending cuts.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default summernewsletter;
