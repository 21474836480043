import icon from '../assets/icon.png';
import rachellane from '../assets/rachellane.jpg';
import Calculator from '../assets/calculator.jpg';
import atTable from '../assets/atTable.jpg';

const homebuyerCalculatorConfig = {
  route: '/homebuyer-calculator',
  title: 'Group Rapport | Home Buyer Calculator',
  content: [
    {
      component: 'public-journey',
      assumptions: {
        title: 'Important Information & Assumptions',
        content: [
          'Mortgages are complex and the information provided here is only a guide, for full personal advice on your own circumstances you should always speak to an adviser.',
          'This calculator uses several assumptions which may not accruately reflect your own position',
          'This calculator should not be used to make financial decisions or for tax planning.',
        ],
      },
      intro: {
        mainImg: Calculator,
        title: 'Home Buyer Calculator',
        subTitle:
          'Use our mortgage calculators to work out how much you could borrow and how much deposit you need for a mortgage.',
        cta: 'Get Started',
        ctaType: 'Journey-Increment',
        testimonial: {
          logo: icon,
          name: 'Rachel Lane',
          company: 'Group Rapport',
          role: 'Sales & Marketing Director',
          image: rachellane,
          quote:
            "We've designed this calculator to be the starting point for everyones home buying journey.",
        },
        bullets: [
          {
            title: 'How Much Can I Borrow?',
            content:
              'See how much you could afford to borrow, based on your current circumstances.',
          },
          {
            title: 'What Will It Cost?',
            content:
              'Work out what your monthly payments might be, together with the costs of running the house.',
          },
          {
            title: 'Can I Afford It?',
            content:
              'Check how an interest rate change could affect you and see if it stacks up financially.',
          },
        ],
      },
      journey: [
        {
          type: 'data-capture',
          title: 'About You',
          help: {
            img: atTable,
            title: 'All about you',
            content: [
              "We'll use your information to personalise caluclations to you circumstances",
              'This calculator is only a guide, so feel free to enter estimates, you can always refine things later.',
            ],
          },
          data: [
            {
              name: 'age',
              type: 'number',
              min: 18,
              max: 65,
              step: 1,
              label: 'Age',
              tooltip:
                'Your age can affect the mortgages which you are eligble for',
            },
            {
              name: 'joint',
              type: 'boolean',
              label: 'Is this a joint application',
              tooltip:
                'Will you be buying the property with someone else and so have two names on the mortgage application?',
            },
          ],
        },
      ],
    },
  ],
};

export default homebuyerCalculatorConfig;
