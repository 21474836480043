import contactsEditRecordConfig from './contactsEditRecordConfig';
import contactsDataConfig from './contactsDataConfig';

const stages = ['Lead', 'Prospect', 'Complete'];
const outcomes = [
  'Lead',
  'Premier Plus (P+)',
  'Premier Planning (PP)',
  'Premier Transact (PT)',
  'Premier Corporate (PC)',
  'Activeplan+',
  'No Longer Client',
];

const contactsConfig = {
  title: 'Private Clients',
  name: 'Client',
  breadcrumbs: [
    { type: 'simpleLink', label: 'Private Clients', route: '/contacts' },
    { type: 'currentRecord' },
  ],
  addBtnLabel: 'Add Client',
  tabs: ['manage', 'analyse'],
  manageTabConfig: {
    filterByLabel: 'Name',
    tableConfig: [
      { label: 'Name', ref: 'name' },
      { label: 'Client Adviser', ref: 'clientAdviserID', useAdditional: true },
      { label: 'Admin', ref: 'adminID', useAdditional: true },
      { label: 'Firm', ref: 'tenant' },
      { label: 'Status', ref: 'stage' },
      { label: 'Type', ref: 'outcome' },
    ],
  },
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Clients' },
  },
  newDataSteps: [
    {
      title: 'Introduction',
      guidance:
        'Add some basic details about your new lead. Once you create the new record you will be taken to the full client record, where additional details can be added; including New Business Cases & Fact Find information.',
    },
    {
      title: 'Personal Details',
      guidance: "Please fill out these details and click 'Next'",
      data: [{ ref: 'title' }, { ref: 'name', mandatory: true }],
    },
    {
      title: 'Contact Details',
      data: [
        { ref: 'email', mandatory: true },
        { ref: 'mobilePhoneNumber' },
        { ref: 'homePhoneNumber' },
        { ref: 'workPhoneNumber' },
      ],
    },
    {
      title: 'Lead Details',
      guidance:
        'Details about the source of this client and the assigned Administrator',
      data: [
        { ref: 'leadReceivedDate', mandatory: true },
        { ref: 'source', mandatory: true },
        { ref: 'adminID', mandatory: true },
      ],
    },
    {
      title: 'Client Adviser',
      guidance:
        'State the introducing / client adviser who will own the overall relationship with this client. Note, a different adviser can be added for each new business case if required.',
      data: [{ ref: 'clientAdviserID', mandatory: true }],
    },
    {
      title: 'Notes',
      guidance:
        'Add any notes you want to record about this lead and display on the case information to the rest of the team.',
      data: [{ ref: 'contactNotes' }],
    },
  ],
  stages: stages,
  outcomes: outcomes,
  recordEditTabs: [
    'summary',
    'cases',
    'personal details',
    'expenditure',
    'documents',
  ],
  recordEditConfig: contactsEditRecordConfig,
  data: contactsDataConfig,
  documents: {
    clientAgreement: {
      type: 'document',
      label: 'Client Agreement',
      guidance: 'Optional. Upload a signed Client Agreement.',
      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'version', label: 'Version' },
      ],
      saveFolder: 'clientdocuments',
    },
    letterOfAuthority: {
      type: 'document',
      label: 'Letter(s) of Authority Agreement',
      guidance: 'Optional. Upload a signed LOA.',
      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'provider', label: 'Provider' },
      ],
      saveFolder: 'clientdocuments',
    },
    general: {
      type: 'document',
      label: 'Other Documents',

      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'type', label: 'Document Type' },
      ],
      saveFolder: 'clientdocuments',
    },
  },
};

export default contactsConfig;
