import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../Controls';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  margin-bottom: -1rem;
`;

const Notice = styled.div`
  width: 100%;
  background: ${(props) => `rgba(${props.theme.colors.danger[2]}, 1)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 2rem;
  margin-top: 2px;
  font-size: 0.8rem;
  align-items: center;
`;

const Flexi = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem 1rem 0 1rem;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    width: 100%;
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
    outline: none;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
    border-radius: 10px;
    &:focus,
    &:active {
      outline: none;
      color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
      border: ${(props) =>
        `1px solid rgba(${props.theme.colors.success[0]},1)`};
    }
    margin: 0.5rem 1rem;
  }
`;

const ImportantNotes = ({ handleImportantNotes, data }) => {
  const [localData, setLocalData] = useState([]);
  const [active, setActive] = useState(false);
  const [current, setCurrent] = useState('');
  useEffect(() => {
    !!data && !!data.importantNotes && setLocalData(data.importantNotes);
    !!data && !data.importantNotes && setLocalData([]);
  }, [data]);

  const handleSubmit = () => {
    if (!!current) {
      const update = localData;
      update.push({ value: current });
      handleImportantNotes(update);
      setActive(false);
      setCurrent('');
    } else setActive(false);
  };
  return (
    <Wrapper>
      {!!localData.length &&
        localData.map((item, index) => (
          <Notice key={index}>
            <Flexi>
              <Icon
                iconType='stickyNote'
                size={1.3}
                color='light'
                margin='0 0.5rem 0 0'
              />
              {item.value}
            </Flexi>
          </Notice>
        ))}
      {!!active && (
        <InputWrapper>
          <input
            type='text'
            placeholder='Type here...'
            value={current}
            onChange={(e) => {
              setCurrent(e.target.value);
            }}
          />
        </InputWrapper>
      )}
      {!active && (
        <BtnGroup>
          <Button
            color='linkBlue'
            size='small'
            fontSize={0.8}
            margin='4px 4px 0px 0px'
            onClick={() => {
              setActive(true);
            }}
          >
            <Icon
              iconType='stickyNote'
              size={1}
              color='light'
              margin='0 0.5rem 0 0'
            />
            Add Sticky Note
          </Button>
        </BtnGroup>
      )}
      {!!active && (
        <BtnGroup>
          <Button
            size='small'
            fontSize={0.8}
            color='linkBlue'
            onClick={() => {
              handleSubmit();
            }}
          >
            <Icon
              iconType='plus'
              size={1}
              color='light'
              margin='0 0.5rem 0 0'
            />
            Add Note
          </Button>
          <Button
            color='light'
            size='small'
            fontSize={0.8}
            onClick={() => {
              setActive(false);
              setCurrent('');
            }}
          >
            <Icon
              iconType='close'
              size={1}
              color='linkBlue'
              margin='0 0.5rem 0 0'
            />
            Cancel
          </Button>
        </BtnGroup>
      )}
    </Wrapper>
  );
};

export default ImportantNotes;
