import forestpic from './forest.jpg';
import Rachel from './rachellane.jpg';

const greenwashing = {
  title: 'Responsible investing and greenwashing',
  date: '03/08/2020',
  readTime: 3,
  image: forestpic,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Investing', 'Environmental'],
  intro:
    'There has been huge growth in the number of ‘sustainable’ and ‘responsible investment’ funds, which now look at a company’s environmental, social and governance (ESG) track record as part of the investment process. However not every ‘green’ label should be taken at face value',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'There has been huge growth in the number of ‘sustainable’ and ‘responsible investment’ funds, which now look at a company’s environmental, social and governance (ESG) track record as part of the investment process. However not every ‘green’ label should be taken at face value',
      },
    ],
    [
      {
        content:
          'In some cases these terms are simply being used as a marketing tool — a trend known as ‘greenwashing’. Investors might assume they are in a climate-friendly fund, but the reality could be quite different.',
      },
    ],
    [
      {
        content:
          'MPs are now calling on regulators to do more to address this issue and more widely there is a growing push for European regulators to police how the fund management industry reports ESG issues.',
      },
    ],
    [
      {
        content:
          'It is likely to be some time before there is regulation on this issue. Until then, investors will need to take a closer look at funds. This process isn’t always easy and is complicated by the raft of financial and technical jargon used. To select a fund that is aligned to your values it’s worth considering the following issues.',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Active or passive',
        content:
          'Passive funds may have limited ability to exclude stocks, but there are specialist indices weighted on carbon emissions as well as the FTSE4Good indices.',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Top ten holdings',
        content:
          ' Most funds publish their ten biggest holdings on fund fact sheets, giving an indication of where your money is invested.',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'commitments and pledges',
        content:
          'There are numerous organisations working with the financial industry to address climate change, such as Make My Money Matter, Climate Action 100+ and the Net Zero Asset Managers Initiative. Check whether a fund manager has signed up to the aims of one or more of these organisations.',
      },
    ],
    [
      {
        content:
          'For guidance on making your investments more sustainable please get in touch.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investment and the income from it can go down as well as up and you may not get back the full amount you invested. Past performance is not a reliable indicator of future performance. ',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Investing in shares should be regarded as a long-term investment and should fit with your overall attitude to risk and financial circumstances.',
      },
    ],
  ],
};

export default greenwashing;
