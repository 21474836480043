const contactsEditRecordConfig = [
  {
    reference: 'summary',
    tiles: [
      {
        title: 'Contact Details',
        subtitle:
          'Use the tabs at the top of the page to view more, full personal details can be found in the "Personal Details" tab.',
        data: ['name', 'email'],
      },
      {
        title: 'Team',
        data: ['adminID', 'clientAdviserID'],
      },
      {
        title: 'Tasks',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'tasks',
        },
      },
      {
        title: 'Timeline',
        namedComponent: {
          component: 'timeline',
        },
      },
      {
        title: 'Notes',
        data: ['contactNotes'],
      },
      {
        title: 'Lead Details',
        data: ['leadReceivedDate', 'source'],
      },
    ],
  },
  {
    reference: 'cases',
    tiles: [
      {
        title: 'Business Cases',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'opportunities',
        },
      },
      {
        title: 'Annual Reviews',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'reviews',
        },
      },
    ],
  },
  {
    reference: 'Client details',
    tiles: [
      {
        title: 'Personal Information',
        data: [
          'title',
          'name',
          'previousName',
          'healthStatus',
          'smokerStatus',
          'maritalStatus',
          'dateOfBirth',
          'gender',
          'nationality',
          'domicileStatus',
          'niNumber',
          'marginalTaxRate',
          'scottishTaxPayer',
        ],
      },
      {
        title: 'Contact Details',
        data: [
          'email',
          'homePhoneNumber',
          'workPhoneNumber',
          'mobilePhoneNumber',
        ],
      },
      {
        title: 'Contact Preferences',
        data: [
          'preferredContactMethod',
          'preferredContactDay',
          'preferredContactTime',
        ],
      },
      {
        title: 'Address Records',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'address',
        },
      },
      {
        title: 'Connected Person Records',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'connectedperson',
        },
      },
      {
        title: 'Employment Records',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'employment',
        },
      },
      {
        title: 'Income Records',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'income',
        },
      },
      {
        title: 'Credit History',
        data: [
          'adverseCreditHistory',
          'refusedMortgageLoan',
          'judgementForDebtDefault',
          'declaredBankrupt',
          'arrangementWithCreditors',
          'failedToKeepUpRepayments',
          'creditHistoryNotes',
        ],
      },
      {
        title: 'Liability Records',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'liability',
        },
      },
      {
        title: 'Asset Records',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'asset',
        },
      },
      {
        title: 'Insurance Records',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'insurance',
        },
      },
    ],
  },
  {
    reference: 'expenditure',
    tiles: [
      {
        namedComponent: {
          component: 'spendingPlanner',
          recordRef: 'expenditure',
        },
      },
    ],
  },
  {
    reference: 'documents',
    tiles: [
      {
        namedComponent: {
          component: 'document',
          recordRef: 'clientAgreement',
        },
      },
      {
        namedComponent: {
          component: 'document',
          recordRef: 'letterOfAuthority',
        },
      },
      {
        namedComponent: {
          component: 'document',
          recordRef: 'general',
        },
      },
    ],
  },
];

export default contactsEditRecordConfig;
