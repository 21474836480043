import Image from './image.png';
import Newsletter from './newsletter.pdf';
import Rachel from './rachellane.jpg';

const summernewsletter = {
  title: 'Financial Focus - Winter 2022 Newsletter',
  date: '15/12/2023',
  readTime: 15,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['winter 2022', 'IHT', 'Investing'],
  intro:
    "The Chancellor's Autumn Statement delivered both expectations and some surprises: two-year extensions on the tax threshold freezes, scaled back financial support for energy bills and cuts on both dividend allowances and capital gains exemptions. Our feature in this edition focuses on how these and other changes may affect your tax planning ahead of the new financial year. We also consider the effects of recent market behaviour on pensions, particularly the ‘mini-Budget’ in September. Whether you’re coming up for retirement or still making plans, fund turmoil and the rise of over 65s remaining in employment may spur you towards reviewing your retirement planning. Investors looking for income may be encouraged by recent improvements to government bond yields to investigate their fund holdings. Those with some surplus funds are increasingly helping their adult children with meeting cost of living needs, from new homes to daily expenses, essentially spending potential inheritance early. Such gifting should be done within the inheritance tax framework to ensure a tax-efficient outcome and minimal effect on your own standard of living in retirement.",
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default summernewsletter;
