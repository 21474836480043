import React, { Fragment, useState } from 'react';
import firebase, { db } from '../../context/firebase';
import styled from 'styled-components';
import { Button } from '../Controls';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  width: 100vw;
  overflow-x: hidden;
  min-height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(
    125deg, rgba(${props.theme.colors.primary[0]}, 0.97), rgba(${props.theme.colors.neutral[0]}, 0.55)), url(${props.bg}) `};
`;

const InnerWrap = styled.div`
  max-width: 1100px;
  margin: auto;
`;

const Container = styled.div`
  float: ${(props) => props.alignRight && 'right'};
  text-align: ${(props) => props.alignRight && 'right'};
  max-width: 650px;
  padding: 4rem 2rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.2rem;
  }

  h2 {
    padding: 1rem 0;
    font-weight: 300;
    font-size: 1.1rem;
  }

  @media only screen and (max-width: 800px) {
    padding: 7rem 2rem 2rem 2rem;
  }
`;

const FormWrapper = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.tertiary[2]}, 1)`};
  padding: 2rem;
  background: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 0.4)`};
  border-radius: 5px;
  h2 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
    font-weight: bold;
    font-size: 2rem;
  }
  h3 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    font-size: 1.4rem;
  }
  @media only screen and (max-width: 800px) {
    padding: 0;
    background: none;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  font-family: ${(props) => props.theme.fonts.mainFontFamily};
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background: ${(props) =>
    props.important === 'true'
      ? `rgba(${props.theme.colors.danger[0]}, 0.1)`
      : `rgba(${props.theme.colors.neutral[5]}, 1)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
  outline: none;
  border: ${(props) => `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
  border-radius: 10px;
  margin-bottom: 0.5rem;
  &:focus,
  &:active {
    outline: none;
    color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
    border: ${(props) => `1px solid rgba(${props.theme.colors.success[0]},1)`};
  }
`;

const StyledInput = styled.input`
  width: 100%;
  margin-bottom: 0.5rem;
  font-family: ${(props) => props.theme.fonts.mainFontFamily};
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
  outline: none;
  border: ${(props) => `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
  border-radius: 10px;
  &:focus,
  &:active {
    outline: none;
    color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
    border: ${(props) => `1px solid rgba(${props.theme.colors.success[0]},1)`};
  }
`;

const Warning = styled.div`
  background: ${(props) => `rgba(${props.theme.colors.danger[0]},1)`};
  padding: 1rem;
  margin-bottom: 0.5rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]},1)`};
  font-weight: bold;
  display: flex;
`;

const HeroContactForm = ({ config }) => {
  const [submittingStatus, setSubmittingStatus] = useState(false);
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const update = { ...formData };
    update[e.target.name] = e.target.value;
    setFormData(update);
  };

  const handleSubmit = () => {
    let formComplete = true;
    config.form.data.forEach((dataItem) => {
      if (!!dataItem.isRequired && !formData[dataItem.name]) {
        formComplete = false;
      }
    });

    if (!!formComplete) {
      setSubmittingStatus('pending');
      submitToFirebase();
    } else {
      setSubmittingStatus('missingdata');
    }
  };

  const submitToFirebase = () => {
    let message = "You've recieved a new request on the website. details: ";
    config.form.data.forEach((dataItem) => {
      if (!!formData[dataItem.name]) {
        message += ` ${dataItem.label}: ${formData[dataItem.name]}.`;
      }
    });
    db.collection('contactrequests')
      .doc()
      .set({
        data: formData,
        message: message,
        created: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        console.log('Document successfully written!');
        setFormData({});
        setSubmittingStatus('complete');
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  };

  return (
    <Wrapper bg={config.img}>
      <InnerWrap>
        <Container alignRight={config.alignRight}>
          <FormWrapper>
            <h2>
              {submittingStatus === 'pending'
                ? 'Submitting Request...'
                : submittingStatus === 'complete'
                ? 'Message Recieved! '
                : config.form.title}
            </h2>
            {submittingStatus === 'complete' && <h3>We'll Be In Touch...</h3>}
            {submittingStatus === 'missingdata' && (
              <Warning>
                <Icon
                  iconType='exclamationTriangle'
                  color='light'
                  size={1}
                  margin='0 0.5rem 0 0'
                />
                Please complete all fields
              </Warning>
            )}
            {submittingStatus !== 'pending' && submittingStatus !== 'complete' && (
              <div>
                {config.form.data.map((item, index) => (
                  <Fragment key={index}>
                    {item.type === 'textarea' ? (
                      <StyledTextArea
                        name={item.name}
                        placeholder={item.label}
                        required={item.isRequired}
                        value={!!formData[item.name] ? formData[item.name] : ''}
                        onChange={(e) => handleChange(e)}
                      ></StyledTextArea>
                    ) : (
                      <StyledInput
                        type={item.type}
                        name={item.name}
                        placeholder={item.label}
                        required={item.isRequired}
                        value={!!formData[item.name] ? formData[item.name] : ''}
                        onChange={(e) => handleChange(e)}
                      />
                    )}
                  </Fragment>
                ))}

                <Button
                  onClick={() => handleSubmit()}
                  block='true'
                  color='linkBlue'
                >
                  {config.form.submitBtnLabel}
                </Button>
              </div>
            )}
          </FormWrapper>
        </Container>
      </InnerWrap>
    </Wrapper>
  );
};

export default HeroContactForm;
