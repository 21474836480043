import React, { useState, useContext, useEffect } from 'react';

import AuthContext from '../context/auth/authContext';
import ConfigContext from '../context/config/configContext';
import AlertContext from '../context/alert/alertContext';
import AuthFormWithQuote from '../components/Authentication/AuthFormWithQuote';

const Login = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);

  const { setAlert } = alertContext;
  const { register, error, clearErrors, isAuthenticated, loadUser } =
    authContext;

  useEffect(() => {
    loadUser();
    if (isAuthenticated) {
      props.history.push('/verify-email');
    }

    if (!!error) {
      setAlert(error.message, 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    email: '',
    name: '',
    password: '',
  });

  const { email, name, password, loading } = user;

  const onChange = (e) =>
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    if (email === '' || password === '' || name === '') {
      setAlert('Please enter all fields', 'danger');
    } else {
      register(email, password, name, null, configContext.config.tenant);
    }
  };
  return (
    <AuthFormWithQuote
      customLoading={false}
      submit={onSubmit}
      change={onChange}
      data={[
        { name: 'name', type: 'text', label: 'Full Name', value: name },
        { name: 'email', type: 'email', label: 'Email', value: email },
        {
          name: 'password',
          type: 'password',
          label: 'Password',
          value: password,
        },
      ]}
      otherAction={{
        text: 'Already have an account?',
        label: 'Log In',
        route: '/login',
      }}
      loading={loading}
      buttonLabel='Register'
      title='Welcome  to'
      subtitle='Register to create your account'
    />
  );
};

export default Login;
