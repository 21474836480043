import portfolioHero from '../assets/potfoliobacker.jpg';
import profilePick from '../assets/profile-pic.png';
import port1 from '../assets/port1.jpg';
import port2 from '../assets/port2.jpg';
import port3 from '../assets/port3.jpg';
import port5 from '../assets/portfolio/port5.jpg';
import port6 from '../assets/portfolio/port6.jpg';
import port7 from '../assets/portfolio/port7.jpg';
import port8 from '../assets/portfolio/port8.jpg';
import port9 from '../assets/portfolio/port9.jpg';
import port10 from '../assets/portfolio/port10.jpg';
import port11 from '../assets/portfolio/port11.jpg';
import port12 from '../assets/portfolio/port12.jpg';
import port13 from '../assets/portfolio/port13.jpg';
import port14 from '../assets/portfolio/port14.jpg';
import port15 from '../assets/portfolio/port15.jpg';
import port16 from '../assets/portfolio/port16.jpg';
import port17 from '../assets/portfolio/port17.jpg';
import port19 from '../assets/portfolio/port19.jpg';
import port20 from '../assets/portfolio/port20.jpg';
import port21 from '../assets/portfolio/port21.jpg';
import port22 from '../assets/portfolio/port22.jpg';
import port23 from '../assets/portfolio/port23.jpg';

const homeConfig = {
  route: '/portfolio',
  title: 'Laura Lane | Portfolio',
  content: [
    {
      component: 'hero-with-image-background',
      img: portfolioHero,
      noTriangle: true,
      title: ['Stylist', 'Salon', 'Products'],
    },
    {
      component: 'features-images-title-only',
      title: 'Portfolio',
      extraPadding: true,
      background: true,
      content: [
        port1,
        port2,
        port3,
        profilePick,
        port5,
        port6,
        port7,
        port8,
        port9,
        port10,
        port11,
        port12,
        port13,
        port14,
        port15,
        port16,
        port17,
        port19,
        port20,
        port21,
        port22,
        port23,
      ],
    },
  ],
};

export default homeConfig;
