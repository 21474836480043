import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import List from './List';

const Wrapper = styled.div`
  padding: ${(props) => (props.noMargin ? '0' : '1rem')};
`;

const ManageTab = ({ baseData, pageConfig, redirectOverride, noMargin }) => {
  const [mutatedData, setMutatedData] = useState();
  useEffect(() => {
    if (!!baseData) {
      const tableData = [];
      baseData.forEach((item, index) => {
        const rowData = [];
        pageConfig.manageTabConfig.tableConfig.forEach((tableItem, index) => {
          if (tableItem.ref === 'admin') {
            rowData.push(item.admin.name);
          } else if (tableItem.useAdditional) {
            rowData.push(
              item[tableItem.ref] && item[tableItem.ref].additional
                ? item[tableItem.ref].additional
                : 'Not Set'
            );
          } else if (tableItem.ref === 'outcome') {
            rowData.push(
              !!item[tableItem.ref]
                ? pageConfig.outcomes[item[tableItem.ref].value]
                : 'No Set'
            );
          } else if (tableItem.ref === 'stage') {
            rowData.push(
              !!item[tableItem.ref]
                ? pageConfig.stages[item[tableItem.ref].value]
                : 'Not Set'
            );
          } else if (!item[tableItem.ref]) {
            rowData.push('No Data');
          } else if (item[tableItem.ref].value === undefined) {
            rowData.push(item[tableItem.ref]);
          } else if (!item[tableItem.ref].value) {
            rowData.push('No Data');
          } else {
            rowData.push(item[tableItem.ref].value);
          }
        });
        tableData.push({ record: { ...item }, rowData: rowData });
      });
      const tableHeadings = [];
      pageConfig.manageTabConfig.tableConfig.forEach((tableItem, index) => {
        tableHeadings.push(tableItem.label);
      });
      const mutatedDataSet = { headings: tableHeadings, data: tableData };
      setMutatedData(mutatedDataSet);
    } // eslint-disable-next-line
  }, [baseData]);

  return (
    <Wrapper noMargin={noMargin}>
      <List data={mutatedData} redirectOverride={redirectOverride} />
    </Wrapper>
  );
};

export default ManageTab;
