import eyeBrushes from '../assets/eye-brushes.jpg';
import faceBrushes from '../assets/face-brushes.jpg';
import giftCert from '../assets/giftcert.jpg';
import multibrush from '../assets/multibrush.png';

const checkoutConfig = {
  route: '/checkout',
  title: 'Laura Lane | Checkout',
  content: [
    {
      component: 'ecommerce-checkout',
      products: [
        {
          img: eyeBrushes,
          title: 'Eye Brushes',
          productCode: 'eye-brushes',
          cost: '45',
        },
        {
          img: faceBrushes,
          title: 'Face Brushes',
          productCode: 'face-brushes',
          cost: '55',
        },
        {
          img: multibrush,
          title: 'Eye + Face Brushes',
          productCode: 'eye-face-brushes',
          cost: '95',
        },
        {
          img: giftCert,
          title: 'Gift Certificate',
          productCode: 'gift-cert',
          cost: '25',
        },
      ],
    },
  ],
};

export default checkoutConfig;
