import React, { useContext, useState } from 'react';
import firebase, { db } from '../../context/firebase';
import styled from 'styled-components';

import ConfigContext from '../../context/config/configContext';

import { Button } from '../Controls';

const MailingGroup = styled.div`
  p {
    font-size: 2rem;
  }
`;

const MailingTitle = styled.div`
  font-size: 1.3rem;
  letter-spacing: 0.4rem;
  color: ${(props) =>
    props.light
      ? `rgba(${props.theme.colors.neutral[5]}, 1)`
      : `rgba(${props.theme.colors.neutral[3]}, 1)`};
  text-transform: uppercase;
`;

const MailingForm = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  input {
    width: 100%;
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
    outline: none;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
    border-radius: 10px;
    &:focus,
    &:active {
      outline: none;
      color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
      border: ${(props) =>
        `1px solid rgba(${props.theme.colors.success[0]},1)`};
    }
  }
`;

const MailingListSimpleWidget = ({ hideTitle, lightTitle }) => {
  const configContext = useContext(ConfigContext);
  const [email, setEmail] = useState('');
  const [submittingStatus, setSubmittingStatus] = useState(false);

  const handleSubmit = () => {
    setSubmittingStatus('pending');
    submitToFirebase();
  };

  const submitToFirebase = () => {
    db.collection('mailingList')
      .doc()
      .set({
        data: email,
        created: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        setEmail('');
        setSubmittingStatus('complete');
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  };

  return (
    <MailingGroup>
      {!hideTitle && (
        <MailingTitle light={lightTitle}>
          {configContext.config.companyConfig.mailingTitle}
        </MailingTitle>
      )}
      {!submittingStatus && (
        <MailingForm>
          <input
            type='email'
            name='email'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Button
            color='success'
            onClick={() => {
              handleSubmit();
            }}
            disabled={email === ''}
          >
            {configContext.config.companyConfig.subscribeLabel}
          </Button>
        </MailingForm>
      )}
      {submittingStatus === 'pending' && (
        <p>{configContext.config.companyConfig.loadingLabel}</p>
      )}
      {submittingStatus === 'complete' && (
        <p>{configContext.config.companyConfig.completeLabel}</p>
      )}
    </MailingGroup>
  );
};

export default MailingListSimpleWidget;
