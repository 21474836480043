import familyimg from './family.jpg';
import Rachel from './rachellane.jpg';

const taxplanning = {
  title: 'Tax Planning For Families',
  date: '03/08/2020',
  readTime: 3,
  image: familyimg,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Tax', '2021'],
  intro:
    'In his spring 2021 Budget, the Chancellor announced that the following thresholds and bands will not change until April 2026: Personal Allowance, Higher Rate Threshold, Capital Gains Tax Annual Exempt Amount, Standard Person Lifetime Allowance, Inheritance Nil Rate Band and Residence Nil Rate Band',    
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'In his spring 2021 Budget, the Chancellor announced that the following thresholds and bands will not change until April 2026:',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'The Personal Allowance - £12,750;',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'The Higher Rate Threshold - £50,270 outside scotland;',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'The Capital Gains Tax Annual Exempt Amount - £12,300',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'The Standard Pension Lifetime Allowance - £1,073,100',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'The Inheritance Tax Nil Rate Band (£325,000) and Residence Nil Rate Band (£175,000).',
      },
    ],
    [
      {
        content:
          'The Chancellor said nothing about the many other tax thresholds and limits that are not subject to automatic inflation-linking. For example, the starting point for additional rate tax has been £150,000 since it first appeared in 2010/11. ',
      },
    ],
    [
      {
        content:
          'This form of stealth tax is favoured not only by Mr Sunak, but also appealed to many of his predecessors. Unless inflation falls to zero – and the forecast is for prices to rise faster in the short term – such freezes mean that more people become taxpayers and those who are already taxpayers will pay more tax',
      },
    ],
    [
      {
        content:
          'The government estimates that by 2025/26 it expects 1.3 million more people to be paying income tax and one million more to be higher rate taxpayers than would have been the case if the tax thresholds were inflation linked. ',
      },
    ],
    [
      {
        content:
          'The eroding effect of these freezes means that many couples who have not had to think about their tax planning jointly now need to do so. For example:',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'The high income child benefit charge only applies if one or either of a child’s parents, or adults in the child’s household (married or not), has income exceeding £50,000 – a figure unchanged since January 2013. When combined with higher rate tax, the result is a marginal tax rate of up to 58.3% (59.3% in Scotland) for a two-child family. By rearranging ownership of their investments – and hence receipt of investment income – some couples may be able to avoid either of them reaching the £50,000 trigger point.',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'Charitable gifts that qualify for gift aid automatically receive basic rate relief at source, meaning that an £80 net gift is worth £100 to the receiving charity. What many people don’t know is that higher and additional rate taxpayers can claim extra personal tax relief. For instance, a higher rate taxpayer can claim £20 relief (£21 in Scotland) on the same £80 net gift. Couples making joint decisions about charitable donations therefore need to decide who should make the gift. ',
      },
    ],
    [
      {
        type: 'quote',
        content:
          'The government estimates that by 2025/26 there will be one million more higher rate taxpayers than if the tax thresholds were inflation linked.',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'Capital gains and capital losses for married couples and civil partners. You each have a capital gains tax (CGT) annual allowance of £12,300. If you make a capital gain of £15,300 in a tax year and your partner makes a loss of £3,000, you end up with a CGT charge on that loss, even though your joint net gains match the annual exemption. On the other hand, if your partner transferred their loss-making asset to you and then you sold it, the loss could offset your gain. ',
      },
    ],
    [{ type: 'heading', content: 'Relationship Status Matters' }],
    [
      {
        content:
          'As with any area of tax planning, make sure you take advice before acting. For instance, the capital gains tax example above will not work for couples that are neither married nor civil partners – the transfer of shares would crystallise the loss. ',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The Financial Conduct Authority does not regulate tax advice. Levels and bases of taxation and tax reliefs are subject to change and their value depends on individual circumstances. Tax laws can change. ',
      },
    ],
  ],
};

export default taxplanning;
