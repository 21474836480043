import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AuthContext from '../../context/auth/authContext';
import ConfigContext from '../../context/config/configContext';
import MainrecordContext from '../../context/mainrecord/mainrecordContext';

import PageLoader from '../Feedback/PageLoader';
import SuccessAlert from '../Feedback/SuccessAlert';
import AddNewPopout from '../Forms/AddNewPopout';
import HeaderWithActionsAndTabs from '../Headers/HeaderWithActionsAndTabs';
import AnalyseTab from '../Widgets/AnalyseTab';
import ManageTab from '../Widgets/ManageTab';

const CorePageWithTabs = () => {
  const location = useLocation();
  const history = useHistory();

  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const mainrecordContext = useContext(MainrecordContext);

  const pageReference = location.pathname.replace(/^\/|\/$/g, '');
  const [tabSelected, setTabSelected] = useState('manage');
  const [toggleAdd, setToggleAdd] = useState(false);

  //getConfig
  const [pageConfig, setPageConfig] = useState();
  useEffect(() => {
    setPageConfig(configContext.config[pageReference + 'Config']);
  }, [pageReference, configContext.config]);

  //getData
  useEffect(() => {
    mainrecordContext.clearMainrecords();
    //handle special cases
    if (!!pageConfig) {
      if (!!pageConfig && pageReference === 'users') {
        mainrecordContext.getRecordsByTenantWhereNotClient(
          authContext.userData.tenant,
          pageReference,
          pageConfig
        );
      } else {
        mainrecordContext.getRecordsByTenant(
          authContext.userData.tenant,
          pageReference,
          pageConfig
        );
      }
    }
    // eslint-disable-next-line
  }, [authContext.loading, location.pathname, pageConfig]);

  //Create New
  const [completeNotification, setCompleteNotification] = useState(false);
  const createNew = (data) => {
    mainrecordContext
      .addMainrecord(
        data,
        pageReference,
        authContext.user.uid,
        authContext.userData.tenant,
        authContext.userData.name
      )
      .then((res) => {
        mainrecordContext.setCurrent(res);
        setToggleAdd(false);
        setCompleteNotification(true);
      });
  };
  useEffect(() => {
    let isMounted = true;
    if (!!isMounted && completeNotification === 'completed') {
      history.push('/' + pageReference + 'record');
    }
    return () => {
      isMounted = false;
    }; // eslint-disable-next-line
  }, [completeNotification]);

  return (
    <Fragment>
      {authContext.loading || mainrecordContext.loading || !pageConfig ? (
        <PageLoader />
      ) : (
        <Fragment>
          <HeaderWithActionsAndTabs
            title={pageConfig.title}
            btnLabel={pageConfig.addBtnLabel}
            count={
              !!mainrecordContext.records && mainrecordContext.records.length
            }
            tabs={pageConfig.tabs}
            tabSelected={tabSelected}
            setTabSelected={setTabSelected}
            name={pageConfig.name}
            setToggleAdd={setToggleAdd}
            disableAddRecord={pageConfig.disbaleDirectAddRecord}
          />
          {!pageConfig.disbaleDirectAddRecord && (
            <AddNewPopout
              active={toggleAdd}
              title={pageConfig.addBtnLabel}
              setToggleAdd={setToggleAdd}
              newDataSteps={pageConfig.newDataSteps}
              dataConfig={pageConfig.data}
              handleSubmit={createNew}
            />
          )}

          {tabSelected === 'manage' && (
            <ManageTab
              baseData={mainrecordContext.records}
              pageConfig={pageConfig}
            />
          )}
          {tabSelected === 'analyse' && (
            <AnalyseTab data={mainrecordContext} pageConfig={pageConfig} />
          )}
          <SuccessAlert
            subtext={[`New record added`, 'Loading record']}
            currentState={completeNotification}
            setCurrentState={setCompleteNotification}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default CorePageWithTabs;
