import React, { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { LinkButton, LinkButtonExternal } from '../Controls';
import PublicSubMenu from './PublicSubMenu';

const MenuWrapper = styled.div`
  position: fixed;
  width: 100%;
  @media only screen and (max-width: 799px) {
    display: none;
  }
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  min-height: 5rem;
`;

const Inner = styled.div`
  max-width: 1100px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    height: 3rem;
    margin: 1rem 3rem 0.5rem 1rem;
  }
`;

const CTAGroup = styled.div`
  display: flex;
  margin-right: 1rem;
`;

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin: 0 0.25rem;
  height: 4rem;
  font-size: 1.1rem;
  padding: 0 1rem;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  color: ${(props) =>
    props.active === 'true'
      ? `rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : `rgba(${props.theme.colors.neutral[2]}, 1)`};
  border-bottom: ${(props) =>
    props.active === 'true'
      ? `3px solid rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : `3px solid rgba(${props.theme.colors.neutral[5]}, 1)`};
`;

const LargeScreenPublicNav = ({ companyLogo, fullConfig, footerMenu }) => {
  const location = useLocation();
  const [selectedSubMenu, setSelectedSubMenu] = useState('');

  return (
    <MenuWrapper>
      <Inner>
        <Main>
          <Link to='/'>
            <img src={companyLogo} alt='logo' />
          </Link>
          {fullConfig.main.map((item, index) => (
            <Fragment key={index}>
              {!item.submenu ? (
                <StyledLink
                  to={item.route}
                  active={location.pathname === item.route ? 'true' : 'false'}
                >
                  {item.label}
                </StyledLink>
              ) : (
                <PublicSubMenu
                  config={item}
                  selected={selectedSubMenu}
                  setSelectedSubMenu={setSelectedSubMenu}
                />
              )}
            </Fragment>
          ))}
        </Main>
        <CTAGroup>
          {footerMenu.map((item, index) => (
            <Fragment key={index}>
              {item.external ? (
                <LinkButtonExternal
                  href={item.route}
                  size='small'
                  color={
                    index === footerMenu.length - 1 ? 'linkBlue' : 'lightLink'
                  }
                >
                  {item.label}
                </LinkButtonExternal>
              ) : (
                <LinkButton
                  to={item.route}
                  size='small'
                  color={
                    index === footerMenu.length - 1 ? 'linkBlue' : 'lightLink'
                  }
                >
                  {item.label}
                </LinkButton>
              )}
            </Fragment>
          ))}
        </CTAGroup>
      </Inner>
    </MenuWrapper>
  );
};

export default LargeScreenPublicNav;
