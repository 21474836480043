import Stairs from './climbing-steps.jpg';
import Rachel from './rachellane.jpg';

const taxrisesontheway = {
  title: 'Tax rises on the way?',
  date: '24/07/2020',
  readTime: 2,
  image: Stairs,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Tax', 'Covid-19'],
  intro:
    'When the Chancellor launched the Self-Employed Income Support Scheme, he commented that “it is now much harder to justify the inconsistent contributions between people of different employment statuses”. The comment was widely seen as a hint that the self-employed could soon face higher national insurance contributions (NICs , bringing the amount they pay closer to the level paid by employees.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'When the Chancellor launched the Self-Employed Income Support Scheme, he commented that “it is now much harder to justify the inconsistent contributions between people of different employment statuses”. The comment was widely seen as a hint that the self-employed could soon face higher national insurance contributions (NICs , bringing the amount they pay closer to the level paid by employees.',
      },
    ],
    [
      {
        content:
          'A rise in the NICs rate would be unlikely to be the only tax increase. Shortly after Rishi Sunak’s announcement, the Office for Budget Responsibility (OBR) revealed an estimate that Covid-19 would lead to the Government borrowing £273 billion in the current financial year. That’s almost five times the figure it had projected at the time of the spring Budget. The Coronavirus Job Retention Scheme has been extended by four months since the OBR’s April calculations, so its next borrowing estimate could be over £350 billion.',
      },
    ],
    [
      {
        content:
          'The Government won the December election with a manifesto commitment that it would “not raise the rate of income tax, VAT or National Insurance”. If it keeps to that pledge, then it could be forced to look to areas such as pension tax relief for extra revenue.',
      },
    ],
    [
      {
        content: 'Forewarned is forearmed',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of tax reliefs depends on your individual circumstances. Tax laws can change. The Financial Conduct Authority does not regulate tax advice.',
      },
    ],
  ],
};

export default taxrisesontheway;
