import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import initials from '../../utils/initials';
import Icon from '../UI/Icon';

const MainNav = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  position: fixed;
  width: 250px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-right: ${(props) =>
    `1px solid rgba(${props.theme.colors.neutral[3]}, 0.3)`};
  @media only screen and (max-width: 799px) {
    display: none;
  }
`;

const MainTop = styled.div`
  padding: 1rem;
  margin-bottom: 4rem;
  overflow-y: auto;
`;
const MainBottom = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  position: fixed;
  width: 249px;
  bottom: 0;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  border-top: ${(props) =>
    `1px solid rgba(${props.theme.colors.neutral[3]}, 0.3)`};
`;

const ProfileName = styled.div`
  h4 {
    text-transform: capitalize;
  }
  p {
    font-size: 0.7rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const MenuGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

const Line = styled.div`
  border-bottom: ${(props) =>
    ` 1px solid rgba(${props.theme.colors.neutral[4]},1)`};
`;

const MainMenuItem = styled(Link)`
  background-color: ${(props) =>
    props.active === 'true'
      ? `rgba(${props.theme.colors.bgColor[0]}, 1)`
      : `rgba(${props.theme.colors.neutral[5]}, 1)`};
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  &:hover {
    background: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 0.5)`};
  }
`;

const MainNotificationsWrapper = styled(Link)`
  display: flex;
  margin: 2rem 0 1rem 0.5rem;
  p {
    margin-left: 0.5rem;
  }
`;

const AppName = styled(Link)`
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const Notifications = styled.div`
  margin-left: -0.75rem;
  margin-top: -0.25rem;
  padding: 0 0 0.1rem 0.075rem;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 15px;
  font-size: 0.6rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  background-color: ${(props) => `rgba(${props.theme.colors.danger[0]}, 1)`};
`;
const Profile = styled(Link)`
  margin: 0 1rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  background-color: ${(props) =>
    props.active === 'true'
      ? `rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : `rgba(${props.theme.colors.primary[0]}, 1)`};
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
`;

const LargescreenAppNav = ({
  appName,
  appIcon,
  unreadNotifications,
  mainMenu,
  footerMenu,
  userData,
}) => {
  const location = useLocation();
  return (
    <MainNav>
      <MainTop>
        <AppName to='/dashboard'>
          <Icon
            iconType={appIcon}
            size={1.8}
            margin='0 0.5rem 0 0'
            color='primary'
          />
          {appName}
        </AppName>
        <MainNotificationsWrapper to='/notifications'>
          <Icon
            iconType='bell'
            size={1.5}
            color={
              location.pathname === '/notifications' ? 'linkBlue' : 'midGrey'
            }
          />
          {unreadNotifications !== 0 && (
            <Notifications>{unreadNotifications}</Notifications>
          )}
          <p>Notifications</p>
        </MainNotificationsWrapper>
        <Line />
        <MenuGroupWrapper>
          {mainMenu.map((item) => (
            <MainMenuItem
              key={item.route}
              to={item.route}
              active={(location.pathname === item.route).toString()}
            >
              {item.label}
            </MainMenuItem>
          ))}
        </MenuGroupWrapper>
        <Line />
        <MenuGroupWrapper>
          {footerMenu.map((item) => (
            <MainMenuItem
              key={item.route}
              to={item.route}
              active={(location.pathname === item.route).toString()}
            >
              {item.label}
            </MainMenuItem>
          ))}
        </MenuGroupWrapper>
      </MainTop>
      <MainBottom>
        {userData && userData.name && userData.email && (
          <Fragment>
            <Profile
              to='/profile'
              active={(location.pathname === '/profile').toString()}
            >
              {initials(userData.name)}
            </Profile>
            <ProfileName>
              <h4>
                {userData.name.length > 18
                  ? userData.name.substring(0, 18) + '...'
                  : userData.name}
              </h4>
              <p>
                {userData.email.length > 24
                  ? userData.email.substring(0, 24) + '...'
                  : userData.email}
              </p>
            </ProfileName>
          </Fragment>
        )}
      </MainBottom>
    </MainNav>
  );
};

export default LargescreenAppNav;
