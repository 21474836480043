import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  background: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;

const Container = styled.div`
  padding: 2rem;
  max-width: 1100px;
  margin: auto;
  @media only screen and (max-width: 800px) {
    padding-top: 4rem;
  }
`;

const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
    margin-bottom: 1rem;
  }
  span {
    color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
    margin-left: 0.25rem;
    font-size: 0.9rem;
  }
  p {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
  img {
    object-fit: cover;
    width: 100%;
    max-height: 60vh;
    border-radius: 5px;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  justify-content: flex;
  align-items: center;
  flex-wrap: wrap;
  text-transform: uppercase;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.8)`};
  font-weight: 500;
  margin: 1rem 0;
  font-size: 0.9rem;
  * {
    margin-right: 1rem;
    letter-spacing: 0.07rem;
  }
`;

const Badge = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  background: ${(props) =>
    props.color
      ? `rgba(${props.theme.colors[props.color][0]}, 1)`
      : `rgba(${props.theme.colors.neutral[5]}, 1)`};
  padding: 0 0.25rem;
  border-radius: 3px;
`;

const Tag = styled.div``;

const Author = styled.div`
  margin: 1rem 0 2rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
  }
  h4 {
    font-weight: bold;
  }
`;

const ContentWrapper = styled.div``;

const Section = styled.div`
  margin-bottom: 1rem;
  * {
    display: inline;
  }
  a {
    color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  }
`;

const Content = styled.div`
  img {
    max-height: 300px;
    object-fit: contain;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
  color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
`;

const Footer = styled.p`
  font-size: 0.8rem;
`;

const Bullet = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Quote = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 2rem 0 1rem 0;
  p {
    font-style: italic;
    font-size: 1.1rem;
    padding: 1rem;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: ${(props) =>
      `rgba(${props.theme.colors.primary[0]}, 0.1)`};
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
  text-transform: capitalize;
  margin-right: 0.25rem;
`;

const DownloadWrapper = styled.div`
  font-size: 2rem;
  margin-top: 2rem;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${(props) =>
      ` 3px solid rgba(${props.theme.colors.linkBlue[0]}, 1)`};
    padding: 1rem 2rem;
    border-radius: 15px;
    width: 35%;
    min-width: 300px;
    margin: auto;
  }
`;

const BlogTemplate = ({ config }) => {
  return (
    <Wrapper>
      <Container>
        <StyledLink to='/news'>
          <Icon
            iconType='back'
            size={2}
            color='linkBlue'
            margin='0 0.5rem 0 0'
          />
          Back
        </StyledLink>
        <PostWrapper>
          <img src={config.image} alt={config.title} />
          <TagsWrapper>
            <Badge color={config.typeColor}>{config.type}</Badge>
            {config.tags.map((tag, tagIndex) => (
              <Tag key={tagIndex}>{tag}</Tag>
            ))}
          </TagsWrapper>
          <Author>
            <img src={config.author.image} alt={config.author.name} />
            <div>
              <h4>{config.author.name}</h4>
              <p>{config.author.role}</p>
            </div>
          </Author>
          <h2>{config.title}</h2>
          <ContentWrapper>
            {config.lineItems.map((lineItem, lineItemIndex) => (
              <Section key={lineItemIndex}>
                {lineItem.type === 'newsletter' ? (
                  <DownloadWrapper>
                    <a href={lineItem.content} download>
                      <Icon
                        iconType='download'
                        size={3}
                        margin='0.5rem 1rem 0 0'
                        color='linkBlue'
                      />
                      Download
                    </a>
                  </DownloadWrapper>
                ) : (
                  <Fragment>
                    {lineItem.map((item, itemIndex) => (
                      <Content key={itemIndex}>
                        {!item.type && <p>{item.content}</p>}
                        {item.type === 'heading' && <h3>{item.content}</h3>}
                        {item.type === 'bullet' && (
                          <Bullet>
                            <Icon
                              iconType='circle'
                              size={1}
                              margin='0.5rem 1rem'
                            />
                            <p>
                              {item.boldtext && (
                                <BoldSpan>{item.boldtext}</BoldSpan>
                              )}
                              {item.content}
                            </p>
                          </Bullet>
                        )}
                        {item.type === 'quote' && (
                          <Quote>
                            <Icon
                              iconType='quote'
                              size={2}
                              margin='0.5rem 1rem'
                            />
                            <p>{item.content}</p>
                          </Quote>
                        )}
                        {item.type === 'image' && (
                          <img src={item.content} alt={item.alt} />
                        )}
                        {item.type === 'footer' && (
                          <Footer>{item.content}</Footer>
                        )}
                        {item.type === 'external-link' && (
                          <a href={item.href}>{item.content}</a>
                        )}
                      </Content>
                    ))}
                  </Fragment>
                )}
              </Section>
            ))}
          </ContentWrapper>
        </PostWrapper>
        <StyledLink to='/news'>
          <Icon
            iconType='back'
            size={2}
            color='linkBlue'
            margin='0 0.5rem 0 0'
          />
          Back
        </StyledLink>
      </Container>
    </Wrapper>
  );
};

export default BlogTemplate;
