import Image from './image.png';
import Newsletter from './summer_22.pdf';
import Rachel from './rachellane.jpg';

const summer_22 = {
  title: 'Financial Focus - Summer 2022 Newsletter',
  date: '01/07/2022',
  readTime: 17,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['summer 2022', 'retirement', 'inflation'],
  intro:
    "Financial concerns are taking hold in an increasing number of households. The Chancellor's promise of a £400 discount on everyone's gas bill this year will be a welcome relief for many, but with petrol prices at eye-watering levels it's not going to fix everything. Those nearing retirement may have to consider their options carefully. Rising inflation exacerbates existing concerns about funding a longer retirement, so there may be changes you could make to improve your standard of living in your later years. If you're not already a higher rate taxpayer, you could be soon. The Office for Budget Responsibility is predicting nearly 20% of taxpayers will be in the top bracket by 2025/26 and with inflation also affecting real returns on investments, wealth erosion is affecting all areas of finance. Meanwhile, if you are looking forward to a long-awaited trip to Europe this summer, be aware of additional costs you may need to factor in since Brexit and Covid-19.",
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default summer_22;
