import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthContext from '../context/auth/authContext';

import AppLayout from '../components/Layout/AppLayout';
import PageLoader from '../components/Feedback/PageLoader';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, user, userData, loadUser, updateUserData } =
    authContext;

  useEffect(() => {
    !userData && loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        (!isAuthenticated || !user) && !loading ? (
          <Redirect to='/login' />
        ) : !!user && !user.emailVerified ? (
          <Redirect to='/verify-email' />
        ) : !!userData ? (
          <AppLayout userData={userData}>
            <Component
              {...props}
              userData={userData}
              updateUserData={updateUserData}
            />
          </AppLayout>
        ) : (
          <AppLayout userData={userData}>
            <PageLoader />
          </AppLayout>
        )
      }
    />
  );
};

export default PrivateRoute;
