import houseimg from './house.jpg';
import Rachel from './rachellane.jpg';

const secondhome = {
  title: 'The rise of second homes',
  date: '03/08/2020',
  readTime: 3,
  image: houseimg,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Tax', 'Property'],
  intro:
    'Homes that are classed as a ‘furnished holiday let’ (FHL) benefit from several extra tax breaks. For example, owners can deduct as expenses the cost of furnishing the property and mortgage interest charges. Income from FHLs can be used to make pension contributions, which is not permitted for income from buy-to-let property. ',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      { type: 'quote',
        content:
        'The number of people purchasing second properties in the UK has boomed according to government figures, with the total number of second homes rising 30% over the past five years.',
      }
    ],
    [
      {
        content:
          'The recent Stamp Duty holiday has further increased activity in the housing market, as people look to benefit from this short-term concession.',
      },
    ],
    [
      {
        content:
          'Second home-owners include those buying properties to rent out, either as short-term holiday lets or on longer-term rental contracts to tenants, as well as those buying a property solely for their own use. Many will do a bit of both: using a second property for weekends away, while also letting it to friends, family and also commercially for some of the year to help cover costs. However, this can affect how much tax you need to pay on any income the property generates',
      },
    ],
    [
      {
        content:
          'Homes that are classed as a ‘furnished holiday let’ (FHL) benefit from several extra tax breaks. For example, owners can deduct as expenses the cost of furnishing the property and mortgage interest charges. Income from FHLs can be used to make pension contributions, which is not permitted for income from buy-to-let property. There are several rules for a property to qualify: for example, it must be available for letting at a commercial rate for at least 210 days a year and it must not normally be let to the same person for a period of more than 31 days in the tax year. ',
      },
    ],
    [
      {
        content:
          'If you are renting out a second property, you should seek qualified tax advice to ensure you make the most of the rules. ',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The Financial Conduct Authority does not regulate tax advice. Levels and bases of taxation and tax reliefs are subject to change and their value depends on individual circumstances. Tax laws can change.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Your home may be repossessed if you do not keep up repayments on a mortgage or other loans secured on it. Business buy-to-let and commercial mortgages are not regulated by the FCA. Think carefully before securing other debts against your home. ',
      },
    ],
  ],
};

export default secondhome;
