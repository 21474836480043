import Cover from './cover.jpg';
import ATR3 from './ATR3.pdf';
import ATR4 from './ATR4.pdf';
import ATR5 from './ATR5.pdf';
import ATR6 from './ATR6.pdf';
import ATR7 from './ATR7.pdf';
import ATR8 from './ATR8.pdf';
import Rachel from './rachellane.jpg';

const summernewsletter = {
  title: 'Investment Rebalance - October 2021',
  date: '01/11/2021',
  readTime: 10,
  image: Cover,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['autumn 2021', 'Investing'],
  intro: 'Quarterly Portfolio update.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [{ type: 'heading', content: 'ATR3 - 2 short term - portfolio review' }],
    { type: 'newsletter', content: ATR3 },
    [{ type: 'heading', content: 'ATR4 - 3 short term - portfolio review' }],
    { type: 'newsletter', content: ATR4 },
    [{ type: 'heading', content: 'ATR5 - 3 medium term - portfolio review' }],
    { type: 'newsletter', content: ATR5 },
    [{ type: 'heading', content: 'ATR6 - 3 long term - portfolio review' }],
    { type: 'newsletter', content: ATR6 },
    [{ type: 'heading', content: 'ATR7 - 4 medium term - portfolio review' }],
    { type: 'newsletter', content: ATR7 },
    [{ type: 'heading', content: 'ATR8 - 5 medium term - portfolio review' }],
    { type: 'newsletter', content: ATR8 },
  ],
};

export default summernewsletter;
