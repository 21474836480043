import Image from './image.jpg';
import Newsletter from './statement.pdf';
import Rachel from './rachellane.jpeg';

const statement = {
  title: 'Spring Statement - 2022',
  date: '25/03/2021',
  readTime: 4,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['spring 2022'],
  intro:
    'Exactly two years since the first lockdown was announced, the eyes of the public were firmly fixed on the Chancellor, Rishi Sunak, as he rose to the despatch box in the House of Commons to deliver his Spring Statement.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default statement;
