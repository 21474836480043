import Swingphoto from './swing-carousel.jpg';
import Rachel from './rachellane.jpg';

const highmarkets = {
  title: 'Handling High Markets',
  date: '03/08/2020',
  readTime: 3,
  image: Swingphoto,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Investing', 'Covid-19'],
  intro:
    'Global stock markets have been performing strongly since the first successful vaccine trials in November 2020. Anticipation – and evidence – of economic recovery has prompted share prices to rally around the world. In the world’s largest market, the US, the S&P 500 Index breached the 4,000 level for the first time ever, following a drop to below 2,500 in March 2020 when the pandemic panic was at its worst. Even the UK, which has lagged behind in recent years, has picked up, with the FTSE 100 index crossing the 7,000 barrier again.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'Global stock markets have been performing strongly since the first successful vaccine trials in November 2020. Anticipation – and evidence – of economic recovery has prompted share prices to rally around the world. In the world’s largest market, the US, the S&P 500 Index breached the 4,000 level for the first time ever, following a drop to below 2,500 in March 2020 when the pandemic panic was at its worst. Even the UK, which has lagged behind in recent years, has picked up, with the FTSE 100 index crossing the 7,000 barrier again.',
      },
    ],
    [
      {
        content:
          'Inevitably, the flow of “…hits new high” headlines has prompted questions about the wisdom of investing in share markets now. Neither of the obvious alternatives of cash deposits or fixed interest securities are particularly attractive, given current ultra-low interest rates. However, if you have similar market doubts, there are several strategies to consider:',
      },
    ],
    [
      {
        content:
          '',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Drip feeding',
        content:
          'Instead of investing a lump sum all at once, spread the investment over a period. That way you can be sure all your money does not get invested at a market peak. The corollary is that you may miss out on some investment return if markets continue their upward path.',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Keep and adequate cash reserve',
        content:
          'Make sure you have sufficient instant access deposits so you can avoid cashing in your investments if you need funds quickly. A paper loss is just that until investments are realised – as events since February 2020 have amply demonstrated.',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Be aware of sequencing risk',
        content:
          'If you intend to draw on your investment immediately, as might be the case with pension drawdown, a sudden drop in values can have a dramatic effect on the sustainability of your withdrawals. There are several approaches to limit this risk, such as holding a separate low risk reserve.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investment and the income from it can go down as well as up and you may not get back the full amount you invested. Past performance is not a reliable indicator of future performance.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Investing in shares should be regarded as a long-term investment and should fit in with your overall attitude to risk and financial circumstances. ',
      },
    ],
  ],
};

export default highmarkets;
