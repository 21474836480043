import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const VideoWrapper = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 0)`};
  background: ${(props) => `linear-gradient(200deg,
    rgba(${props.theme.colors.neutral[1]}, 0.7) 0%,
    rgba(${props.theme.colors.tertiary[0]}, 1) 100%
  )`};
  padding: 0;
  margin: 0;
  height: 60vh;
  width: 100vw;
  max-width: 1800px;
  z-index: -10;
  position: relative;
  margin: auto;
  @media (max-width: 900px) {
    display: none;
  }
`;
const VideoBanner = styled.video`
  opacity: 0.3;
  position: relative;
  object-fit: cover;
  margin: auto;
  width: 100vw;
  max-width: 1800px;
  height: 60vh;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Container = styled.div`
  overflow: hidden;
  margin-top: -300px;
  padding-top: 2rem;
  @media (max-width: 900px) {
    margin-top: 0;
  }
`;

const StyledLogo = styled.div`
  width: 350px;
  margin-bottom: -12rem;
  margin-left: 10vw;
  @media (max-width: 900px) {
    width: 300px;
    right: 0;
    margin-bottom: 0;
    margin: auto;
    margin-top: -4rem;
  }
`;

const SubWrapper = styled.div``;

const HeroWrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  display: flex;
  margin-left: 30vw;
  padding: 0rem 2rem 4rem 2rem;
  justify-content: space-between;
  @media (max-width: 900px) {
    display: inline;
  }
`;

const HeroLeftWrapper = styled.div`
  max-width: 50%;
  @media (max-width: 900px) {
    text-align: center;
    max-width: 70vw;
    margin: auto;
    margin-top: -2rem;
  }
`;
const HeroTitle = styled.h1`
  font-size: 5rem;
  font-weight: 800;
  color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
  padding-bottom: 2rem;
  div {
    margin-bottom: -1.5rem;
  }
  @media (max-width: 900px) {
    font-size: 3rem;
  }
`;

const Lighter = styled.span`
  opacity: 0.6;
`;

const HeroWithVideoBackground = ({ config }) => {
  return (
    <Wrapper>
      <VideoWrapper>
        <VideoBanner loop muted autoPlay poster={config.img}>
          <source src={config.video} type='video/mp4' />
        </VideoBanner>
      </VideoWrapper>
      <Container>
        <StyledLogo>
          <img src={config.logo} alt='lauralane' />
        </StyledLogo>
        <SubWrapper />
        <HeroWrapper>
          <HeroLeftWrapper>
            <HeroTitle>
              <div>
                Stylist<Lighter>.</Lighter>
              </div>
              <div>
                Salon<Lighter>.</Lighter>
              </div>
              <div>
                Products<Lighter>.</Lighter>
              </div>
            </HeroTitle>
          </HeroLeftWrapper>
        </HeroWrapper>
      </Container>
    </Wrapper>
  );
};

export default HeroWithVideoBackground;
