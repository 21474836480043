import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const blockStyles = ({ block }) => {
  if (block === 'true') {
    return css`
      display: block;
      width: 100%;
    `;
  } else {
    return css`
      display: inline-block;
    `;
  }
};

const sizeStyles = ({ size }) => {
  if (size === 'small') {
    return css`
      padding: 0.3rem 1rem;
      margin-right: 0.2rem;
    `;
  } else if (size === 'large') {
    return css`
      padding: 0.6rem 2rem;
      margin-right: 0.4rem;
    `;
  } else {
    return css`
      font-size: 1rem;
      padding: 1rem 1.5rem;
      margin-right: 0.5rem;
    `;
  }
};

const colorStyles = ({ color }) => {
  if (color === 'dark') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else if (color === 'alt') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.secondary[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: none;
    `;
  } else if (color === 'light') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[4]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
      border: none;
    `;
  } else if (color === 'outline-light') {
    return css`
      background-color: none;
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    `;
  } else if (color === 'tertiary') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.tertiary[2]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.tertiary[2]}, 1)`};
    `;
  } else if (color === 'outline-dark') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[4]}, 0.5)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
    `;
  } else if (color === 'linkBlue') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
    `;
  } else if (color === 'lightLink') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[5]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    `;
  } else {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.primary[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  }
};

export const LinkButton = styled((props) => <Link {...props} />)`
  font-family: ${(props) => props.theme.fonts.mainFontFamily};

  font-size: ${(props) => (props.fontSize ? `${props.fontSize}rem` : '1rem')};
  outline: none;
  text-transform: capitalize;
  border-radius: 7px;
  white-space: nowrap;
  font-weight: ${(props) => props.bold && 'bold'};
  ${blockStyles}
  ${colorStyles}
${sizeStyles} 

&:hover {
    opacity: 0.7;
    cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
  }
  &:active {
    ${colorStyles}
  }
  > * {
    display: inline;
    margin: ${(props) => (props.margin ? props.margin : '0 0.2rem')};
  }
  margin: ${(props) => (props.margin ? props.margin : '0 0.2rem')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

LinkButton.propTypes = {
  color: PropTypes.oneOf([
    'dark',
    'alt',
    'light',
    'tertiary',
    'outline-light',
    'outline-dark',
    'linkBlue',
    'lightLink',
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  block: PropTypes.oneOf(['true']),
};
