const accountsEditRecordConfig = [
  {
    reference: 'summary',
    tiles: [
      {
        title: 'Contact Details',
        subtitle:
          'Use the tabs at the top of the page to view more, full personal details can be found in the "Company Details" tab.',
        data: ['primaryContactName', 'primaryContactEmail'],
      },
      {
        title: 'Team',
        data: ['adminID', 'clientAdviserID'],
      },
      {
        title: 'Timeline',
        namedComponent: {
          component: 'timeline',
        },
      },
      {
        title: 'Notes',
        data: ['companyNotes'],
      },
      {
        title: 'Lead Details',
        data: ['leadReceivedDate', 'source', 'stage', 'outcome'],
      },
    ],
  },
  {
    reference: 'cases',
    tiles: [
      {
        title: 'Business Cases',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'opportunities',
        },
      },
      {
        title: 'Annual Reviews',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'reviews',
        },
      },
    ],
  },
  {
    reference: 'company details',
    tiles: [
      {
        title: 'Company Information',
        data: [
          'name',
          'natureOfBusiness',
          'country',
          'website',
          'numberOfEmployees',
          'companyType',
          'companyRegNumber',
          'companyYearEnd',
          'PAYEReference',
          'VATRegNumber',
          'connectedBusinesses',
        ],
      },
      {
        title: 'Primary Contact Details',
        data: [
          'primaryContactName',
          'primaryContactEmail',
          'primaryContactPhoneNumber',
          'primaryContactBuildingName',
          'primaryContactStreet',
          'primaryContactTown',
          'primaryContactCounty',
          'primaryContactPostcode',
        ],
      },
      {
        title: 'Schemes',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'schemes',
        },
      },
    ],
  },
  {
    reference: 'Schemes',
    tiles: [
      {
        title: 'Schemes',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'schemes',
        },
      },
    ],
  },
  {
    reference: 'Employees', // need probably a custom component (same for contact info), to add a contact and link them here.
    tiles: [
      {
        title: 'Directors / Partners / Owners',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'contacts',
        },
      },
      {
        title: 'Employees',
        namedComponent: {
          component: 'relatedRecords',
          recordRef: 'contacts',
        },
      },
    ],
  },
  {
    reference: 'documents',
    tiles: [
      {
        namedComponent: {
          component: 'document',
          recordRef: 'clientAgreement',
        },
      },
      {
        namedComponent: {
          component: 'document',
          recordRef: 'letterOfAuthority',
        },
      },
      {
        namedComponent: {
          component: 'document',
          recordRef: 'general',
        },
      },
    ],
  },
];

export default accountsEditRecordConfig;
