import Cover from './cover.png';
import Rachel from './rachellane.jpg';

const content = {
  title:
    'Should you really trust TikTok to help with your important financial decisions?',
  date: '14/12/2022',
  readTime: 5,
  image: Cover,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Investing', 'Social Media'],
  intro:
    'Social media is now a way of life, with millions of us checking our different apps every day for news and other information, or to upload photos for our family and friends. It therefore comes as somewhat no surprise, that research has revealed that a third of adults now turn to social media for money-saving tips and financial advice amid the cost-of-living crisis. The survey by OnePoll which was conducted for mall builder Westfield* also showed that a fifth of adults aged 45 – 54 say they are more likely to turn to social media for financial advice; so, it’s not just the younger generation! But should you really trust TikTok to help with your important financial decisions, or do you need to be wary of information that hasn’t come from a proper financial adviser which could potentially be a scam?',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'Social media is now a way of life, with millions of us checking our different apps every day for news and other information, or to upload photos for our family and friends. It therefore comes as somewhat no surprise, that research has revealed that a third of adults now turn to social media for money-saving tips and financial advice amid the cost-of-living crisis. The survey by OnePoll which was conducted for mall builder Westfield* also showed that a fifth of adults aged 45 – 54 say they are more likely to turn to social media for financial advice; so, it’s not just the younger generation! But should you really trust TikTok to help with your important financial decisions, or do you need to be wary of information that hasn’t come from a proper financial adviser which could potentially be a scam?',
      },
    ],
    [
      {
        content:
          'From the 2,025 adults surveyed, it discovered the reasons for them using social media was that they felt it was unfiltered, comes from someone in a more ‘real’ way and that it was easier to understand than financial advice from other sources. Finfluencers (Financial Influencers) giving tips and money-saving ideas is not a new thing, as you can find help to any question on social media somewhere, but with financial advice you definitely do need to be more wary.',
      },
    ],
    [
      {
        content:
          'With money-saving tips all over the internet, as influencers try to help people cut back and save money, this can in most cases be a good thing and can help people make their money stretch further. However, when it comes to the bigger financial decisions like buying a house, taking out a business loan or investing, you should always gather information from many different sources and then speak to a financial advisor.',
      },
    ],
    [
      {
        content:
          'Whilst a video on TikTok or another platform may be easier to understand, this is because it has been simplified to keep it short and therefore, this should always be your entry point for going and seeking out credible financial information. Always take what you watch with a pinch of salt, as it all depends on the information you are watching and the risk involved. If you are looking at useful tips on how to budget or tips on how to spend less money, then there is no risk involved by taking this advice. Alternatively, if you’re viewing content on investing and alternative currencies such as bitcoin, be extremely careful.',
      },
    ],
    [
      {
        content:
          'In the current cost-of-living crisis, financial advice is big business, but this unfortunately means that there are many influencers spreading false information. It always pays to check that anyone that you are thinking about taking advice from is registered with the FCA (Financial Conduct Authority). Whilst there are certified financial planners and certified public accountants on TikTok (and you can normally Google them to see if they’re legitimate), if you do then follow advice from someone who is not regulated, you are doing this at your own risk. Additionally, if a content creator appears to be making a lot of money from the advice that they are giving, then you might want to question who is really gaining the most from you following it?',
      },
    ],
    [
      {
        content:
          'However, one thing social media is definitely great for is educating youngsters on money matters - something schools are trying to bring in at an earlier age to prepare students for the future. The OnePoll survey conducted by Westfield Mall also carried out similar research on 1,046 12 to 17-year-olds, which discovered 43% rely on social media channels and influencers for financial advice. 45% of this age group also thought social media platforms will be used to carry out all money transactions in the next 10 years, with 36% saying they’ve gained a greater understanding of the cost-of-living crisis through social media than elsewhere. Just over half (51%) of those surveyed said they valued the opinions of social media influencers who are similar to them, while 42% looked to influencers for tips because they’re explained in an easy-to-understand way.',
      },
    ],
    [
      {
        content:
          'Tik Tok and other social media platforms are therefore, effective at getting financial information, advice and other money saving tips to all age groups to empower them to better manage their finances. However, if it is a deeper financial issue which involves more risk, always double check the information is correct and that the person is qualified to actually advise you.',
      },
    ],
    [
      {
        content:
          'Really understanding money, and particularly investing, takes time and there are some things we just can’t understand by watching a short video. Whilst it can be tempting, especially in the current economic climate to be sucked in by ‘get rich quick’ tips, we need to be able to recognise when money advice is simply too good to be true. By always checking the influencers background and credentials before you part with any hard- earned cash (as so many are in it for the fame or money trying to sell products they don’t understand) it will avoid you getting involved in a risky investment or scammed.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investments, and the income from them, can go down as well as up and you may not get back the full amount you invested.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Past performance is not a reliable indicator of future performance.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Investing in shares should be regarded as a long-term investment and should fit in with your overall attitude to risk and financial circumstances.',
      },
    ],
  ],
};

export default content;
