import truinvestConfig from './truinvest/truinvestConfig';
import rapportteamConfig from './rapportteam/rapportteamConfig';
import llaneConfig from './llane/llaneConfig';
import intrustedpensions from './intrustedpensions/intrustedpensionsConfig';

const tenantsConfig = {
  truinvest: truinvestConfig,
  rapportteam: rapportteamConfig,
  llane: llaneConfig,
  intrustedpensions: intrustedpensions,
};

export default tenantsConfig;
