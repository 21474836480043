import { UPDATE_CART, CLEAR_CART } from '../types';

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case UPDATE_CART:
      return {
        ...state,
        cartItems: {
          ...state.cartItems,
          [action.payload.productCode]: action.payload.quantity,
        },
      };

    case CLEAR_CART:
      return {
        ...state,
        cartItems: {},
      };
    default:
      return state;
  }
};
