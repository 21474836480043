import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MobileMenu = styled.div`
  z-index: 1000;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  position: fixed;
  width: 100vw;
  margin-top: 3.9rem;
  height: calc(100vh - 3rem);
  padding: 3rem;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  right: ${(props) => (props.toggle ? '0' : '100vw')};
  transition: all 0.2s ease-in;
`;

const MobileGroupWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-size: 1.3rem;
  a {
    background-color: ${(props) =>
      `rgba(${props.theme.colors.bgColor[0]}, 0.8)`};
    padding: 0.5rem 2rem;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
  }
`;

const MobileNavMenu = ({
  handleMobileNavToggle,
  showMobileNav,
  mainMenu,
  footerMenu,
}) => {
  return (
    <MobileMenu toggle={showMobileNav}>
      <MobileGroupWrapper>
        {mainMenu.map((item, index) => (
          <Fragment key={index}>
            <Link
              to={item.route}
              onClick={() => {
                handleMobileNavToggle();
              }}
            >
              {item.label}
            </Link>
          </Fragment>
        ))}
        {footerMenu.map((item, index) => (
          <Fragment key={index}>
            {item.external ? (
              <a href={item.route}> {item.label}</a>
            ) : (
              <Link
                to={item.route}
                onClick={() => {
                  handleMobileNavToggle();
                }}
              >
                {item.label}
              </Link>
            )}
          </Fragment>
        ))}
      </MobileGroupWrapper>
    </MobileMenu>
  );
};

export default MobileNavMenu;
