import React, { Fragment } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]},1)`};
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.7rem;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.4)`};
  }
  @media only screen and (max-width: 800px) {
    background-color: ${(props) =>
      `rgba(${props.theme.colors.neutral[4]},0.3)`};
  }
`;

const Tag = styled.div`
  display: flex;
  text-transform: capitalize;
  align-items: center;
  margin: 0.5rem 1rem 0.5rem 0;
  background-color: ${(props) => ` rgba(${props.theme.colors.neutral[4]}, 1)`};
  padding: 0.5rem 1rem;
  border-radius: 10px;
`;

const Small = styled.span`
  font-size: 0.9rem;
  margin-left: 0.25rem;
`;

const Strong = styled.span`
  font-weight: bold;
  font-size: 0.9rem;
  margin-right: 0.25rem;
  text-transform: capitalize;
`;

const Dot = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 0.5rem 0.25rem;
`;

const EditBtn = styled.div`
  color: ${(props) => ` rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  font-weight: bold;
  padding-top: 0.5rem;
  &:hover {
    opacity: 0.7;
  }
`;

const Header = styled.div`
  font-weight: bold;
  color: ${(props) => ` rgba(${props.theme.colors.neutral[2]}, 1)`};
  font-size: 1.2rem;
  text-transform: capitalize;
`;

const SubHeader = styled.div`
  text-transform: capitalize;
  color: ${(props) => ` rgba(${props.theme.colors.neutral[2]}, 0.8)`};
  font-size: 0.9rem;
`;

const Body = styled.div`
  flex-grow: 100;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const DotWrapper = styled.div`
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Tile = ({ labels, data }) => {
  return (
    !!data && (
      <Wrapper>
        <DotWrapper>
          <Dot />
          <EditBtn>Edit</EditBtn>
        </DotWrapper>
        <Body>
          <Header>
            {labels[0] === 'First Name' ? data[0] + ' ' + data[1] : data[0]}
          </Header>
          <SubHeader>
            {labels[0] === 'First Name' ? (
              <Fragment>
                {data[2]} <Small>({data[3]})</Small>
              </Fragment>
            ) : (
              <Fragment>
                {data[1]} <Small>({data[2]})</Small>
              </Fragment>
            )}
          </SubHeader>
          <TagsWrapper>
            {labels.map((label, index) => (
              <Fragment key={index}>
                {index !== 0 &&
                  label !== 'First Name' &&
                  label !== 'Last Name' &&
                  label !== 'Firm' &&
                  label !== 'Adviser' && (
                    <Tag>
                      <Strong>{label}: </Strong> {data[index]}
                    </Tag>
                  )}
              </Fragment>
            ))}
          </TagsWrapper>
        </Body>
      </Wrapper>
    )
  );
};

export default Tile;
