import moment from 'moment';

const calculateNext12Months = () => {
  const next12 = [];
  let i;
  for (i = 0; i < 12; i++) {
    const dateNow = new Date();
    dateNow.setMonth(dateNow.getMonth() + i);
    next12.push({
      value: moment(dateNow).format('MMMMYYYY'),
      label: moment(dateNow).format('MMMM YYYY'),
    });
  }

  return next12;
};

export default calculateNext12Months;
