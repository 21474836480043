import familyimg from './family-inheritance.jpg';
import Rachel from './rachellane.jpg';

const inheritancetax = {
  title: 'The future of inheritance tax',
  date: '03/08/2020',
  readTime: 3,
  image: familyimg,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Tax', 'Inheritance'],
  intro:
    'The £325,000 IHT threshold (or £650,000 for married couples and civil partners) has remained unchanged since 2009. The Budget 2021 freeze on this nil rate band until 2025/26 means that many more estates could be subject to IHT, as inflation – particularly of house prices – pushes up many people’s total net worth.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'The £325,000 IHT threshold (or £650,000 for married couples and civil partners) has remained unchanged since 2009. The Budget 2021 freeze on this nil rate band until 2025/26 means that many more estates could be subject to IHT, as inflation – particularly of house prices – pushes up many people’s total net worth.',
      },
    ],
    [
      {
        content:
          'IHT is generally charged at 40% on the value of assets over the threshold. There are exemptions, the most significant being assets left to a spouse or civil partner. These transfers are normally free of IHT, regardless of their value. In addition, if parents leave a home to children or grandchildren the threshold increases to £500,000 using the residential nil rate band of £175,000. For married couples and civil partners this effectively means an estate of up to £1 million can be left to their children tax-free.',
      },
    ],
    [{ type: 'heading', content: 'SIMPLE STEPS TO REDUCE IHT' }],
    [
      {
        content:
          'You can give away money or other assets during your lifetime. But it has to be a genuine transfer – giving away a property while continuing to live in it wouldn’t count.',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Small gifts',
        content:
          'You can make unlimited gifts of up to £250 per recipient during the tax year, plus up to £3,000 per tax year – as one gift or multiple gifts – under the ‘annual exemption’ rule. None of these gifts are included within your estate for IHT purposes. ',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Exempted gifts',
        content:
          'Further gifts are permitted each year for specific reasons, e.g. £5,000 or £2,500 towards a child’s or a grandchild’s wedding; or payments to help with an elderly parent or a child’s (under 18) living costs. ',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Larger gifts',
        content:
          ' If you give away more than £325,000 in the seven years before your death, these gifts may be subject to IHT. If you survive three years or more after making a non-exempt gift, taper relief reduces the tax payable on a sliding scale and no tax is payable if you survive the seven full years following the date of the gift. ',
      },
    ],
    [{ type: 'heading', content: 'POTENTIAL REFORM' }],
    [
      {
        content:
          'Significant changes to IHT may be on the horizon. Last year an All-Party Parliamentary Group made a series of recommendations including a 10% tax rate on lifetime gifts over £30,000 per year, 10% on gifts on death up to £2 million and possibly 20% thereafter. ',
      },
    ],
    [
      {
        content:
          'Meanwhile the Office of Tax Simplification (OTS) has also published recommended reforms including:',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Exemptions',
        content:
          'The three main exemptions – the £3,000 annual exemption (frozen since 1981), the £250 small gifts exemption (frozen since 1980) and the normal expenditure exemption (with no monetary limit), together with marriage gifts (frozen since 1975) – should be consolidated into a single annual gift allowance. The OTS did not specify an amount but noted that £25,000 would cover just over half of all normal expenditure claims. ',
      },
    ],
    [
      {
        type: 'bullet', boldtext: 'Business Relief',
        content:
          ' IHT business relief and capital gains tax (CGT) uplift on death can mean business assets pass with no IHT and no CGT, if sold immediately. The OTS proposed ending the CGT uplift, but the Chancellor might take a different view, given the growing popularity of certain portfolios (including ISAs) tailored to benefit from business relief. ',
      },
    ],
    [
      {
        type: 'quote',
        content:
          'Changes to IHT may be on the horizon... recommendations include a 10% tax rate on lifetime gifts over £30,000 per year, and 10% on gifts on death up to £2 million.',
      },
    ],
    [{ type: 'heading', content: 'PUT PLANS IN PLACE' }],
    [
      {
        content:
          'While the future shape of IHT is uncertain, the tax itself is unlikely to disappear. You should not defer IHT planning – which should be interwoven with your will and other estate planning – waiting for changes that may not happen. If IHT is a concern for you, the time to talk to us is now, especially as any reform could see the removal of some opportunities',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The Financial Conduct Authority does not regulate tax advice, and levels and bases of taxation and tax reliefs are subject to change and their value depends on individual circumstances. Tax laws can change.', 
      },
    ],
  ],
};

export default inheritancetax;
