import contactImg from '../assets/contact.jpg';

const contactConfig = {
  route: '/contact',
  title: 'Group Rapport | Contact',
  content: [
    {
      component: 'hero-contactform',
      img: contactImg,
      form: {
        title: 'Get In Touch',
        formName: 'website-contact-form',
        submitBtnLabel: 'Send Message',
        data: [
          {
            type: 'text',
            name: 'fullName',
            label: 'Name',
            isRequired: true,
          },
          {
            type: 'tel',
            name: 'telNumber',
            label: 'Contact Number',
          },
          {
            type: 'email',
            name: 'email',
            label: 'Email',
            isRequired: true,
          },
          {
            type: 'textarea',
            name: 'message',
            label: 'Your Message Here...',
            isRequired: true,
          },
        ],
      },
    },
  ],
};

export default contactConfig;
