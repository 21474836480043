import React, { useContext } from 'react';
import styled from 'styled-components';

import ConfigContext from '../../context/config/configContext';

import AuthForm from './AuthForm';
import Icon from '../UI/Icon';
import EmailVerifiedNotification from './EmailVerifiedNotification';

const Wrapper = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
  min-height: 100vh;
  padding: 4rem;
  @media only screen and (max-width: 800px) {
    padding: 0;
    background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  }
`;

const Container = styled.div`
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: auto;
`;

const QuoteWrapper = styled.div`
  padding: 2rem 0 2rem 2rem;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const StyledLogo = styled.img`
  width: 200px;
  margin-bottom: 2rem;
`;

const InnerWrapper = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  h2 {
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

const QuoterLine = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  img {
    width: 5rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  p {
    font-size: 1.2rem;

    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  h4 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
  }
`;
const NameWrap = styled.div``;

const AuthFormContainer = styled.div`
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
  width: 50%;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
  img {
    height: 30px;
  }
`;

const AuthFormWithQuote = ({
  verify,
  customLoading,
  title,
  subtitle,
  submit,
  change,
  data,
  otherAction,
  loading,
  buttonLabel,
  setEmailSent,
  explainer,
}) => {
  const configContext = useContext(ConfigContext);
  return (
    <Wrapper>
      <Container>
        <AuthFormContainer>
          {!verify ? (
            <AuthForm
              appIcon={configContext.config.companyConfig.appIcon}
              appName={configContext.config.companyConfig.appName}
              customLoading={customLoading}
              title={title}
              subtitle={subtitle}
              submit={submit}
              change={change}
              data={data}
              otherAction={otherAction}
              loading={loading}
              buttonLabel={buttonLabel}
            />
          ) : (
            <EmailVerifiedNotification
              appIcon={configContext.config.companyConfig.appIcon}
              appName={configContext.config.companyConfig.appName}
              title={title}
              subtitle={subtitle}
              explainer={explainer}
              setEmailSent={setEmailSent}
            />
          )}
        </AuthFormContainer>
        <QuoteWrapper>
          <Icon
            iconType='quote'
            size={2}
            color='lightGrey'
            margin='6rem 1rem 0 0'
          />

          <InnerWrapper>
            <StyledLogo
              src={configContext.config.companyConfig.logo}
              alt='logo'
            />
            <h2>{configContext.config.authConfig.quoteText}</h2>
            <QuoterLine>
              <img
                src={configContext.config.authConfig.quoteImg}
                alt={configContext.config.authConfig.quoteName}
              />
              <NameWrap>
                <p>{configContext.config.authConfig.quoteName}</p>
                <h4>{configContext.config.authConfig.quoteJobTitle}</h4>
              </NameWrap>
            </QuoterLine>
            {!!configContext.config.authConfig.logos && (
              <LogoWrapper>
                {configContext.config.authConfig.logos.map((logo, index) => (
                  <img src={logo} key={index} alt={index} />
                ))}
              </LogoWrapper>
            )}
          </InnerWrapper>
        </QuoteWrapper>
      </Container>
    </Wrapper>
  );
};

export default AuthFormWithQuote;
