import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Button = styled.div`
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin: 0 0.25rem;
  height: 4rem;
  font-size: 1.1rem;
  padding: 0 1rem;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  color: ${(props) =>
    props.active === 'true'
      ? `rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : `rgba(${props.theme.colors.neutral[2]}, 1)`};
  border-bottom: ${(props) =>
    `3px solid rgba(${props.theme.colors.neutral[5]}, 1)`};
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const SubWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  width: 100vw;
  top: ${(props) => (props.active === 'true' ? 'calc(5rem - 1px)' : '-20rem')};
  left: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem 4rem;
  box-shadow: ${(props) => props.theme.boxShadow};
  border-top: ${(props) =>
    `1px solid rgba(${props.theme.colors.neutral[4]}, 1)`};
`;

const SubItem = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem;
  padding: 1rem 0.5rem;
  border-radius: 7px;
  &:hover {
    background-color: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
  }
`;

const ItemContent = styled.div`
  h3 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
    font-size: 1.1rem;
    display: flex;
  }
  p {
    color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
    font-size: 0.9rem;
  }
`;

const PublicSubMenu = ({ config, selected, setSelectedSubMenu }) => {
  return (
    <Fragment>
      <Button
        active={selected === config.ref && 'true'}
        onClick={() => {
          selected === config.ref
            ? setSelectedSubMenu('')
            : setSelectedSubMenu(config.ref);
        }}
      >
        {config.label}
        <Icon
          iconType={selected === config.ref ? 'close' : 'downcaret'}
          size={1.1}
          color={selected === config.ref ? 'linkBlue' : 'midGrey'}
          margin='auto auto auto 0.25rem'
        />
      </Button>
      <SubWrapper active={selected === config.ref && 'true'}>
        {config.submenu.map((item, index) => (
          <SubItem key={index} to={item.route}>
            <Icon
              iconType={item.icon}
              size={2}
              color='linkBlue'
              margin='0.25rem 0.5rem'
            />
            <ItemContent>
              <h3>
                {item.label}
                <Icon
                  iconType='chevron-right'
                  size={0.9}
                  color='dark'
                  margin='auto auto auto 0.5rem;'
                />
              </h3>
              <p>{item.description}.</p>
            </ItemContent>
          </SubItem>
        ))}
      </SubWrapper>
    </Fragment>
  );
};

export default PublicSubMenu;
