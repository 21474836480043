import mark from '../assets/mark.png';

const authConfig = {
  quoteImg: mark,
  quoteName: 'Mark Smith',
  quoteJobTitle: 'Director @Intrusted Pensions',
  quoteText:
    'After many years of seeing a shift from traditional pension arrangements with large insurance companies to bespoke SSAS and SIPP operators, I believe we are now seeing what I have been anticipating for some time in this space.',
  loginTitle: 'Welcome back to Compass',
  loginSubTitle: 'Login in to your account',
};
export default authConfig;
