import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const blockStyles = ({ block }) => {
  if (block === 'true') {
    return css`
      display: block;
      width: 100%;
    `;
  } else {
    return css`
      display: inline-block;
    `;
  }
};

const sizeStyles = ({ size }) => {
  if (size === 'small') {
    return css`
      padding: 0.3rem 1rem;
      margin: ${(props) => (props.margin ? props.margin : '0 0.2rem')};
    `;
  } else if (size === 'large') {
    return css`
      padding: 0.6rem 2rem;
      ${(props) => (props.margin ? props.margin : '0 0.4rem')};
    `;
  } else {
    return css`
      padding: 0.75rem 1.5rem;
      ${(props) => (props.margin ? props.margin : '0 0.5rem')};
    `;
  }
};

const colorStyles = ({ color }) => {
  if (color === 'dark') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else if (color === 'alt') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.secondary[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: none;
    `;
  } else if (color === 'none') {
    return css`
      background-color: inherit;
      color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
      border: none;
    `;
  } else if (color === 'light') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[4]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
      border: none;
    `;
  } else if (color === 'success') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.success[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else if (color === 'outline-light') {
    return css`
      background-color: none;
      color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
    `;
  } else if (color === 'linkBlue') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else if (color === 'outline-dark') {
    return css`
      background-color: none;
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
    `;
  } else if (color === 'danger') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.danger[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.primary[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  }
};

export const Button = styled.button`
  font-family: ${(props) => props.theme.fonts.mainFontFamily};

  font-size: ${(props) => (props.fontSize ? `${props.fontSize}rem` : '1rem')};
  outline: none;
  text-transform: capitalize;
  border-radius: 7px;
  white-space: nowrap;
  font-weight: ${(props) => props.bold && 'bold'};
  ${blockStyles}
  ${colorStyles}
  ${sizeStyles} 

  &:hover {
    opacity: 0.7;
    cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
  }
  &:active {
    ${colorStyles}
  }
  > * {
    display: inline;
    margin: ${(props) => (props.margin ? props.margin : '0 0.2rem')};
  }
  margin: ${(props) => (props.margin ? props.margin : '0 0.2rem')};
  float: ${(props) => props.float && props.float};
  display: flex;
  justify-content: center;
  align-items: center;
`;

Button.propTypes = {
  color: PropTypes.oneOf([
    'dark',
    'alt',
    'light',
    'outline-light',
    'outline-dark',
    'danger',
    'success',
    'primary',
    'none',
    'linkBlue',
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  block: PropTypes.oneOf(['true']),
  fontSize: PropTypes.number,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
};
