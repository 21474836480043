import styling from './styling';
import companyConfig from './companyConfig';
import authConfig from './app/authConfig';
import appNavConfig from './app/appNavConfig';
import contactsConfig from './app/contacts/contactsConfig';
import accountsConfig from './app/accounts/accountsConfig';
import opportunitiesConfig from './app/opportunitiesConfig';
import reviewsConfig from './app/reviewsConfig';
import tasksConfig from './app/tasksConfig';
import addressConfig from './app/addressConfig';
import connectedpersonConfig from './app/connectedpersonConfig';
import employmentConfig from './app/employmentConfig';
import incomeConfig from './app/incomeConfig';
import usersConfig from './app/usersConfig';
import assetConfig from './app/assetConfig';
import insuranceConfig from './app/insuranceConfig';
import liabilityConfig from './app/liabilityConfig';
import expenditureDataConfig from './app/expenditureDataConfig';
import schemesConfig from './app/schemesConfig';
import complianceConfig from './app/complianceConfig';

import homeConfig from './public/homeConfig';
import aboutConfig from './public/aboutConfig';

const truinvestConfig = {
  tenant: 'truinvest',
  companyConfig: companyConfig,
  styling: styling,
  publicConfig: [homeConfig, aboutConfig],
  authConfig: authConfig,
  appNavConfig: appNavConfig,
  contactsConfig: contactsConfig,
  accountsConfig: accountsConfig,
  opportunitiesConfig: opportunitiesConfig,
  reviewsConfig: reviewsConfig,
  tasksConfig: tasksConfig,
  incomeConfig: incomeConfig,
  schemesConfig: schemesConfig,
  complianceConfig: complianceConfig,
  employmentConfig: employmentConfig,
  connectedpersonConfig: connectedpersonConfig,
  addressConfig: addressConfig,
  expenditureDataConfig: expenditureDataConfig,
  liabilityConfig: liabilityConfig,
  insuranceConfig: insuranceConfig,
  assetConfig: assetConfig,
  usersConfig: usersConfig,
};

export default truinvestConfig;
