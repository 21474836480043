import React, { useEffect, useContext, Fragment } from 'react';
import { uid } from 'uid';
import styled from 'styled-components';

import AuthContext from '../../context/auth/authContext';
import ConfigContext from '../../context/config/configContext';
import Icon from '../UI/Icon';

const UserInvite = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.mainFontFamily};
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
  outline: none;
  border: ${(props) => `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
  border-radius: 10px;
  &:hover {
    outline: none;
    color: ${(props) => `rgba(${props.theme.colors.success[0]},1)`};
    border: ${(props) => `1px solid rgba(${props.theme.colors.success[0]},1)`};
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  width: calc(100%-2rem);
  margin: 0.5rem;
  margin-top: 1.5rem;
  h2 {
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
  }
  p {
    font-size: 0.9rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
  color: ${(props) => `rgba(${props.theme.colors.primary[2]}, 1)`};
`;

const Token = ({
  handleBlur,
  data,
  dataItemConfig,
  userName,
  userEmail,
  userStage,
}) => {
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);

  useEffect(() => {
    !data && handleBlur({ target: { name: 'token', value: uid(32) } }); // eslint-disable-next-line
  }, [data]);

  const generateEmailLink = () => {
    const subject = `${authContext.userData.name} is inviting you to register for ${configContext.config.companyConfig.appName}`;
    const body = `Hi ${userName}, %0D%0A %0D%0AHere is your unique invite link to register for the ${
      configContext.config.companyConfig.appName
    } portal${
      dataItemConfig.isClient &&
      ' which I use to share information and collaborate with my clients on their financial plan.'
    }. %0D%0A %0D%0A ${
      window.location.origin
    }/claimaccount?token=${data} %0D%0A %0D%0A Simply click on the link to confirm your account with a password. You'll be sent another email from the portal to verify your email address; then log in and follow the process. %0D%0A %0D%0AKind regards ${
      authContext.userData.name
    }.`;
    const fullEmail = `mailto:${userEmail}?subject=${subject}&body=${body}`;
    return fullEmail;
  };

  return (
    <Wrapper>
      <Title>Portal Invite</Title>
      {userStage < 2 ? (
        <Fragment>
          <SubTitle>
            <Icon
              iconType='infoCircle'
              size={1}
              margin='0 0.5rem 0 0'
              color='dark'
            />
            Click to generate a unique invite email to this
            {dataItemConfig.isClient ? ' client' : ' team member'} with thier
            account access information.
          </SubTitle>
          <UserInvite
            href={generateEmailLink()}
            onClick={() => {
              userStage !== 1 &&
                handleBlur({ target: { name: 'stage', value: 1 } });
            }}
          >
            <Icon iconType='mail' size={1.5} margin='0 0.5rem 0 0' />
            Generate Invite Email
          </UserInvite>
        </Fragment>
      ) : (
        <div>Registered</div>
      )}
    </Wrapper>
  );
};

export default Token;
