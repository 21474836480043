const stages = ['Active', 'Inactive'];
const outcomes = ['Current', 'Old'];
const incomeConfig = {
  title: 'Employment Records',
  name: 'Employment',
  addBtnLabel: 'Create Employment Record',
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Description' },
  breadcrumbs: [
    { type: 'simpleLink', label: 'Private Clients', route: '/contacts' },
    {
      type: 'relatedRecord',
      dbRef: 'contacts',
      recordRefFromCurrent: 'contactsID',
    },
    { type: 'simpleLabel', label: 'Income Record' },
    { type: 'currentRecord' },
  ],
  stages: stages,
  outcomes: outcomes,
  manageTabConfig: {
    filterByLabel: 'Description',
    tableConfig: [
      { label: 'Description', ref: 'name' },
      { label: 'Income Type', ref: 'type' },
      { label: 'Gross Annual Amount', ref: 'amount' },
    ],
  },
  newDataSteps: [
    {
      title: 'Income Summary',
      data: [
        { ref: 'name', mandatory: true },
        { ref: 'type', mandatory: true },
      ],
    },
    {
      title: 'Income Details',
      data: [
        { ref: 'amount', mandatory: true },
        { ref: 'paymentFrequency', mandatory: true },
      ],
    },
    {
      title: 'Notes',
      guidance: 'Optional',
      data: [{ ref: 'notes' }],
    },
  ],
  recordEditTabs: ['summary'],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Employment Records' },
  },
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Client Record',
          data: ['contactsID'],
        },
        {
          title: 'Income Summary',
          data: ['name', 'type'],
        },
        {
          title: 'Income Details',
          data: ['amount', 'paymentFrequency'],
        },
        {
          title: 'Notes',
          data: ['notes'],
        },
      ],
    },
  ],
  data: {
    name: {
      type: 'text',
      label: 'Name - Summary of income',
    },
    type: {
      type: 'select',
      label: 'Income Type',
      valueOptions: [
        { value: 'salary', label: 'Salary / Wage' },
        { value: 'partnerSoleProprietor', label: 'Partner / Sole Proprietor' },
        {
          value: 'additionalFullPartTime',
          label: 'Additional Full / Part-time Employment',
        },
        { value: 'statePension', label: 'State Pension' },
        { value: 'companyPension', label: 'Company Pension' },
        { value: 'privatePension', label: 'Private Pension' },
        { value: 'investmentIncome', label: 'InvestmentIncome' },
        { value: 'childBenefit', label: 'Child Benefit' },
        { value: 'dividends', label: 'Dividends' },
        { value: 'trustIncome', label: 'Trust Income' },
        { value: 'annuities', label: 'Annuities' },
        { value: 'overtime', label: 'Overtime' },
        { value: 'bonus', label: 'Bonus' },
        { value: 'courtOrderMaintainence', label: 'Court Order Maintainence' },
        { value: 'bedroomRental', label: 'Bedroom Rental' },
        { value: 'allowance', label: 'Allowance' },
        {
          value: 'londonLargeTownAllowance',
          label: 'London Large Town Allowance',
        },
        { value: 'mortgageSubsidy', label: 'Mortgage Subsidy' },
        { value: 'benefits', label: 'Benefits' },
        { value: 'paternalPay', label: 'Paternal Pay' },
        { value: 'sickPay', label: 'Sick Pay' },
        { value: 'maintainencePayments', label: 'MaintainencePayments' },
        { value: 'nonContributoryPension', label: 'Non-Contributory Pension' },
        { value: 'carAllowance', label: 'Car Allowance' },
        { value: 'stateBenefit', label: 'State Benefit' },
        { value: 'workingTaxCredit', label: 'Working Tax Credit' },
        { value: 'shiftAllowance', label: 'Shift Allowance' },
        { value: 'lifeAssuranceBond', label: 'Life Assurance Bond' },
        { value: 'pensionDrawdown', label: 'Pension Drawdown' },
        { value: 'superannuable', label: 'Superannuable' },
        { value: 'nonsuperannuable', label: 'Non-Superannuable' },
        { value: 'otherIncome', label: 'Other Income' },
        { value: 'basicIncome', label: 'Basic Income' },
        { value: 'otherIrregularIncome', label: 'Other Irregular Income' },
      ],
    },
    amount: {
      type: 'number',
      label: 'Current Gross Amount (annually)',
    },
    paymentFrequency: {
      type: 'select',
      label: 'Payment Frequency',
      valueOptions: [
        { value: 'annually', label: 'Annually' },
        { value: 'biAnnually', label: 'Bi Annually' },
        { value: 'quarterly', label: 'Quarterly' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'single', label: 'Single' },
      ],
    },
    notes: {
      type: 'textArea',
      label: 'Notes',
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
  },
};

export default incomeConfig;
