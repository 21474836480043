import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import initials from '../../utils/initials';
import Icon from '../UI/Icon';

const Container = styled(Link)``;

const TopLine = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
  height: 150px;
  width: 100%;
  @media only screen and (max-width: 800px) {
    background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  }
`;

const UserBox = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: ${(props) => `rgba(${props.theme.colors.tertiary[0]}, 1)`};
  padding: 1rem;
  border-radius: 7px;
  margin: auto;
  margin-top: -75px;
  @media only screen and (max-width: 800px) {
    border-radius: 0;
  }
`;
const HeaderRow = styled.div`
  display: flex;
  justify-content: center;
`;

const UserIcon = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  display: flex;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  justify-content: center;
  align-items: center;

  padding: 1.5rem;
  border-radius: 50%;
  font-size: 2rem;
  border: ${(props) => `7px solid rgba(${props.theme.colors.tertiary[0]}, 1)`};
  margin-top: -4rem;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
    text-transform: capitalize;
    font-size: 1.8rem;
    padding-top: 0.5rem;
  }
  h2 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
    text-transform: capitalize;
    font-size: 1.2rem;
  }
`;

const TagsLine = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  div {
    margin: 0.5rem;
    display: flex;
    align-items: center;
    color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    div {
      margin: 0.5rem 0 0 0;
    }
  }
`;

const ProfileHeader = ({ user }) => {
  return (
    <Container to='/profile'>
      <TopLine />
      <UserBox>
        <HeaderRow>
          <UserIcon>
            {initials(
              !!user.editableName &&
                !!user.editableName.value &&
                user.editableName.value
            )}
          </UserIcon>
        </HeaderRow>
        <Body>
          <h1>
            {!!user.editableName &&
              !!user.editableName.value &&
              user.editableName.value}
          </h1>
          {!!user.jobTitle && !!user.jobTitle.value && (
            <h2>{user.jobTitle.value}</h2>
          )}
          <TagsLine>
            {!!user.location && user.location.value && (
              <div>
                <Icon
                  iconType='mapMarker'
                  size={1}
                  color='linkBlue'
                  margin='0 0.5rem 0 0'
                />
                {user.location.value}
              </div>
            )}
            {user.phone && user.phone.value && (
              <div>
                <Icon
                  iconType='phone'
                  size={1}
                  color='linkBlue'
                  margin='0 0.5rem 0 0'
                />
                {user.phone.value}
              </div>
            )}
            {!!user.editableTenant && !!user.editableTenant.value && (
              <div style={{ textTransform: 'capitalize' }}>
                <Icon
                  iconType='connections'
                  size={1}
                  color='linkBlue'
                  margin='0 0.5rem 0 0'
                />
                {user.editableTenant.value}
              </div>
            )}
          </TagsLine>
        </Body>
      </UserBox>
    </Container>
  );
};

export default ProfileHeader;
