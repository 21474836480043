import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;

const Wrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 2rem 2rem 1rem 2rem;
`;

const Title = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const Content = styled.div`
  p {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
`;

const AssumptionsFooter = ({ config }) => {
  return (
    <Container>
      <Wrapper>
        <Title>{config.title}</Title>
        <Content>
          {config.content.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </Content>
      </Wrapper>
    </Container>
  );
};

export default AssumptionsFooter;
