import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import AuthContext from '../context/auth/authContext';

import { Button } from '../components/Controls';
import Avatar from '../components/UI/Avatar';

const Container = styled.div`
  margin: auto;
  padding: 2rem 1rem;
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Heading = styled.div`
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid
    ${(props) => `rgba(${props.theme.colors.neutral[3]}, 0.5)`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const Label = styled.span`
  color: ${(props) => `rgba(${props.theme.colors.tertiary[4]}, 1)`};
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
`;

const ProfileGroup = styled.div`
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid
    ${(props) => `rgba(${props.theme.colors.neutral[3]}, 0.5)`};
  display: grid;
  grid-template-columns: 2fr 3fr;
  h2 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    font-weight: bold;
    font-size: 1.4rem;
  }
`;

const Account = (props) => {
  const authContext = useContext(AuthContext);
  const { logout, userData, loadUser } = authContext;
  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  const onLogout = () => {
    props.history.push('/login');
    logout();
  };

  return (
    <Container>
      <Heading>
        <h1>Account Settings</h1>
        <Button onClick={onLogout} color={'danger'} fontSize={1}>
          logout
        </Button>
      </Heading>
      <ProfileGroup>
        <h2>Personal Info</h2>
        <div>
          <div className='py'>
            <Label>Name: </Label>
            {userData && userData.name}
          </div>
          <div className='py'>
            <Label>Email: </Label>
            {userData && userData.email}
          </div>
        </div>
      </ProfileGroup>
      <ProfileGroup>
        <h2>Profile Image</h2>
        <Avatar
          size={100}
          name={!!userData && !!userData.name && userData.name}
        />
      </ProfileGroup>
    </Container>
  );
};

export default Account;
