import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../Controls';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  background: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`}; ;
`;

const Container = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: auto;
  h2 {
    margin-bottom: 1rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    font-weight: bold;
  }
`;

const ItemWrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    font-weight: bold;
  }
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  margin: 0.5rem;
  background-color: ${(props) =>
    props.outline === 'true'
      ? `none`
      : props.outline === 'grey'
      ? `none`
      : `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  color: ${(props) =>
    props.outline === 'true'
      ? `none`
      : props.outline === 'grey'
      ? `rgba(${props.theme.colors.neutral[3]}, 0.8)`
      : `rgba(${props.theme.colors.neutral[5]}, 1)`};
  border: ${(props) =>
    props.outline === 'true'
      ? `1px solid rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : props.outline === 'grey'
      ? `1px solid rgba(${props.theme.colors.neutral[3]}, 0.8)`
      : 'none'};

  &:hover {
    cursor: ${(props) => (props.selectable === 'true' ? 'pointer' : 'no-drop')};
    opacity: ${(props) => props.selectable === 'true' && '0.7'};
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
`;

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  display: ${(props) => (props.hideSection ? 'none' : 'block')};
`;

const TrackerLine = styled.div`
  min-width: 30px;
  min-height: 20px;
  margin: 0 0.5rem;
`;

const TrackerLineInner = styled.div`
  background: ${(props) =>
    props.success === 'true'
      ? `rgba(${props.theme.colors.linkBlue[0]}, 0.8)`
      : `rgba(${props.theme.colors.neutral[3]}, 0.3)`};
  width: 2px;
  margin: auto;
  height: 100%;
`;

const FlexLine = styled.div`
  display: flex;
  margin: 1rem 0;
`;

const Guidance = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.8)`};
  font-size: 0.9rem;
  line-height: 1.3rem;
`;

const Vertical = ({ config }) => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Wrapper>
      <Container>
        <h2>{config.title}</h2>
        {config.steps.map((item, index) => (
          <ItemWrapper key={index}>
            <Header>
              <Circle
                onClick={() => {
                  index < activeStep && setActiveStep(index);
                }}
                selectable={index < activeStep ? 'true' : 'false'}
                outline={
                  activeStep > index
                    ? 'true'
                    : activeStep === index
                    ? 'false'
                    : 'grey'
                }
              >
                {activeStep > index ? (
                  <Icon
                    iconType='check'
                    size={1.2}
                    color='linkBlue'
                    margin='0'
                  />
                ) : (
                  index + 1
                )}
              </Circle>
              <p>{item.title}</p>
            </Header>
            <Body>
              <TrackerLine>
                {index !== config.steps.length - 1 && (
                  <TrackerLineInner success={(activeStep > index).toString()} />
                )}
              </TrackerLine>
              <Content hideSection={index !== activeStep}>
                <Guidance>{item.content}</Guidance>

                <FlexLine>
                  <Button
                    color='none'
                    size='small'
                    disabled={index === 0}
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                    }}
                  >
                    Back
                  </Button>
                  {activeStep !== config.steps.length - 1 && (
                    <Button
                      color='light'
                      size='small'
                      onClick={() => {
                        setActiveStep(activeStep + 1);
                      }}
                    >
                      Next
                    </Button>
                  )}
                </FlexLine>
              </Content>
            </Body>
          </ItemWrapper>
        ))}
      </Container>
    </Wrapper>
  );
};

export default Vertical;
