import Cover from './cover.png';
import Rachel from './rachellane.jpg';

const content = {
  title: 'Prenuptial agreements - are they legally binding in the UK?',
  date: '14/12/2022',
  readTime: 6,
  image: Cover,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Marriage', 'Estate Planning'],
  intro:
    'Whilst prenuptial agreements have been around in America since 1848, it has taken the UK a lot longer to recognise a ‘prenup’ as having any form of legal footing in the event of divorce.    ',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'Whilst prenuptial agreements have been around in America since 1848, it has taken the UK a lot longer to recognise a ‘prenup’ as having any form of legal footing in the event of divorce.        ',
      },
    ],

    [
      {
        content:
          'Indeed they are still somewhat of a grey area in the UK law, and due to the nature of preparing for the worst case scenario, it is often a conversation that couples don’t wish to have when in the throes of arranging what should be the happiest day of their lives. A more sensible view would be to perceive a prenup as a very open and honest way to start a marriage, helping to give a degree of clarity around pre-existing assets in the event of it breaking down in the future.',
      },
    ],

    [
      {
        content:
          'Essentially a prenup is a contract between two parties that is entered into, willingly and without any duress, within plenty of time before they actually marry. It states the assets that each party already owns and records what will happen to these assets should the marriage lead to divorce. Anyone can enter into such an agreement, regardless of the value of each party’s assets, as once two people marry, under UK law, all assets are “pooled together” and are treated as matrimonial assets. As an example if one person already owns property and the other doesn’t, in its simplest form, both parties can claim on that property should the worst happen.',
      },
    ],

    [
      {
        content:
          'Similarly if there is a significant difference in earning potential or future wealth opportunities between couples, the wealthier spouse is likely to be more adversely affected and therefore the prenup can add a level of protection.',
      },
    ],

    [
      {
        content:
          'This is also the case with pensions and savings, including safeguarding any future inheritances that may be due. If people marry at an older age, a prenup can either shield inheritance that is due from parents, but also ensure assets that are due to be passed to children or grandchildren are kept separate from any assets that are generated during the marriage and kept safe.        ',
      },
    ],
    [
      {
        content:
          'It should also make provision for debts, to ensure both partners are not liable should one spouse built up significant debts whilst married, and if one spouse owns a business, this should also be fully accounted for with provision made to safeguard commercial assets.',
      },
    ],

    [
      {
        content:
          'As this agreement could be used in a court it is absolutely advisable for both parties to take independent advice from their own solicitors. It is also important to point out that even when drawn by solicitors, a Court still has the power to override the prenup depending on the circumstance in which a couple finds themselves when a divorce is the last resort. A Court will look at the context of the agreement when it was made and the impact of holding the couple to its terms at the time of the relationship breaking down. In other words, if the prenup proves to be unfair to one side, a Court would not allow the agreement to force one spouse into financial hardship.',
      },
    ],
    [
      {
        content:
          'There are also certain guidelines that a Court will look for:',
      },
    ],
    [
      {
        content:
          'Each party must have fully disclosed all financial assets at the time when the prenup was made, and understand all financial resources and liabilities involved.',
      },
    ],

    [
      {
        content:
          'It is important that both sides have time to consider the agreement before signing it. If it is deemed to have been rushed, or forcibly signed very close to the wedding day, a Court could easily overturn it.',
      },
    ],

    [
      {
        content:
          'As alluded to earlier, both parties should seek their own independent legal advice and be happy with the outcome.',
      },
    ],

    [
      {
        content:
          'The UK are now moving much more towards the legal acceptance of prenups as long as they are drawn up properly and do not contravene the Matrimonial Causes Act. The cost to raise a prenup is usually between £1000-£3000 depending on the complexity and value of the assets, which is a small fraction of the cost for an average wedding which currently stands close to £20,000. They are also valid for couples who are entering civil partnerships as well as traditional weddings.',
      },
    ],
    [
      {
        content:
          'In conclusion, a prenup whilst not being particularly romantic, is not more likely to see a marriage end in tears, but start the union off on a more open, transparent level. It can be revised as life unfolds, especially where children are born or illnesses transpire and will last for the duration of the marriage itself. It is also worth pointing out that co-habitation agreements are equally as important for those couples living together without being married or in a civil partnership, and can provide peace of mind that the same level of protection is provided if the relationship deteriorates.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investments, and the income from them, can go down as well as up and you may not get back the full amount you invested.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Past performance is not a reliable indicator of future performance.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Investing in shares should be regarded as a long-term investment and should fit in with your overall attitude to risk and financial circumstances.',
      },
    ],
  ],
};

export default content;
