import React from 'react';
import styled from 'styled-components';

import AddToCartWidget from './AddToCartWidget';

const Container = styled.div`
  background: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;

const Wrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 2rem;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

const Content = styled.div`
  p {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    margin-bottom: 1rem;
  }
`;

const ContentTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
  margin-bottom: 1rem;
  span {
    margin-right: 0.5rem;
    font-weight: 300;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const FeatureWithImage = ({ config }) => {
  return (
    <Container>
      <Wrapper>
        {config.alignleft && <img src={config.img} alt={config.title} />}
        <Content>
          <ContentTitle>
            <span>£{config.cost}</span>
            {config.title}
          </ContentTitle>
          {config.content.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          <AddToCartWidget config={config} />
        </Content>
        {!config.alignleft && <img src={config.img} alt={config.title} />}
      </Wrapper>
    </Container>
  );
};

export default FeatureWithImage;
