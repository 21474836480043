import cover from './background.jpg';
import Rachel from './rachellane.jpg';

const StudentFinance = {
  title:
    'How to Protect your Kids Financial Future by instilling Good Money Habits',
  date: '07/07/2023',
  readTime: 12,
  image: cover,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['education', 'children'],
  intro:
    'Financial education can never start too young when it comes to our children, and establishing sound financial habits is beneficial, whatever their age.  A study conducted by the University of Cambridge* found that by the age of seven, the majority of children know the value of money and most money habits are set by age 9, so, it&apos;s vital to start moulding their financial behaviour early on.  Managing our money and making good financial decisions is something we all need to learn as it sets you up for life but the key is to teach children financial responsibility in an age-appropriate way.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'Financial education can never start too young when it comes to our children, and establishing sound financial habits is beneficial, whatever their age.  A study conducted by the University of Cambridge* found that by the age of seven, the majority of children know the value of money and most money habits are set by age 9, so, it&apos;s vital to start moulding their financial behaviour early on.  Managing our money and making good financial decisions is something we all need to learn as it sets you up for life but the key is to teach children financial responsibility in an age-appropriate way.',
      },
    ],
    [
      {
        content:
          'Your individual ability to manage money and make good decisions does not just happen overnight and sometimes it is a case of learning new habits, unlearning bad ones and relearning the right ones. Having these discussions as early as possible and into their adulthood is the best preparation for children to develop good money management skills. Here is how you can help your children grasp the basic concept of money and learn how to manage their finances whatever their age.',
      },
    ],
    [{ type: 'heading', content: '1. Let them handle small amounts of money' }],
    [
      {
        content:
          'Even as young as age 2 or 3 you can involve your child by letting them count out the cash at the supermarket or a restaurant and then hand it over.  Giving them their own purse and letting them pay for small items like sweets at the local shop is also great practice. Piggy banks are great fun when they are very young, as they can start saving their coins if they are given any money from relatives or have helped with small jobs around the home like tidying up their toys.',
      },
    ],
    [
      {
        type: 'heading',
        content: '2. Give them a weekly or monthly allowance',
      },
    ],
    [
      {
        content:
          'Everyone has a different idea of the amount they think is appropriate, but giving them an allowance from a young age (and increasing it as they get older) gets them used to handling money and making buying decisions. You can ask them to undertake specific chores to earn their allowance or to earn extra money on top. Whatever best suits your child and family situation, but it gives them decision-making power with their own money and the opportunity to fail, which helps them learn these lessons early on rather than in adulthood.',
      },
    ],
    [
      {
        type: 'heading',
        content: '3. Let them set a saving goal',
      },
    ],
    [
      {
        content:
          'Rather than encouraging your child to save for a long-term goal like their first car or university, start off with a short-term goal of a toy or a new game they really want and work up from this. Suggest that if they want something bigger, they will need to help save towards it via their allowance or doing chores. This allows them to identify their wants and lets them buy them with their own money. Therefore, when they start identifying wants that cost more than they actually have, they will know how to start saving for their new goal. Suggest splitting their money between spending now and saving for bigger purchases that they want; this will build a positive relationship with saving and a healthy understanding of spending.',
      },
    ],
    [
      {
        type: 'heading',
        content: '4. Research the best pre-paid card/debit card',
      },
    ],
    [
      {
        content:
          'When your child earns any money or is given money by family or friends, where it used to be in cash, following the pandemic far less of us now carry cash around and so many payments are now by card only. Swapping your child to a card as they get older has many advantages, as it helps teach them about money and how banking works. You can also monitor how much they spend! There are two options – one is a prepaid card which is available for children aged 6+ and the second is a debit card linked to a children&apos;s bank account for children 11+. They both work in a similar way, but there are some key differences.',
      },
    ],
    [
      {
        type: 'heading',
        content: 'Pre-Paid Card',
      },
    ],
    [
      {
        content:
          'As your child gets older, introduce them to parent managed cards to show them how to spend sensibly.  One way to do this is by setting up a pre-paid card like &apos;Go Henry&apos; which is a debit card linked to an app. You can then set tasks around the home and tick them off when they have completed them (or an older child can just download the app and tick tasks off themselves). You can set specific amounts for tasks and specify if they are daily, weekly or monthly.  At the end of each week they get paid via your bank and they can then use the card as a contactless payment card/online and it sends you text notifications of their spending. They can also move money over to a savings pot to save for a particular item. There is a small monthly fee, so it is always good to compare other options like &apos;Revolut&apos; and &apos;Rooster&apos; money.',
      },
    ],
    [
      {
        type: 'heading',
        content: 'Debit card',
      },
    ],
    [
      {
        content:
          'As a parent, you may have opened a bank account for your child at a young age so you have somewhere to add birthday or Christmas money into whilst contributing to it yourself. Whilst some accounts such as ISAs cannot be touched until the child reaches 18, others can be added to and have linked cards which enables your child to withdraw money with your permission.  A child (aged 11+) can transfer money to other accounts, set up direct debits/standing orders and they come with a debit card.  Alternatively, you can opt for a card which can only be used for cash withdrawals at ATMs.  Unlike prepaid cards, your child must apply for the account in their own name and most accounts then turn into an adult account when your child reaches 18.',
      },
    ],
    [
      {
        type: 'heading',
        content: '5. Set-up a Junior ISA',
      },
    ],
    [
      {
        content:
          'If you want to maximise tax free amounts for your child, opening a Junior ISA for them is a great option. You can add up to £9000 into it in the 2023/24 tax year and they can also add to it on a regular basis. However, they are not able to access the account or draw any money out until they reach 18. This is the best option for saving for their first car or a pot to start them off when they start university.',
      },
    ],
    [
      {
        content:
          'Financial management is a life tool and the earlier a child can start learning about money and gaining money management skills the better. This will make them more prepared for the future and take responsibility for their financial wellbeing from a young age. It will also help make them more tech savvy and learn how to avoid potential scammers. ',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'https://www.theguardian.com/money/2021/dec/13/how-to-teach-children-the-real-value-of-money',
      },
    ],
  ],
};

export default StudentFinance;
