import React from 'react';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.boxShadow};
  background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 7px;
  text-align: center;
  h1 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }
  h2 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }
  h3 {
    color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
    font-size: 0.7rem;
  }
`;

const LinkTile = ({ title, subtitle, icon, to, guidance }) => {
  return (
    <Wrapper to={to}>
      <Icon iconType={icon} size={3} color='linkBlue' margin='1rem auto' />
      <h1>{title}</h1>
      {subtitle && <h2>{subtitle}</h2>} {guidance && <h3>{guidance}</h3>}
    </Wrapper>
  );
};

export default LinkTile;
