import React from 'react';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  background: ${(props) =>
    props.backgroundColor && `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;

const QuoteWrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: ${(props) => (props.padding ? props.padding : ' 0 1rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  h3 {
    padding: 1rem 0;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
    font-size: 1.3rem;
  }
`;

const Quoter = styled.div`
  padding: 1rem 0;
  img {
    border-radius: 50%;
    height: 5rem;
  }
  h4 {
    font-weight: bold;
  }
  p {
    color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
  }
`;

const Centered = ({ config }) => {
  return (
    <Wrapper backgroundColor={config.backgroundColor}>
      {config.contents.map((item, index) => (
        <QuoteWrapper key={index} padding={config.padding}>
          <Icon iconType='quote' size={3} color='lightGrey' />
          <h3>"{item.quote}"</h3>
          <Quoter>
            <img src={item.photo} alt={item.name} />
            <h4>{item.name}</h4>
            <p>{item.role}</p>
          </Quoter>
        </QuoteWrapper>
      ))}
    </Wrapper>
  );
};

export default Centered;
