import React, { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import ConfigContext from '../../context/config/configContext';

const HelmetItems = () => {
  const configContext = useContext(ConfigContext);
  return (
    <Fragment>
      {!!configContext.config &&
        configContext.config.styling &&
        configContext.config.styling.fonts &&
        configContext.config.styling.fonts.href && (
          <Helmet>
            <link
              rel='stylesheet'
              href={configContext.config.styling.fonts.href}
            />
          </Helmet>
        )}
      {!!configContext.config &&
        configContext.config.styling &&
        configContext.config.styling.favico && (
          <Fragment>
            <Helmet>
              <link
                rel='shortcut icon'
                type='image/x-icon'
                href={configContext.config.styling.favico}
              />
            </Helmet>
            <Helmet>
              <link
                rel='icon'
                type='image/x-icon'
                href={configContext.config.styling.favico}
              />
            </Helmet>
          </Fragment>
        )}
      {!!configContext.config &&
        configContext.config.companyConfig &&
        configContext.config.companyConfig.author && (
          <Helmet>
            <meta
              name='author'
              content={configContext.config.companyConfig.author}
            />
          </Helmet>
        )}
      {!!configContext.config &&
        configContext.config.companyConfig &&
        configContext.config.companyConfig.keywords && (
          <Helmet>
            <meta
              name='keywords'
              content={configContext.config.companyConfig.keywords}
            />
          </Helmet>
        )}
      {!!configContext.config &&
        configContext.config.companyConfig &&
        configContext.config.companyConfig.description && (
          <Helmet>
            <meta
              name='description'
              content={configContext.config.companyConfig.description}
            />
          </Helmet>
        )}
    </Fragment>
  );
};

export default HelmetItems;
