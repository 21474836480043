import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
  }

  a {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    text-decoration: none;
  }

  a:hover {
  opacity: 0.7; cursor: pointer;
  }

h1 {

  font-weight: 400;
}
h2 {
 
  font-weight: 400;
}
h3 {
 
  font-weight: 400;
}
h4 {

  font-weight: 400;
}
h5 {
 
  font-weight: 400;
}
h6 {

  font-weight: 400;
}


`;

export default GlobalStyles;
