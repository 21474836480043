import cover from './graduates.jpg';
import Rachel from './rachellane.jpg';

const StudentFinance = {
  title: 'Managing student finance',
  date: '22/09/2021',
  readTime: 3,
  image: cover,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['education', 'loans'],
  intro:
    'Most students in England, Wales and Northern Ireland now take out loans to cover tuition fees and living costs, with an average debt of £45,000 for 2020 graduates. Focusing on this figure, however, can be misleading. The way these loans are structured means the majority will never repay the full amount, so the actual cost is far less.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'Most students in England, Wales and Northern Ireland now take out loans to cover tuition fees and living costs, with an average debt of £45,000 for 2020 graduates. Focusing on this figure, however, can be misleading. The way these loans are structured means the majority will never repay the full amount, so the actual cost is far less.',
      },
    ],
    [{ type: 'heading', content: 'Graduate tax' }],
    [
      {
        content:
          'Student debt is more akin to a tax, with graduates on higher salaries paying more. Students in England, Wales and Northern Ireland only start repaying their loans once their earnings reach a certain level – currently £27,295. In Scotland the rules are different – see below.',
      },
    ],
    [
      {
        content:
          'Repayments are equivalent to 9% of earnings above the threshold. Graduates earning £30,000, for example, repay 9% of £2,705 – £243.45 for that year and any outstanding debt is cancelled after 30 years.',
      },
    ],
    [{ type: 'heading', content: 'What can you borrow?' }],
    [
      {
        type: 'bullet',
        boldtext: 'Tuition fees loan:',
        content:
          'Students in England can apply for a loan of up to £9,250 a year to cover tuition fees.',
      },
    ],
    [
      {
        type: 'bullet',
        boldtext: 'Maintenance loan:',
        content:
          'Students in England can apply for a maximum of £9,488 (£12,382 in London) to cover living costs if they are studying away from home. This is means-tested against parental income, so many will get significantly less',
      },
    ],
    [{ type: 'heading', content: 'Regional differences' }],
    [
      {
        content:
          'The rules differ within the UK depending on where you reside.',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'Scottish students don’t pay tuition fees if they study at a Scottish institution. They can apply for a means-tested student loan and bursary to help with living costs. Repayment of the loan begins once earnings reach £25,000 a year for students taking out loans in 2021/22.',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'The maximum tuition fee for Welsh students studying in Wales is £9,000.',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'Northern Ireland residents studying in the country pay a maximum £4,530 in tuition fees. All outstanding debt is cancelled after 25 years. ',
      },
    ],
  ],
};

export default StudentFinance;
