import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  min-height: 60vh;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
`;

const Main = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;

const Help = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
`;
const DataCaptureStep = ({ config, data }) => {
  return (
    <Wrapper>
      <Main>
        <Title>{config.title}</Title>
      </Main>
      <Help>
        <img src={config.help.img} alt={config.help.title} />
      </Help>
    </Wrapper>
  );
};

export default DataCaptureStep;
