import office from './homeoffice.jpg';
import Rachel from './rachellane.jpg';

const ChangesToSelfEmployed = {
  title: 'Important changes for the self-employed',
  date: '24/09/2021',
  readTime: 3,
  image: office,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['tax', 'self-employed'],
  intro:
    'As well as details of the fifth and final Self-Employed Income Support Grant (closed as of the end of September), additional changes to taxation - actual and potential - came out which are worth bearing in mind for the future.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'As well as details of the fifth and final Self-Employed Income Support Grant (closed as of the end of September), additional changes to taxation - actual and potential - came out which are worth bearing in mind for the future.',
      },
    ],
    [{ type: 'heading', content: 'Loss carry back' }],
    [
      {
        content:
          'Guidance was issued on the new extended loss carry back provisions. For trade losses made in 2020/21 and 2021/22 tax years only, unrelieved losses can be carried back and set against profits of the same trade for three years before the tax year of the loss. The previous maximum period was one year. ',
      },
    ],
    [{ type: 'heading', content: 'Tax basis for the self-employed' }],
    [
      {
        content:
          'HMRC launched a consultation on changing the basis of self-employed taxation. At present your tax liability is generally based on your profits in the 12 months ending on your accounting date, e.g. if that date was 30 June, then your 2021/22 assessment would use your          profits for the year to 30 June 2021. ',
      },
    ],
    [
      {
        content:
          'From 2023/24, HMRC wants to tax actual profits made in the tax year. The result could be a large tax bill covering the 2022/23 transitional year – for profits from 30 June 2021 to 5 April 2023 for a 30 June accounting date. ',
      },
    ],
    [
      {
        content:
          'If the idea of a phased retirement appeals, but you want to avoid those lifestyle compromises, then the sooner you start planning the transition, the better. Assessing your income and expenditure as you move from work through to full retirement is key and not as simple as it might sound. For tax and other reasons, for example, it could make sense not to start drawing on your pension as soon as you stop full time work. Contact us to review your retirement options further',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of tax reliefs depends on your individual circumstances. Tax laws can change. The Financial Conduct Authority does not regulate tax advice. ',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investment and the income from it can go down as well as up and you may not get back the full amount you invested. Past performance is not a reliable indicator of future performance. Investing in shares should be regarded as a long-term investment and should fit with your overall attitude to risk and financial circumstances',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The Financial Conduct Authority does not regulate tax advice, and levels and bases of taxation and tax reliefs are subject to change and their value depends on individual circumstances. Tax laws can change.',
      },
    ],
  ],
};

export default ChangesToSelfEmployed;
