import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import PageLoader from '../Feedback/PageLoader';
import { Button } from '../Controls';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  box-shadow: ${(props) => props.theme.boxShadow};
  padding: 2rem;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  border-radius: 15px;
  @media only screen and (max-width: 800px) {
    box-shadow: none;
    margin-top: 2rem;
  }
`;

const AppName = styled.div`
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const Title = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  margin: 2rem 0 0.5rem 0;
`;

const SubTitle = styled.div`
  font-size: 1.4rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.8)`};
  margin: 0rem 0 0.5rem 0;
`;

const Form = styled.form`
  margin-top: 2rem;
`;

const InputGroup = styled.div`
  label {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    margin: 2rem 0 0.25rem 0;
    font-weight: bold;
  }
  input {
    width: 100%;
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
    outline: none;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
    border-radius: 10px;
    &:focus,
    &:active {
      outline: none;
      color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
      border: ${(props) =>
        `1px solid rgba(${props.theme.colors.success[0]},1)`};
    }
  }
`;

const LabelGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  a {
    margin-bottom: 0.25rem;
    font-weight: bold;
    font-size: 0.8rem;
    text-align: capitalize;
    color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

const OtherAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-size: 1.1rem;
  a {
    margin-left: 0.5rem;
    color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;

const AuthForm = ({
  appIcon,
  appName,
  customLoading,
  title,
  subtitle,
  submit,
  change,
  data,
  otherAction,
  loading,
  buttonLabel,
}) => {
  return (
    <Fragment>
      <Wrapper>
        {customLoading ? (
          <PageLoader />
        ) : (
          <Fragment>
            <AppName>
              <Icon
                iconType={appIcon}
                size={2.6}
                margin='0 0.5rem 0 0'
                color='primary'
              />
              {appName}
            </AppName>
            <Title>
              {title} {appName}
            </Title>
            <SubTitle>{subtitle}</SubTitle>
            <Form>
              {data.map((item, index) => (
                <InputGroup key={index}>
                  <LabelGroup>
                    <label>{item.label}</label>
                    {item.inLineAction && (
                      <Link to={item.inLineAction.route}>
                        {item.inLineAction.label}
                      </Link>
                    )}
                  </LabelGroup>
                  <input
                    type={item.type}
                    name={item.name}
                    value={item.value}
                    onChange={change}
                  />
                </InputGroup>
              ))}
            </Form>
            <Button block='true' onClick={submit} margin='3rem 0 1rem 0'>
              {loading ? 'Loading' : buttonLabel}
            </Button>
          </Fragment>
        )}
      </Wrapper>
      {!customLoading && !!otherAction && (
        <OtherAction>
          <p>{otherAction.text}</p>
          <Link to={otherAction.route}>{otherAction.label}</Link>
        </OtherAction>
      )}
    </Fragment>
  );
};

export default AuthForm;
