import React from 'react';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  max-width: 1100px;
  display: grid;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  padding: 2rem 1rem;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 2rem 1rem 2rem 0;
  }
`;

const Tile = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

const TwoByTwoFeatures = ({ config }) => {
  return (
    <Wrapper>
      {config.features.map((item, index) => (
        <Tile key={index}>
          <Icon iconType={item.icon} size={2} color='linkBlue' margin='1rem' />
          <div>
            <h4>{item.title}</h4>
            <p>{item.text}</p>
          </div>
        </Tile>
      ))}
    </Wrapper>
  );
};

export default TwoByTwoFeatures;
