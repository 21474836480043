import CompanyLogo from './assets/logo.png';
import styling from './styling';

import publicNavConfig from './public/publicNavConfig';
import footerConfig from './public/footerConfig';
import homeConfig from './public/homeConfig';
import aboutConfig from './public/aboutConfig';
import contactConfig from './public/contactConfig';
import newsConfig from './public/news/newsConfig';
import companyConfig from './companyConfig';
import portfolioConfig from './public/portfolioConfig';
import studioConfig from './public/studioConfig';
import servicesConfig from './public/servicesConfig';
import productConfig from './public/productConfig';
import checkoutConfig from './public/checkoutConfig';

const baseConfig = {
  tenant: 'llane',
  companyLogo: CompanyLogo,
  companyConfig: companyConfig,
  styling: styling,
  newsConfig: newsConfig,
  publicConfig: [
    homeConfig,
    aboutConfig,
    contactConfig,
    newsConfig,
    portfolioConfig,
    studioConfig,
    servicesConfig,
    productConfig,
    checkoutConfig,
  ],
  publicNavConfig: publicNavConfig,
  publicFooterConfig: footerConfig,
};

export default baseConfig;
