const accountsDataConfig = {
  name: {
    type: 'text',
    label: 'Company Name',
  },
  natureOfBusiness: {
    type: 'textArea',
    label: 'Nature of Business',
    includeTitle: true,
  },
  companyType: {
    type: 'select',
    label: 'Company Type',
    valueOptions: [
      { value: 'soleTrader', label: 'Sole Trader' },
      { value: 'partnership', label: 'Partnership' },
      { value: 'privateLimitedCompany', label: 'Private Limited Company' },
      { value: 'publicLimitedCompany', label: 'Public Limited Company' },
    ],
  },
  companyRegNumber: {
    type: 'text',
    label: 'Company Registration Number',
    visibilityRules: [
      {
        target: 'companyType',
        comparisionOperator: 'includes',
        valueTest: ['privateLimitedCompany', 'publicLimitedCompany'],
      },
    ],
  },
  companyYearEnd: {
    type: 'text',
    label: 'Company Year End',
  },
  PAYEReference: {
    type: 'text',
    label: 'PAYE Reference',
  },
  VATRegNumber: {
    type: 'text',
    label: 'VAT Registration Number',
  },
  connectedBusinesses: {
    type: 'textArea',
    label: 'Connected Businesses (if applicable)',
    includeTitle: true,
  },
  country: {
    type: 'text',
    label: 'Country',
  },
  website: {
    type: 'text',
    label: 'Website',
  },
  numberOfEmployees: {
    type: 'select',
    label: 'Number Of Employees',
    valueOptions: [
      { value: 'zeroToFive', label: '0 - 5' },
      { value: 'sixToTwenty', label: '6 - 20' },
      { value: 'twentyToFifty', label: '21 - 50' },
      { value: 'fiftyToTwoFifty', label: '51 - 250' },
      { value: 'twoFiftyToThousand', label: '251 - 1,000' },
      { value: 'twoThousandToFiveThousand', label: '1,001 - 5,000' },
      { value: 'FiveThousandPlus', label: '5,000 +' },
    ],
  },
  primaryContactName: {
    type: 'text',
    label: 'Full Name',
  },
  primaryContactEmail: {
    type: 'email',
    label: 'Email Address',
  },
  primaryContactPhoneNumber: {
    type: 'tel',
    label: 'Phone Number',
  },
  primaryContactBuildingName: {
    type: 'text',
    label: 'Building Name / Number',
  },
  primaryContactStreet: {
    type: 'text',
    label: 'Street',
  },
  primaryContactTown: {
    type: 'text',
    label: 'Town',
  },
  primaryContactCounty: {
    type: 'text',
    label: 'County',
  },
  primaryContactPostcode: {
    type: 'text',
    label: 'Postcode',
  },
  adminID: {
    isComplex: true,
    type: 'teamlookup',
    icon: 'usersingle',
    dbRef: 'users',
    label: 'Administrator',
    roleTypes: ['admin', 'manager'],
  },
  clientAdviserID: {
    isComplex: true,
    type: 'teamlookup',
    icon: 'usersingle',
    dbRef: 'users',
    label: 'Client Adviser',
    roleTypes: ['adviser'],
  },
  companyNotes: {
    type: 'textArea',
    label: 'Company Notes',
  },
};

export default accountsDataConfig;
