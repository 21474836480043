import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import checkIsVisible from '../../utils/checkIsVisible';
import SingleDocument from '../Documents/SingleDocument';

import InputGroup from '../Forms/InputGroup';
import Lookup from '../Forms/Lookup';
import TeamLookup from '../Forms/TeamLookup';
import RelatedRecords from '../Widgets/RelatedRecords';
import SpendingRecord from '../Widgets/SpendingRecord';
import Timeline from './Timeline';
import Token from './Token';

const Wrapper = styled.div`
  padding: 0.5rem;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const TileOuter = styled.div`
  width: calc(100%-2rem);
  margin: 0.5rem;
  margin-top: 1.5rem;
  h2 {
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
  }
  p {
    font-size: 0.9rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const Tile = styled.div`
  margin-top: 0.5rem;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  padding: 1rem;
  border-radius: 7px;
  box-shadow: ${(props) => props.theme.boxShadow};
  @media only screen and (max-width: 800px) {
    border-radius: 0;
    border: none;
    box-shadow: none;
    border-top: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]}, 0.5)`};
  }
`;

const InputTiles = ({
  config,
  dataRefs,
  handleChange,
  handleBlur,
  handleToggle,
  data,
  pageRef,
  docsRefs,
  directSave,
}) => {
  return (
    <Wrapper>
      {!!dataRefs &&
        !!config &&
        !!config.tiles &&
        config.tiles.map((tile, index) => (
          <Fragment key={index}>
            {!tile.namedComponent && (
              <TileOuter key={index}>
                <h2>{tile.title}</h2>
                {tile.subtitle && <p>{tile.subtitle}</p>}
                <Tile>
                  {tile.data.map((dataItem, index) => (
                    <Fragment key={index}>
                      {!!dataRefs[dataItem] &&
                        (!dataRefs[dataItem].visibilityRules ||
                          checkIsVisible(
                            data,
                            dataRefs[dataItem].visibilityRules
                          )) && (
                          <Fragment>
                            {!dataRefs[dataItem].isComplex ? (
                              <InputGroup
                                name={dataItem}
                                dataItemConfig={dataRefs[dataItem]}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleToggle={handleToggle}
                                dataItem={
                                  !!data[dataItem] &&
                                  data[dataItem].value !== undefined
                                    ? data[dataItem].value.seconds
                                      ? moment(
                                          data[dataItem].value
                                            .toDate()
                                            .toString()
                                        ).format('YYYY-MM-DD HH: MM')
                                      : data[dataItem].value
                                    : ''
                                }
                              />
                            ) : (
                              <Fragment>
                                {dataRefs[dataItem].type === 'lookup' && (
                                  <Lookup
                                    data={data[dataItem]}
                                    dataItemConfig={dataRefs[dataItem]}
                                  />
                                )}
                                {dataRefs[dataItem].type === 'token' && (
                                  <Token
                                    handleBlur={handleBlur}
                                    dataItemName={dataItem}
                                    tenant={data.tenant}
                                    data={
                                      !!data[dataItem] && data[dataItem].value
                                        ? data[dataItem].value
                                        : ''
                                    }
                                    dataItemConfig={dataRefs[dataItem]}
                                    userName={
                                      !!data.editableName &&
                                      data.editableName.value
                                        ? data.editableName.value
                                        : ''
                                    }
                                    userEmail={
                                      !!data.editableEmail &&
                                      !!data.editableEmail.value
                                        ? data.editableEmail.value
                                        : ''
                                    }
                                    userStage={
                                      !!data.stage && data.stage.value
                                        ? data.stage.value
                                        : 0
                                    }
                                  />
                                )}
                                {dataRefs[dataItem].type === 'teamlookup' && (
                                  <TeamLookup
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    dataItemName={dataItem}
                                    tenant={data.tenant}
                                    data={
                                      !!data[dataItem] && data[dataItem].value
                                        ? data[dataItem].value
                                        : ''
                                    }
                                    dataItemConfig={dataRefs[dataItem]}
                                  />
                                )}
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                    </Fragment>
                  ))}
                </Tile>
              </TileOuter>
            )}
            {!!tile.namedComponent &&
              tile.namedComponent.component === 'relatedRecords' && (
                <RelatedRecords
                  componentConfig={tile.namedComponent}
                  pageRef={pageRef}
                  mainrecordID={data.id}
                  title={tile.title}
                />
              )}
            {!!tile.namedComponent &&
              tile.namedComponent.component === 'document' && (
                <TileOuter key={index}>
                  <h2>{docsRefs[tile.namedComponent.recordRef].label}</h2>
                  {docsRefs[tile.namedComponent.recordRef].guidance && (
                    <p>{docsRefs[tile.namedComponent.recordRef].guidance}</p>
                  )}
                  <Tile>
                    <SingleDocument
                      currentContext={data}
                      updateContext={directSave}
                      documentRef={tile.namedComponent.recordRef}
                      clientID={data.id}
                      tenantName={data.tenant}
                      saveFolder={
                        docsRefs[tile.namedComponent.recordRef].saveFolder
                      }
                      metaInputs={docsRefs[tile.namedComponent.recordRef].meta}
                      pageRef={pageRef}
                    />
                  </Tile>
                </TileOuter>
              )}
            {!!tile.namedComponent &&
              tile.namedComponent.component === 'spendingPlanner' && (
                <SpendingRecord
                  currentContext={data}
                  pageRef={pageRef}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleToggle={handleToggle}
                />
              )}
            {!!tile.namedComponent &&
              tile.namedComponent.component === 'timeline' && (
                <Timeline itemID={data.id} pageRef={pageRef} />
              )}
          </Fragment>
        ))}
    </Wrapper>
  );
};

export default InputTiles;
