import {
  GET_MAINRECORDS,
  ADD_MAINRECORD,
  DELETE_MAINRECORD,
  SET_CURRENT_MAINRECORD,
  CLEAR_CURRENT_MAINRECORD,
  MAINRECORD_ERROR,
  CLEAR_MAINRECORDS,
  SET_LOADING,
  UPDATE_MAINRECORD,
  FILTER_MAINRECORDS,
  CLEAR_MAINRECORD_FILTER,
} from '../types';

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_MAINRECORDS:
      return {
        ...state,
        records: action.payload.records,
        tenants: action.payload.tenants,
        advisers: action.payload.advisers,
        mainCount: action.payload.mainCount,
        outcomesAggregate: action.payload.outcomesAggregate,
        stagesAggregate: action.payload.stagesAggregate,
        productTypesAggregate: action.payload.productTypesAggregate,
        tenantAggregate: action.payload.tenantAggregate,
        adviserAggregate: action.payload.adviserAggregate,
        valueGroupsAggregate: action.payload.valueGroupsAggregate,
        timelineAggregate: action.payload.timelineAggregate,
        loading: false,
      };
    case ADD_MAINRECORD:
      return {
        ...state,
        records: [action.payload, ...state.records],
        loading: false,
      };
    case UPDATE_MAINRECORD:
      return {
        ...state,
        records: state.records.map((record) => {
          return record.id === action.payload.id ? action.payload : record;
        }),
        current: action.payload,
        loading: false,
      };
    case DELETE_MAINRECORD:
      return {
        ...state,
        records: state.records.filter((record) => record.id !== action.payload),
        loading: false,
      };
    case CLEAR_MAINRECORDS:
      return {
        ...state,
        records: null,
        filtered: null,
        error: null,
        current: null,
        mainCount: null,
        outcomesAggregate: null,
        stagesAggregate: null,
        productTypesAggregate: null,
        tenantAggregate: null,
        adviserAggregate: null,
        valueGroupsAggregate: null,
        timelineAggregate: null,
      };
    case SET_CURRENT_MAINRECORD:
      return {
        ...state,
        current: action.payload,
      };
    case CLEAR_CURRENT_MAINRECORD:
      return {
        ...state,
        current: null,
      };
    case FILTER_MAINRECORDS:
      return {
        ...state,
        tenantFilter: action.payload.tenantFilter,
        adviserFilter: action.payload.adviserFilter,
        filtered: action.payload.processedData.records,
        mainCount: action.payload.processedData.mainCount,
        outcomesAggregate: action.payload.processedData.outcomesAggregate,
        stagesAggregate: action.payload.processedData.stagesAggregate,
        productTypesAggregate:
          action.payload.processedData.productTypesAggregate,
        tenantAggregate: action.payload.processedData.tenantAggregate,
        adviserAggregate: action.payload.processedData.adviserAggregate,
        valueGroupsAggregate: action.payload.processedData.valueGroupsAggregate,
        timelineAggregate: action.payload.processedData.timelineAggregate,
        loading: false,
      };
    case CLEAR_MAINRECORD_FILTER:
      return {
        ...state,
        tenantFilter: action.payload.tenantFilter,
        adviserFilter: action.payload.adviserFilter,
        filtered: null,
        mainCount: action.payload.processedData.mainCount,
        outcomesAggregate: action.payload.processedData.outcomesAggregate,
        stagesAggregate: action.payload.processedData.stagesAggregate,
        productTypesAggregate:
          action.payload.processedData.productTypesAggregate,
        tenantAggregate: action.payload.processedData.tenantAggregate,
        adviserAggregate: action.payload.processedData.adviserAggregate,
        valueGroupsAggregate: action.payload.processedData.valueGroupsAggregate,
        timelineAggregate: action.payload.processedData.timelineAggregate,
        loading: false,
      };
    case MAINRECORD_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
