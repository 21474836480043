import favico from './assets/favicon.ico';

const styling = {
  colors: {
    neutral: [
      '0, 0, 0',
      '34, 34, 34',
      '68, 68, 68',
      '153, 153, 153',
      '238, 238, 238',
      '255, 255, 255',
    ],
    primary: [
      '234, 196, 184',
      '238, 208, 198',
      '242, 220, 212',
      '247, 231, 227',
      '251, 243, 241',
    ],
    secondary: [
      '173, 219, 248',
      '173, 219, 248',
      '173, 219, 248',
      '173, 219, 248',
      '173, 219, 248',
    ],
    tertiary: [
      '158, 130, 118',
      '177, 155, 145',
      '197, 180, 173',
      '216, 205, 200',
      '236, 230, 228',
    ],
    danger: [
      '252, 229, 232',
      '252, 229, 232',
      '252, 229, 232',
      '252, 229, 232',
      '252, 229, 232',
    ],
    success: [
      '182, 226, 211',
      '197, 232, 220',
      '211, 238, 229',
      '226, 243, 237',
      '240, 249, 246',
    ],
    linkBlue: ['41, 160, 177'],
    bgColor: ['245, 237, 224'],
  },
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.1) 0px 2px 4px',
  fonts: {
    mainFontFamily: "'Petrona', serif",
    href: 'https://fonts.googleapis.com/css2?family=Petrona:wght@300;400;700&display=swap',
  },
  favico: favico,
};

export default styling;
