import React, { Fragment } from 'react';
import MainCount from '../Stats/MainCount';
import FlowStats from '../Stats/FlowStats';
import PieChartStats from '../Stats/PieChartStats';

import styled from 'styled-components';

const Grid = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  @media only screen and (max-width: 1150px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

const AnalyseTab = ({ data, pageConfig }) => {
  const mutatateToArray = (data) => {
    const asArray = [];
    Object.keys(data).forEach(function (objectKey, index) {
      asArray.push({
        ...data[objectKey],
        label: objectKey,
      });
    });
    return asArray;
  };
  return (
    <Fragment>
      <Grid>
        <PieChartStats
          config={pageConfig.analysisTabConfig.aggregateConfig}
          data={data.outcomesAggregate}
          title='Status'
          valueToChart={'count'}
        />
        <MainCount
          data={data.mainCount}
          config={pageConfig.analysisTabConfig.aggregateConfig}
        />
      </Grid>
      <FlowStats
        config={pageConfig.analysisTabConfig.aggregateConfig}
        data={data.stagesAggregate}
        title='Stage'
      />
      {pageConfig.keyFutureDataReference && (
        <FlowStats
          config={pageConfig.analysisTabConfig.aggregateConfig}
          data={data.timelineAggregate.slice(0, 5)}
          title='Expectation Date'
        />
      )}
      <FlowStats
        config={pageConfig.analysisTabConfig.aggregateConfig}
        data={mutatateToArray(data.adviserAggregate)}
        title='Adviser'
        disableNumber={true}
      />
    </Fragment>
  );
};

export default AnalyseTab;
