import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ConfigContext from '../../context/config/configContext';
import MailingListSimpleWidget from '../Banners/MailingListSimpleWidget';

import Icon from '../UI/Icon';

const Wrapper = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.tertiary[0]}, 1)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  padding-bottom: 1rem;
`;

const Triangle = styled.div`
  @media only screen and (max-width: 800px) {
    display: none;
  }
  height: 6rem;
  border: none;
  outline: none;
  background: ${(props) => `linear-gradient(
    to bottom right,
    rgba(${props.theme.colors.bgColor[0]}, 1) calc(50% - 1px),
    rgba(${props.theme.colors.tertiary[0]}, 1) calc(50% + 1px)
  )`};
`;
const Container = styled.div`
  padding: 2rem;
  max-width: 1100px;
  margin: auto;
`;

const MainFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
    text-transform: capitalize;
    margin-bottom: 0.25rem;
  }
  @media only screen and (max-width: 800px) {
    padding: 2rem 0;
  }
`;

const ContactsWrapper = styled.div``;

const Socials = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const TelLink = styled.div`
  margin-top: 1rem;
  a {
    color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
    display: flex;
    align-items: center;
    @media only screen and (max-width: 800px) {
      justify-content: center;
    }
  }
`;

const SubFooter = styled.div`
  padding: 2rem;
  max-width: 1100px;
  margin: auto;
  @media only screen and (max-width: 800px) {
    text-align: center;
  }
`;

const PublicFooter = () => {
  const configContext = useContext(ConfigContext);
  return (
    <Fragment>
      <Wrapper>
        {!configContext.config.publicFooterConfig.noTriangle && <Triangle />}
        <Container>
          <MainFooter>
            <div>
              <h2>{configContext.config.companyConfig.companyName}</h2>
              <div>
                {configContext.config.companyConfig.registeredOffice.map(
                  (item, index) => (
                    <p key={index}>{item}</p>
                  )
                )}
              </div>
              <h3>
                © {new Date().getFullYear()}{' '}
                {configContext.config.companyConfig.companyName}
              </h3>
            </div>
            <Menu>
              {configContext.config.publicFooterConfig.menu.map((item) => (
                <Fragment key={item.route}>
                  {item.external ? (
                    <a href={item.route}>{item.label}</a>
                  ) : (
                    <Link to={item.route}>{item.label}</Link>
                  )}
                </Fragment>
              ))}
            </Menu>
            <ContactsWrapper>
              <Socials>
                {!!configContext.config.companyConfig.email && (
                  <a
                    href={`mailto:${configContext.config.companyConfig.email}`}
                  >
                    <Icon
                      iconType='mail'
                      size={2}
                      color='dark'
                      background='light'
                      isTile={true}
                      margin='0 0.5rem 0 0'
                    />
                  </a>
                )}
                {!!configContext.config.companyConfig.linkedin && (
                  <a href={configContext.config.companyConfig.linkedin}>
                    <Icon
                      iconType='linkedin'
                      size={2}
                      color='dark'
                      background='light'
                      margin='0 0.5rem 0 0'
                      isTile={true}
                    />
                  </a>
                )}
                {!!configContext.config.companyConfig.twitter && (
                  <a href={configContext.config.companyConfig.twitter}>
                    <Icon
                      iconType='twitter'
                      size={2}
                      color='dark'
                      background='light'
                      margin='0 0.5rem 0 0'
                      isTile={true}
                    />
                  </a>
                )}
                {!!configContext.config.companyConfig.facebook && (
                  <a href={configContext.config.companyConfig.facebook}>
                    <Icon
                      iconType='facebook'
                      size={2}
                      color='dark'
                      background='light'
                      margin='0 0.5rem 0 0'
                      isTile={true}
                    />
                  </a>
                )}
                {!!configContext.config.companyConfig.instagram && (
                  <a href={configContext.config.companyConfig.instagram}>
                    <Icon
                      iconType='instagram'
                      size={2}
                      color='dark'
                      background='light'
                      margin='0 0.5rem 0 0'
                      isTile={true}
                    />
                  </a>
                )}
              </Socials>
              {!!configContext.config.companyConfig.tel && (
                <TelLink>
                  <a href={'tel:' + configContext.config.companyConfig.tel}>
                    <Icon
                      iconType='phone'
                      size={1}
                      color='light'
                      margin='0 0.5rem 0 0'
                    />{' '}
                    {configContext.config.companyConfig.tel}
                  </a>
                </TelLink>
              )}
              {!!configContext.config.companyConfig.fax && (
                <TelLink>
                  <a href={'tel:' + configContext.config.companyConfig.fax}>
                    <Icon
                      iconType='fax'
                      size={1}
                      color='light'
                      margin='0 0.5rem 0 0'
                    />{' '}
                    {configContext.config.companyConfig.fax}
                  </a>
                </TelLink>
              )}
              {configContext.config.companyConfig.mailingTitle && (
                <MailingListSimpleWidget lightTitle={true} />
              )}
            </ContactsWrapper>
          </MainFooter>
        </Container>
      </Wrapper>

      <SubFooter>
        {!!configContext.config.publicFooterConfig.subfooterText &&
          configContext.config.publicFooterConfig.subfooterText.map(
            (item, index) => (
              <div key={index} style={{ marginBottom: '1rem' }}>
                {item}
              </div>
            )
          )}
        {configContext.config.publicFooterConfig.websitePoweredBy && (
          <div>
            Website by{' '}
            <a
              href={
                configContext.config.publicFooterConfig.websitePoweredBy.link
              }
            >
              {configContext.config.publicFooterConfig.websitePoweredBy.label}
            </a>
          </div>
        )}
        <div style={{ marginTop: '1rem' }}>
          <Link to='/terms'>Terms & Conditions</Link> |{' '}
          <Link to='/terms'>Privacy Policy & Cookies</Link>
        </div>
      </SubFooter>
    </Fragment>
  );
};

export default PublicFooter;
