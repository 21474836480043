const stages = ['Active', 'Inactive'];
const outcomes = ['Current', 'Old'];
const employmentConfig = {
  title: 'Employment Records',
  name: 'Employment',
  addBtnLabel: 'Create Employment Record',
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Job Title' },
  breadcrumbs: [
    { type: 'simpleLink', label: 'Private Clients', route: '/contacts' },
    {
      type: 'relatedRecord',
      dbRef: 'contacts',
      recordRefFromCurrent: 'contactsID',
    },
    { type: 'simpleLabel', label: 'Employment Record' },
    { type: 'currentRecord' },
  ],
  stages: stages,
  outcomes: outcomes,
  manageTabConfig: {
    filterByLabel: 'Job Title',
    tableConfig: [
      { label: 'Job Title', ref: 'name' },
      { label: 'Employer', ref: 'employerName' },
      { label: 'Start Date', ref: 'startDate' },
      { label: 'End Date', ref: 'endDate' },
      { label: 'Basis', ref: 'employmentBasis' },
      { label: 'Status', ref: 'employmentStatus' },
    ],
  },
  newDataSteps: [
    {
      title: 'Role Details',
      data: [
        { ref: 'name', mandatory: true },
        { ref: 'employerName', mandatory: true },
        { ref: 'primaryEmployment' },
        { ref: 'employmentBasis', mandatory: true },
        { ref: 'employmentStatus', mandatory: true },
      ],
    },
    {
      title: 'Dates',
      data: [
        { ref: 'startDate', mandatory: true },
        { ref: 'probationEndDate' },
        { ref: 'endDate' },
      ],
    },
    {
      title: 'Notes',
      guidance: 'Optional.',
      data: [{ ref: 'notes' }],
    },
  ],
  recordEditTabs: ['summary'],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Employment Records' },
  },
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Client Record',
          data: ['contactsID'],
        },
        {
          title: 'Role Details',
          data: [
            'name',
            'employerName',
            'primaryEmployment',
            'employmentBasis',
            'employmentStatus',
          ],
        },
        {
          title: 'Dates',
          data: ['startDate', 'probationEndDate', 'endDate'],
        },
        {
          title: 'Notes',
          data: ['notes'],
        },
      ],
    },
  ],
  data: {
    name: {
      type: 'text',
      label: 'Job Title',
    },
    employerName: {
      type: 'text',
      label: 'Employer Name',
    },
    startDate: {
      type: 'date',
      label: 'Start Date',
    },
    endDate: {
      type: 'date',
      label: 'End Date',
    },
    probationEndDate: {
      type: 'date',
      label: 'Probation End Date',
    },
    primaryEmployment: {
      type: 'select',
      label: 'Primary Employment',
      valueOptions: [
        { value: 'primaryEmployment', label: 'Primary Employment' },
        {
          value: 'nonprimaryEmployment',
          label: 'Not Primary Employment',
        },
      ],
    },
    employmentBasis: {
      type: 'select',
      label: 'Employment Basis',
      valueOptions: [
        { value: 'fullTime', label: 'Full time' },
        { value: 'partTime', label: 'Part time' },
        { value: 'contract', label: 'Contract time' },
      ],
    },
    employmentStatus: {
      type: 'select',
      label: 'Employment Status',
      valueOptions: [
        { value: 'employed', label: 'Employed' },
        { value: 'selfEmployed', label: 'Self Employed' },
        { value: 'companyDirector', label: 'Company Director' },
        { value: 'retired', label: 'Retired' },
        { value: 'unemployed', label: 'Unemployed' },
        { value: 'houseperson', label: 'Houseperson' },
        { value: 'student', label: 'Student' },
        { value: 'maternityLeave', label: 'Maternity Leave' },
        { value: 'longTermIllness', label: 'Long Term Illness' },
        { value: 'contractWorker', label: 'Contract Worker' },
      ],
    },
    notes: {
      type: 'textArea',
      label: 'Notes',
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
  },
};

export default employmentConfig;
