const publicNavConfig = {
  main: [
    {
      ref: 'about',
      label: 'about',
      submenu: [
        {
          route: '/about',
          label: 'Team',
          description: 'A formidable team for our clients’ benefit',
          icon: 'team',
        },
        {
          route: '/approach',
          label: 'Approach',
          description: 'We deliver a proactive and bespoke advice service',
          icon: 'income',
        },
        {
          route: '/news',
          label: 'News',
          description: 'News, publications, blogs and announcements',
          icon: 'rssFeed',
        },
      ],
    },
    {
      route: '/personal',
      label: 'Personal',
    },
    {
      route: '/corporate',
      label: 'Business',
    },
  ],
  footer: [
    {
      route: 'https://www.seminars.grclients.com',
      label: 'Sign In',
      external: true,
    },
    { route: '/contact', label: 'Contact Us' },
  ],
  mobileCTA: {
    external: true,
    route: 'https://www.seminars.grclients.com',
    icon: 'profile',
  },
};
export default publicNavConfig;
