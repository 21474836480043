import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { db } from '../../context/firebase';
import styled from 'styled-components';

import MainrecordContext from '../../context/mainrecord/mainrecordContext';

import Icon from '../UI/Icon';

const Wrapper = styled.div`
  h3 {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.removeStyling ? 'none' : `0.75rem 1rem`)};
  font-size: ${(props) => (props.removeStyling ? 'none' : `1rem`)};
  background: ${(props) =>
    props.removeStyling ? 'none' : `rgba(${props.theme.colors.neutral[5]}, 1)`};
  color: ${(props) =>
    props.removeStyling
      ? `rgba(${props.theme.colors.neutral[2]},1)`
      : `rgba(${props.theme.colors.neutral[2]},1)`};
  outline: none;
  border: ${(props) =>
    props.removeStyling
      ? 'none'
      : `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
  border-radius: ${(props) => (props.removeStyling ? 'none' : '10px')};
  text-transform: capitalize;
  &:hover {
    outline: none;
    color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]},1)`};
    border: ${(props) =>
      props.removeStyling
        ? 'none'
        : `2px solid rgba(${props.theme.colors.linkBlue[0]},1)`};
    background: ${(props) =>
      props.removeStyling
        ? 'none'
        : `rgba(${props.theme.colors.neutral[4]}, 0.2)`};
    cursor: pointer;
    opacity: 0.7;
  }
`;

const Lookup = ({ data, dataItemConfig, removeStyling }) => {
  const history = useHistory();
  const mainrecordContext = useContext(MainrecordContext);

  //get record
  const [record, setRecord] = useState({});

  const getRecord = async (dbRef, recordID) => {
    const docRef = db.collection(dbRef).doc(recordID);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setRecord(doc.data());
        } else {
          console.log('no record');
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!!data && !!dataItemConfig && !!isMounted) {
      getRecord(dataItemConfig.dbRef, data);
    }
    return () => {
      isMounted = false;
    };
  }, [data, dataItemConfig]);

  const handleSelect = () => {
    mainrecordContext.setCurrent([record]);
    history.push('/' + dataItemConfig.dbRef + 'record');
  };
  return (
    <Fragment>
      {!!record && !!data && (
        <Wrapper
          onClick={() => {
            handleSelect();
          }}
          removeStyling={removeStyling}
        >
          {!!dataItemConfig.label && <h3>{dataItemConfig.label}</h3>}
          <LinkWrapper removeStyling={removeStyling}>
            {!!dataItemConfig.icon && (
              <Icon
                iconType={dataItemConfig.icon}
                size={2}
                color='primary'
                margin='0 1rem 0 0'
              />
            )}
            {!!record.name && !!record.name.value && record.name.value}
          </LinkWrapper>
        </Wrapper>
      )}
    </Fragment>
  );
};

export default Lookup;
