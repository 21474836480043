import React, { useState, useContext, useEffect } from 'react';

import AuthContext from '../context/auth/authContext';
import AlertContext from '../context/alert/alertContext';
import AuthFormWithQuote from '../components/Authentication/AuthFormWithQuote';

const Login = (props) => {
  const [customLoading, setCustomLoading] = useState(false);

  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const { login, error, clearErrors, isAuthenticated, loadUser, loading } =
    authContext;

  useEffect(() => {
    loadUser();

    if (isAuthenticated) {
      setCustomLoading(true);
      setTimeout(() => {
        props.history.push('/dashboard');
      }, 500);
    }

    if (!!error && error.code === 'auth/wrong-password') {
      setAlert('Invalid email address or password', 'danger');
      clearErrors();
    } else if (!!error) {
      setAlert(error.message, 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const { email, password } = user;

  const onChange = (e) =>
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    if (email === '' || password === '') {
      setAlert('Please fill in all fields', 'danger');
    } else {
      login({ email, password });
    }
  };
  return (
    <AuthFormWithQuote
      customLoading={customLoading}
      submit={onSubmit}
      change={onChange}
      data={[
        { name: 'email', type: 'email', label: 'Email', value: email },
        {
          name: 'password',
          type: 'password',
          label: 'Password',
          value: password,
          inLineAction: {
            label: 'Forgot Password?',
            route: '/password-reset',
          },
        },
      ]}
      otherAction={{
        text: "Don't have an account?",
        label: 'Sign Up',
        route: '/register',
      }}
      loading={loading}
      buttonLabel='Log In'
      title='Welcome back to'
      subtitle='Log in to your account'
    />
  );
};

export default Login;
