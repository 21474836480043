import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${(props) =>
    props.background === 'true' && `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 4rem 2rem;
`;

const Title = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.4rem;
  color: ${(props) => `rgba(${props.theme.colors.tertiary[0]}, 1)`};
  margin-bottom: 2rem;
`;

const SubTitle = styled.div`
  text-align: center;
  color: ${(props) => `rgba(${props.theme.colors.tertiary[0]}, 1)`};
  max-width: 800px;
  margin: auto;
  p {
    margin-bottom: 0.5rem;
  }
`;

const Hashtags = styled.div`
  text-align: center;
  color: ${(props) => `rgba(${props.theme.colors.tertiary[0]}, 1)`};
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 2rem auto;
  p {
    margin-bottom: 0.5rem;
    min-width: 300px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1.6rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
`;

const ContentItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  img {
    width: 400px;
    border-radius: 10px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  p {
    max-width: 300px;
    text-align: center;
    color: ${(props) => `rgba(${props.theme.colors.tertiary[0]}, 1)`};
    line-height: 150%;
  }
`;

const ContentTitle = styled(Link)`
  text-align: center;
  font-size: 1.6rem;
  margin: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.4rem;
  color: ${(props) => `rgba(${props.theme.colors.tertiary[0]}, 1)`};
  margin-bottom: 2rem;
`;

const SimpleSideBySide = ({ config }) => {
  return (
    <Wrapper>
      <Container>
        <Title>{config.title}</Title>
        <SubTitle>
          {config.subTitle.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </SubTitle>
        <Hashtags>
          {config.hashTags.map((item, index) => (
            <p key={index}>#{item}</p>
          ))}
        </Hashtags>
        <ContentWrapper>
          {config.content.map((content, contentIndex) => (
            <ContentItem key={contentIndex}>
              <img src={content.photo} alt={content.title} />
              <ContentTitle to={content.route}>{content.title}</ContentTitle>
              <p>{content.paragraph}</p>
            </ContentItem>
          ))}
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default SimpleSideBySide;
