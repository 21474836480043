import fishflagpole from './fishflagpole.jpg';
import Rachel from './rachellane.jpg';

const PensionTripleLock = {
  title: 'Pension triple lock falls to double',
  date: '20/09/2021',
  readTime: 3,
  image: fishflagpole,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['pensions', 'inflation'],
  intro:
    'Next April’s increases to State pensions will now follow a less expected, and less expensive, path. What percentage increase should apply to State pensions in 2022? For all but the old basic State pension and the new State pension the answer is simple: the annual rate of CPI inflation to September 2021. For the last ten years, however, the triple lock has applied to the basic and new State pensions, meaning the increase has been the highest  of the CPI change, earnings growth or 2.5%. From the 2015 election onwards, all the major political parties signed up to maintaining the triple lock, although there is no legislation behind it. The law simply links the two pensions to earnings growth.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'Next April’s increases to State pensions will now follow a less expected, and less expensive, path. What percentage increase should apply to State pensions in 2022? For all but the old basic State pension and the new State pension the answer is simple: the annual rate of CPI inflation to September 2021. For the last ten years, however, the triple lock has applied to the basic and new State pensions, meaning the increase has been the highest  of the CPI change, earnings growth or 2.5%. From the 2015 election onwards, all the major political parties signed up to maintaining the triple lock, although there is no legislation behind it. The law simply links the two pensions to earnings growth.',
      },
    ],
    [
      {
        content:
          'Even that is not straightforward, because the method of measuring earnings growth is not spelled out but instead left as a decision for the Secretary of State at the Department for Work and Pensions (DWP). The practice in previous years has been to use the annual increase in average weekly earnings (including bonuses) in the May–July period. In 2020, there was no increase: Covid-19 meant a drop in earnings of 1.0% over the year. CPI inflation to September 2020 was only 0.5%, so the April 2021 increase was the 2.5% minimum.',
      },
    ],
    [
      {
        content:
          'As the UK economy emerges from the pandemic, earnings have rebounded. Some of the rise stems from statistical quirks, such as fewer low-paid jobs increasing average pay, but the net result is dramatic. The latest (April –June) annual rise was no less than 8.8%. If that had been applied to the basic and the new State pension, the cost would have been over £4bn extra a year according to the DWP',
      },
    ],
    [
      {
        type: 'bullet',
        boldtext: 'A manifest difficulty',
        content:
          'The Prime Minister was said to be reluctant to renege on his triple lock manifesto pledge. It was perhaps no small coincidence, therefore, that on the day Mr Johnson announced increases to national insurance contributions to fund health and social care reforms, the DWP revealed that next year’s basic and new State pension increases would take no account of earnings inflation: for 2022/23 only, the triple lock becomes a double lock.',
      },
    ],
    [
      {
        content:
          'Assuming 3% CPI inflation, that means the new State pension will rise to about £185 a week, rather than the £195 that it might have been. Even the latter is still a long way from enough for a comfortable retirement. ',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'You can check a forecast of your expected State pension on www.gov.uk/check-state-pension.',
      },
    ],
  ],
};

export default PensionTripleLock;
