import React from 'react';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  width: 100vw;
  overflow-x: hidden;
  min-height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(
    125deg, rgba(${props.theme.colors.primary[0]}, 0.97), rgba(${props.theme.colors.neutral[0]}, 0.55)), url(${props.bg}) `};
`;

const InnerWrap = styled.div`
  max-width: 1100px;
  margin: auto;
`;

const Container = styled.div`
  float: ${(props) => props.alignRight && 'right'};
  text-align: ${(props) => props.alignRight && 'right'};
  max-width: 950px;
  margin: auto;
  text-align: center;
  padding: 4rem 1rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.2rem;
  }

  h2 {
    padding: 1rem 0;
    font-weight: 300;
    font-size: 1.1rem;
  }
  svg {
    width: 100%;
  }

  h3 {
    color: ${(props) => `rgba(${props.theme.colors.secondary[0]}, 1)`};
    font-weight: bold;
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 800px) {
    padding: 7rem 2rem 2rem 2rem;
  }
`;

const Triangle = styled.div`
  @media only screen and (max-width: 800px) {
    display: none;
  }
  height: 6rem;
  border: none;
  outline: none;
  background: ${(props) => `linear-gradient(
    to bottom right,
    rgba(${props.theme.colors.neutral[5]}, 1) calc(50% - 1px),
    rgba(${props.theme.colors.primary[0]}, 0) calc(50% + 1px)
  )`};
`;

const HeroWithImageBackground = ({ config }) => {
  return (
    <Wrapper bg={config.img}>
      <Triangle />
      <InnerWrap>
        <Container alignRight={config.alignRight}>
          {!!config.quote && (
            <Icon iconType='quote' size={2} color='lightGrey' margin='auto' />
          )}
          {!config.quote &&
            config.title.map((item, index) => <h1 key={index}>{item}</h1>)}
          <h2>{config.sub}</h2>
          {!!config.quote &&
            config.title.map((item, index) => <h3 key={index}>{item}</h3>)}
        </Container>
      </InnerWrap>
    </Wrapper>
  );
};

export default HeroWithImageBackground;
