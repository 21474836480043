import React, { Fragment } from 'react';
import styled from 'styled-components';
import { LinkButton } from '../Controls';

const Wrapper = styled.div`
  background: ${(props) =>
    props.background === 'true' && `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 2rem 2rem;
`;

const Tile = styled.div`
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media only screen and (max-width: 600px) {
    display: block;
    img {
      height: 17rem;
      padding-right: 0rem;
    }
  }
`;

const TileInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: 2rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
    font-weight: bold;
  }
  h3 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    font-weight: bold;
    margin-top: 1rem;
  }
  p {
    margin: 1rem 0;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
  img {
    height: 185px;
    margin: auto;
    padding: 0.5rem 0;
    margin: 0.5rem 0;
  }
`;

const SmallWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 600px) {
    display: block;
  }
  > :first-child {
    margin-right: 2rem;
  }
`;

const WithImages = ({ config }) => {
  return (
    <Wrapper background={config.background && 'true'}>
      <Container>
        {config.content.map((item, index) => (
          <Fragment key={index}>
            {item.small ? (
              <SmallWrapper>
                {item.tiles.map((smallItem, index) => (
                  <Tile key={index}>
                    <img src={smallItem.photo} alt={smallItem.title} />
                    <TileInner>
                      <div>
                        <h2>{smallItem.title}</h2>
                        <h3>{smallItem.bold}</h3>
                        <p>{smallItem.paragraph}</p>
                      </div>
                      <LinkButton to={smallItem.cta.route} color='linkBlue'>
                        {smallItem.cta.label}
                      </LinkButton>
                    </TileInner>
                  </Tile>
                ))}
              </SmallWrapper>
            ) : (
              <Tile key={index}>
                {!item.alignRight && <img src={item.photo} alt={item.title} />}
                <TileInner>
                  <div>
                    <h2>{item.title}</h2>
                    <h3>{item.bold}</h3>
                    <p>{item.paragraph}</p>
                    {item.subPhoto && (
                      <img src={item.subPhoto} alt={item.title} />
                    )}
                  </div>
                  <LinkButton to={item.cta.route} color='linkBlue'>
                    {item.cta.label}
                  </LinkButton>
                </TileInner>
                {!!item.alignRight && <img src={item.photo} alt={item.title} />}
              </Tile>
            )}
          </Fragment>
        ))}
      </Container>
    </Wrapper>
  );
};

export default WithImages;
