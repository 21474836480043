import React, { useReducer } from 'react';
import firebase, { db, functions } from '../firebase';
import AuthContext from './authContext';
import authReducer from './authReducer';

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  USER_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  VERIFY_EMAIL_COMPLETE,
  PASSWORD_RESET,
  VERIFY_EMAIL,
  AUTH_LOADING,
  UPDATE_USER,
} from '../types';

const AuthState = (props) => {
  const initalState = {
    isAuthenticated: null,
    loading: true,
    user: null,
    userData: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initalState);

  // Load User
  const loadUser = (props) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        loadUserData(user)
          .then((userData) => {
            firebase
              .auth()
              .currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                let isAdviser = false;
                let isGroup = false;
                let tenant = false;
                if (idTokenResult.claims.isAdviser) {
                  isAdviser = true;
                }
                if (idTokenResult.claims.isGroup) {
                  isGroup = true;
                }
                if (idTokenResult.claims.tenant) {
                  tenant = idTokenResult.claims.tenant;
                }
                dispatch({
                  type: USER_LOADED,
                  payload: {
                    user,
                    userData: userData,
                    isAdviser,
                    isGroup,
                    tenant,
                  },
                });
              });
          })
          .catch((err) => {
            dispatch({
              type: AUTH_ERROR,
            });
          });
      } else {
        dispatch({
          type: AUTH_ERROR,
        });
      }
    });
  };

  const loadUserData = (user) => {
    const UserRecord = db
      .collection('users')
      .where('authUserID', '==', user.uid)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length !== 0 && querySnapshot.docs[0].exists) {
          return querySnapshot.docs[0].data();
        }
        return null;
      })
      .catch((err) => {
        return err;
      });
    return UserRecord;
  };

  // Register User
  const register = async (email, password, name, userID, tenant) => {
    dispatch({
      type: AUTH_LOADING,
    });
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        res.user
          .updateProfile({
            displayName: name,
          })
          .then(() => {
            !!userID && handleAccountClaimNewUser(res.user.uid, userID);
            !userID && handleNewAuthUser(email, name, res.user.uid, tenant);
            dispatch({
              type: REGISTER_SUCCESS,
              payload: res.data,
            });
            loadUser();
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: REGISTER_FAIL,
              payload: err,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: REGISTER_FAIL,
          payload: err,
        });
      });
  };

  // Update user Record
  const handleAccountClaimNewUser = (authID, userID) => {
    db.collection('users')
      .doc(userID)
      .update({ authUserID: authID })
      .then(() => {})
      .catch((err) => {
        dispatch({
          type: REGISTER_FAIL,
          payload: err,
        });
      });
    return 'complete';
  };

  // Create user Record
  const handleNewAuthUser = (email, name, authID, tenant) => {
    const newUser = db.collection('users').doc();
    const newContact = db.collection('contacts').doc();
    const newUserData = {
      name,
      email,
      authUserID: authID,
      id: newUser.id,
      status: 'New',
      directSignup: true,
      tenant: tenant,
      editableEmail: { value: email },
      editableName: { value: name },
      editableRole: { value: 'client' },
      role: 'client',
      editableTenant: { value: tenant },
      outcome: { value: 0 },
      recordType: 'users',
      stage: { value: 2 },
      contactsID: newContact.id,
    };
    const newContactData = {
      usersID: newUser.id,
      outcome: { value: 0 },
      stage: { value: 0 },
      tenant: tenant,
      id: newContact.id,
      directSignup: true,
      recordType: 'contacts',
      email: { value: email },
      name: { value: name },
    };
    newUser
      .set({
        ...newUserData,
        created: firebase.firestore.Timestamp.now(),
      })
      .then(() => {})
      .catch((err) => {
        dispatch({
          type: REGISTER_FAIL,
          payload: err,
        });
      });
    newContact
      .set({
        ...newContactData,
        created: firebase.firestore.Timestamp.now(),
      })
      .then(() => {})
      .catch((err) => {
        dispatch({
          type: REGISTER_FAIL,
          payload: err,
        });
      });

    return newUser;
  };

  // Update Logged In Count
  const updateUserLogins = functions.httpsCallable('updateUserRecordOnLogin');

  // Login User
  const login = async (formData) => {
    dispatch({
      type: AUTH_LOADING,
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(formData.email, formData.password)
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });

        loadUser();
        updateUserLogins();
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: err,
        });
      });
  };

  // Logout
  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        dispatch({ type: LOGOUT });
      })
      .catch(function (err) {
        console.log(err);
        dispatch({
          type: LOGIN_FAIL,
          payload: err.response.data.msg,
        });
      });
  };

  // Send Password Reset
  const passwordReset = async (email) => {
    dispatch({
      type: AUTH_LOADING,
    });
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({
          type: AUTH_ERROR,
          payload: {
            code: 'password-reset-success',
            message:
              'Message Send! Please check your emails for your reset link',
          },
        });
        dispatch({
          type: PASSWORD_RESET,
        });
        loadUser();
      })
      .catch((err) => {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };

  // Send Email Verification
  const verifyEmail = async () => {
    dispatch({
      type: AUTH_LOADING,
    });
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() => {
        dispatch({
          type: VERIFY_EMAIL,
        });

        dispatch({
          type: AUTH_ERROR,
          payload: {
            code: 'email-verify-danger',
            message:
              'Message Send! Please check your emails for your verification link',
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };

  // Process Email Verification
  const handleEmailVerified = async (actionCode) => {
    dispatch({
      type: AUTH_LOADING,
    });
    firebase
      .auth()
      .applyActionCode(actionCode)
      .then((res) => {
        dispatch({
          type: VERIFY_EMAIL_COMPLETE,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };

  // Update UserData
  const updateUserData = async (user) => {
    db.collection('users')
      .doc(user.id)
      .update({ ...user })
      .then(() => {
        dispatch({
          type: UPDATE_USER,
          payload: user,
        });
      })
      .catch((err) => {
        dispatch({
          type: USER_ERROR,
          payload: err,
        });
      });
  };

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        userData: state.userData,
        error: state.error,
        register,
        loadUser,
        login,
        logout,
        clearErrors,
        passwordReset,
        verifyEmail,
        handleEmailVerified,
        updateUserData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
