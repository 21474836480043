const annualise = (amount, frequency) => {
  const freq = frequency ? frequency : 'monthly';

  const mutatedFrequency = freq.toLowerCase();
  let mutatedAmount = parseInt(amount);

  if (mutatedFrequency === 'monthly') {
    mutatedAmount = mutatedAmount * 12;
  }
  if (mutatedFrequency === 'quarterly') {
    mutatedAmount = mutatedAmount * 4;
  }

  if (mutatedFrequency === 'bi-annual') {
    mutatedAmount = mutatedAmount * 2;
  }

  return mutatedAmount;
};

export default annualise;
