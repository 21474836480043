import React, { useState, Fragment, useContext, useEffect } from 'react';
import { db } from '../../context/firebase';

import ConfigContext from '../../context/config/configContext';
import AuthContext from '../../context/auth/authContext';

import MobileNavMenu from './MobileNavMenu';
import LargescreenAppNav from './LargescreenAppNav';
import MobileAppNavHeader from './MobileAppNavHeader';
import LargeScreenPublicNav from './LargeScreenPublicNav';
import MobilePublicNavHeader from './MobilePublicNavHeader';

const Navigation = ({ isPublic }) => {
  const configContext = useContext(ConfigContext);
  const authContext = useContext(AuthContext);

  //handle Mobile Menu Toggle
  const [showMobileNav, setShowMobileNav] = useState(false);

  const handleMobileNavToggle = () => {
    !showMobileNav ? setShowMobileNav(true) : setShowMobileNav(false);
  };

  //get Notifications

  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const getUnreadNotifications = (userID) => {
    let unreadCount = 0;
    db.collection('notifications')
      .where('adviserID', '==', userID)
      .orderBy('created')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const messageItem = { ...doc.data() };
          !messageItem.readByAdviser && (unreadCount += 1);
        });
        setUnreadNotifications(unreadCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    !!authContext.userData && getUnreadNotifications(authContext.userData.id);
  }, [authContext.userData]);

  //get Main Menu
  const [mainMenu, setMainMenu] = useState([]);
  useEffect(() => {
    if (!!isPublic) {
      const temp = [];
      configContext.config.publicNavConfig.main.forEach((page) => {
        page.submenu
          ? page.submenu.forEach((subPage) => {
              temp.push({ route: subPage.route, label: subPage.label });
            })
          : temp.push({ route: page.route, label: page.label });
      });
      setMainMenu(temp);
    } else {
      const temp = [];
      configContext.config.appNavConfig.main.forEach((page) => {
        (!page.groupOnly ||
          (!!authContext.userData && !!authContext.userData.isGroup)) &&
          temp.push({ route: page.route, label: page.label });
      });
      setMainMenu(temp);
    }
  }, [authContext.userData, isPublic, configContext]);

  //get Footer Menu
  const [footerMenu, setFooterMenu] = useState([]);
  useEffect(() => {
    if (!isPublic) {
      const temp = [];
      configContext.config.appNavConfig.footer.forEach((page) => {
        (!page.groupOnly ||
          (!!authContext.userData && !!authContext.userData.isGroup)) &&
          temp.push({
            route: page.route,
            label: page.label,
            external: page.external,
          });
      });
      setFooterMenu(temp);
    } else {
      const temp = [];
      configContext.config.publicNavConfig.footer.forEach((page) => {
        temp.push({
          route: page.route,
          label: page.label,
          external: page.external,
        });
      });
      setFooterMenu(temp);
    }
  }, [authContext.userData, isPublic, configContext]);

  return (
    <Fragment>
      {!isPublic && (
        <LargescreenAppNav
          appIcon={configContext.config.companyConfig.appIcon}
          appName={configContext.config.companyConfig.appName}
          unreadNotifications={unreadNotifications}
          mainMenu={mainMenu}
          footerMenu={footerMenu}
          userData={!!authContext.userData ? authContext.userData : false}
        />
      )}
      {!!isPublic && (
        <LargeScreenPublicNav
          companyLogo={configContext.config.companyLogo}
          fullConfig={configContext.config.publicNavConfig}
          footerMenu={footerMenu}
        />
      )}
      {!isPublic && (
        <MobileAppNavHeader
          userData={!!authContext.userData ? authContext.userData : false}
          handleMobileNavToggle={handleMobileNavToggle}
          showMobileNav={showMobileNav}
          appIcon={configContext.config.companyConfig.appIcon}
          appName={configContext.config.companyConfig.appName}
          unreadNotifications={unreadNotifications}
        />
      )}
      {!!isPublic && (
        <MobilePublicNavHeader
          handleMobileNavToggle={handleMobileNavToggle}
          showMobileNav={showMobileNav}
          companyLogo={configContext.config.companyLogo}
          cta={configContext.config.publicNavConfig.mobileCTA}
        />
      )}
      <MobileNavMenu
        handleMobileNavToggle={handleMobileNavToggle}
        showMobileNav={showMobileNav}
        mainMenu={mainMenu}
        footerMenu={footerMenu}
      />
    </Fragment>
  );
};

export default Navigation;
