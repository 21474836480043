import laptopimg from './portfolio.jpg';
import graph from './graph.jpg';
import Rachel from './rachellane.jpg';

const portfoliosummer = {
  title: 'Portfolio Rebalance - Summer 2021',
  date: '03/08/2020',
  readTime: 3,
  image: laptopimg,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Investing', 'Portfolio'],
  intro:
    'You know the feeling. Sometimes it just seems easier to leave things as they are for another year rather than take any action. Call it the ‘If it ain’t broke don’t fix it’ syndrome. But putting things off risks costly problems that could be avoided with regular maintenance.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'You know the feeling. Sometimes it just seems easier to leave things as they are for another year rather than take any action. Call it the ‘If it ain’t broke don’t fix it’ syndrome. But putting things off risks costly problems that could be avoided with regular maintenance.',
      },
    ],
    [
      {
        content:
          'As it is with central heating boilers, so it is with investment. A portfolio created several years ago can alter over time without the changes being obvious. The names on the investment will generally be the same, as will your share/ unit holdings, but much may have happened underneath:',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'Different sectors and different global markets will have performed differently. That is why diversification is so often highlighted as important: you do not want all your eggs in one basket.',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'The relative importance of markets or sectors may have changed. A classic example is China, which has risen from being just another emerging market to becoming the third largest global stock market after the US and Japan. ',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'Funds may have changed ownership, manager and/or investment approach, but still retain the same name.',
      },
    ],
    [{ type: 'heading', content: 'UNMANAGED FUNDS DRIFT' }],
    [
      {
        content:
          'As an example, imagine a portfolio split equally across five major investment sectors that was established on 30 April 2016. Each holding would account for 20% of the total. Five years later, based on the average performance for each of the sectors, the picture is rather different as illustrated in the chart below. ',
      },
    ],
    [{ type: 'image', content: graph, alt: 'Graph representing some data' }],
    [
      {
        content:
          'The North America sector (basically the US) is now 25.5% of the portfolio, while the Sterling Strategic Bond has shrunk to 14.4% and the UK All Companies to 16.5%. The overall result is a less diversified portfolio with 69.1% in overseas share markets against 60% in 2016. ',
      },
    ],
    [
      {
        content:
          'If the portfolio had been reviewed and rebalanced each year, it would not have drifted so far from its starting point. That is the penalty for investment inertia and why we recommend regular reviews, even if the result in some years is ‘no change’. ',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investment and the income from it can go down as well as up and you may not get back the full amount you invested. Past performance is not a reliable indicator of future performance. ',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Investing in shares should be regarded as a long-term investment and should fit in with your overall attitude to risk and financial circumstances.',
      },
    ],
  ],
};

export default portfoliosummer;
