const stages = ['Active', 'Inactive'];
const outcomes = ['Current', 'Old'];
const insuranceConfig = {
  title: 'Insurance Records',
  name: 'Insurance',
  addBtnLabel: 'Create Insurance Record',
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Type' },
  breadcrumbs: [
    { type: 'simpleLink', label: 'Private Clients', route: '/contacts' },
    {
      type: 'relatedRecord',
      dbRef: 'contacts',
      recordRefFromCurrent: 'contactsID',
    },
    { type: 'simpleLabel', label: 'Insurance Record' },
    { type: 'currentRecord' },
  ],
  stages: stages,
  outcomes: outcomes,
  manageTabConfig: {
    filterByLabel: 'Type',
    tableConfig: [
      { label: 'Type', ref: 'name' },
      { label: 'Provider', ref: 'provider' },
      { label: 'Premium', ref: 'payment' },
      { label: 'Sum Assured', ref: 'amount' },
    ],
  },
  newDataSteps: [
    {
      title: 'Details',
      data: [{ ref: 'name', mandatory: true }, { ref: 'provider' }],
    },
    {
      title: 'Payment Information',
      data: [{ ref: 'payment', mandatory: true }, { ref: 'amount' }],
    },
    {
      title: 'Notes',
      guidance: 'Optional.',
      data: [{ ref: 'notes' }],
    },
  ],
  recordEditTabs: ['summary'],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Insurance Records' },
  },
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Client Record',
          data: ['contactsID'],
        },
        {
          title: 'Details',
          data: ['name', 'provider'],
        },
        {
          title: 'Policy Information',
          data: ['payment', 'amount', 'startDate', 'endDate'],
        },
        {
          title: 'Notes',
          data: ['notes'],
        },
      ],
    },
  ],
  data: {
    name: {
      type: 'select',
      label: 'Type',
      valueOptions: [
        { value: 'lifeCover', label: 'Life Cover' },
        { value: 'incomeProtection', label: 'Income Protection' },
        { value: 'privateHealth', label: 'Private Health' },
        { value: 'buildingsAndContents', label: 'Buildings and Contents' },
        { value: 'other', label: 'Other' },
      ],
    },
    provider: {
      type: 'text',
      label: 'Provider',
    },
    payment: {
      type: 'number',
      label: 'Premium',
    },
    paymentFrequency: {
      type: 'select',
      label: 'Premium Frequency',
      valueOptions: [
        { value: 'monthly', label: 'Monthly' },
        { value: 'quarterly', label: 'Quarterly' },
        { value: 'biannual', label: 'Bi-Annual' },
        { value: 'annually', label: 'Annually' },
        { value: 'lumpsum', label: 'Lumpsum / One-off' },
      ],
    },
    amount: {
      type: 'number',
      label: 'Sum Assured',
    },
    rate: {
      type: 'number',
      label: 'Escalation',
    },
    startDate: {
      type: 'date',
      label: 'Start Date',
    },
    endDate: {
      type: 'date',
      label: 'End Date',
    },
    notes: {
      type: 'textArea',
      label: 'Notes',
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
  },
};

export default insuranceConfig;
