import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import ShoppingcartContext from '../../context/shoppingcart/shoppingcartContext';

import { Button, LinkButton } from '../Controls';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Count = styled.div`
  display: flex;
  align-items: center;
`;

const Total = styled.div`
  margin-left: 2rem;
  font-size: 1.5rem;
  letter-spacing: 0.4rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  text-transform: uppercase;
`;

const AddToCartWidget = ({ config, checkout }) => {
  const shoppingcartContext = useContext(ShoppingcartContext);
  const [currentProductCount, setCurrentProductCount] = useState(0);

  const handleCartChange = (direction) => {
    let update = currentProductCount + 1;
    if (direction === 'remove') {
      update = currentProductCount - 1;
    }
    setCurrentProductCount(update);
    shoppingcartContext.updateCart(config.productCode, update);
  };

  useEffect(() => {
    if (
      currentProductCount === 0 &&
      shoppingcartContext.cartItems[config.productCode]
    ) {
      setCurrentProductCount(shoppingcartContext.cartItems[config.productCode]);
    } // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      {!!currentProductCount && (
        <Button
          color='linkBlue'
          onClick={(e) => {
            handleCartChange('remove');
          }}
        >
          -
        </Button>
      )}
      {!!currentProductCount && (
        <Count>
          <Icon iconType='shopping' size={2} color='dark' />
          {currentProductCount}
        </Count>
      )}
      {!currentProductCount && (
        <Button
          color='linkBlue'
          onClick={(e) => {
            handleCartChange();
          }}
        >
          {!!config.cta && !!config.cta.label
            ? config.cta.label
            : 'Add To Cart'}
        </Button>
      )}
      {!!currentProductCount && (
        <Button
          color='linkBlue'
          onClick={(e) => {
            handleCartChange();
          }}
        >
          +
        </Button>
      )}
      {!!currentProductCount && !checkout && (
        <LinkButton to='/checkout'>
          <Icon iconType='shoppingCart' size={1} color='light' /> Checkout
        </LinkButton>
      )}
      {!!checkout && <Total>£{currentProductCount * config.cost}</Total>}
    </Wrapper>
  );
};

export default AddToCartWidget;
