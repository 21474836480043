const contactsDataConfig = {
  contactNotes: {
    type: 'textArea',
    label: 'Client Notes',
  },
  title: {
    type: 'select',
    label: 'Title',
    valueOptions: [
      { value: 'mr', label: 'Mr' },
      { value: 'mrs', label: 'Mrs' },
      { value: 'miss', label: 'Miss' },
      { value: 'ms', label: 'Ms' },
      { value: 'dr', label: 'Dr' },
    ],
  },
  name: {
    type: 'text',
    label: 'Full Name',
  },
  previousName: {
    type: 'text',
    label: 'Previous Name',
  },
  email: {
    type: 'email',
    label: 'Email Address',
  },
  homePhoneNumber: {
    type: 'tel',
    label: 'Home Phone Number',
  },
  workPhoneNumber: {
    type: 'tel',
    label: 'Work Phone Number',
  },
  mobilePhoneNumber: {
    type: 'tel',
    label: 'Mobile Phone Number',
  },
  preferredContactMethod: {
    type: 'select',
    label: 'Preferred Contact Method',
    valueOptions: [
      { value: 'email', label: 'Email' },
      { value: 'phone', label: 'Phone' },
      { value: 'any', label: 'Any' },
    ],
  },
  preferredContactDay: {
    type: 'select',
    label: 'Preferred Contact Day',
    valueOptions: [
      { value: 'monday', label: 'Monday' },
      { value: 'tuesday', label: 'Tuesday' },
      { value: 'wednesday', label: 'Wednesday' },
      { value: 'thursday', label: 'Thursday' },
      { value: 'friday', label: 'Friday' },
    ],
  },
  preferredContactTime: {
    type: 'select',
    label: 'Preferred Contact Time',
    valueOptions: [
      { value: 'morning', label: 'Morning' },
      { value: 'midday', label: 'Midday' },
      { value: 'afternoon', label: 'Afternoon' },
      { value: 'evening', label: 'Evening' },
    ],
  },
  healthStatus: {
    type: 'select',
    label: 'Health Status',
    valueOptions: [
      { value: 'excellent', label: 'Excellent' },
      { value: 'good', label: 'Good' },
      { value: 'poor', label: 'Poor' },
      { value: 'terminal', label: 'Terminal' },
    ],
  },
  smokerStatus: {
    type: 'select',
    label: 'Smoker Status',
    valueOptions: [
      { value: 'smoker', label: 'Smoker' },
      { value: 'nonSmoker', label: 'Non Smoker' },
    ],
  },
  maritalStatus: {
    type: 'select',
    label: 'Marital Status',
    valueOptions: [
      { value: 'single', label: 'Single' },
      { value: 'cohabiting', label: 'Cohabiting' },
      {
        value: 'marriedCivilPartnership',
        label: 'Married / Civil Partnership',
      },
      { value: 'widowed', label: 'Widowed' },
      { value: 'divorced', label: 'Divorced' },
    ],
  },
  dateOfBirth: {
    type: 'date',
    label: 'Date of Birth',
  },
  gender: { type: 'text', label: 'Gender' },
  nationality: { type: 'text', label: 'Nationality' },
  domicileStatus: { type: 'text', label: 'Domicile Status' },
  niNumber: { type: 'text', label: 'National Insurance Number' },
  marginalTaxRate: { type: 'text', label: 'Marginal Tax Rate' },
  scottishTaxPayer: {
    type: 'select',
    label: 'Scottish Tax Payer Status',
    valueOptions: [
      { value: 'scottishTaxPayer', label: 'Scottish Tax Payer' },
      { value: 'nonscottishTaxPayer', label: 'Non Scottish Tax Payer' },
    ],
  },
  adverseCreditHistory: {
    type: 'boolean',
    label: 'Adverse Credit History',
  },
  refusedMortgageLoan: {
    type: 'boolean',
    label: 'Ever Refused Mortgage / Loan',
  },
  judgementForDebtDefault: {
    type: 'boolean',
    label: 'Judgement for Debt / Default',
  },
  declaredBankrupt: {
    type: 'boolean',
    label: 'Declared Bankrupt',
  },
  arrangementWithCreditors: {
    type: 'boolean',
    label: 'Arrangement With Creditors',
  },
  failedToKeepUpRepayments: {
    type: 'boolean',
    label: 'Failed To Keep Up Repayments',
  },
  creditHistoryNotes: {
    type: 'textArea',
    includeTitle: true,
    label: 'Full Details - if answered "Yes" to any of the above',
  },
  adminID: {
    isComplex: true,
    type: 'teamlookup',
    icon: 'usersingle',
    dbRef: 'users',
    label: 'Administrator',
    roleTypes: ['admin', 'manager'],
  },
  clientAdviserID: {
    isComplex: true,
    type: 'teamlookup',
    icon: 'usersingle',
    dbRef: 'users',
    label: 'Client Adviser',
    roleTypes: ['adviser'],
  },
  leadReceivedDate: {
    type: 'date',
    group: 'caseInformation',
    label: 'Date Lead Received',
  },
  source: {
    type: 'select',
    group: 'caseInformation',
    label: 'Source',
    valueOptions: [
      { value: 'migration', label: 'Migration of Previous Pipeline' },
      { value: 'existingClient', label: 'Existing Client' },
      { value: 'clientReferral', label: 'New: Client Referral' },
      { value: 'introducerReferral', label: 'New: Introducer Referral' },
      { value: 'faceToFace', label: 'New: Face To Face' },
      { value: 'teleMarketing', label: 'New: Telemarketing' },
      { value: 'mailshot', label: 'New: Postal / Mailshot' },
      {
        value: 'socialMediaMarketing',
        label: 'New: Social Media Marketing',
      },
      { value: 'website', label: 'New: Website' },
      { value: 'marketingList', label: 'New: Marketing List' },
      { value: 'other', label: 'New: Other' },
    ],
  },
};

export default contactsDataConfig;
