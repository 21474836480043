import logo from './assets/logo.png';

const companyConfig = {
  appName: 'iTru',
  appIcon: 'infoCircle',
  logo: logo,
  companyName: 'Group Rapport',
  registeredOffice: ['Marie House', '5 Baker Street', 'Weybridge', 'KT13 8AE'],
  email: 'info@rapportteam.com',
  linkedin: 'https://www.linkedin.com/company/group-rapport-ltd/',
  twitter: 'https://twitter.com/GroupRapport',
  tel: '0193 283 2210',
  fax: '0845 371 5010',
  author: 'Group Rapport | Truinvest',
  keywords:
    'Group Rapport, Truinvest, Financial Advice, Corporate Benefits, Services, Service, Wealth, Financial Planning, Financial Advisor',
  description:
    'We provide a coherent financial structure to help you achieve your objectives. We work with businesses & high net worth private clients; our specalist teams are focused on providing services tailored to the specific needs and circumstances of each.',
};

export default companyConfig;
