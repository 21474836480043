import React, { useEffect, useState } from 'react';

const ThreeDots = () => {
  const [count, setCount] = useState(4);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count === 4) {
        setCount(1);
      } else {
        setCount(count + 1);
      }
    }, 300);
    return () => clearInterval(interval); // eslint-disable-next-line
  }, [count]);

  return (
    <div style={{ textAlign: 'center', fontSize: '3rem' }}>
      {count === 1 && '.'}
      {count === 2 && '..'}
      {count === 3 && '...'}
      {count === 4 && '....'}
    </div>
  );
};

export default ThreeDots;
