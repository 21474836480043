const stages = ['Active', 'Inactive'];
const outcomes = ['Current', 'Old'];
const connectedpersonConfig = {
  title: 'Connected Person Records',
  name: 'Connected Person',
  addBtnLabel: 'Create Connected Person Record',
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Name' },
  breadcrumbs: [
    { type: 'simpleLink', label: 'Private Clients', route: '/contacts' },
    {
      type: 'relatedRecord',
      dbRef: 'contacts',
      recordRefFromCurrent: 'contactsID',
    },
    { type: 'simpleLabel', label: 'Connected Person' },
    { type: 'currentRecord' },
  ],
  stages: stages,
  outcomes: outcomes,
  manageTabConfig: {
    filterByLabel: 'Name',
    tableConfig: [
      { label: 'Name', ref: 'name' },
      { label: 'Date of Birth / Current Age', ref: 'ageRef' },
      { label: 'Nature of Relationship', ref: 'natureOfConnection' },
      { label: 'Financially Dependant', ref: 'financiallyDependant' },
      { label: 'Dependant Until Age', ref: 'dependantUntilAge' },
    ],
  },
  newDataSteps: [
    {
      title: 'Address',
      data: [
        { ref: 'name', mandatory: true },
        { ref: 'ageRef', mandatory: true },
        { ref: 'natureOfConnection', mandatory: true },
        { ref: 'financiallyDependant' },
        { ref: 'dependantUntilAge' },
      ],
    },
    {
      title: 'Notes',
      guidance:
        'Optional. Add any notes you want to record about this company and display on the address record to the rest of the team.',
      data: [{ ref: 'notes' }],
    },
  ],
  recordEditTabs: ['summary'],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Address Records' },
  },
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Related Record',
          data: ['contactsID'],
        },
        {
          title: 'Details',
          data: [
            'name',
            'ageRef',
            'natureOfConnection',
            'financiallyDependant',
            'dependantUntilAge',
          ],
        },
        {
          title: 'Notes',
          data: ['notes'],
        },
      ],
    },
  ],
  data: {
    name: {
      type: 'text',
      label: 'Name',
    },
    ageRef: {
      type: 'text',
      label: 'Date of Birth / Current Age',
    },
    natureOfConnection: {
      type: 'text',
      label: 'Nature of Relationship',
    },
    financiallyDependant: {
      type: 'select',
      label: 'Financially Dependant',
      valueOptions: [
        { value: 'financiallyDependant', label: 'Financially Dependant' },
        {
          value: 'nonFinanciallyDependant',
          label: 'Not Financially Dependant',
        },
      ],
    },
    dependantUntilAge: {
      type: 'number',
      label: 'Dependant Until Age',
    },
    notes: {
      type: 'textArea',
      label: 'Notes',
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
  },
};

export default connectedpersonConfig;
