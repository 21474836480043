import Image from './image.png';
import Newsletter from './newsletter.pdf';
import Rachel from './rachellane.jpg';

const SpringNewsLetter = {
  title: 'Spring Statement 2023 Summary',
  date: '23/03/22023',
  readTime: 8,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['Spring 2023'],
  intro:
    'Just a few days short of the third anniversary of the first Covid lockdown, Chancellor Jeremy Hunt rose to the Despatch Box to deliver the first full Budget to have taken place in 504 days and the first unaffected by the immediate impact of the pandemic since October 2018.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default SpringNewsLetter;
