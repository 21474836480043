import React, { useContext } from 'react';
import BoringAvatar from 'boring-avatars';
import ConfigContext from '../../context/config/configContext';

const Avatar = ({ name, size }) => {
  const configContext = useContext(ConfigContext);
  return (
    <BoringAvatar
      size={size}
      name={name}
      variant='beam'
      colors={[
        `rgb(${configContext.config.styling.colors.success[0]})`,
        `rgb(${configContext.config.styling.colors.secondary[0]})`,
        `rgb(${configContext.config.styling.colors.primary[0]})`,
        `rgb(${configContext.config.styling.colors.tertiary[0]})`,
        `rgb(${configContext.config.styling.colors.danger[0]})`,
      ]}
    />
  );
};

export default Avatar;
