import React, { Fragment } from 'react';
import NoData from '../Feedback/NoData';
import Tile from './Tile';

const Tiler = ({ data, handleSelect }) => {
  return data.data.length ? (
    <Fragment>
      {data.data.map((row, index) => (
        <div
          key={index}
          onClick={() => {
            handleSelect(row.record);
          }}
        >
          <Tile labels={data.headings} data={row.rowData} />
        </div>
      ))}
      <Tile />
    </Fragment>
  ) : (
    <NoData />
  );
};

export default Tiler;
