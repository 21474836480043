import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 2rem;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  img {
    width: 100%;
  }
  h2 {
    font-size: 2rem;
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
    margin-bottom: 1rem;
  }
  p {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
const Content = styled.div``;
const Bullets = styled.div`
  margin-top: 4rem;
  h2 {
    font-size: 1.3rem;
    margin-bottom: 0.25rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const FeatureWithBullets = ({ config }) => {
  return (
    <Wrapper>
      <Content>
        <h2>{config.title}</h2>
        {config.content.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </Content>
      <Bullets>
        {config.bullets.map((item, index) => (
          <div key={index}>
            {item.title && <h2>{item.title}</h2>}
            <p>{item.content}</p>
          </div>
        ))}
      </Bullets>
    </Wrapper>
  );
};

export default FeatureWithBullets;
