import Image from './image.png';
import Newsletter from './newsletter.pdf';
import Rachel from './rachellane.jpeg';

const winter = {
  title: 'Financial Focus - Winter 2021 Newsletter',
  date: '16/12/2021',
  readTime: 13,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['winter 2021', 'Tax', 'Inflation'],
  intro:
    "At this time of year many of us feel like staying in out of the cold and spending time with our families and friends, particularly after the difficult festive season last year. For those reaching pension age, and even those who think they don't have to worry about it yet, we consider a new report that puts a price on the cost of retirement in 2021 and highlights the gap between the State pension and the cost of a moderate living standard. This time of year also draws our focus to our finances before the new tax year begins and our feature recommends some strategies to make sure you take advantage of tax reliefs and allowances. But before all that, there is Christmas to prepare for. You may feel moved to avoid all the uncertainty about delivery times and available components for gift giving this year, and opt for an investment instead. For extra planet-friendly credentials, you could choose the first green bond launched by NS&I, although you may wish to shop around for a better rate of return.",
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default winter;
