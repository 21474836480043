import aboutPhoto from '../assets/about.jpg';
import aboutSection from '../assets/Laura.jpg';

const aboutConfig = {
  route: '/about',
  title: 'Laura Lane | About',
  content: [
    {
      component: 'hero-with-image-background',
      img: aboutPhoto,
      noTriangle: true,
      title: ['Laura Lane'],
      sub: 'Makeup and Hair Artist | Studio based in Market Harborough UK based 🇬🇧| Mummy to four |Bridal Events Parties Film TV | lauralanemakeup@outlook.com ✨',
    },
    {
      component: 'features-with-image',
      img: aboutSection,
      title: 'About Me',
      content: [
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
      ],
    },
  ],
};

export default aboutConfig;
