import newsImg from '../../assets/news.jpg';
import brushesLaunch from './posts/brushesLaunch/brushesLaunch';

const newsConfig = {
  route: '/news',
  title: 'Laura Lane | News',
  content: [
    {
      component: 'hero-newspage',
      img: newsImg,
      content: {
        title: 'Latest News & Information',
        subtitle: 'News, publications, blogs and announcements.',
      },
      posts: [brushesLaunch],
    },
  ],
};

export default newsConfig;
