import accountsDataConfig from './accountsDataConfig';
import accountsEditRecordConfig from './accountsEditRecordConfig';

const stages = ['Lead', 'Prospect', 'Complete'];
const outcomes = [
  'Pending',
  'Signed-Up Client',
  'Not Taken Up',
  'Deferred',
  'Lost to Competitor',
];
const accountsConfig = {
  title: 'Corporate Clients',
  name: 'Company',
  addBtnLabel: 'Create Company',
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Company Name' },
  breadcrumbs: [
    { type: 'simpleLink', label: 'Corporate Records', route: '/accounts' },
    { type: 'currentRecord' },
  ],
  stages: stages,
  outcomes: outcomes,
  manageTabConfig: {
    filterByLabel: 'Company Name',
    tableConfig: [
      { label: 'Company Name', ref: 'name' },
      { label: 'Contact', ref: 'primaryContactName' },
      { label: 'Adviser', ref: 'admin' },
      { label: 'Firm', ref: 'tenant' },
      { label: 'Status', ref: 'stage' },
      { label: 'Type', ref: 'outcome' },
    ],
  },
  newDataSteps: [
    {
      title: 'Introduction',
      guidance:
        'Add some basic details about this new Company. Once you create the new record you will be taken to the full details page, where additional details can be added; including New Business Cases & Fact Find information.',
    },
    {
      title: 'Company Details',
      guidance: "Please fill out these details and click 'Next'",
      data: [
        { ref: 'name', mandatory: true },
        { ref: 'companyType' },
        { ref: 'country', mandatory: true },
      ],
    },
    {
      title: 'Contact Details',
      data: [
        { ref: 'primaryContactName', mandatory: true },
        { ref: 'primaryContactEmail', mandatory: true },
        { ref: 'primaryContactPhoneNumber' },
      ],
    },
    {
      title: 'Notes',
      guidance:
        'Add any notes you want to record about this company and display on the case information to the rest of the team.',
      data: [{ ref: 'companyNotes' }],
    },
  ],
  recordEditTabs: [
    'summary',
    'cases',
    'company details',
    'schemes',
    'employees',
    'documents',
  ],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Accounts' },
  },
  recordEditConfig: accountsEditRecordConfig,
  data: accountsDataConfig,
  documents: {
    clientAgreement: {
      type: 'document',
      label: 'Client Agreement',
      guidance: 'Optional. Upload a signed Client Agreement.',
      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'version', label: 'Version' },
      ],
      saveFolder: 'clientdocuments',
    },
    companyAccounts: {
      type: 'document',
      label: 'Company Accounts',
      guidance: 'Optional. Upload any company accounts.',
      meta: [{ ref: 'documentDate', label: 'Accounting Year End Date' }],
      saveFolder: 'clientdocuments',
    },
    companyArticlesOfAssociation: {
      type: 'document',
      label: 'Company Articles Of Association',
      guidance: 'Optional. Upload any company Articles Of Association.',
      saveFolder: 'clientdocuments',
    },
    companyBusinessAssets: {
      type: 'document',
      label: 'Company Business Assets Schedule',
      guidance: 'Optional. Upload a schedule of Business Assets.',
      meta: [{ ref: 'documentDate', label: 'Document Date' }],
      saveFolder: 'clientdocuments',
    },
    companyBusinessLiabilities: {
      type: 'document',
      label: 'Company Business Liabilities Schedule',
      guidance: 'Optional. Upload a schedule of Business Liabilities.',
      meta: [{ ref: 'documentDate', label: 'Document Date' }],
      saveFolder: 'clientdocuments',
    },
    general: {
      type: 'document',
      label: 'Other Documents',
      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'type', label: 'Document Type' },
      ],
      saveFolder: 'clientdocuments',
    },
  },
};

export default accountsConfig;
