import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import MainrecordContext from '../context/mainrecord/mainrecordContext';
import ConfigContext from '../context/config/configContext';

import ProfileHeader from '../components/Headers/ProfileHeader';
import LinkTile from '../components/Controls/LinkTile';
import TileButton from '../components/Controls/TileButton';

const LinksWrapper = styled.div`
  margin: 1rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Dashboard = (props) => {
  const history = useHistory();
  const configContext = useContext(ConfigContext);
  const mainrecordContext = useContext(MainrecordContext);

  const handleSelect = () => {
    mainrecordContext.getRecordByIDSetToCurrent(
      props.userData.contactsID,
      'contacts',
      configContext.config.contactsConfig
    );
    history.push('/contactsrecord');
  };

  return (
    <div>
      <ProfileHeader user={props.userData} />
      {props.userData.role === 'client' ? (
        <LinksWrapper>
          <TileButton
            onClick={() => {
              handleSelect();
            }}
            title='Profile Data'
            icon='userlist'
            subtitle='Manage your personal data profile known as a "Factfind"'
          />
        </LinksWrapper>
      ) : (
        <LinksWrapper>
          <LinkTile
            to='/contacts'
            title='Private Clients'
            icon='userlist'
            subtitle='Create new and manage existing private clients and leads'
          />
          <LinkTile
            to='/accounts'
            title='Corporate'
            icon='bank'
            subtitle='Create new employer & corporate account records and manage existing records'
          />
          <LinkTile
            to='/opportunities'
            title='Business Cases'
            icon='barChart'
            subtitle='Manage new Business Cases, tracking key pipeline and compliance information'
            guidance='New Business Cases can be added via the client / corporate account record'
          />
          <LinkTile
            to='/reviews'
            title='Annual Reviews'
            icon='crossCal'
            subtitle='Manage annual reviews cases'
            guidance='New Annual Reviews can be added via the client / corporate account record'
          />
          <LinkTile
            to='/tasks'
            title='Tasks'
            icon='tasklist'
            subtitle='Manage system generated, recurring and scheduled tasks'
          />
          <LinkTile
            to='/compliance'
            title='Compliance Notifications'
            icon='alert'
            subtitle='Report breaches and make notifications to the Compliance Officer'
          />
        </LinksWrapper>
      )}
    </div>
  );
};

export default Dashboard;
