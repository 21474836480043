const accountsDataConfig = {
  name: {
    type: 'text',
    label: 'Company Name',
  },
  natureOfBusiness: {
    type: 'textArea',
    label: 'Nature of Business',
    includeTitle: true,
  },
  companyType: {
    type: 'select',
    label: 'Company Type',
    valueOptions: [
      { value: 'soleTrader', label: 'Sole Trader' },
      { value: 'partnership', label: 'Partnership' },
      { value: 'privateLimitedCompany', label: 'Private Limited Company' },
      { value: 'publicLimitedCompany', label: 'Public Limited Company' },
    ],
  },
  companyRegNumber: {
    type: 'text',
    label: 'Company Registration Number',
    visibilityRules: [
      {
        target: 'companyType',
        comparisionOperator: 'includes',
        valueTest: ['privateLimitedCompany', 'publicLimitedCompany'],
      },
    ],
  },
  companyYearEnd: {
    type: 'text',
    label: 'Company Year End',
  },
  adminID: {
    isComplex: true,
    type: 'teamlookup',
    icon: 'usersingle',
    dbRef: 'users',
    label: 'Administrator',
    roleTypes: ['admin', 'manager'],
  },
  clientAdviserID: {
    isComplex: true,
    type: 'teamlookup',
    icon: 'usersingle',
    dbRef: 'users',
    label: 'Client Adviser',
    roleTypes: ['adviser'],
  },
  leadReceivedDate: {
    type: 'date',
    group: 'caseInformation',
    label: 'Date Lead Received',
  },
  source: {
    type: 'select',
    group: 'caseInformation',
    label: 'Source',
    valueOptions: [
      { value: 'migration', label: 'Migration of Previous Pipeline' },
      { value: 'existingClient', label: 'Existing Client' },
      { value: 'clientReferral', label: 'New: Client Referral' },
      { value: 'introducerReferral', label: 'New: Introducer Referral' },
      { value: 'faceToFace', label: 'New: Face To Face' },
      { value: 'teleMarketing', label: 'New: Telemarketing' },
      { value: 'mailshot', label: 'New: Postal / Mailshot' },
      {
        value: 'socialMediaMarketing',
        label: 'New: Social Media Marketing',
      },
      { value: 'website', label: 'New: Website' },
      { value: 'marketingList', label: 'New: Marketing List' },
      { value: 'other', label: 'New: Other' },
    ],
  },
  PAYEReference: {
    type: 'text',
    label: 'PAYE Reference',
  },
  VATRegNumber: {
    type: 'text',
    label: 'VAT Registration Number',
  },
  connectedBusinesses: {
    type: 'textArea',
    label: 'Connected Businesses (if applicable)',
    includeTitle: true,
  },
  country: {
    type: 'text',
    label: 'Country',
  },
  website: {
    type: 'text',
    label: 'Website',
  },
  numberOfEmployees: {
    type: 'select',
    label: 'Number Of Employees',
    valueOptions: [
      { value: 'zeroToFive', label: '0 - 5' },
      { value: 'sixToTwenty', label: '6 - 20' },
      { value: 'twentyToFifty', label: '21 - 50' },
      { value: 'fiftyToTwoFifty', label: '51 - 250' },
      { value: 'twoFiftyToThousand', label: '251 - 1,000' },
      { value: 'twoThousandToFiveThousand', label: '1,001 - 5,000' },
      { value: 'FiveThousandPlus', label: '5,000 +' },
    ],
  },
  primaryContactName: {
    type: 'text',
    label: 'Full Name',
  },
  primaryContactEmail: {
    type: 'email',
    label: 'Email Address',
  },
  primaryContactPhoneNumber: {
    type: 'tel',
    label: 'Phone Number',
  },
  primaryContactBuildingName: {
    type: 'text',
    label: 'Building Name / Number',
  },
  primaryContactStreet: {
    type: 'text',
    label: 'Street',
  },
  primaryContactTown: {
    type: 'text',
    label: 'Town',
  },
  primaryContactCounty: {
    type: 'text',
    label: 'County',
  },
  primaryContactPostcode: {
    type: 'text',
    label: 'Postcode',
  },
  companyNotes: {
    type: 'textArea',
    label: 'Company Notes',
  },
  stage: {
    type: 'select',
    label: 'Pipeline Stage',
    valueOptions: ['Lead', 'Prospect', 'Complete'],
  },
  outcome: {
    type: 'select',
    label: 'Client Type',
    valueOptions: [
      'Lead',
      'Premier Plus (P+)',
      'Premier Planning (PP)',
      'Premier Transact (PT)',
      'Premier Corporate (PC)',
      'Activeplan+',
      'No Longer Client',
    ],
  },
};

export default accountsDataConfig;
