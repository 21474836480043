const stages = [
  'Not Started',
  'In Progress',
  'Waiting On Someone Else',
  'Complete',
];

const outcomes = ['Pending', 'Successful', 'Cancelled', 'Defferred'];

const natureOfTasks = [
  'Administration',
  'Paraplanning',
  'Advice',
  'Compliance',
  'Business Management',
  'Finance',
];

const categories = [
  'Client Onboarding',
  'New Business',
  'Annual Financial Review',
  'Other',
];

const tasksConfig = {
  title: 'Task & Activities',
  name: 'Tasks',
  addBtnLabel: 'Create Task',
  disbaleDirectAddRecord: true,
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Nature of Task' },
  manageTabConfig: {
    tableConfig: [
      { label: 'Due Date', ref: 'dueDate' },
      { label: 'Complete Date', ref: 'completeDate' },
      { label: 'Nature of Task', ref: 'natureOfTask' },
      { label: 'Category', ref: 'category' },
      { label: 'Adviser', ref: 'admin' },
      { label: 'Firm', ref: 'tenant' },
      { label: 'Stage', ref: 'stage' },
      { label: 'Outcome', ref: 'outcome' },
    ],
  },
  analysisTabConfig: {
    aggregateConfig: {
      count: 'Total Tasks',
    },
  },
  newDataSteps: [
    {
      title: 'Task Details',
      guidance: "Please fill out these details and click 'Next'",
      data: [
        { ref: 'dueDate', mandatory: true },
        { ref: 'natureOfTask', mandatory: true },
        { ref: 'category', mandatory: true },
      ],
    },
    {
      title: 'Summary',
      guidance: 'Please provide a summary of what needs to be done',
      data: [{ ref: 'name', mandatory: true }],
    },
    {
      title: 'Notes',
      guidance:
        'Add any notes you want to record about this tasks and display on the case information to the rest of the team.',
      data: [{ ref: 'taskNotes' }],
    },
  ],
  keyFutureDataReference: 'dueDate',
  stages: stages,
  outcomes: outcomes,
  recordEditTabs: ['summary', 'documents'],
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          data: ['name', 'taskNotes'],
        },
        {
          title: 'Tasks Status',
          data: [
            'stage',
            'outcome',
            'category',
            'natureOfTask',
            'contactsID',
            'accountsID',
            'opportunitiesID',
            'reviewsID',
          ],
        },
        {
          title: 'Team',
          data: ['adminID', 'clientAdviserID'],
        },
        {
          title: 'Key Dates',
          subtitle:
            'Track the case through the advice process and record the case outcome',
          data: ['dueDate', 'completeDate'],
        },
        {
          title: 'Timeline',
          namedComponent: {
            component: 'timeline',
          },
        },
      ],
    },
    {
      reference: 'documents',
      tiles: [
        {
          namedComponent: {
            component: 'document',
            recordRef: 'general',
          },
        },
      ],
    },
  ],
  data: {
    stage: {
      type: 'select',
      label: 'Case Stage',
      valueOptions: stages,
    },
    outcome: {
      type: 'select',
      label: 'Case Outcome',
      valueOptions: outcomes,
    },
    natureOfTask: {
      type: 'select',
      label: 'Nature of Task',
      valueOptions: natureOfTasks,
    },
    category: {
      type: 'select',
      label: 'Category',
      valueOptions: categories,
    },
    name: {
      type: 'text',
      label: 'Task Summary',
    },
    dueDate: {
      type: 'date',
      label: 'Due Date',
    },
    completeDate: {
      type: 'date',
      label: 'Date Completed',
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
    adminID: {
      isComplex: true,
      type: 'teamlookup',
      icon: 'usersingle',
      dbRef: 'users',
      label: 'Administrator',
      roleTypes: ['admin', 'manager'],
    },
    clientAdviserID: {
      isComplex: true,
      type: 'teamlookup',
      icon: 'usersingle',
      dbRef: 'users',
      label: 'Client Adviser',
      roleTypes: ['adviser'],
    },
    accountsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'accounts',
      label: 'Corporate Client',
    },
    reviewsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'reviews',
      label: 'Annual Review',
    },
    opportunitiesID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'opportunities',
      label: 'New Business Case',
    },
    taskNotes: {
      type: 'textArea',
      includeTitle: true,
      label: 'Task Details',
    },
  },
  documents: {
    general: {
      type: 'document',
      label: 'Task Documents',

      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'type', label: 'Document Type' },
      ],
      saveFolder: 'clientdocuments',
    },
  },
};

export default tasksConfig;
