import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
  }
  h4 {
    font-weight: bold;
  }
`;

const NameJobPhoto = ({ config }) => {
  return (
    <Wrapper>
      <img src={config.image} alt={config.name} />
      <div>
        <h4>{config.name}</h4>
        <p>{config.role}</p>
      </div>
    </Wrapper>
  );
};

export default NameJobPhoto;
