import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
  font-size: 2rem;
`;
const DataDisplayStep = ({ config, data }) => {
  return (
    <div>
      <Title>{config.title}</Title>
    </div>
  );
};

export default DataDisplayStep;
