const stages = [
  'Not Started',
  'In Progress',
  'Waiting On Someone Else',
  'Complete',
];

const outcomes = ['Pending', 'Successful', 'Cancelled', 'Defferred'];

const notificationTypes = [
  'Gifts & Hospitality',
  'Financial Promotions',
  'Compliant',
  'Known Rule Breach',
  'Conflict of Interest',
  'Financial Crime',
  'Early Encashment',
  'Lapsed or Cancelled Policies',
  'Optout & Non-Joiners',
  'Returned Client Assets',
  'Personal Data Breach',
  'Personal Account Dealing',
  'Staff Mandates & Power of Attorney',
];

const complianceConfig = {
  title: 'Compliance',
  name: 'Compliance',
  addBtnLabel: 'Make Compliance Notification',
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Type of Notification' },
  manageTabConfig: {
    tableConfig: [
      { label: 'Notification Date', ref: 'notificationDate' },
      { label: 'Complete Date', ref: 'completeDate' },
      { label: 'Type of Notification', ref: 'typeOfNotification' },
      { label: 'Adviser', ref: 'admin' },
      { label: 'Firm', ref: 'tenant' },
      { label: 'Stage', ref: 'stage' },
      { label: 'Outcome', ref: 'outcome' },
    ],
  },
  analysisTabConfig: {
    aggregateConfig: {
      count: 'Total Notifications',
    },
  },
  newDataSteps: [
    {
      title: 'Notification Details',
      guidance: "Please fill out these details and click 'Next'",
      data: [
        { ref: 'notificationDate', mandatory: true },
        { ref: 'typeOfNotification', mandatory: true },
      ],
    },
    {
      title: 'Summary',
      guidance: 'Please provide a summary of this notification',
      data: [{ ref: 'name', mandatory: true }],
    },
    {
      title: 'Notes',
      guidance: 'Optional',
      data: [{ ref: 'notificationNotes' }],
    },
  ],
  keyFutureDataReference: 'notificationDate',
  stages: stages,
  outcomes: outcomes,
  recordEditTabs: ['summary', 'documents'],
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          data: ['name', 'notificationNotes'],
        },
        {
          title: 'Notification Status',
          data: ['stage', 'outcome', 'category', 'typeOfNotification'],
        },
        {
          title: 'Team',
          data: ['adminID', 'clientAdviserID'],
        },
        {
          title: 'Key Dates',
          subtitle:
            'Track the case through the advice process and record the case outcome',
          data: ['notificationDate', 'completeDate'],
        },
        {
          title: 'Timeline',
          namedComponent: {
            component: 'timeline',
          },
        },
      ],
    },
    {
      reference: 'documents',
      tiles: [
        {
          namedComponent: {
            component: 'document',
            recordRef: 'general',
          },
        },
      ],
    },
  ],
  data: {
    stage: {
      type: 'select',
      label: 'Notification Stage',
      valueOptions: stages,
    },
    outcome: {
      type: 'select',
      label: 'Notification Outcome',
      valueOptions: outcomes,
    },
    typeOfNotification: {
      type: 'select',
      label: 'Type of Notification',
      valueOptions: notificationTypes,
    },
    name: {
      type: 'text',
      label: 'Notification Summary',
    },
    notificationDate: {
      type: 'date',
      label: 'Notification Date',
    },
    completeDate: {
      type: 'date',
      label: 'Date Completed',
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
    adminID: {
      isComplex: true,
      type: 'teamlookup',
      icon: 'usersingle',
      dbRef: 'users',
      label: 'Administrator',
      roleTypes: ['admin', 'manager'],
    },
    clientAdviserID: {
      isComplex: true,
      type: 'teamlookup',
      icon: 'usersingle',
      dbRef: 'users',
      label: 'Client Adviser',
      roleTypes: ['adviser'],
    },
    accountsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'accounts',
      label: 'Corporate Client',
    },
    reviewsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'reviews',
      label: 'Annual Review',
    },
    opportunitiesID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'opportunities',
      label: 'New Business Case',
    },
    notificationNotes: {
      type: 'textArea',
      includeTitle: true,
      label: 'Task Details',
    },
  },
  documents: {
    general: {
      type: 'document',
      label: 'Notification Documents',

      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'type', label: 'Document Type' },
      ],
      saveFolder: 'clientdocuments',
    },
  },
};

export default complianceConfig;
