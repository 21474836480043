import Image from './image.png';
import Newsletter from './october_2022.pdf';
import Rachel from './rachellane.jpg';

const summer_22 = {
  isHidden: true,
  title: 'Quarterly Update - October 2022',
  date: '01/11/2022',
  readTime: 17,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['Quarterly Update'],
  intro:
    'The third quarter ended on a weaker note for investors after a relatively strong recovery in global equity markets through most of July and early August.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default summer_22;
