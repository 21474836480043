import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import firebase, { db } from '../../context/firebase';

import AuthContext from '../../context/auth/authContext';
import ConfigContext from '../../context/config/configContext';
import MainrecordContext from '../../context/mainrecord/mainrecordContext';

import ManageTab from './ManageTab';
import PageLoader from '../Feedback/PageLoader';
import { Button } from '../Controls';
import Icon from '../UI/Icon';
import AddNewPopout from '../Forms/AddNewPopout';

const Container = styled.div`
  width: calc(100%-2rem);
  margin: 0.5rem;
  margin-top: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
  }
`;

const RelatedRecords = ({ componentConfig, pageRef, mainrecordID, title }) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const mainrecordContext = useContext(MainrecordContext);

  //getConfig
  const [pageConfig, setPageConfig] = useState();
  useEffect(() => {
    setPageConfig(configContext.config[componentConfig.recordRef + 'Config']);
  }, [componentConfig, configContext.config]);

  //Load Records
  const [loading, setLoading] = useState(true);
  const [recordsList, setRecordsList] = useState([]);

  const getRecords = async (recordType, ref, refID) => {
    const tempRecordsList = [];
    db.collection(recordType)
      .where(ref, '==', refID)
      .orderBy('created')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const recordItem = { ...doc.data() };
          recordItem.id = doc.id;
          recordItem.recordType = recordType;
          recordItem.created = recordItem.created.toDate();
          tempRecordsList.push(recordItem);
        });
        setLoading(false);
        setRecordsList(tempRecordsList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!!componentConfig && !!pageRef && !!mainrecordID && !!isMounted) {
      getRecords(componentConfig.recordRef, pageRef + 'ID', mainrecordID);
    }
    return () => {
      isMounted = false;
    };
  }, [pageRef, componentConfig, mainrecordID]);

  //Add Records
  const [toggleAdd, setToggleAdd] = useState(false);
  const createNew = (data) => {
    addRecord(data).then((res) => {
      mainrecordContext.setCurrent(res);
      history.push('/' + componentConfig.recordRef + 'record');
    });
  };

  const addRecord = async (data) => {
    const newRecord = db.collection(componentConfig.recordRef).doc();

    const newRecordData = {
      ...data,
      id: newRecord.id,
      recordType: componentConfig.recordRef,
      admin: {
        name: authContext.userData.name,
        id: authContext.user.uid,
      },
      tenant: authContext.userData.tenant,
      stage: { value: 0 },
      outcome: { value: 0 },
      status: { value: 'active' },
    };
    newRecordData[pageRef + 'ID'] = mainrecordID;

    newRecord
      .set({
        ...newRecordData,
        created: firebase.firestore.Timestamp.now(),
      })
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });

    const results = [newRecordData];
    return results;
  };

  return !loading && !!pageConfig ? (
    <Container>
      <Header>
        <h2>{title}</h2>
        <Button
          color='linkBlue'
          onClick={() => {
            setToggleAdd(true);
          }}
        >
          <Icon iconType='file-add' size={1.5} color='light' />
          New {title.substring(0, title.length - 1)}
        </Button>
      </Header>
      <ManageTab
        noMargin={true}
        baseData={recordsList}
        pageConfig={pageConfig}
        redirectOverride={componentConfig.recordRef}
      />
      <AddNewPopout
        active={toggleAdd}
        title={'New ' + title.substring(0, title.length - 1)}
        setToggleAdd={setToggleAdd}
        newDataSteps={pageConfig.newDataSteps}
        dataConfig={pageConfig.data}
        handleSubmit={createNew}
      />
    </Container>
  ) : (
    <PageLoader />
  );
};

export default RelatedRecords;
