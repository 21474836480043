const checkIsVisible = (dataset, config) => {
  let isVisible = false;
  config.forEach((configItem) => {
    if (!!dataset[configItem.target])
      if (configItem.comparisionOperator === 'isEqualTo') {
        if (
          configItem.valueTest !== false &&
          dataset[configItem.target] &&
          dataset[configItem.target].value === configItem.valueTest
        ) {
          isVisible = true;
        } else if (
          configItem.valueTest === false &&
          !!dataset[configItem.target] &&
          !dataset[configItem.target].value
        ) {
          isVisible = true;
        }
      }
    if (configItem.comparisionOperator === 'includes') {
      !!configItem.valueTest.includes(dataset[configItem.target].value) &&
        (isVisible = true);
    }
  });

  return isVisible;
};

export default checkIsVisible;
