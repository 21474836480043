import React, { useState, useEffect, useContext, Fragment } from 'react';
import { db } from '../../context/firebase';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import AuthContext from '../../context/auth/authContext';

import Icon from '../UI/Icon';
import LoadingSpinner from '../Feedback/LoadingSpinner';

const Wrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  label {
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
  input {
    width: 100%;
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
    outline: none;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
    border-radius: 10px;
    &:focus,
    &:active {
      outline: none;
      color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
      border: ${(props) =>
        `1px solid rgba(${props.theme.colors.success[0]},1)`};
    }
  }
  select {
    width: 100%;
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
    outline: none;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
    border-radius: 10px;
    &:focus,
    &:active {
      outline: none;
      color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
      border: ${(props) =>
        `1px solid rgba(${props.theme.colors.success[0]},1)`};
    }
  }
`;

const TeamNotice = styled.div`
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
  a {
    margin: 0 0.5rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[5]},1)`};
    display: flex;
    padding: 0.5rem 0.75em 0.5rem 0.5rem;
    border-radius: 7px;
    background-color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]},1)`};
  }
`;

const TeamLookup = ({
  handleBlur,
  handleChange,
  dataItemName,
  data,
  dataItemConfig,
}) => {
  const authContext = useContext(AuthContext);
  const [listOptions, setListOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getRecordForTenant = async (tenant, roleTypes) => {
    const tempUserList = [];
    db.collection('users')
      .where('tenant', '==', tenant)
      .where('role', 'in', roleTypes)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const name = doc.data().name;
          const role = doc.data().role;
          tempUserList.push({
            id: doc.id,
            name,
            role,
          });
        });
        setIsLoading(false);
        setListOptions(tempUserList);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  };
  const getRecordForGroup = async (roleTypes) => {
    const tempUserList = [];
    db.collection('users')
      .where('role', 'in', roleTypes)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const name = doc.data().name;
          const role = doc.data().role;
          tempUserList.push({
            id: doc.id,
            name,
            role,
          });
        });
        setIsLoading(false);
        setListOptions(tempUserList);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (
      !!authContext.userData &&
      !!authContext.userData.tenant &&
      !!dataItemConfig &&
      !!isMounted
    ) {
      if (!!dataItemConfig.fullGroup) {
        getRecordForGroup(dataItemConfig.roleTypes);
      } else {
        getRecordForTenant(
          authContext.userData.tenant,
          dataItemConfig.roleTypes
        );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [authContext, dataItemConfig]);

  return (
    <Wrapper>
      <label>{dataItemConfig.label}</label>
      {isLoading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <Fragment>
          {!listOptions.length && (
            <TeamNotice>
              No Team Members with this Role Type
              <Link to='/users'>
                <Icon iconType='add' size={1} margin='0 0.5rem' color='light' />
                Manage Team
              </Link>
            </TeamNotice>
          )}
          {listOptions.length > 0 && (
            <select
              name={dataItemName}
              value={data}
              onBlur={(e) => {
                !!handleBlur &&
                  handleBlur(
                    e,
                    listOptions.filter(
                      (option) => option.id === e.target.value
                    )[0].name
                  );
              }}
              onChange={(e) =>
                handleChange(
                  e,
                  listOptions.filter(
                    (option) => option.id === e.target.value
                  )[0].name
                )
              }
            >
              <option value='' disabled>
                Select Team Member
              </option>
              {listOptions.map((option, index) => (
                <option key={option.id} value={option.id}>
                  {option.name + ' (' + option.role + ')'}
                </option>
              ))}
            </select>
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};

export default TeamLookup;
