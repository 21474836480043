import React, { useEffect } from 'react';
import useStorage from '../../hooks/useStorage';
import styled from 'styled-components';

const StyledProgressBar = styled.div`
  height: 1rem;
  background-color: ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
  margin-top: 2rem;
`;

const ProgressBar = ({ clientID, file, tenant, folder, setFile, saveFile }) => {
  const { url, progress } = useStorage(clientID, file, tenant, folder);

  useEffect(() => {
    if (url) {
      saveFile(url);
      setFile(null);
    }
  }, [url, setFile, saveFile]);

  return (
    <StyledProgressBar style={{ width: progress + '%' }}></StyledProgressBar>
  );
};

export default ProgressBar;
