import React, { useReducer } from 'react';
import firebase, { db } from '../firebase';
import MainrecordContext from './mainrecordContext';
import mainrecordReducer from './mainrecordReducer';
import {
  GET_MAINRECORDS,
  ADD_MAINRECORD,
  DELETE_MAINRECORD,
  SET_CURRENT_MAINRECORD,
  CLEAR_CURRENT_MAINRECORD,
  MAINRECORD_ERROR,
  CLEAR_MAINRECORDS,
  UPDATE_MAINRECORD,
  SET_LOADING,
  FILTER_MAINRECORDS,
  CLEAR_MAINRECORD_FILTER,
} from '../types';
import processRecordData from './processRecordData';

const MainrecordState = (props) => {
  const initalState = {
    records: null,
    advisers: null,
    tenants: null,
    current: null,
    tenantFilter: null,
    adviserFilter: null,
    filtered: null,
    error: null,
    loading: false,
    mainCount: null,
    outcomesAggregate: null,
    stagesAggregate: null,
    productTypesAggregate: null,
    tenantAggregate: null,
    adviserAggregate: null,
    valueGroupsAggregate: null,
    timelineAggregate: null,
  };

  const [state, dispatch] = useReducer(mainrecordReducer, initalState);

  // Get Record By ID and Set To Current
  const getRecordByIDSetToCurrent = async (recordID, recordType, config) => {
    dispatch({
      type: SET_LOADING,
    });
    db.collection(recordType)
      .where('id', '==', recordID)
      .orderBy('created')
      .get()
      .then((querySnapshot) => {
        dispatch({
          type: GET_MAINRECORDS,
          payload: processRecordData(querySnapshot, false, recordType, config),
        });
        dispatch({
          type: SET_CURRENT_MAINRECORD,
          payload: processRecordData(querySnapshot, false, recordType, config)
            .records[0],
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: MAINRECORD_ERROR,
          payload: err,
        });
      });
  };

  // Get Records By Tenant
  const getRecordsByTenant = async (tenant, recordType, config) => {
    dispatch({
      type: SET_LOADING,
    });
    db.collection(recordType)
      .where('tenant', '==', tenant)
      .orderBy('created')
      .get()
      .then((querySnapshot) => {
        dispatch({
          type: GET_MAINRECORDS,
          payload: processRecordData(querySnapshot, false, recordType, config),
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: MAINRECORD_ERROR,
          payload: err,
        });
      });
  };

  // Get Records By Tenant Where NotClient
  const getRecordsByTenantWhereNotClient = async (
    tenant,
    recordType,
    config
  ) => {
    dispatch({
      type: SET_LOADING,
    });
    db.collection(recordType)
      .where('tenant', '==', tenant)
      .where('role', 'in', ['admin', 'adviser', 'manager', 'group'])
      .orderBy('created')
      .get()
      .then((querySnapshot) => {
        dispatch({
          type: GET_MAINRECORDS,
          payload: processRecordData(querySnapshot, false, recordType, config),
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: MAINRECORD_ERROR,
          payload: err,
        });
      });
  };

  // Add Mainrecord
  const addMainrecord = async (
    mainrecord,
    recordType,
    userUID,
    userTenant,
    userName
  ) => {
    const newMainrecord = db.collection(recordType).doc();

    const newMainrecordData = {
      ...mainrecord,
      id: newMainrecord.id,
      recordType: recordType,
      admin: {
        name: !!userName ? userName : 'not set',
        id: userUID,
      },
      tenant: userTenant,
      stage: { value: 0 },
      outcome: { value: 0 },
      status: { value: 'active' },
    };

    newMainrecord
      .set({
        ...newMainrecordData,
        created: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        dispatch({
          type: ADD_MAINRECORD,
          payload: {
            ...newMainrecordData,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: MAINRECORD_ERROR,
          payload: err,
        });
      });

    const results = [newMainrecordData];
    return results;
  };

  // Update Mainrecord
  const updateMainrecord = async (mainrecord, recordType) => {
    db.collection(recordType)
      .doc(mainrecord.id)
      .update({ ...mainrecord })

      .then(() => {
        dispatch({
          type: UPDATE_MAINRECORD,
          payload: mainrecord,
        });
      })
      .catch((err) => {
        dispatch({
          type: MAINRECORD_ERROR,
          payload: err,
        });
      });
  };

  // Delete Mainrecord
  const deleteMainrecord = async (id, recordType) => {
    db.collection(recordType)
      .doc(id)
      .delete()
      .then(() => {
        dispatch({
          type: DELETE_MAINRECORD,
          payload: id,
        });
      })
      .catch((err) => {
        dispatch({
          type: MAINRECORD_ERROR,
          payload: err,
        });
      });
  };

  // Clear Mainrecord
  const clearMainrecords = () => {
    dispatch({ type: CLEAR_MAINRECORDS });
  };

  // Set Current Mainrecord
  const setCurrent = (mainrecord) => {
    dispatch({ type: SET_CURRENT_MAINRECORD, payload: mainrecord[0] });
  };

  // Clear Current Mainrecord
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT_MAINRECORD });
  };

  // Filter Mainrecord
  const filterMainrecords = (text, recordType) => {
    dispatch({ type: FILTER_MAINRECORDS, payload: { text, recordType } });
  };

  // Clear Filters
  const clearFilter = (recordType, config) => {
    dispatch({
      type: CLEAR_MAINRECORD_FILTER,
      payload: {
        adviserFilter: null,
        tenantFilter: null,
        processedData: processRecordData(
          state.records,
          true,
          recordType,
          config
        ),
      },
    });
  };

  // Filter By Adviser
  const filterByAdviser = (adviserName, recordType, config) => {
    const adviserFilteredValue = adviserName;
    const tenantFilteredValue = null;
    const filteredList = state.records.filter(
      (record) => record.admin.name === adviserName
    );
    dispatch({
      type: FILTER_MAINRECORDS,
      payload: {
        adviserFilter: adviserFilteredValue,
        tenantFilter: tenantFilteredValue,
        processedData: processRecordData(
          filteredList,
          true,
          recordType,
          config
        ),
      },
    });
  };

  const filterByTenant = (tenant, recordType, config) => {
    const adviserFilteredValue = null;
    const tenantFilteredValue = tenant;
    const filteredList = state.records.filter(
      (record) => record.tenant === tenant
    );
    dispatch({
      type: FILTER_MAINRECORDS,
      payload: {
        adviserFilter: adviserFilteredValue,
        tenantFilter: tenantFilteredValue,
        processedData: processRecordData(
          filteredList,
          true,
          recordType,
          config
        ),
      },
    });
  };

  return (
    <MainrecordContext.Provider
      value={{
        records: state.records,
        current: state.current,
        filtered: state.filtered,
        advisers: state.advisers,
        tenants: state.tenants,
        tenantFilter: state.tenantFilter,
        adviserFilter: state.adviserFilter,
        error: state.error,
        loading: state.loading,
        outcomesAggregate: state.outcomesAggregate,
        stagesAggregate: state.stagesAggregate,
        productTypesAggregate: state.productTypesAggregate,
        tenantAggregate: state.tenantAggregate,
        adviserAggregate: state.adviserAggregate,
        valueGroupsAggregate: state.valueGroupsAggregate,
        timelineAggregate: state.timelineAggregate,
        mainCount: state.mainCount,
        addMainrecord,
        deleteMainrecord,
        setCurrent,
        clearCurrent,
        updateMainrecord,
        filterMainrecords,
        filterByAdviser,
        filterByTenant,
        clearFilter,
        getRecordsByTenant,
        clearMainrecords,
        getRecordsByTenantWhereNotClient,
        getRecordByIDSetToCurrent,
      }}
    >
      {props.children}
    </MainrecordContext.Provider>
  );
};

export default MainrecordState;
