const appNavConfig = {
  main: [
    { ref: 'dashboard', route: '/dashboard', label: 'Dashboard', client: true },
    { ref: 'contacts', route: '/contacts', label: 'Private Clients' },
    { ref: 'accounts', route: '/accounts', label: 'Corporate' },
    {
      ref: 'opportunities',
      route: '/opportunities',
      label: 'Business Cases',
    },
    {
      ref: 'reviews',
      route: '/reviews',
      label: 'Annual Reviews',
    },
  ],
  footer: [
    { ref: 'users', route: '/users', label: 'Team' },
    { ref: 'tasks', route: '/tasks', label: 'Tasks' },
    { ref: 'compliance', route: '/compliance', label: 'Compliance' },
    {
      ref: 'support',
      route: '/support',
      label: 'Help & Support',
      client: true,
    },
  ],
};
export default appNavConfig;
