import React from 'react';
import styled from 'styled-components';
import NoData from '../Feedback/NoData';

const StyledTable = styled.table`
  width: 100%;
  margin: 1rem 0;
  border: ${(props) => `1px solid rgba(${props.theme.colors.neutral[3]}, 0.3)`};
  border-spacing: 0;
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  thead {
    background: ${(props) => ` rgba(${props.theme.colors.primary[0]}, 0.7)`};
  }

  th {
    text-transform: uppercase;
    color: ${(props) => ` rgba(${props.theme.colors.neutral[5]}, 1)`};
    font-size: 0.8rem;
    text-align: left;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-bottom: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]}, 0.3)`};
  }
  tr {
    &:hover {
      cursor: pointer;
      color: ${(props) => ` rgba(${props.theme.colors.linkBlue[0]}, 1)`};
      td {
        font-weight: bold;
        font-size: 0.77rem;
        background-color: ${(props) =>
          ` rgba(${props.theme.colors.bgColor[0]}, 1)`};
      }
    }
  }
  td {
    padding: 1rem 0.5rem 1rem 1rem;
    background: ${(props) => ` rgba(${props.theme.colors.neutral[5]}, 1)`};
    font-size: 0.8rem;
    text-transform: capitalize;
    border-bottom: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]}, 0.3)`};
  }
`;

const EditBtn = styled.td`
  color: ${(props) => ` rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  font-weight: bold;
`;

const Table = ({ data, handleSelect }) => {
  return data.data.length ? (
    <StyledTable>
      <thead>
        <tr>
          {data.headings.map((heading, index) => (
            <th key={index}>{heading}</th>
          ))}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.data.map((row, index) => (
          <tr
            key={index}
            onClick={() => {
              handleSelect(row.record);
            }}
          >
            {row.rowData.map((data, index) => (
              <td key={index}>{data}</td>
            ))}
            <EditBtn>Edit</EditBtn>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  ) : (
    <NoData />
  );
};

export default Table;
