import Corporate from '../assets/corporate.jpg';
import corporateScrollover from '../assets/corporate-scrollover.jpg';
import corporateSection from '../assets/corporate-section.jpg';
import hayleysyrett from '../assets/hayleysyrett.jpg';

const corporateConfig = {
  route: '/corporate',
  title: 'Group Rapport | Corporate',
  content: [
    {
      component: 'hero-with-image-background',
      img: Corporate,
      title: ['Company Rapport'],
      sub: 'From small family firms through to larger companies; looking after your staff is important. We provide the package that you and your staff will value.',
      primaryCTA: { route: '/contact', label: 'Get In Contact' },
      secondaryCTA: { route: '/about', label: 'Meet The Team' },
    },
    {
      component: 'features-with-bullets',
      title: 'Staff Benefit Schemes',
      content: [
        'It’s proven that employers with staff benefit schemes in place will gain an advantage over those that don’t. Employees are more motivated, display greater loyalty to the business and personal performance improves.',
        'Benefit schemes help attract key potential employees and can be the deciding factor when deciding between offers of employment.',
        "We'll work with you to establish the correct blend of benefits for your team. This will then be combined into a bespoke personally branded booklet, further helping staff to identify with your brand and value those benefits as part of your contribution.",
      ],
      bullets: [
        {
          content:
            'We provide bespoke workplace benefit solutions for small, medium and large companies by considering the needs of both the Company and your employees.',
          title: 'BESPOKE',
        },
        {
          content:
            'Our approach is to work with you to design a benefits package that is valued by your employees and will consequently support staff recruitment, retention and motivation.',
          title: 'OUR APPROACH',
        },
        {
          content:
            'We provide access to; Death in Service, Group Critical Illness, Group Income Protection, Auto Enrolment pensions, Private Health Care, Cashplans, bespoke financial advice for key employees, director’s benefit packages.',
          title: 'SERVICES',
        },
      ],
    },
    {
      component: 'scrollover-text-with-background-img',
      img: corporateScrollover,
      title: ['Not just for big business'],
      sub: 'Irrespective of the size of the business, most employees share the same fundamental personal needs.',
    },
    {
      component: 'features-with-image',
      img: corporateSection,
      title: 'Keeping You Informed',
      content: [
        'Ensuring that your employees are fully informed of their personal benefits package is achieved through our bespoke employee communications programme (employee brochures) with on line access to all work related information providing a view of all costs and benefits provided by you, their employer.',
        'Whether you’re considering a benefit programme for a select group of employees or an entire workforce, our qualified advisers will tailor-make a package that satisfies the requirements of all concerned.',
      ],
    },
    {
      component: 'testimonial-centered',
      backgroundColor: true,
      contents: [
        {
          quote:
            'Whichever of our group businesses you deal with, you will benefit from our unique ethos based on an in-depth understanding of your needs, objectives and concerns, together with an intimate knowledge of investments, taxation and financial and HR legislation.',
          name: 'Hayley Syrett',
          role: 'Head of Employee Benefits',
          photo: hayleysyrett,
        },
      ],
    },
  ],
};

export default corporateConfig;
