import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import slugify from 'slugify';

import ConfigContext from '../../context/config/configContext';
import InputGroup from '../Forms/InputGroup';
import integerOrZero from '../../utils/integerOrZero';
import formatCurrency from '../../utils/formatCurrency';
import MoneyToNumber from '../../utils/moneyToNumber';

const Container = styled.div`
  width: calc(100%-2rem);
  margin: 0.5rem;
  margin-top: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
  }
  span {
    color: ${(props) => `rgba(${props.theme.colors.primary[1]}, 1)`};
    margin-left: 0.5rem;
    font-size: 1.3rem;
  }
`;

const SubHeading = styled.div`
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
  font-size: 1.3rem;
  margin: 1rem 0 0.5rem 0;
  span {
    color: ${(props) => `rgba(${props.theme.colors.primary[1]}, 1)`};
    margin-left: 0.5rem;
    font-size: 1.1rem;
  }
`;

const SpendingRecord = ({
  currentContext,
  pageRef,
  handleChange,
  handleBlur,
  handleToggle,
}) => {
  //Todo - show tables of asset, liability and a total for this category - you should be able to add new assets and liabilities from here too
  const configContext = useContext(ConfigContext);

  const [totalAnnual, setTotalAnnual] = useState('');

  useEffect(() => {}, [configContext]);

  useEffect(() => {
    const simpleList = [];
    configContext.config.expenditureDataConfig.forEach((group) => {
      group.items.forEach((item) => {
        simpleList.push(item);
      });
    });

    setTotalAnnual(getTotals(simpleList)); // eslint-disable-next-line
  }, [currentContext]);

  const getTotals = (configList) => {
    const searchList = [];
    configList.forEach((item) => {
      searchList.push(mutateNameIntoRef(item));
    });
    let total = 0;
    searchList.forEach((listItem) => {
      if (!!currentContext[listItem] && !!currentContext[listItem].value) {
        total = total + integerOrZero(currentContext[listItem].value);
      }
    });
    return formatCurrency(total);
  };

  const mutateNameIntoRef = (name) => {
    return (
      'expenditure' +
      slugify(name, {
        replacement: '',
      })
    );
  };

  const calculateNetDisposable = () => {
    let ndi = 0;
    if (
      !!currentContext.estimatedNetTotalIncome &&
      currentContext.estimatedNetTotalIncome.value !== undefined
    ) {
      ndi =
        currentContext.estimatedNetTotalIncome.value -
        MoneyToNumber(totalAnnual);
    }
    return formatCurrency(ndi);
  };
  return (
    <Container>
      <div>
        <SubHeading>Income Summary</SubHeading>
        <InputGroup
          name='estimatedNetTotalIncome'
          dataItemConfig={{
            type: 'number',
            label: 'Estimated Total Net Income (Monthly)',
          }}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleToggle={handleToggle}
          dataItem={
            !!currentContext.estimatedNetTotalIncome &&
            currentContext.estimatedNetTotalIncome.value !== undefined
              ? currentContext.estimatedNetTotalIncome.value
              : ''
          }
        />
      </div>
      <Header>
        <h2>
          Spending Record <span> Total {totalAnnual}</span>
          {!!calculateNetDisposable() && (
            <span>Net Disposable Income {calculateNetDisposable()}</span>
          )}
        </h2>
      </Header>
      {configContext.config.expenditureDataConfig.map((group, index) => (
        <div key={index}>
          <SubHeading>
            {group.title}{' '}
            <span>Total: {getTotals(group.items)} (per month)</span>
          </SubHeading>
          {group.items.map((item, itemIndex) => (
            <InputGroup
              key={itemIndex}
              name={mutateNameIntoRef(item)}
              dataItemConfig={{ type: 'number', label: item }}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleToggle={handleToggle}
              dataItem={
                !!currentContext[mutateNameIntoRef(item)] &&
                currentContext[mutateNameIntoRef(item)].value !== undefined
                  ? currentContext[mutateNameIntoRef(item)].value
                  : ''
              }
            />
          ))}
        </div>
      ))}
      <div>
        <SubHeading>Notes</SubHeading>
        <InputGroup
          name={mutateNameIntoRef('notes')}
          dataItemConfig={{ type: 'textArea', label: 'Notes' }}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleToggle={handleToggle}
          dataItem={
            !!currentContext[mutateNameIntoRef('notes')] &&
            currentContext[mutateNameIntoRef('notes')].value !== undefined
              ? currentContext[mutateNameIntoRef('notes')].value
              : ''
          }
        />
      </div>
    </Container>
  );
};

export default SpendingRecord;
