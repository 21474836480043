import newsImg from '../../assets/news.jpg';
import holdyournerve from './posts/holdyournerve/holdyournerve';
import taxrisesontheway from './posts/taxrisesontheway/taxrisesontheway';
import portfoliorebalanceseminar from './posts/portfoliorebalanceseminar/portfoliorebalance';
import dividends from './posts/dividends/dividends';
import highmarkets from './posts/highmarkets/highmarkets';
import inheritancetax from './posts/inheritancetax/inheritancetax';
import portfoliobalance from './posts/portfoliobalance/portfoliobalance';
import secondhomes from './posts/secondhomes/secondhomes';
import taxplanningfamily from './posts/taxplanningfamily/taxplanningfamily';
import greenwashing from './posts/greenwashing/greenwashing';
import summernewsletter from './posts/summernewsletter/summernewsletter';
import selfemployed from './posts/changesforselfemployed/selfemployed';
import autumnnewsletter from './posts/autumnnewsletter/autumnnewsletter';
import incomeprotection from './posts/incomeprotection/incomeprotection';
import studentfinance from './posts/managingstudentfinance/studentfinance';
import newstyleretirement from './posts/newstyleretirement/newstyleretirement';
import nofaultdivorce from './posts/nofaultdivorce/nofaultdivorce';
import pensiontriplelock from './posts/pensiontriplelock/pensiontriplelock';
import returnofinflation from './posts/returnofinflation/returnofinflation';
import octoberrebalance from './posts/octoberrebalance/rebalance';
import winter from './posts/winternewsletter/winter';
import spring from './posts/springnl/spring';
import springstatement from './posts/springstatement/statement';
import summer_22 from './posts/summer_22/summer_22';
import stonewood_quarterly from './posts/stonewood_quarterly';
import autumnstatement from './posts/autumnstatement';
import tiktok from './posts/tictok';
import prenup from './posts/prenup';
import winter_22 from './posts/winter_22';
import springtwothree from './posts/springtwothree';
import financialeducationforchildren from './posts/financial-education-for-children'

const newsConfig = {
  route: '/news',
  title: 'Group Rapport | News',
  content: [
    {
      component: 'hero-newspage',
      img: newsImg,
      content: {
        title: 'Latest News & Information',
        subtitle: 'News, publications, blogs and announcements.',
      },
      posts: [financialeducationforchildren, springtwothree,
        winter_22,
        prenup,
        tiktok,
        autumnstatement,
        stonewood_quarterly,
        summer_22,
        springstatement,
        spring,
        winter,
        octoberrebalance,
        returnofinflation,
        pensiontriplelock,
        nofaultdivorce,
        newstyleretirement,
        studentfinance,
        incomeprotection,
        selfemployed,
        autumnnewsletter,
        summernewsletter,
        dividends,
        highmarkets,
        inheritancetax,
        portfoliobalance,
        secondhomes,
        greenwashing,
        taxplanningfamily,
        portfoliorebalanceseminar,
        holdyournerve,
        taxrisesontheway,
      ],
    },
  ],
};

export default newsConfig;
