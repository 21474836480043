const stages = ['Active', 'Inactive'];
const outcomes = ['Current', 'Old'];
const assetConfig = {
  title: 'Asset Records',
  name: 'Asset',
  addBtnLabel: 'Create Asset Record',
  tabs: ['manage', 'analyse'],
  breadcrumbs: [
    { type: 'simpleLink', label: 'Private Clients', route: '/contacts' },
    {
      type: 'relatedRecord',
      dbRef: 'contacts',
      recordRefFromCurrent: 'contactsID',
    },
    { type: 'simpleLabel', label: 'Assets' },
    { type: 'currentRecord' },
  ],
  tableConfig: { filterByLabel: 'Type' },
  stages: stages,
  outcomes: outcomes,
  manageTabConfig: {
    filterByLabel: 'Type',
    tableConfig: [
      { label: 'Type', ref: 'name' },
      { label: 'Provider', ref: 'provider' },
      { label: 'Regular Payment', ref: 'payment' },
      { label: 'Value', ref: 'amount' },
    ],
  },
  newDataSteps: [
    {
      title: 'Details',
      data: [{ ref: 'name', mandatory: true }, { ref: 'provider' }],
    },
    {
      title: 'Payment Information',
      data: [
        { ref: 'payment', mandatory: true },
        { ref: 'paymentFrequency' },
        { ref: 'amount' },
        { ref: 'rate' },
      ],
    },
    {
      title: 'Notes',
      guidance: 'Optional.',
      data: [{ ref: 'notes' }],
    },
  ],
  recordEditTabs: ['summary'],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Asset Records' },
  },
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Client Record',
          data: ['contactsID'],
        },
        {
          title: 'Details',
          data: ['name', 'provider'],
        },
        {
          title: 'Payment Information',
          data: ['payment', 'paymentFrequency', 'amount', 'rate', 'endDate'],
        },
        {
          title: 'Notes',
          data: ['notes'],
        },
      ],
    },
  ],
  data: {
    name: {
      type: 'select',
      label: 'Type',
      valueOptions: [
        { value: 'property', label: 'Property' },
        { value: 'pension', label: 'Pension' },
        { value: 'investment', label: 'Investment' },
        { value: 'bankAccount', label: 'Bank Account' },
      ],
    },
    provider: {
      type: 'text',
      label: 'Provider',
    },
    payment: {
      type: 'number',
      label: 'Payment',
    },
    paymentFrequency: {
      type: 'select',
      label: 'Payment Frequency',
      valueOptions: [
        { value: 'monthly', label: 'Monthly' },
        { value: 'quarterly', label: 'Quarterly' },
        { value: 'biannual', label: 'Bi-Annual' },
        { value: 'annually', label: 'Annually' },
        { value: 'lumpsum', label: 'Lumpsum / One-off' },
      ],
    },
    amount: {
      type: 'number',
      label: 'Current Value',
    },
    rate: {
      type: 'number',
      label: 'Growth Rate',
    },
    startDate: {
      type: 'date',
      label: 'Acquisition Date',
    },
    endDate: {
      type: 'date',
      label: 'Sale Date',
    },
    notes: {
      type: 'textArea',
      label: 'Notes',
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
  },
};

export default assetConfig;
