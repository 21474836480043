const footerConfig = {
  menu: [
    { label: 'home', route: '/' },
    { label: 'team', route: '/about' },
    { label: 'approach', route: '/approach' },
    { label: 'personal', route: '/personal' },
    { label: 'business', route: '/corporate' },
    { label: 'contact', route: '/contact' },
    { label: 'news', route: '/news' },
  ],
  subfooterText: [
    'Group Rapport Employee Benefits is a trading style of Stonewood Financial Planning Limited who are authorised and regulated by the Financial Conduct Authority – FRN: 149889.',
    'Registered in England under no 02656267. Registered office address: Marie House, 5 Baker Street, Weybridge, Surrey, KT13 8AE.',
  ],
};

export default footerConfig;
