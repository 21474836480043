import React, { Fragment, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import MainrecordContext from '../../context/mainrecord/mainrecordContext';
import Table from '../DataDisplay/Table';
import Tiler from '../DataDisplay/Tiler';
import PageLoader from '../Feedback/PageLoader';

const SmallScreens = styled.div`
  padding: 1rem 0;
  @media only screen and (min-width: 1001px) {
    display: none;
  }
`;

const BigScreens = styled.div`
  padding: 1rem 0;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const List = ({ data, redirectOverride }) => {
  const location = useLocation();
  const pageReference = location.pathname.replace(/^\/|\/$/g, '');
  const history = useHistory();
  const mainrecordContext = useContext(MainrecordContext);

  const handleSelect = (record) => {
    mainrecordContext.setCurrent([record]);
    let redirect = pageReference;
    redirectOverride && (redirect = redirectOverride);
    history.push('/' + redirect + 'record');
  };

  return !data ? (
    <PageLoader />
  ) : (
    <Fragment>
      <BigScreens>
        <Table data={data} handleSelect={handleSelect} />
      </BigScreens>
      <SmallScreens>
        <Tiler data={data} handleSelect={handleSelect} />
      </SmallScreens>
    </Fragment>
  );
};

export default List;
