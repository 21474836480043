import Image from './image.png';
import Newsletter from './newsletter.pdf';
import Rachel from './rachellane.jpg';

const summernewsletter = {
  title: 'Financial Focus - Autumn 2021 Newsletter',
  date: '16/09/2020',
  readTime: 15,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['summer 2021', 'IHT', 'Investing'],
  intro:
    'As we settle into autumn after a difficult summer worldwide, challenging decisions on managing the fallout from the pandemic remain. Whether we will have a Budget this year is still uncertain, but there are a number of developments to continue to factor into your planning. One central issue for many is of course looking towards retirement. In our feature this issue, we look at the adjustments to end of work patterns made over the last year and consider the positives of a gradual transition from full time work to full time retirement. The Covid-19 support benefits, vital to many over the past 18 months, are coming to an end so we consider the benefits of income protection to help cover unforeseen events such as future health issues. And as the new intake of freshers arrive at university, we look closely at student loan schemes and repayment schedules – perhaps not as onerous as you may have thought.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default summernewsletter;
