import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import InputGroup from '../Forms/InputGroup';
import { Button } from '../Controls';
import Icon from '../UI/Icon';
import checkIsVisible from '../../utils/checkIsVisible';
import Lookup from '../Forms/Lookup';
import TeamLookup from '../Forms/TeamLookup';

const Wrapper = styled.div`
  padding: 1rem 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;

const ItemWrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    font-weight: bold;
  }
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  margin: 0.5rem;
  background-color: ${(props) =>
    props.outline === 'true'
      ? `none`
      : props.outline === 'grey'
      ? `none`
      : `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  color: ${(props) =>
    props.outline === 'true'
      ? `none`
      : props.outline === 'grey'
      ? `rgba(${props.theme.colors.neutral[3]}, 0.8)`
      : `rgba(${props.theme.colors.neutral[5]}, 1)`};
  border: ${(props) =>
    props.outline === 'true'
      ? `1px solid rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : props.outline === 'grey'
      ? `1px solid rgba(${props.theme.colors.neutral[3]}, 0.8)`
      : 'none'};

  &:hover {
    cursor: ${(props) => (props.selectable === 'true' ? 'pointer' : 'no-drop')};
    opacity: ${(props) => props.selectable === 'true' && '0.7'};
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
`;

const Content = styled.div`
  width: 100%;
  max-width: 400px;
  display: ${(props) => (props.hideSection ? 'none' : 'block')};
`;

const TrackerLine = styled.div`
  min-width: 30px;
  min-height: 20px;
  margin: 0 0.5rem;
`;

const TrackerLineInner = styled.div`
  background: ${(props) =>
    props.success === 'true'
      ? `rgba(${props.theme.colors.linkBlue[0]}, 0.8)`
      : `rgba(${props.theme.colors.neutral[3]}, 0.3)`};
  width: 2px;
  margin: auto;
  height: 100%;
`;

const FlexLine = styled.div`
  display: flex;
  margin: 1rem 0;
`;

const Guidance = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.8)`};
  font-size: 0.9rem;
  line-height: 1.3rem;
`;

const Vertical = ({ newDataSteps, dataConfig, handleSubmit }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [newDataState, setNewDataState] = useState({});

  const handleChange = (e, additional) => {
    const update = { ...newDataState };
    update[e.target.name] = {
      ...newDataState[e.target.name],
      value: e.target.value,
    };
    !!additional &&
      (update[e.target.name] = {
        ...update[e.target.name],
        additional,
      });
    setNewDataState(update);
  };

  const handleToggle = (name) => {
    const update = { ...newDataState };
    if (!update[name]) {
      update[name] = { value: true };
    } else {
      update[name] = { ...update[name], value: !update[name].value };
    }
    setNewDataState(update);
  };

  const checkCanProceed = (config) => {
    if (!config) {
      return false;
    }
    let mandatorys = [];
    config.forEach((item) => {
      if (item.mandatory) {
        if (
          !dataConfig[item.ref].visibilityRules ||
          !!checkIsVisible(newDataState, dataConfig[item.ref].visibilityRules)
        ) {
          mandatorys.push(item.ref);
        }
      }
    });
    if (mandatorys.length === 0) {
      return false;
    }
    let complete = false;
    mandatorys.forEach((item) => {
      if (!newDataState[item]) {
        complete = true;
      }
    });
    return complete;
  };

  return (
    <Wrapper>
      {newDataSteps.map((item, index) => (
        <ItemWrapper key={index}>
          <Header>
            <Circle
              onClick={() => {
                index < activeStep && setActiveStep(index);
              }}
              selectable={index < activeStep ? 'true' : 'false'}
              outline={
                activeStep > index
                  ? 'true'
                  : activeStep === index
                  ? 'false'
                  : 'grey'
              }
            >
              {activeStep > index ? (
                <Icon iconType='check' size={1.2} color='linkBlue' margin='0' />
              ) : (
                index + 1
              )}
            </Circle>
            <p>{item.title}</p>
          </Header>
          <Body>
            <TrackerLine>
              {index !== newDataSteps.length - 1 && (
                <TrackerLineInner success={(activeStep > index).toString()} />
              )}
            </TrackerLine>
            <Content hideSection={index !== activeStep}>
              <Guidance>{item.guidance}</Guidance>
              {item.data &&
                item.data.map((dataItem, index) => (
                  <Fragment key={dataItem.ref}>
                    {(!dataConfig[dataItem.ref].visibilityRules ||
                      !!checkIsVisible(
                        newDataState,
                        dataConfig[dataItem.ref].visibilityRules
                      )) && (
                      <Fragment>
                        {!dataConfig[dataItem.ref].isComplex ? (
                          <InputGroup
                            overrideReadOnly={true}
                            mandatory={dataItem.mandatory}
                            name={dataItem.ref}
                            dataItemConfig={dataConfig[dataItem.ref]}
                            handleChange={handleChange}
                            handleToggle={handleToggle}
                            dataItem={
                              !!newDataState[dataItem.ref] &&
                              newDataState[dataItem.ref].value !== undefined
                                ? newDataState[dataItem.ref].value.seconds
                                  ? moment(
                                      newDataState[dataItem.ref].value
                                        .toDate()
                                        .toString()
                                    ).format('YYYY-MM-DD HH: MM')
                                  : newDataState[dataItem.ref].value
                                : ''
                            }
                          />
                        ) : (
                          <Fragment>
                            {dataConfig[dataItem.ref].type === 'lookup' && (
                              <Lookup
                                data={newDataState[dataItem.ref]}
                                dataItemConfig={dataConfig[dataItem.ref]}
                              />
                            )}

                            {dataConfig[dataItem.ref].type === 'teamlookup' && (
                              <TeamLookup
                                handleChange={handleChange}
                                dataItemName={dataItem.ref}
                                data={
                                  !!newDataState[dataItem.ref] &&
                                  newDataState[dataItem.ref].value
                                    ? newDataState[dataItem.ref].value
                                    : ''
                                }
                                dataItemConfig={dataConfig[dataItem.ref]}
                              />
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                ))}
              <FlexLine>
                <Button
                  color='none'
                  size='small'
                  disabled={index === 0}
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                >
                  Back
                </Button>
                <Button
                  color='light'
                  size='small'
                  disabled={checkCanProceed(item.data)}
                  onClick={() => {
                    activeStep === newDataSteps.length - 1
                      ? handleSubmit(newDataState)
                      : setActiveStep(activeStep + 1);
                  }}
                >
                  {activeStep === newDataSteps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </FlexLine>
            </Content>
          </Body>
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};

export default Vertical;
