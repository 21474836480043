import React from 'react';
import styled from 'styled-components';

import Icon from '../UI/Icon';
import MailingListSimpleWidget from './MailingListSimpleWidget';

const Wrapper = styled.div`
  background: ${(props) =>
    props.background && `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 4rem 2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

const SocialsTitle = styled.div`
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

const Socials = styled.div`
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

const FlexLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-left: 4rem;
`;

const SocialsMailingList = ({ config }) => {
  return (
    <Wrapper background={config.background}>
      <Container>
        <SocialsTitle>{config.title}</SocialsTitle>
        <Grid>
          <Socials>
            {config.instagramLink && (
              <FlexLink to={config.instagramLink}>
                <Icon iconType='instagram' size={5} color='primary' />
                {config.instagramLabel}
              </FlexLink>
            )}
            {config.facebookLink && (
              <FlexLink to={config.facebookLink}>
                <Icon iconType='facebook-square' size={5} color='primary' />
                {config.facebookLabel}
              </FlexLink>
            )}
          </Socials>
          <MailingListSimpleWidget />
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default SocialsMailingList;
