import Brushes from './eye-brushes.jpg';
import Laura from './Laura.jpg';

const brushesLaunch = {
  title: 'My own makeup brushes ✨',
  date: '5/07/2021',
  readTime: 3,
  image: Brushes,
  type: 'New',
  typeColor: 'linkBlue',
  tags: ['Makeup', 'Brushes'],
  intro:
    'I will only be using my own brushes in the studio from now on and love love love the quality and versatility. These are pro brushes so for any makeup artists out there that want to update their kit or makeup lovers these are perfect ✨',
  author: {
    image: Laura,
    name: 'Laura Lane',
    role: 'Makeup Artist',
  },
  lineItems: [
    [
      {
        content:
          'I’m so proud to announce the development of my own makeup brushes ✨',
      },
    ],
    [
      {
        content:
          'I will only be using my own brushes in the studio from now on and love love love the quality and versatility. These are pro brushes so for any makeup artists out there that want to update their kit or makeup lovers these are perfect ✨',
      },
    ],
    [
      {
        content:
          'There is a face brush set which consists of 8 brushes and retails at £55 and a 11 piece eye brush set that retails at £45. There is a £5 saving when buying both sets together. All brushes are cruelty free and vegan ✨',
      },
    ],

    [
      {
        content:
          'Order through the website for those outside the local area, UK only at the moment. I am offering free local delivery or collection from the studio too, dm me to order ✨',
      },
    ],
  ],
};

export default brushesLaunch;
