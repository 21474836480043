import React, { useEffect } from 'react';
import styled from 'styled-components';
import Icon from '../UI/Icon';
import ThreeDots from '../UI/ThreeDots';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) =>
    `rgba(${props.theme.colors.primary[0]}, 0.97)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  position: fixed;
  top: 0;
  left: 0;
  top: ${(props) => (props.active === true ? '0' : '100vh')};
  transition: all 0.2s ease-in;
  z-index: 1000000000;
`;

const SuccessAlert = ({ subtext, currentState, setCurrentState }) => {
  useEffect(() => {
    const interval = () => {
      setInterval(() => {
        setCurrentState('completed');
      }, 4000);
    };
    if (currentState === true) {
      interval();
    }
    return () => clearInterval(interval); // eslint-disable-next-line
  }, [currentState]);

  return (
    <Wrapper active={currentState}>
      <h1>Success</h1>
      <Icon iconType='checkCircle' size={10} color='light' margin=' 2rem 0' />
      {subtext &&
        subtext.map((item, index) => (
          <h2 key={index}>
            {item}
            {index === subtext.length - 1 && <ThreeDots />}
          </h2>
        ))}
    </Wrapper>
  );
};

export default SuccessAlert;
