const stages = ['Active', 'Inactive'];
const outcomes = ['Current', 'Old'];
const addressConfig = {
  title: 'Address Records',
  name: 'Address',
  addBtnLabel: 'Create Address Record',
  breadcrumbs: [
    { type: 'simpleLink', label: 'Private Clients', route: '/contacts' },
    {
      type: 'relatedRecord',
      dbRef: 'contacts',
      recordRefFromCurrent: 'contactsID',
    },
    { type: 'simpleLabel', label: 'Address Record' },
    { type: 'currentRecord' },
  ],
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'House Name / Number' },
  stages: stages,
  outcomes: outcomes,
  manageTabConfig: {
    filterByLabel: 'House Name / Number',
    tableConfig: [
      { label: 'House Name / Number', ref: 'name' },
      { label: 'City', ref: 'city' },
      { label: 'Postcode', ref: 'postcode' },
      { label: 'Date Moved In', ref: 'dateMovedIn' },
      { label: 'Date Moved Out', ref: 'dateMovedOut' },
    ],
  },
  newDataSteps: [
    {
      title: 'Address',
      data: [
        { ref: 'name', mandatory: true },
        { ref: 'addressLine2' },
        { ref: 'addressLine3' },
        { ref: 'city', mandatory: true },
        { ref: 'postcode', mandatory: true },
      ],
    },
    {
      title: 'History',
      data: [{ ref: 'dateMovedIn', mandatory: true }, { ref: 'dateMovedOut' }],
    },
    {
      title: 'Notes',
      guidance:
        'Optional. Add any notes you want to record about this company and display on the address record to the rest of the team.',
      data: [{ ref: 'notes' }],
    },
  ],
  recordEditTabs: ['summary'],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Address Records' },
  },
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Related Record',
          data: ['contactsID', 'accountsID'],
        },
        {
          title: 'Address Details',
          data: ['name', 'addressLine2', 'addressLine3', 'city', 'postcode'],
        },
        {
          title: 'History',
          data: ['dateMovedIn', 'dateMovedOut'],
        },
        {
          title: 'Notes',
          data: ['notes'],
        },
      ],
    },
  ],
  data: {
    name: {
      type: 'text',
      label: 'House Name / Number',
    },
    addressLine2: {
      type: 'text',
      label: 'Address Line 2',
    },
    addressLine3: {
      type: 'text',
      label: 'Address Line 3',
    },
    city: {
      type: 'text',
      label: 'City',
    },
    dateMovedIn: {
      type: 'date',
      label: 'Date Moved In',
    },
    dateMovedOut: {
      type: 'date',
      label: 'Date Moved Out',
    },
    postcode: {
      type: 'text',
      label: 'Postcode',
    },
    notes: {
      type: 'textArea',
      label: 'Notes',
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
    accountsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'accounts',
      label: 'Corporate Account',
    },
  },
};

export default addressConfig;
