import React, { Fragment } from 'react';
import styled from 'styled-components';
import formatCurrency from '../../utils/formatCurrency';

const Container = styled.div`
  margin: 0 1rem 1rem 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Tile = styled.div`
  width: ${(props) => `${props.width}%`};
  h2 {
    font-weight: bold;
  }
  @media only screen and (max-width: 1000px) {
    width: 350px;
    margin-bottom: 0.5rem;
    h2 {
      font-size: 1.5rem;
    }
  }
`;

const FlexLine = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-size: 0.8rem;
    max-width: 100px;
    margin-bottom: 0.5rem;
  }
  @media only screen and (max-width: 1300px) {
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.7rem;
    }
  }
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    p {
      margin-bottom: 0;
      margin-top: 0.5rem;
      max-width: 300px;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
  span {
    font-weight: 300;
    font-size: 1rem;
  }
`;

const Header = styled.div`
  min-height: 50px;
  display: flex;
  background-color: ${(props) => `rgba(${props.theme.colors.primary[0]},0.5)`};
  margin-right: 1rem;
  align-items: center;
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]},1)`};
  border-top-right-radius: 1rem;
  border-top-left-radius: 5px;
  h2 {
    font-size: 1rem;
    @media only screen and (max-width: 1200px) {
      font-size: 0.8rem;
    }
  }
  div {
    font-size: 1rem;
    padding: 0.5rem 0.8rem;
  }
`;

const Body = styled.div`
  min-height: 190px;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]},1)`};
  padding: 1rem;
  margin: 0 1rem 0 0;
  box-shadow: ${(props) => props.theme.boxShadow};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const FlowStats = ({ config, data, title, disableNumber }) => {
  return (
    <Container>
      <Title>
        <span>By </span>
        {title}
      </Title>

      <Wrapper>
        {data.map((item, index) => (
          <Fragment key={index}>
            <Tile width={100 / data.length}>
              <Header>
                <div>{!disableNumber && index + 1}</div>
                <h2>{item.label}</h2>
              </Header>
              <Body>
                {config.count && (
                  <FlexLine>
                    <p>{config.count}</p>
                    <h3>{item.count ? item.count : '0'}</h3>
                  </FlexLine>
                )}
                {config.amount && (
                  <FlexLine>
                    <p>{config.amount}</p>
                    <h3>{item.amount ? formatCurrency(item.amount) : '0'}</h3>
                  </FlexLine>
                )}
                {config.aua && (
                  <FlexLine>
                    <p>{config.aua}</p>
                    <h3>{item.aua ? formatCurrency(item.aua) : '0'}</h3>
                  </FlexLine>
                )}
                {config.initial && (
                  <FlexLine>
                    <p>{config.initial}</p>
                    <h3>{item.initial ? formatCurrency(item.initial) : '0'}</h3>
                  </FlexLine>
                )}
                {config.ongoing && (
                  <FlexLine>
                    <p>{config.ongoing}</p>
                    <h3>{item.ongoing ? formatCurrency(item.ongoing) : '0'}</h3>
                  </FlexLine>
                )}
              </Body>
            </Tile>
          </Fragment>
        ))}
      </Wrapper>
    </Container>
  );
};

export default FlowStats;
