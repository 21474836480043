import React, { useReducer } from 'react';
import ConfigContext from './configContext';
import configReducer from './configReducer';
import { REMOVE_CONFIG } from '../types';

import TenantsConfig from '../../config/tenantConfig/tenantsConfig';

const ConfigState = (props) => {
  const initalState = !!TenantsConfig[process.env.REACT_APP_SUBTENANT]
    ? {
        ...TenantsConfig[process.env.REACT_APP_TENANT],
        ...TenantsConfig[process.env.REACT_APP_SUBTENANT],
      }
    : { ...TenantsConfig[process.env.REACT_APP_TENANT] };

  const [state, dispatch] = useReducer(configReducer, initalState);

  // Remove Config
  const removeConfig = () => {
    dispatch({
      type: REMOVE_CONFIG,
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        config: state,
        removeConfig,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigState;
