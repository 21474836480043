import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import Pie from '../Charts/Pie';
import PageLoader from '../Feedback/PageLoader';

const Container = styled.div`
  margin: 0 1rem;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 450px) {
    flex-direction: column-reverse;
    justify-content: center;
    margin: auto;
  }
`;

const Table = styled.div`
  min-width: 170px;
  @media only screen and (max-width: 450px) {
    margin-bottom: -4rem;
  }
`;

const FlexItem = styled.div`
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 5fr 1fr;
  p {
    font-weight: bold;
    margin-right: 1rem;
  }
  h4 {
    font-size: 1.4rem;
  }
`;
const Title = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
  span {
    font-weight: 300;
    font-size: 1rem;
  }
`;

const PieChartStats = ({ config, data, title, valueToChart }) => {
  const [chartData, setChartData] = useState({ loading: true });
  useEffect(() => {
    const chartData = [];
    data.forEach((item, index) => {
      chartData.push({ y: item[valueToChart], name: item.label });
    });
    setChartData({ data: chartData, loading: false, seriesName: title });
    return () => {};
  }, [data, title, valueToChart]);

  return chartData.loading ? (
    <PageLoader />
  ) : (
    <Fragment>
      <Container>
        <Pie seriesName={chartData.seriesName} chartData={chartData.data} />
        <div>
          <Title>
            <span>By</span> {title}
          </Title>
          <Table>
            {chartData.data.map((item, index) => (
              <FlexItem key={index}>
                <p>{item.name}</p>
                <h4>{item.y}</h4>
              </FlexItem>
            ))}
          </Table>
        </div>
      </Container>
    </Fragment>
  );
};

export default PieChartStats;
