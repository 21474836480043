import favico from './assets/favicon.ico';

const styling = {
  colors: {
    neutral: [
      '0, 0, 0',
      '34, 34, 34',
      '68, 68, 68',
      '153, 153, 153',
      '238, 238, 238',
      '255, 255, 255',
    ],
    primary: [
      '50, 71, 102',
      '50, 71, 102',
      '50, 71, 102',
      '50, 71, 102',
      '50, 71, 102',
    ],
    secondary: [
      '237, 155, 64',
      '237, 155, 64',
      '237, 155, 64',
      '237, 155, 64',
      '237, 155, 64',
    ],
    tertiary: [
      '35, 37, 40',
      '65, 67, 70 ',
      '85, 87, 90',
      '105, 107, 110',
      '125, 127, 130',
    ],
    danger: [
      '230, 57, 70',
      '230, 57, 70',
      '230, 57, 70',
      '230, 57, 70',
      '230, 57, 70',
    ],
    success: [
      '87, 158, 114',
      '87, 158, 114',
      '87, 158, 114',
      '87, 158, 114',
      '87, 158, 114',
    ],
    linkBlue: ['15, 131, 254'],
    bgColor: ['237, 242, 247'],
  },
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.1) 0px 2px 4px',
  fonts: {
    mainFontFamily: "'Poppins', sans-serif",
    href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap',
  },
  favico: favico,
};

export default styling;
