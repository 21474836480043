import React, { useContext, useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { uid } from 'uid';
import Moment from 'react-moment';
import firebase, { db } from '../../context/firebase';

import AuthContext from '../../context/auth/authContext';

import Icon from '../UI/Icon';
import Loader from '../Feedback/LoadingSpinner';
import { Button } from '../Controls';

const Container = styled.div`
  width: calc(100%-2rem);
  margin: 0.5rem;
  margin-top: 1.5rem;
`;

const Header = styled.div`
  h2 {
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
  }

  p {
    font-size: 0.9rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const Wrapper = styled.div`
  margin-top: 0.5rem;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  padding: 1rem;
  border-radius: 7px;
  box-shadow: ${(props) => props.theme.boxShadow};
  @media only screen and (max-width: 800px) {
    border-radius: 0;
    border: none;
    box-shadow: none;
    border-top: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]}, 0.5)`};
  }
`;

const QuestionWrapper = styled.div`
  padding: 0.5rem;
  background: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 0.4)`};
  border-radius: 7px;
  margin-bottom: 0.5rem;
`;

const QuestionTitleLine = styled.div`
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const QuestionText = styled.div`
  text-transform: none;
`;

const QuestionMetas = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
`;

const PostMetaItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.7rem;
  margin-right: 0.25rem;
  min-width: 40px;
  color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const LoadingWrapper = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 100%;
  font-family: ${(props) => props.theme.fonts.mainFontFamily};
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
  outline: none;
  border: ${(props) => `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
  border-radius: 10px;
  &:focus,
  &:active {
    outline: none;
    color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
    border: ${(props) => `1px solid rgba(${props.theme.colors.success[0]},1)`};
  }
`;

const QuestionAskWrapper = styled.div`
  margin: 1rem 0;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
`;

const Gap = styled.span`
  margin-left: 0.5rem;
`;

const ActionsGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RepliesWrapper = styled.div``;

const Reply = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
  padding: 0.5rem;
  margin: 0.25rem 0;
  border-radius: 25px;
  border-top-left-radius: 0px;
`;

const ReplyText = styled.div`
  text-transform: none;
`;

const ReplyDate = styled.div`
  font-size: 0.7rem;
  margin-bottom: 0.25rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
`;

const PaddingGroup = styled.div`
  padding-top: 1rem;
`;

const Timeline = ({ itemID, pageRef }) => {
  const authContext = useContext(AuthContext);

  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [questionToAsk, setQuestionToAsk] = useState('');
  const [replyToPost, setReplyToPost] = useState('');
  const [questions, setQuestions] = useState([]); // eslint-disable-next-line
  const [questionChange, setQuestionChange] = useState(false);
  const [commentsToShow, setCommentsToShow] = useState(false);
  useEffect(() => {
    let mounted = true;
    setLoadingQuestions(true);
    const loadedQuestions = [];
    if (!!itemID) {
      db.collection('comments')
        .where('referenceID', '==', itemID)
        .orderBy('created')
        .get()
        .then((querySnapshot) => {
          if (mounted) {
            querySnapshot.forEach((doc) => {
              const question = { ...doc.data() };
              if (!question.deleted) {
                question.id = doc.id;
                question.created = question.created.toDate();
                if (question.replies) {
                  const mutatedReplies = [];
                  question.replies.forEach((reply) => {
                    if (!reply.deleted) {
                      mutatedReplies.push(reply);
                    }
                  });
                  question.replies = mutatedReplies;
                }
                loadedQuestions.push(question);
              }
            });
            setQuestions(loadedQuestions.reverse());
            setLoadingQuestions(false);
          }
        })
        .catch((err) => {
          setLoadingQuestions(false);
          console.log(err);
        });
    }
    return function cleanup() {
      mounted = false;
    };
  }, [itemID]);

  const handleQuestionToAskChange = (e) => {
    e.preventDefault();
    setQuestionToAsk(e.target.value);
  };

  const handleQuestionSubmit = () => {
    const newQuestion = db.collection('comments').doc();
    const newQuestionData = {
      question: questionToAsk,
      referenceID: itemID,
      referenceRecordType: pageRef,
      authUserName: authContext.userData.name,
      authUserID: authContext.userData.id,
      id: newQuestion.id,
      created: firebase.firestore.Timestamp.now(),
    };
    newQuestion
      .set({
        ...newQuestionData,
      })
      .then(() => {
        const update = {
          ...newQuestionData,
          created: new Date(),
        };
        const updatedQuestions = questions;
        updatedQuestions.unshift(update);
        setQuestions(updatedQuestions);
        setQuestionToAsk('');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteQuestion = (questionID, questionIndex) => {
    setQuestionChange(true);
    const questionUpdate = { ...questions[questionIndex], deleted: true };

    db.collection('comments')
      .doc(questionID)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setQuestionChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReplyToPostChange = (e) => {
    e.preventDefault();
    setReplyToPost(e.target.value);
  };

  const handleReplySubmit = (questionIndex) => {
    const newReplyData = {
      reply: replyToPost,
      authUserName: authContext.userData.name,
      authUserID: authContext.userData.id,
      id: uid(16),
      created: firebase.firestore.Timestamp.now(),
    };
    const questionUpdate = { ...questions[questionIndex] };
    !!questionUpdate.replies
      ? questionUpdate.replies.push(newReplyData)
      : (questionUpdate.replies = [newReplyData]);

    db.collection('comments')
      .doc(questionUpdate.id)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setReplyToPost('');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReplyDelete = (questionIndex, replyIndex) => {
    setQuestionChange(true);
    const questionUpdate = {
      ...questions[questionIndex],
    };
    questionUpdate.replies[replyIndex] = {
      ...questionUpdate.replies[replyIndex],
      deleted: true,
    };

    db.collection('comments')
      .doc(questions[questionIndex].id)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setQuestionChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpvote = (questionIndex) => {
    setQuestionChange(true);
    const questionUpdate = { ...questions[questionIndex] };
    !!questionUpdate.upvotes
      ? questionUpdate.upvotes.push(authContext.userData.id)
      : (questionUpdate.upvotes = [authContext.userData.id]);

    db.collection('businesscaseComments')
      .doc(questionUpdate.id)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setQuestionChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemoveUpvote = (questionIndex) => {
    setQuestionChange(true);
    const questionUpdate = {
      ...questions[questionIndex],
    };

    const index = questionUpdate.upvotes.indexOf(authContext.userData.id);

    if (index > -1) {
      questionUpdate.upvotes.splice(index, 1);
    }

    db.collection('comments')
      .doc(questions[questionIndex].id)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setQuestionChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggleCommentsSection = (questionID) => {
    setReplyToPost('');
    commentsToShow === questionID
      ? setCommentsToShow(false)
      : setCommentsToShow(questionID);
  };

  return (
    <Container>
      <Header>
        <h2>Timeline</h2>
        <p>
          Post updates, reply to comments and keep everyone up todate with how
          things are going.
        </p>
      </Header>
      <Wrapper>
        {!!questions && (
          <>
            <QuestionAskWrapper>
              <FlexGroup>
                <StyledInput
                  placeholder='Post an update...'
                  type='text'
                  value={questionToAsk}
                  onChange={(e) => {
                    handleQuestionToAskChange(e);
                  }}
                ></StyledInput>
              </FlexGroup>
              {questionToAsk !== '' && (
                <BtnGroup>
                  <Button
                    color={'linkBlue'}
                    onClick={() => handleQuestionSubmit()}
                  >
                    Post Comment
                  </Button>
                  <Button onClick={() => setQuestionToAsk('')} color={'light'}>
                    Cancel
                  </Button>
                </BtnGroup>
              )}
            </QuestionAskWrapper>
            {!!loadingQuestions ? (
              <LoadingWrapper>
                <Loader />
              </LoadingWrapper>
            ) : (
              <>
                {questions.map((question, questionIndex) => (
                  <Fragment key={question.id}>
                    {!question.deleted && (
                      <QuestionWrapper>
                        <QuestionTitleLine>
                          <Icon
                            iconType='comments'
                            margin={'0 0.25rem 0 0'}
                            size={1}
                            color={'dark'}
                          />
                          <Moment format='Do MMMM YYYY h:mm:ss a'>
                            {question.created}
                          </Moment>
                          {!!question.authUserName && (
                            <Gap> - {question.authUserName}</Gap>
                          )}
                        </QuestionTitleLine>
                        <QuestionText>{question.question}</QuestionText>
                        <QuestionMetas>
                          <PostMetaItem
                            onClick={() =>
                              question.authUserID !== authContext.userData.id &&
                              (!question.upvotes ||
                              !question.upvotes.includes(
                                authContext.userData.id
                              )
                                ? handleUpvote(questionIndex)
                                : !!question.upvotes.includes(
                                    authContext.userData.id
                                  ) && handleRemoveUpvote(questionIndex))
                            }
                          >
                            <Icon
                              iconType='like'
                              size={1}
                              color={
                                !!question.upvotes &&
                                !!question.upvotes.includes(
                                  authContext.userData.id
                                )
                                  ? 'success'
                                  : 'midGrey'
                              }
                            />{' '}
                            {!!question.upvotes &&
                              question.upvotes.length &&
                              question.upvotes.length}
                          </PostMetaItem>
                          <PostMetaItem
                            onClick={() =>
                              handleToggleCommentsSection(question.id)
                            }
                          >
                            <Icon iconType='comment' size={1} color='midGrey' />{' '}
                            {!!question.replies &&
                              !!question.replies.length > 0 &&
                              question.replies.length}
                          </PostMetaItem>
                          {question.authUserID === authContext.userData.id && (
                            <PostMetaItem>
                              <Icon
                                iconType='trash'
                                size={1}
                                color='midGrey'
                                onClick={() =>
                                  handleDeleteQuestion(
                                    question.id,
                                    questionIndex
                                  )
                                }
                              />
                            </PostMetaItem>
                          )}
                        </QuestionMetas>
                        {commentsToShow === question.id && (
                          <PaddingGroup>
                            <ActionsGroup>
                              <StyledInput
                                placeholder='Add a comment...'
                                type='text'
                                value={replyToPost}
                                onChange={(e) => {
                                  handleReplyToPostChange(e);
                                }}
                              ></StyledInput>
                              {replyToPost !== '' && (
                                <Button
                                  onClick={() =>
                                    handleReplySubmit(questionIndex)
                                  }
                                  color={'linkBlue'}
                                >
                                  Post
                                </Button>
                              )}
                            </ActionsGroup>
                            {question.replies && (
                              <RepliesWrapper>
                                {question.replies.map((reply, replyIndex) => (
                                  <Fragment key={reply.id}>
                                    {!reply.deleted && (
                                      <Reply>
                                        <ReplyDate>
                                          <Moment format='Do MMMM YYYY h:mm:ss a'>
                                            {reply.created.toDate()}
                                          </Moment>
                                        </ReplyDate>
                                        <ReplyText>{reply.reply}</ReplyText>
                                        {reply.authUserID ===
                                          authContext.userData.id && (
                                          <Icon
                                            onClick={() =>
                                              handleReplyDelete(
                                                questionIndex,
                                                replyIndex
                                              )
                                            }
                                            iconType='trash'
                                            size={1}
                                            margin='0.5rem'
                                            color={'midGrey'}
                                          />
                                        )}
                                      </Reply>
                                    )}
                                  </Fragment>
                                ))}
                              </RepliesWrapper>
                            )}
                          </PaddingGroup>
                        )}
                      </QuestionWrapper>
                    )}
                  </Fragment>
                ))}
              </>
            )}
          </>
        )}
      </Wrapper>
    </Container>
  );
};

export default Timeline;
