import React, { Fragment, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import AuthContext from '../../context/auth/authContext';

import Icon from '../UI/Icon';
import ProgressBar from './ProgressBar';
import NoDocuments from './NoDocuments';
import { Button } from '../Controls';

const Wrapper = styled.div`
  padding: 1rem;
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  select,
  textarea {
    width: 100%;
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
    outline: none;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
    border-radius: 10px;
    &:focus,
    &:active {
      outline: none;
      color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
      border: ${(props) =>
        `1px solid rgba(${props.theme.colors.success[0]},1)`};
    }
  }
  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
    padding: 0.75rem 1rem;
    font-size: 1rem;
    background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},1)`};
    outline: none;
    border: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]},0.5)`};
    border-radius: 10px;
    &:focus,
    &:active {
      outline: none;
      color: ${(props) => `rgba(${props.theme.colors.primary[2]},1)`};
      border: ${(props) =>
        `1px solid rgba(${props.theme.colors.success[0]},1)`};
    }
  }
  form {
    padding-top: 1rem;
  }
`;

const Guidance = styled.div`
  display: flex;
  padding-bottom: 1rem;
  color: ${(props) => `rgba(${props.theme.colors.primary[2]}, 1)`};
  font-size: 0.8rem;
`;

const FileInput = styled.input`
  display: none;
`;

const DocumentLink = styled.a`
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  i {
    color: ${(props) => `rgba(${props.theme.colors.secondary[0]}, 1)`};
    padding: 1rem;
  }
`;

const DocName = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.primary[2]}, 1)`};
  padding: 0 0 0.25rem 0.25rem;
`;
const DocAdmin = styled.div`
  font-size: 0.7rem;
  text-transform: uppercase;
  color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
`;
const DocNotes = styled.div`
  font-size: 0.7rem;
  padding-top: 0.25rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
`;

const DistLineWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const SubmitWrapper = styled.div`
  padding-top: 1.5rem;
`;

const CustomInput = styled.label`
  text-align: center;
  padding: 0.75rem 1.5rem;
  background-color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  border: none;
  font-size: 1rem;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in;
  outline: none;
`;

const SingleDocument = ({
  currentContext,
  updateContext,
  documentRef,
  documentGuidance,
  clientID,
  tenantName,
  saveFolder,
  metaInputs,
  pageRef,
}) => {
  const authContext = useContext(AuthContext);
  useEffect(() => {}, [currentContext]);

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [fileMetaData, setFileMetaData] = useState({});

  const allowedTypes = ['image/png', 'application/pdf', 'image/jpeg'];

  const onSubmit = (e) => {
    let selected = e.target.files[0];
    if (selected && allowedTypes.includes(selected.type)) {
      setFile(selected);
      setError(null);
    } else {
      setFile(null);
      setError(
        'Please select an allowed file type (png, jpeg, PDF). You can export Word and Excel files to PDF format which will enable upload.'
      );
    }
  };

  const saveFile = (url) => {
    const update = {
      ...currentContext,
    };
    if (update.documents === undefined) {
      update.documents = [];
    }
    const newDocument = {
      url: url,
      dateUploaded: new Date(),
      name: file.name,
      type: file.type,
      docTypeRef: documentRef,
      tenant: tenantName,
      admin: { name: authContext.userData.name, id: authContext.user.uid },
      parentRecordID: currentContext.id,
    };
    if (fileMetaData.notes !== undefined) {
      newDocument.notes = fileMetaData.notes;
    }
    update.documents.unshift(newDocument);

    updateContext(update, pageRef);

    setFileMetaData({});
    setFile(null);
  };

  const onchange = (e) => {
    const update = {
      ...fileMetaData,
      [e.target.name]: e.target.value,
    };
    setFileMetaData({
      ...update,
    });
  };

  const countDocsOfType = (docs, type) => {
    return docs.filter((document) => document.docTypeRef === type).length;
  };

  //toggle add
  const [showAddGroup, setShowAddGroup] = useState(false);

  return (
    <Wrapper>
      <div>
        {currentContext &&
        currentContext.documents !== undefined &&
        currentContext.documents.length >= 0 ? (
          <Fragment>
            {!countDocsOfType(currentContext.documents, documentRef) ? (
              <NoDocuments />
            ) : (
              <Fragment>
                {currentContext.documents.map((doc, index) => (
                  <Fragment key={doc.url}>
                    {doc.docTypeRef === documentRef && (
                      <DocumentLink
                        href={doc.url}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <DistLineWrap>
                          {doc.type === 'application/pdf' ? (
                            <Icon iconType='filePdf' size={1} color='dark' />
                          ) : (
                            <Icon iconType='fileImage' size={1} color='dark' />
                          )}
                          <DocName> {doc.name}</DocName>
                        </DistLineWrap>
                        <DocAdmin>
                          {doc.admin.name} -{' '}
                          {!!doc &&
                          !!doc.dateUploaded &&
                          !!doc.dateUploaded.seconds
                            ? moment(
                                doc.dateUploaded.toDate().toString()
                              ).format('YYYY-MM-DD HH: MM')
                            : moment(doc.dateUploaded.toString()).format(
                                'YYYY-MM-DD HH: MM'
                              )}
                        </DocAdmin>
                        <DocNotes>{doc.notes}</DocNotes>
                      </DocumentLink>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            )}
          </Fragment>
        ) : (
          <NoDocuments />
        )}
      </div>
      <Button
        size='small'
        onClick={() => {
          setShowAddGroup(!showAddGroup);
        }}
        color={!showAddGroup ? 'linkBlue' : 'outline-light'}
      >
        <Icon
          iconType={showAddGroup ? 'upcaret' : 'downcaret'}
          size={1}
          color={!showAddGroup ? 'light' : 'dark'}
          margin='0.2rem 0.5rem 0 0'
        />
        Add New Document
      </Button>
      {!!showAddGroup && (
        <form>
          {documentGuidance && (
            <Guidance>
              <Icon
                iconType='infoCircle'
                size={1}
                margin='0 0.5rem 0 0'
                color='dark'
              />
              {documentGuidance}
            </Guidance>
          )}
          {metaInputs.map((metaInput) => (
            <Fragment key={metaInput.ref}>
              <label>{metaInput.label}</label>
              <input
                type='text'
                name={metaInput.ref}
                value={fileMetaData[metaInput.ref] || ''}
                onChange={onchange}
              />
            </Fragment>
          ))}
          <label>Document Notes:</label>
          <textarea
            name='notes'
            value={fileMetaData.notes || ''}
            onChange={onchange}
          />
          <SubmitWrapper>
            <CustomInput htmlFor={`${documentRef}fileInput`}>
              Select File & Upload
            </CustomInput>
          </SubmitWrapper>
          <FileInput
            id={`${documentRef}fileInput`}
            type='file'
            onChange={onSubmit}
          />
          <div className='output'>
            {error && <div className='error'>{error}</div>}
            {file && <div className='error'>{file.name}</div>}
            {file && (
              <ProgressBar
                file={file}
                setFile={setFile}
                saveFile={saveFile}
                clientID={clientID}
                tenant={tenantName}
                folder={`${saveFolder}/${clientID}`}
              />
            )}
          </div>
        </form>
      )}
    </Wrapper>
  );
};

export default SingleDocument;
