const stages = [
  'Not Started',
  'In-Progress: Pre-Review Actions',
  'In-Progress: Post-Review Actions',
  'Completed',
];

const outcomes = [
  'Pending',
  'Client Did Not Respond',
  'Client Did Not Want Meeting',
  'Meeting Held - New Business Transacted',
  'Meeting Held - Without New Business',
];

const reviewsConfig = {
  title: 'Annual Reviews',
  name: 'Reviews',
  addBtnLabel: 'Create review',
  disbaleDirectAddRecord: true,
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Client Name' },
  manageTabConfig: {
    tableConfig: [
      { label: 'Due Date', ref: 'dueDate' },
      { label: 'Complete Date', ref: 'completeDate' },
      { label: 'Adviser', ref: 'admin' },
      { label: 'Firm', ref: 'tenant' },
      { label: 'Stage', ref: 'stage' },
      { label: 'Outcome', ref: 'outcome' },
    ],
  },
  breadcrumbs: [
    { type: 'simpleLink', label: 'Annual Reviews', route: '/reviews' },
    { type: 'currentRecord' },
  ],
  analysisTabConfig: {
    aggregateConfig: {
      count: 'Total Reviews',
    },
  },
  newDataSteps: [
    {
      title: 'Review Details',
      guidance: "Please fill out these details and click 'Next'",
      data: [
        { ref: 'dueDate', mandatory: true },
        { ref: 'clientType', mandatory: true },
      ],
    },
    {
      title: 'Notes',
      guidance:
        'Add any notes you want to record about this case and display on the case information to the rest of the team.',
      data: [{ ref: 'caseNotes' }],
    },
  ],
  keyFutureDataReference: 'dueDate',
  stages: stages,
  outcomes: outcomes,
  recordEditTabs: ['summary', 'Checklist', 'documents'],
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Case Status',
          subtitle:
            'Track the case through the advice process and record the case outcome',
          data: ['name', 'stage', 'outcome', 'contactsID', 'accountsID'],
        },
        {
          title: 'Team',
          data: ['adminID', 'clientAdviserID'],
        },
        {
          title: 'Key Dates',
          subtitle:
            'Track the case through the advice process and record the case outcome',
          data: ['dueDate', 'meetingDate', 'completeDate'],
        },
        {
          title: 'Timeline',
          namedComponent: {
            component: 'timeline',
          },
        },
        {
          title: 'Case Notes',
          data: ['caseNotes'],
        },
      ],
    },
    {
      reference: 'Checklist',
      tiles: [
        {
          title: 'Preparation',
          subtitle: 'Mark the following as done when they are complete.',
          data: [
            'bookReview',
            'prepareValuation',
            'prepareMeetingPack',
            'prepareReviewLetter',
          ],
        },
        {
          title: 'File Checks',
          subtitle: 'Check the following are on file for this client.',
          data: [
            'checkClientAgreement',
            'checkDataPrivacyNotice',
            'checkID',
            'checkSanctionsList',
            'checkRiskProfile',
            'checkCapacityForLoss',
            'checkLastFactfind',
            'checkRecentCorrespondence',
            'checkNewBusiness',
          ],
        },
        {
          title: 'Delivery',
          subtitle:
            'Confirm the following have been delivered / presented to the client.',
          data: [
            'deliveredValuation',
            'deliveredMeetingPack',
            'deliveredReviewLetter',
          ],
        },
      ],
    },
    {
      reference: 'documents',
      tiles: [
        {
          namedComponent: {
            component: 'document',
            recordRef: 'letterOfAuthority',
          },
        },
        {
          namedComponent: {
            component: 'document',
            recordRef: 'general',
          },
        },
      ],
    },
  ],
  data: {
    name: {
      type: 'text',
      label: 'Reference',
    },
    stage: {
      type: 'select',
      label: 'Case Stage',
      valueOptions: stages,
    },
    outcome: {
      type: 'select',
      label: 'Case Outcome',
      valueOptions: outcomes,
    },
    dueDate: {
      type: 'date',
      label: 'Due Date',
    },
    completeDate: {
      type: 'date',
      label: 'Date Completed',
    },
    meetingDate: {
      type: 'date',
      label: 'Meeting Date',
    },
    clientType: {
      type: 'select',
      label: 'Client Type',
      valueOptions: [
        { value: 'personalClient', label: 'Personal / Private Client' },
        {
          value: 'corporateClient',
          label: 'Corporate / Employee Benefits Client',
        },
      ],
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
    accountsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'accounts',
      label: 'Corporate Client',
    },
    adminID: {
      isComplex: true,
      type: 'teamlookup',
      icon: 'usersingle',
      dbRef: 'users',
      label: 'Administrator',
      roleTypes: ['admin', 'manager'],
    },
    clientAdviserID: {
      isComplex: true,
      type: 'teamlookup',
      icon: 'usersingle',
      dbRef: 'users',
      label: 'Client Adviser',
      roleTypes: ['adviser'],
    },
    caseNotes: {
      type: 'textArea',
      label: 'Case Notes',
    },
    bookReview: {
      type: 'boolean',
      label: 'Schedule Review Meeting',
    },
    prepareValuation: {
      type: 'boolean',
      label: 'Prepare Valuation',
    },
    prepareMeetingPack: {
      type: 'boolean',
      label: 'Prepare Meeting Pack',
    },
    prepareReviewLetter: {
      type: 'boolean',
      label: 'Prepare Review Letter',
    },
    checkClientAgreement: {
      type: 'boolean',
      label: 'Client Agreement',
    },
    checkDataPrivacyNotice: {
      type: 'boolean',
      label: 'Privacy Notice',
    },
    checkID: {
      type: 'boolean',
      label: 'Identity Documentation',
    },
    checkSanctionsList: {
      type: 'boolean',
      label: 'Sanctions List Checked',
    },
    checkRiskProfile: {
      type: 'boolean',
      label: 'Risk Profile Questionnaire dated within last 12 Months',
    },
    checkCapacityForLoss: {
      type: 'boolean',
      label: 'Capacity For Loss dated within last 12 Months',
    },
    checkLastFactfind: {
      type: 'boolean',
      label: 'Last Fact-Find / Meeting Note on File',
    },
    checkRecentCorrespondence: {
      type: 'boolean',
      label: 'Most Recent Correspondence on File',
    },
    checkNewBusiness: {
      type: 'boolean',
      label: 'All New Business Signed Up',
    },
    deliveredValuation: {
      type: 'boolean',
      label: 'Valuation',
    },
    deliveredMeetingPack: {
      type: 'boolean',
      label: 'Meeting Pack',
    },
    deliveredReviewLetter: {
      type: 'boolean',
      label: 'Review Letter',
    },
  },
  documents: {
    letterOfAuthority: {
      type: 'document',
      label: 'Letter(s) of Authority Agreement',
      guidance: 'Optional. Upload a signed LOA.',
      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'provider', label: 'Provider' },
      ],
      saveFolder: 'clientdocuments',
    },
    general: {
      type: 'document',
      label: 'Other Documents',

      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'type', label: 'Document Type' },
      ],
      saveFolder: 'clientdocuments',
    },
  },
};

export default reviewsConfig;
