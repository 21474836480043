import React from 'react';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]},1)`};
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  box-shadow: ${(props) => props.theme.boxShadow};
  @media only screen and (max-width: 800px) {
    border-radius: 0;
    border: none;
    box-shadow: none;
    border-top: ${(props) =>
      `1px solid rgba(${props.theme.colors.neutral[3]}, 0.5)`};
  }
`;

const NoData = () => {
  return (
    <Wrapper>
      <Icon iconType='file-add' size={2} margin='0 1rem 0 0' />
      No records - start by adding data
    </Wrapper>
  );
};

export default NoData;
