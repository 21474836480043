import React from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import PublicFooter from './PublicFooter';

const StyledPublicLayout = styled.div`
  width: 100vw;
  overflow-x: hidden;
`;

const PublicContent = styled.div`
  @media only screen and (min-width: 800px) {
    padding-top: 5rem;
  }
`;

const PublicLayout = ({ children }) => {
  return (
    <StyledPublicLayout>
      <Navigation isPublic={true} />
      <PublicContent>{children}</PublicContent>
      <PublicFooter />
    </StyledPublicLayout>
  );
};

export default PublicLayout;
