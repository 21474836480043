const footerConfig = {
  menu: [
    { label: 'home', route: '/' },
    { label: 'products', route: '/products' },
    { label: 'what we do', route: '/services' },
    { label: 'portfolio', route: '/portfolio' },
    { label: 'studio', route: '/studio' },
    { label: 'about me', route: '/about' },
    { label: 'news', route: '/news' },
  ],
  noTriangle: true,
  websitePoweredBy: {
    label: 'Dubdub Digital',
    link: 'https://www.linkedin.com/in/peter-ridlington-fpfs-58568a52/',
  },
};

export default footerConfig;
