import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import firebase from '../context/firebase';

import AuthContext from '../context/auth/authContext';
import SuccessAlert from '../components/Feedback/SuccessAlert';
import LoadingSpinner from '../components/Feedback/LoadingSpinner';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) =>
    `rgba(${props.theme.colors.primary[0]}, 0.97)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  h1 {
    margin-bottom: 2rem;
  }
`;

const Success = (props) => {
  const authContext = useContext(AuthContext);
  const { user, loadUser, loading } = authContext;
  const querymode = useQuery().get('mode');
  const queryoobCode = useQuery().get('oobCode');

  const [pending, setPending] = useState(true);

  useEffect(() => {
    if (!user) {
      loadUser();
    }
    if (!!user && !!user.emailVerified && !loading) {
      setPending(false);
    }
    if (!!user && !user.emailVerified) {
      if (querymode === 'verifyEmail' && pending === true) {
        setPending('inprogress');
        authContext.handleEmailVerified(queryoobCode);
      }
    } // eslint-disable-next-line
  }, [loading, pending, authContext.user]);

  const [completeNotification, setCompleteNotification] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      firebase.auth().currentUser.reload();
      if (firebase.auth().currentUser.emailVerified) {
        setPending(false);
        setCompleteNotification(true);
      }
    }, 1000);
    return () => clearInterval(interval); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (!!isMounted && completeNotification === 'completed') {
      props.history.push('/dashboard');
    }
    return () => {
      isMounted = false;
    }; // eslint-disable-next-line
  }, [completeNotification]);

  return (
    <div>
      {pending ? (
        <Wrapper>
          <h1>Verifing Account</h1> <LoadingSpinner />
        </Wrapper>
      ) : (
        <div>
          <SuccessAlert
            subtext={[`Account Verified`, 'Opening your dashboard']}
            currentState={completeNotification}
            setCurrentState={setCompleteNotification}
          />
        </div>
      )}
    </div>
  );
};

export default Success;
