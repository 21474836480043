const expenditureDataConfig = [
  {
    title: 'Finance',
    items: [
      'mortgage / rent payments',
      'credit cards / store cards',
      'loan Agreements',
      'hire purchase agreements',
      'savings',
      'pensions',
      'insurances',
    ],
  },
  {
    title: 'Living Expenses',
    items: [
      'food',
      'clothing',
      'personal goods',
      'mobile phone',
      'entertainment',
      'holidays',
    ],
  },
  {
    title: 'Household Bills',
    items: [
      'household goods',
      'household repairs',
      'council tax',
      'gas',
      'electricity',
      'water',
      'tv / broadband',
      'ground rent / service charge',
      'other',
    ],
  },
  {
    title: 'Other',
    items: ['maintainence for dependants', 'school fees / child care', 'other'],
  },
];

export default expenditureDataConfig;
