import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AuthContext from '../../context/auth/authContext';
import ConfigContext from '../../context/config/configContext';
import MainrecordContext from '../../context/mainrecord/mainrecordContext';

import PageLoader from '../Feedback/PageLoader';
import ImportantNotes from '../Forms/ImportantNotes';
import HeaderWithActionsAndTabs from '../Headers/HeaderWithActionsAndTabs';
import InputTiles from '../Widgets/InputTiles';

const RecordPage = (props) => {
  const location = useLocation();

  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const mainrecordContext = useContext(MainrecordContext);

  const pageReference = location.pathname.replace(/^\/|\/$/g, '').slice(0, -6);
  const [tabSelected, setTabSelected] = useState('dashboard');
  const [recordEditReference, setRecordEditReference] = useState(0);

  //getConfig
  const [pageConfig, setPageConfig] = useState();
  useEffect(() => {
    setPageConfig(configContext.config[pageReference + 'Config']);
  }, [pageReference, configContext.config]);

  useEffect(() => {
    !!configContext.config &&
      setTabSelected(
        configContext.config[pageReference + 'Config'].recordEditTabs[0]
      );
  }, [pageReference, configContext.config]);

  useEffect(() => {
    !!pageConfig
      ? setRecordEditReference(pageConfig.recordEditTabs.indexOf(tabSelected))
      : setRecordEditReference(0);
  }, [tabSelected, pageConfig]);

  const [localData, setLocalData] = useState({});
  useEffect(() => {
    !!mainrecordContext.current && setLocalData(mainrecordContext.current);
  }, [mainrecordContext]);

  const handleChange = (e, additional) => {
    //todo implement lodash for autosave
    const update = { ...mainrecordContext.current };
    update[e.target.name] = {
      ...mainrecordContext.current[e.target.name],
      value: e.target.value,
    };
    !!additional &&
      (update[e.target.name] = {
        ...update[e.target.name],
        additional,
      });
    setLocalData(update);
  };

  const handleBlur = (e, additional) => {
    const update = { ...mainrecordContext.current };
    update[e.target.name] = {
      ...mainrecordContext.current[e.target.name],
      value: e.target.value,
    };
    !!additional &&
      (update[e.target.name] = {
        ...update[e.target.name],
        additional,
      });
    if (e.target.name === 'token') {
      update.directToken = e.target.value;
    }
    setLocalData(update);
    mainrecordContext.updateMainrecord(update, pageReference);
  };

  const handleImportantNotes = (value) => {
    const update = { ...mainrecordContext.current };
    update.importantNotes = value;
    setLocalData(update);
    mainrecordContext.updateMainrecord(update, pageReference);
  };

  const handleToggle = (name) => {
    const update = { ...mainrecordContext.current };
    if (!update[name]) {
      update[name] = { value: true };
    } else {
      update[name] = { ...update[name], value: !update[name].value };
    }
    setLocalData(update);
    mainrecordContext.updateMainrecord(update, pageReference);
  };

  return (
    <Fragment>
      {authContext.loading || mainrecordContext.loading || !pageConfig ? (
        <PageLoader />
      ) : (
        <Fragment>
          <HeaderWithActionsAndTabs
            title={pageConfig.name + ' Record'}
            tabSelected={tabSelected}
            setTabSelected={setTabSelected}
            tabs={pageConfig.recordEditTabs}
            breadcrumbConfig={pageConfig.breadcrumbs}
            currentRecord={localData}
          />
          <ImportantNotes
            handleImportantNotes={handleImportantNotes}
            data={localData}
            pageRef={pageReference}
          />
          <InputTiles
            config={pageConfig.recordEditConfig[recordEditReference]}
            dataRefs={pageConfig.data}
            docsRefs={pageConfig.documents}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleToggle={handleToggle}
            data={localData}
            pageRef={pageReference}
            directSave={mainrecordContext.updateMainrecord}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default RecordPage;
