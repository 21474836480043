import approachPhoto from '../assets/approach.jpg';
import approachScrollover from '../assets/approach-scrollover.jpg';

const approachConfig = {
  route: '/approach',
  title: 'Group Rapport | Approach',
  content: [
    {
      component: 'hero-with-image-background',
      img: approachPhoto,
      title: ['Our Approach'],
      sub: 'We deliver a proactive and bespoke advice service, based on a long term relationship with our clients. We will start by analysing your circumstances and discussing your objectives.',
      primaryCTA: { route: '/personal', label: 'Personal Advice' },
      secondaryCTA: { route: '/corporate', label: 'Corporate Advice' },
    },
    {
      component: 'features-with-bullets',
      title: 'How We Work',
      content: [
        'For some of our clients ‘long term relationships’ quite literally means we work across all generations of the family. With a broader view across the family and future generations it is possible to ensure the wealth of the ‘Grandparents’ is handled in the most tax efficient manner, preserving wealth for future generations, whilst making certain that our clients still enjoy the fruits of their forward planning and hard earned savings, now.',
        'In addition to working closely with your financial architect, you will also have a dedicated point of contact within our client services team who will assist you with the day to day issues.',
      ],
      bullets: [
        {
          content:
            'Unlike other advisers, our businesses and services are tailored to specific needs and circumstances.',
        },
        {
          content:
            'The scope of the ongoing investment management is bespoke for each of our clients and is defined by you.',
          title: 'INVESTMENT',
        },
        {
          content:
            'A company wide solution, with tailored advice available to each and every member of your team.',
          title: 'CORPORATE',
        },
        {
          content:
            'Our indepth knowledge of finances means we can recommend simple solutions to complex problems you might not have foreseen.',
          title: 'CREATIVE SOLUTIONS',
        },
      ],
    },
    {
      component: 'scrollover-text-with-background-img',
      img: approachScrollover,

      title: ['Children, Parents, Grandparents...we advise them all'],
      sub: 'For some of our clients ‘long term relationships’ means we work across all generations of the family.',
    },
    {
      component: 'testimonial-centered',
      margin: '2rem 1rem 0 1rem',
      backgroundColor: true,
      contents: [
        {
          quote:
            'All our clients are different, and that is why we design a bespoke financial plan for each and every one of them',
        },
      ],
    },
    {
      component: 'steps-publicvertical',
      title: 'How We Work',
      steps: [
        {
          title: 'ESTABLISHMENT',
          content:
            'The first step we will arrange an initial meeting to discuss your overall aims and objectives which will also give you the opportunity to find out more about Group Rapport and to ask any questions you may have about our services.',
        },
        {
          title: 'PLANNING',
          content:
            'We will start to explore with you in more detail, your financial circumstances and work with you to ensure your goals and timeframes are in line with your attitude to investment risk and the resources you have at your disposal.',
        },
        {
          title: 'REPORT',
          content:
            'We will prepare your financial proposal. This will set out the financial structure which we believe will give you the best opportunity to achieve your objectives, along with our recommendations on the course of action you should take.',
        },
        {
          title: 'IMPLEMENTATION',
          content:
            'Once you have had a chance to understand and discuss the financial proposal with us, we will co-ordinate all of the paperwork needed to implement our recommendations in your financial plan.',
        },
        {
          title: 'ONGOING MANAGEMENT',
          content:
            "We'll keep an eye on things...this service is essential for those clients who would like ongoing management of their investments in line with their financial plan and investment risk profile.",
        },
        {
          title: 'REGULAR REVIEWS',
          content:
            'The final key stage is the regular review meetings and reporting of the financial arrangements in our care. The scope of this service is designed bespoke to your needs.',
        },
      ],
    },
  ],
};

export default approachConfig;
