import oldmanretiring from './retirement.JPG';
import Rachel from './rachellane.jpg';

const NewStyleRetirement = {
  title: 'A new style retirement for changing times?',
  date: '20/09/2021',
  readTime: 3,
  image: oldmanretiring,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Pensions', 'Retirement'],
  intro:
    'The acronym ‘WFH’ is now fully embedded in the 21st century lexicon. Whether 18 months of working from home will reshape the future of work for office-based employees is not yet clear, but major employers are now reconsidering office spaces as employees also review fitting work within living space.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'The acronym ‘WFH’ is now fully embedded in the 21st century lexicon. Whether 18 months of working from home will reshape the future of work for office-based employees is not yet clear, but major employers are now reconsidering office spaces as employees also review fitting work within living space.',
      },
    ],
    [
      {
        content:
          'The WFH experience could also accelerate an existing trend for retirement to shift to a gradual, phased process rather than the traditionally abrupt end to working life.',
      },
    ],
    [{ type: 'heading', content: 'Phasing down' }],
    [
      {
        content:
          'There is much to be said for making a gentle transition into retirement instead of simply flicking off the work switch:',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'It avoids a sudden lifestyle change which can be traumatic, both for the new retiree and their partner',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'Employers can retain valuable knowledge in the business that would otherwise be lost.',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'The drop in net income for workers transitioning to part-time is proportionately less than the drop in the hours worked due to the way that income tax and National Insurance operate.',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'With the state pension age continuing to rise – the move to 67 begins in less than five years – gradual retirement may be an affordable option whereas full retirement may not.',
      },
    ],
    [
      {
        type: 'bullet',
        content:
          'Part-time earnings should mean less needs to be drawn from the pension pot or, in some cases, further contributions can be made. Either way, the eventual full retirement should be better financed.',
      },
    ],
    [
      {
        content:
          'A recent survey showed that 56% of people retiring in 2021 did not plan to give up work completely. Of those who had retired in 2020, 34% continued with some work, while another 21% said that they are now considering returning to work part-time. The latest statistics from the ONS show that at age 65 and above, 13.4% of men and 8.1% of women are still in work. The difference between the ONS and survey figures probably reflects the fact that the average surveyed age of those retiring in 2021 was just 60, over half a decade before their state pension begins to be paid.',
      },
    ],
    [{ type: 'heading', content: 'Changed plans' }],
    [
      {
        content:
          'The popularity of semi-retirement in the 2021 survey group is likely to be due, at least partially, to the disruption to retirement plans caused by the pandemic. Over a third had sped up their retirement plans in the preceding 12 months due to Covid-19 related issues, with lockdown and job uncertainty important factors. The pandemic has been a reminder of the need to build flexibility into your retirement plans.',
      },
    ],
    [
      {
        content:
          'Bringing forward retirement will usually mean a lower pension income, hence the need to maintain some flow of earnings. That potential squeeze was reflected in responses to several other questions in the survey. For example, 48% said that they planned to reduce their spending habits to support themselves in retirement while 21% were intending to sell or downsize their property.',
      },
    ],
  ],
};

export default NewStyleRetirement;
