import React from 'react';
import styled from 'styled-components';

import { Button } from '../Controls';

const Container = styled.div`
  background: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;
const Wrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 2rem;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
  h2 {
    font-size: 2rem;
    font-weight: bold;
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
    margin-bottom: 1rem;
  }
  p {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
const Content = styled.div``;

const FeatureWithImage = ({ config }) => {
  return (
    <Container>
      <Wrapper>
        {config.alignleft && <img src={config.img} alt={config.title} />}
        <Content>
          <h2>{config.title}</h2>
          {config.content.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          {config.cta && <Button color='linkBlue'>{config.cta.label}</Button>}
        </Content>
        {!config.alignleft && <img src={config.img} alt={config.title} />}
      </Wrapper>
    </Container>
  );
};

export default FeatureWithImage;
