import Img from '../assets/house-on-cliff.jpg';
import rachellane from '../assets/rachellane.jpg';
import homeScrollover from '../assets/homebuyer-scrollover.jpg';
import calculator from '../assets/calculator.jpg';
import houseblocks from '../assets/house-blocks.jpg';

const homebuyerConfig = {
  route: '/homebuyer',
  title: 'Group Rapport | Home Buyer',
  content: [
    {
      component: 'hero-with-image-background',
      img: Img,
      title: ['Buying A House'],
      sub: 'Buying a home is a big purchase – after all, it’ll normally take you two decades to pay off. There’s also a lot to understand and things you don’t want to forget in the buying process.',
      primaryCTA: { route: '/contact', label: 'Get Advice' },
      secondaryCTA: {
        route: '/homebuyer-calculator',
        label: 'How Much Can I Borrow?',
      },
    },
    {
      component: 'features-two-by-two',
      features: [
        {
          icon: 'line-chart',
          title: 'Planning',
          text: 'Our expert advice can help you save your deposit and buy your house sooner',
        },
        {
          icon: 'puzzle',
          title: 'Application',
          text: "We'll find you the right mortgage and deal with the provider for you to make the application go smoothly",
        },
        {
          icon: 'global',
          title: 'Buying',
          text: 'We have dealt with hundres of house purchases and will be there with you the whole way through',
        },
        {
          icon: 'shield',
          title: 'After',
          text: "We'll help get your finances in top shape once you're in your new home, so you can live the good life",
        },
      ],
    },
    {
      component: 'testimonial-centered',
      contents: [
        {
          quote:
            'We often help people with complex circumstances who have difficulty buying a home through traditional routes.',
          name: 'Rachel Lane',
          role: 'Sales & Marketing Director',
          photo: rachellane,
        },
      ],
    },
    {
      component: 'scrollover-text-with-background-img',
      img: homeScrollover,
      title: ['Getting you the best deal in record time'],
      sub: 'Small changes can add up to big impacts when your dealing with the huge amounts of time and money involved in a house purchase',
    },
    {
      component: 'tiles-with-images',
      background: true,
      content: [
        {
          photo: calculator,
          title: 'Mortgage Calculator',
          bold: 'Whether you’re a first-time buyer or not – considering the different mortgage types, extra costs you need to factor in and your financial situation is a complex undertaking.',
          paragraph:
            "Our financial planners have built a mortgage calculator to help you estimate how much you can afford to borrow, understand the costs associated with the purchase and the new property and give you a guide to how much you'll be paying back with the new mortgage.",
          cta: { route: '/homebuyer-calculator', label: 'Start Calculating' },
        },
        {
          photo: houseblocks,
          alignRight: true,
          title: 'Personal Advice',
          bold: 'You wouldn’t ask your dentist to mend your car so why not have the right specialist when it comes to your money?',
          paragraph:
            'Specialist colleagues focus on business-related advice, whereas others work with high net worth private clients. As such, we can provide expert and qualified guidance on a wide and diverse range of financial subjects and issues.',
          cta: { route: '/contact', label: 'Speak To Our Team' },
        },
      ],
    },
  ],
};

export default homebuyerConfig;
