import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '../context/auth/authContext';
import AlertContext from '../context/alert/alertContext';
import AuthFormWithQuote from '../components/Authentication/AuthFormWithQuote';

const Verify = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const { error, clearErrors, verifyEmail, user, loadUser } = authContext;

  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (!!isMounted) {
      if (!user) {
        loadUser();
      }
      if (!!user && !!user.emailVerified) {
        props.history.push('/dashboard');
      }
      if (!!user && !user.emailVerified && !emailSent) {
        setEmailSent(true);
        verifyEmail();
      }
      if (!!error && error.code !== 'email-verify-danger') {
        setAlert(error.message, 'danger');
        clearErrors();
      }
    }
    return () => {
      isMounted = false;
    }; // eslint-disable-next-line
  }, [clearErrors, emailSent, error, loadUser, props.history, setAlert, user]);

  return (
    <AuthFormWithQuote
      verify={true}
      title='Check your email'
      subtitle='Verify your account to continue'
      explainer={[
        'For security your email address needs to be verified before you can access your account.',
        'Please click the link in the email we just sent to you.',
        'You can then return here to access your account.',
      ]}
    />
  );
};

export default Verify;
