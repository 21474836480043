import annualise from '../../utils/annualise';
import integerOrZero from '../../utils/integerOrZero';
import calculateNext12Months from '../../utils/calculateNext12Months';

const processRecordData = (data, isPreproccessed, recordType, config) => {
  const stagesConfig = config.stages;
  const outcomesConfig = config.outcomes;
  const productTypesConfig = config.productTypes;
  const valueGroupsConfig = config.valueGroups;
  const placeholder = {
    count: 0,
    amount: 0,
    aua: 0,
    initial: 0,
    ongoing: 0,
  };
  const mainCount = {
    ...placeholder,
  };
  const records = [];
  const advisers = [];
  const tenants = [];
  const tenantAggregate = {};
  const adviserAggregate = {};
  const stagesAggregate = [];
  if (!!stagesConfig && stagesConfig.length) {
    stagesConfig.forEach((stage, index) => {
      stagesAggregate.push({ ...placeholder, label: stage });
    });
  }
  const outcomesAggregate = [];
  if (!!outcomesConfig && outcomesConfig.length) {
    outcomesConfig.forEach((outcome, index) => {
      outcomesAggregate.push({ ...placeholder, label: outcome });
    });
  }
  const productTypesAggregate = [];
  if (!!productTypesConfig && productTypesConfig.length) {
    productTypesConfig.forEach((product, index) => {
      productTypesAggregate.push({ ...placeholder, label: product.label });
    });
  }
  const valueGroupsAggregate = [];
  if (!!valueGroupsConfig && valueGroupsConfig.length) {
    valueGroupsConfig.forEach((valueGroup, index) => {
      valueGroupsAggregate.push({ ...placeholder, label: valueGroup.label });
    });
  }
  const Next12Months = calculateNext12Months();
  const timelineAggregate = [];
  if (!!config.keyFutureDataReference) {
    Next12Months.forEach((month) => {
      timelineAggregate.push({ ...placeholder, ...month });
    });
  }

  data.forEach((doc) => {
    //generate base data
    const recordItem = !isPreproccessed ? { ...doc.data() } : { ...doc };
    if (!isPreproccessed) {
      !recordItem.recordType && (recordItem.recordType = recordType);
      recordItem.id = doc.id;
      recordItem &&
        recordItem.created &&
        recordItem.created.seconds &&
        (recordItem.created = recordItem.created.toDate());
    }
    records.push(recordItem);

    //populate list of advisers
    if (
      !!recordItem.clientAdviserID &&
      !!recordItem.clientAdviserID.additional &&
      !advisers.includes(recordItem.clientAdviserID.additional)
    ) {
      advisers.push(recordItem.clientAdviserID.additional);
    }

    //populate list of tenants
    if (!tenants.includes(recordItem.tenant)) {
      tenants.push(recordItem.tenant);
    }

    //main count
    mainCount.count = integerOrZero(mainCount.count) + 1;

    //Amount
    if (recordItem.amount && recordItem.frequency) {
      mainCount.amount =
        integerOrZero(mainCount.amount) +
        annualise(
          integerOrZero(recordItem.amount.value),
          recordItem.frequency.value
        );
      if (
        recordItem.productType &&
        (recordItem.productType.value === 'Pension' ||
          recordItem.productType.value === 'Mortgage')
      ) {
        mainCount.aua =
          integerOrZero(mainCount.aua) +
          annualise(
            integerOrZero(recordItem.amount.value),
            recordItem.frequency.value
          );
      }
    }
    //Initial
    if (recordItem.initial) {
      mainCount.initial =
        integerOrZero(mainCount.initial) +
        integerOrZero(recordItem.initial.value);
    }
    //Ongoing
    if (recordItem.ongoing) {
      mainCount.ongoing =
        integerOrZero(mainCount.ongoing) +
        annualise(integerOrZero(recordItem.ongoing.value), 'monthly');
    }

    //tenants aggregate
    //set up empty if needed
    if (!tenantAggregate[recordItem.tenant]) {
      tenantAggregate[recordItem.tenant] = { ...placeholder };
    }
    //count
    tenantAggregate[recordItem.tenant].count =
      integerOrZero(tenantAggregate[recordItem.tenant].count) + 1;
    //amount
    if (recordItem.amount && recordItem.frequency) {
      tenantAggregate[recordItem.tenant].amount =
        tenantAggregate[recordItem.tenant].amount +
        annualise(
          integerOrZero(recordItem.amount.value),
          recordItem.frequency.value
        );
      if (
        recordItem.productType &&
        (recordItem.productType.value === 'Pension' ||
          recordItem.productType.value === 'Mortgage')
      ) {
        tenantAggregate[recordItem.tenant].aua =
          integerOrZero(tenantAggregate[recordItem.tenant].aua) +
          annualise(
            integerOrZero(recordItem.amount.value),
            recordItem.frequency.value
          );
      }
    }
    //initial
    if (recordItem.initial) {
      tenantAggregate[recordItem.tenant].initial =
        tenantAggregate[recordItem.tenant].initial +
        integerOrZero(recordItem.initial.value);
    }
    //ongoing
    if (recordItem.ongoing) {
      tenantAggregate[recordItem.tenant].ongoing =
        tenantAggregate[recordItem.tenant].ongoing +
        annualise(integerOrZero(recordItem.ongoing.value), 'monthly');
    }

    //Adviser aggregate
    //set up empty if needed
    if (
      !!recordItem.clientAdviserID &&
      !!recordItem.clientAdviserID.additional &&
      !adviserAggregate[recordItem.clientAdviserID.additional]
    ) {
      adviserAggregate[recordItem.clientAdviserID.additional] = {
        ...placeholder,
      };
    }
    if (
      !!recordItem.clientAdviserID &&
      !!recordItem.clientAdviserID.additional
    ) {
      //count
      !!recordItem.clientAdviserID &&
        !!recordItem.clientAdviserID.additional &&
        (adviserAggregate[recordItem.clientAdviserID.additional].count =
          integerOrZero(
            adviserAggregate[recordItem.clientAdviserID.additional].count
          ) + 1);
      //amount
      if (recordItem.amount && recordItem.frequency) {
        adviserAggregate[recordItem.clientAdviserID.additional].amount =
          adviserAggregate[recordItem.clientAdviserID.additional].amount +
          annualise(
            integerOrZero(recordItem.amount.value),
            recordItem.frequency.value
          );
        if (
          recordItem.productType &&
          (recordItem.productType.value === 'pension' ||
            recordItem.productType.value === 'mortgage')
        ) {
          adviserAggregate[recordItem.clientAdviserID.additional].aua =
            integerOrZero(
              adviserAggregate[recordItem.clientAdviserID.additional].aua
            ) +
            annualise(
              integerOrZero(recordItem.amount.value),
              recordItem.frequency.value
            );
        }
      }
      //initial
      if (recordItem.initial) {
        adviserAggregate[recordItem.clientAdviserID.additional].initial =
          adviserAggregate[recordItem.clientAdviserID.additional].initial +
          integerOrZero(recordItem.initial.value);
      }
      //ongoing
      if (recordItem.ongoing) {
        adviserAggregate[recordItem.clientAdviserID.additional].ongoing =
          adviserAggregate[recordItem.clientAdviserID.additional].ongoing +
          annualise(integerOrZero(recordItem.ongoing.value), 'monthly');
      }
    }

    //evaluate stages
    if (!!stagesConfig && !!stagesConfig.length) {
      stagesConfig.forEach((stage, index) => {
        if (
          !!recordItem.stage &&
          integerOrZero(recordItem.stage.value) === index
        ) {
          //count
          stagesAggregate[index].count =
            integerOrZero(stagesAggregate[index].count) + 1;
          //Amount
          if (recordItem.amount && recordItem.frequency) {
            stagesAggregate[index].amount =
              stagesAggregate[index].amount +
              annualise(
                integerOrZero(recordItem.amount.value),
                recordItem.frequency.value
              );
            if (
              recordItem.productType &&
              (recordItem.productType.value === 'pension' ||
                recordItem.productType.value === 'mortgage')
            ) {
              stagesAggregate[index].aua =
                integerOrZero(stagesAggregate[index].aua) +
                annualise(
                  integerOrZero(recordItem.amount.value),
                  recordItem.frequency.value
                );
            }
          }
          //Initial
          if (recordItem.initial) {
            stagesAggregate[index].initial =
              stagesAggregate[index].initial +
              integerOrZero(recordItem.initial.value);
          }
          //Ongoing
          if (recordItem.ongoing) {
            stagesAggregate[index].ongoing =
              stagesAggregate[index].ongoing +
              annualise(integerOrZero(recordItem.ongoing.value), 'monthly');
          }
        }
      });
    }

    //evaluate outcomes
    if (!!outcomesConfig && !!outcomesConfig.length) {
      outcomesConfig.forEach((outcome, index) => {
        if (
          !!recordItem.outcome &&
          integerOrZero(recordItem.outcome.value) === index
        ) {
          //count
          outcomesAggregate[index].count = outcomesAggregate[index].count + 1;
          //Amount
          if (recordItem.amount && recordItem.frequency) {
            outcomesAggregate[index].amount =
              outcomesAggregate[index].amount +
              annualise(
                integerOrZero(recordItem.amount.value),
                recordItem.frequency.value
              );
            if (
              recordItem.productType &&
              (recordItem.productType.value === 'Pension' ||
                recordItem.productType.value === 'Mortgage')
            ) {
              outcomesAggregate[index].aua =
                integerOrZero(outcomesAggregate[index].aua) +
                annualise(
                  integerOrZero(recordItem.amount.value),
                  recordItem.frequency.value
                );
            }
          }
          //Initial
          if (recordItem.initial) {
            outcomesAggregate[index].initial =
              outcomesAggregate[index].initial +
              integerOrZero(recordItem.initial.value);
          }
          //Ongoing
          if (recordItem.ongoing) {
            outcomesAggregate[index].ongoing =
              outcomesAggregate[index].ongoing +
              annualise(integerOrZero(recordItem.ongoing.value), 'monthly');
          }
        }
      });
    }

    //evaluate product types
    if (!!productTypesConfig && !!productTypesConfig.length) {
      productTypesConfig.forEach((productType, index) => {
        if (
          recordItem.productType &&
          recordItem.productType.value === productType.value
        ) {
          //count
          productTypesAggregate[index].count =
            productTypesAggregate[index].count + 1;
          //Amount
          if (recordItem.amount && recordItem.frequency) {
            productTypesAggregate[index].amount =
              productTypesAggregate[index].amount +
              annualise(
                integerOrZero(recordItem.amount.value),
                recordItem.frequency.value
              );
            if (
              recordItem.productType &&
              (recordItem.productType.value === 'Pension' ||
                recordItem.productType.value === 'Mortgage')
            ) {
              productTypesAggregate[index].aua =
                integerOrZero(productTypesAggregate[index].aua) +
                annualise(
                  integerOrZero(recordItem.amount.value),
                  recordItem.frequency.value
                );
            }
          }
          //Initial
          if (recordItem.initial) {
            productTypesAggregate[index].initial =
              productTypesAggregate[index].initial +
              integerOrZero(recordItem.initial.value);
          }
          //Ongoing
          if (recordItem.ongoing) {
            productTypesAggregate[index].ongoing =
              productTypesAggregate[index].ongoing +
              annualise(integerOrZero(recordItem.ongoing.value), 'monthly');
          }
        }
      });
    }

    //evaluate Value Groups
    if (!!valueGroupsConfig && !!valueGroupsConfig.length) {
      valueGroupsConfig.forEach((valueGroup, index) => {
        if (recordItem.initial) {
          let LTEtestpass = false;
          let GTtestpass = false;
          if (
            (valueGroup.LTE &&
              integerOrZero(recordItem.initial.value) <= valueGroup.LTE) ||
            !valueGroup.LTE
          ) {
            LTEtestpass = true;
          }
          if (
            (valueGroup.GT &&
              integerOrZero(recordItem.initial.value) > valueGroup.GT) ||
            !valueGroup.GT
          ) {
            GTtestpass = true;
          }
          if (LTEtestpass && GTtestpass) {
            //count
            valueGroupsAggregate[index].count =
              integerOrZero(valueGroupsAggregate[index].count) + 1;
            //amount
            if (recordItem.amount && recordItem.frequency) {
              valueGroupsAggregate[index].amount =
                valueGroupsAggregate[index].amount +
                annualise(
                  integerOrZero(recordItem.amount.value),
                  recordItem.frequency.value
                );
              if (
                recordItem.productType &&
                (recordItem.productType.value === 'Pension' ||
                  recordItem.productType.value === 'Mortgage')
              ) {
                valueGroupsAggregate[index].aua =
                  integerOrZero(valueGroupsAggregate[index].aua) +
                  annualise(
                    integerOrZero(recordItem.amount.value),
                    recordItem.frequency.value
                  );
              }
            }
            //initial
            if (recordItem.initial) {
              valueGroupsAggregate[index].initial =
                valueGroupsAggregate[index].initial +
                integerOrZero(recordItem.initial.value);
            }
            //ongoing
            if (recordItem.ongoing) {
              valueGroupsAggregate[index].ongoing =
                valueGroupsAggregate[index].ongoing +
                annualise(integerOrZero(recordItem.ongoing.value), 'monthly');
            }
          }
        }
      });
    }

    //evaluate timeline
    if (!!config.keyFutureDataReference) {
      timelineAggregate.forEach((month, index) => {
        if (
          recordItem.expectedMonth &&
          recordItem.expectedMonth.value === month.value
        ) {
          //count
          timelineAggregate[index].count =
            integerOrZero(timelineAggregate[index].count) + 1;
          //Amount
          if (recordItem.amount && recordItem.frequency) {
            timelineAggregate[index].amount =
              timelineAggregate[index].amount +
              annualise(
                integerOrZero(recordItem.amount.value),
                recordItem.frequency.value
              );
            if (
              recordItem.productType &&
              (recordItem.productType.value === 'Pension' ||
                recordItem.productType.value === 'Mortgage')
            ) {
              timelineAggregate[index].aua =
                integerOrZero(timelineAggregate[index].aua) +
                annualise(
                  integerOrZero(recordItem.amount.value),
                  recordItem.frequency.value
                );
            }
          }
          //Initial
          if (recordItem.initial) {
            timelineAggregate[index].initial =
              timelineAggregate[index].initial +
              integerOrZero(recordItem.initial.value);
          }
          //Ongoing
          if (recordItem.ongoing) {
            timelineAggregate[index].ongoing =
              timelineAggregate[index].ongoing +
              annualise(integerOrZero(recordItem.ongoing.value), 'monthly');
          }
        }
      });
    }

    //end of products loop
  });

  return {
    tenants: tenants,
    advisers: advisers,
    records: records.reverse(),
    mainCount: mainCount,
    outcomesAggregate: outcomesAggregate,
    stagesAggregate: stagesAggregate,
    productTypesAggregate: productTypesAggregate,
    tenantAggregate: tenantAggregate,
    adviserAggregate: adviserAggregate,
    valueGroupsAggregate: valueGroupsAggregate,
    timelineAggregate: timelineAggregate,
  };
};

export default processRecordData;
