import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const burgerActivateHoverState = ({ activated }) => {
  if (activated) {
    return css`
      &:hover {
        > div {
          transform: rotate(225deg);
        }
      }
    `;
  }
};

const Burger = styled.div`
  z-index: 1;
  width: 60px;
  height: 50px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${burgerActivateHoverState}
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const burgerActivate = ({ activated }) => {
  if (activated) {
    return css`
      transform: rotate(135deg);
      &:before {
        top: 0;
        transform: rotate(90deg);
      }
      &:after {
        top: 0;
        transform: rotate(90deg);
      }
    `;
  }
};

const BurgerLines = styled.div`
  position: relative;
  flex: none;
  width: 100%;
  height: 2px;
  background: ${(props) =>
    props.isDark
      ? (props) => `rgba(${props.theme.colors.primary[0]}, 1)`
      : (props) => `rgba(${props.theme.colors.primary[1]}, 1)`};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: -10px;
    width: 100%;
    height: 2px;
    background: inherit;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 10px;
    width: 100%;
    height: 2px;
    background: inherit;
  }
  ${burgerActivate}
`;

const Hamburger = ({ isActive, isDark }) => {
  return (
    <Burger activated={isActive}>
      <BurgerLines activated={isActive} isDark={isDark}></BurgerLines>
    </Burger>
  );
};

Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isDark: PropTypes.bool,
};
export default Hamburger;
