const publicNavConfig = {
  main: [
    {
      ref: 'about',
      label: 'about',
      submenu: [
        {
          route: '/about',
          label: 'About Me',
          description:
            'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
          icon: 'brush',
        },
        {
          route: '/studio',
          label: 'Studio',
          description:
            'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
          icon: 'mapMarker',
        },
        {
          route: '/services',
          label: 'Services',
          description:
            'Makeup and Hair Artist | Bridal Events Parties Film TV.',
          icon: 'cardchecklist',
        },
        {
          route: '/news',
          label: 'News',
          description: 'Latest news, blogs and announcements',
          icon: 'rssFeed',
        },
      ],
    },
    {
      route: '/portfolio',
      label: 'Portfolio',
    },
    {
      route: '/products',
      label: 'Products',
    },
  ],
  footer: [{ route: '/contact', label: 'Contact Us' }],
  mobileCTA: {
    route: '/contact',
    icon: 'mail',
  },
};
export default publicNavConfig;
