import React from 'react';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  padding: 1rem;
  padding-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  font-size: 1.2rem;
`;
const NoDocuments = () => {
  return (
    <Wrapper>
      <Icon
        iconType='folderOutline'
        size={3}
        margin='0 1rem 0 0'
        color='primary'
      />
      0 Documents
    </Wrapper>
  );
};

export default NoDocuments;
