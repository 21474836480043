import { SET_CONFIG, REMOVE_CONFIG } from '../types';
// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return { ...state, config: action.payload };
    case REMOVE_CONFIG:
      return { ...state, config: null };
    default:
      return state;
  }
};
