const stages = ['Active', 'Inactive'];
const outcomes = ['Current', 'Old'];
const liabilityConfig = {
  title: 'Liability Records',
  name: 'Liability',
  addBtnLabel: 'Create Liability Record',
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Type' },
  stages: stages,
  outcomes: outcomes,
  breadcrumbs: [
    { type: 'simpleLink', label: 'Private Clients', route: '/contacts' },
    {
      type: 'relatedRecord',
      dbRef: 'contacts',
      recordRefFromCurrent: 'contactsID',
    },
    { type: 'simpleLabel', label: 'Liability Record' },
    { type: 'currentRecord' },
  ],
  manageTabConfig: {
    filterByLabel: 'Type',
    tableConfig: [
      { label: 'Type', ref: 'name' },
      { label: 'Company', ref: 'provider' },
      { label: 'Payment', ref: 'payment' },
      { label: 'Rate', ref: 'rate' },
      { label: 'End Date', ref: 'endDate' },
    ],
  },
  newDataSteps: [
    {
      title: 'Details',
      data: [{ ref: 'name', mandatory: true }, { ref: 'provider' }],
    },
    {
      title: 'Payment Information',
      data: [
        { ref: 'payment', mandatory: true },
        { ref: 'paymentFrequency', mandatory: true },
        { ref: 'amount' },
        { ref: 'rate' },
      ],
    },
    {
      title: 'Notes',
      guidance: 'Optional.',
      data: [{ ref: 'notes' }],
    },
  ],
  recordEditTabs: ['summary'],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Liability Records' },
  },
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Client Record',
          data: ['contactsID'],
        },
        {
          title: 'Details',
          data: ['name', 'provider'],
        },
        {
          title: 'Payment Information',
          data: [
            'payment',
            'paymentFrequency',
            'amount',
            'rate',
            'endDate',
            'toBeRepaidWithMortgage',
          ],
        },
        {
          title: 'Notes',
          data: ['notes'],
        },
      ],
    },
  ],
  data: {
    name: {
      type: 'select',
      label: 'Type',
      valueOptions: [
        { value: 'mortgage', label: 'Mortgage (Main Residential)' },
        { value: 'creditCard', label: 'Credit Card' },
        { value: 'storeCard', label: 'Store Card' },
        { value: 'personalLoan', label: 'Personal Loan' },
        {
          value: 'mortgageRepaymentVehicle',
          label: 'Mortgage Repayment Vehicle',
        },
        { value: 'studentLoan', label: 'Student Loan' },
        { value: 'carLoan', label: 'Car Loan' },
        { value: 'hirePurchase', label: 'Hire Purchase' },
        { value: 'maintenanceAlimony', label: 'Maintenance / Alimony' },
        { value: 'otherSecuredLoan', label: 'Other Secured Loan' },
        { value: 'otherMortgage', label: 'Other Mortgage' },
        { value: 'bridgingLoan', label: 'Bridging Loan' },
        { value: 'equityRelease', label: 'Equity Release' },
        { value: 'other', label: 'Other' },
      ],
    },
    provider: {
      type: 'text',
      label: 'Company',
    },
    payment: {
      type: 'number',
      label: 'Payment',
    },
    paymentFrequency: {
      type: 'select',
      label: 'Payment Frequency',
      valueOptions: [
        { value: 'monthly', label: 'Monthly' },
        { value: 'quarterly', label: 'Quarterly' },
        { value: 'biannual', label: 'Bi-Annual' },
        { value: 'annually', label: 'Annually' },
        { value: 'lumpsum', label: 'Lumpsum / One-off' },
      ],
    },
    amount: {
      type: 'number',
      label: 'Amount Outstanding',
    },
    rate: {
      type: 'number',
      label: 'Interest Rate',
    },
    startDate: {
      type: 'date',
      label: 'Start Date',
    },
    endDate: {
      type: 'date',
      label: 'End Date',
    },
    toBeRepaidWithMortgage: {
      type: 'boolean',
      label: 'To Be Repaid with New Mortgage',
    },
    notes: {
      type: 'textArea',
      label: 'Notes',
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
  },
};

export default liabilityConfig;
