import micky from '../assets/micky.jpeg';
import birchwood from '../assets/birchwood.png';
import nlp from '../assets/nlp.png';
import bfp from '../assets/bfp.png';
import gr from '../assets/gr.png';

const authConfig = {
  quoteImg: micky,
  quoteName: 'Micky Johal',
  quoteJobTitle: 'Founder @Truinvest',
  quoteText:
    'We continue to further invest in cutting-edge technology to make the way we operate simpler for our clients, advisers and staff.',
  logos: [gr, birchwood, nlp, bfp],
  loginTitle: 'Welcome back to Compass',
  loginSubTitle: 'Login in to your account',
};
export default authConfig;
