import OwlPhoto from './owl-looks-ahead.jpg';
import Rachel from './rachellane.jpg';

const holdyournerve = {
  title: 'Holding your nerve with your investments',
  date: '27/07/2020',
  readTime: 3,
  image: OwlPhoto,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Investing', 'Covid-19'],
  intro:
    'There is no disputing the impact of the Covid-19 pandemic. Despite previous coronavirus outbreaks in Asia, such as SARS in 2002, on this occasion it is different. Time now seems to be divided into ‘before and after’: the old normal and the new socially-distanced reality we are coming to terms with.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'There is no disputing the impact of the Covid-19 pandemic. Despite previous coronavirus outbreaks in Asia, such as SARS in 2002, on this occasion it is different. Time now seems to be divided into ‘before and after’: the old normal and the new socially-distanced reality we are coming to terms with.',
      },
    ],
    [
      {
        content:
          'These two eras are clearly visible in the global stock markets, most of which fell sharply in March as the virus spread globally, closely followed by lockdowns and economic contraction.',
      },
    ],
    [
      {
        content:
          'A steady stream of commentary has discussed whether life as we knew it has changed forever, from air travel to working patterns. That perspective of major changes has also extended to suggestions that there has been a fundamental change in the investment world. The scene has certainly altered – at least for now. There has been increased volatility in the values of investments, while businesses have reacted to the new environment in a variety of ways, the most obvious being to reduce dividend payments, which you will probably notice in coming months.',
      },
    ],
    [{ type: 'heading', content: 'TAKING A LONG VIEW' }],
    [
      {
        content:
          'However, it is worth trying to take a longer-term view. Think back – if you can – to previous crises, such as the financial crisis of 2007/08, the 9/11 terrorist attacks, the turn-of-millennium dotcom bubble and even the great storm and accompanying stock market crash of 1987. At the time, each of those events felt momentous and a break in history.',
      },
    ],
    [
      {
        content:
          'Now, with the benefit of hindsight, these may even appear as little more than dips on a long-term investment chart. Investors who stayed the course did suffer in the short term, but they benefited in the long term. Those who panicked and sold up may have chosen the worst point to do so, and then faced the difficult decision of when to reinvest.',
      },
    ],
    [
      {
        content:
          'All we can say with certainty is that 2020 will be remembered as a difficult year for investors, but perhaps just one of many over the life of a portfolio.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investments, and the income from them, can go down as well as up and you may not get back the full amount you invested.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Past performance is not a reliable indicator of future performance.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Investing in shares should be regarded as a long-term investment and should fit in with your overall attitude to risk and financial circumstances.',
      },
    ],
  ],
};

export default holdyournerve;
