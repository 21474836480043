import React from 'react';
import styled from 'styled-components';

import { LinkButton } from '../Controls';

const Wrapper = styled.div`
  background: ${(props) =>
    props.background && `rgba(${props.theme.colors.bgColor[0]}, 1)`};
`;

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: ${(props) =>
    props.extraPadding ? '4rem 2rem' : '0 2rem 4rem 2rem'};
`;

const Title = styled.div`
  text-align: center;
  font-size: 3rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
  margin-bottom: 2rem;
`;

const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  img {
    height: 250px;
    border-radius: 10px;
    border-top-left-radius: 50px;
    border: ${(props) => `3px solid rgba(${props.theme.colors.neutral[0]}, 1)`};
    margin-bottom: 2rem;
  }
`;

const FeaturesImagesTitleOnly = ({ config }) => {
  return (
    <Wrapper background={config.background}>
      <Container extraPadding={config.extraPadding}>
        <Title>{config.title}</Title>
        <ContentWrap>
          {config.content.map((item, index) => (
            <img src={item} key={index} alt='portfolioImg' />
          ))}
        </ContentWrap>
        {config.ctaRoute && (
          <LinkButton to={config.ctaRoute} color='dark'>
            {config.ctaLabel}
          </LinkButton>
        )}
      </Container>
    </Wrapper>
  );
};

export default FeaturesImagesTitleOnly;
