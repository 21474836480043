import aboutPhoto from '../assets/about.jpg';
import rachellane from '../assets/rachellane.jpg';
import jonigrelli from '../assets/jonigrelli.jpg';
import hayleysyrett from '../assets/hayleysyrett.jpg';
import camera from '../assets/camera.jpg';

import aboutScrollover from '../assets/about-scrollover.jpg';
import aboutSection from '../assets/about-section.jpg';

const aboutConfig = {
  route: '/about',
  title: 'Group Rapport | Team',
  content: [
    {
      component: 'hero-with-image-background',
      img: aboutPhoto,
      title: ['Meet Our Experts'],
      sub: 'The combined skills and experience of our senior management forms the basis of a formidable team for our clients’ benefit. Learn about our team below, or take a look at how we can help individuals with their personal finances or businesses.',
      primaryCTA: { route: '/personal', label: 'Personal Advice' },
      secondaryCTA: { route: '/corporate', label: 'Corporate Advice' },
    },
    {
      component: 'team-with-circle-photo',
      imageFallback: camera,
      teamMembers: [
        {
          name: 'Rachel Lane',
          position: 'Chartered Financial Planner',
          imageUrl: rachellane,
          twitter: 'https://twitter.com/rachellane_ifa',
          email: 'info@rapportteam.com',
          facebook: 'https://www.facebook.com/grouprapport/',
          phone: '0845 873 1399',
          bio: 'Rachel qualified in 2008 having graduated from Sheffield University and joined Group Rapport in 2014. Treating each client as an individual and taking time to understand a client’s goals is her main strength, perfectly matching the Group Rapport philosophy.  Rachel swiftly made a great impression and was recognised by the Guildford chapter of the Chartered Institute of Insurance being awarded their Young Achiever of the Year for 2015. She was also awarded the PFS Mortgage Adviser of the year Runner up in 2016 and Winner 2017. Since then she has been busy building her client base and spending time with her two dogs (including our retired office dog Quinn).',
        },
        {
          name: 'Hayley Syrett',
          position: 'Senior Corporate Consultant',
          email: 'info@rapportteam.com',
          imageUrl: hayleysyrett,
          linkedin:
            'https://www.linkedin.com/in/hayley-syrett-dippfs-46538171/',
          phone: '0845 873 1399',
          bio: 'Hayley joined the Group Rapport Team in 2015 as a Senior Corporate Adviser swiftly moving to Head of Employee benefits helping to secure our place as a premier provider of employee benefit packages. Experienced in personal and corporate financial planning Hayley’s expertise is in providing personalised employee benefits packages for companies across the SME sector. Providing advice for all companies Hayley has a specialist knowledge of working with UK starts up in particular with an international parent company. Away from the office and in her spare time she is an avid football fan.',
        },
        {
          name: 'Jo Nigrelli',
          imageUrl: jonigrelli,
          linkedin: 'https://www.linkedin.com/in/jo-nigrelli-36417834/',
          position: 'Personal Client Administration Manager',
          phone: '0845 873 1399',
          bio: 'Jo has been in the financial services industry since 1986 and is an experienced and highly valued member of the team. Her depth of technical knowledge in particular, and financial planning in general, ensures that Rapport clients enjoy the highest level of service and support.',
        },
        {
          name: 'Shainita Hamin',
          position: 'Corporate Client Administration Manager',
          email: 'info@rapportteam.com',
          phone: '0845 873 1399',
          linkedin: 'https://www.linkedin.com/in/shainita-hamin-b2a255b0/',
          bio: 'Shainita’s career has spanned 25 years and included household names such as Friends Life and AVIVA. Shainita is an experienced and highly valued member of the team with a superb depth of technical knowledge and administrative knowledge. Managing 3 administrators Shainita helps ensure that all our schemes receive the highest level of service and support.  A keen traveller Shainita has mastered the art of balancing work and her love of seeing the world with her family.',
        },
      ],
    },
    {
      component: 'scrollover-text-with-background-img',
      img: aboutScrollover,
      quote: true,
      title: [''],
      sub: 'Like you, our people are our greatest asset. They are expert in their respective fields and have one common aim… to meet your financial aims and objectives.',
    },
    {
      component: 'features-with-image',
      img: aboutSection,
      title: 'Local Community',
      content: [
        'Sponsorships and charities supported by Group Rapport.',
        'We support: Windlesham Cricket Club, Windlesham School, Staines Hockey Club, St Mary Magdalene Church, Sir Ian Botham Charitable Foundation, Laureus Foundation, Hall Grove School, Various Justgiving Donations, Spinfields School, Marlow, Weybridge Cricket Club, Andrew Grene Foundation.',
      ],
    },
  ],
};

export default aboutConfig;
