import React, { useState } from 'react';
import styled from 'styled-components';

import Icon from '../UI/Icon';

const Container = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  @media only screen and (max-width: 800px) {
    display: block;
    padding: 1rem;
  }
`;
const TeamMember = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  img {
    width: 100px;
    margin-right: 1rem;
    border-radius: 50%;
  }
  @media only screen and (max-width: 800px) {
    margin-bottom: 2rem;
  }
`;
const Profile = styled.div`
  h2 {
    font-weight: bold;
    font-size: 1.3rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.1rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
  }
  p {
    font-size: 0.95rem;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    span {
      color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 0.8)`};
      margin-left: 0.25rem;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
`;
const Socials = styled.div`
  margin: 0.5rem 0 1rem 0;
  display: flex;
`;

const TeamWithCirclePhoto = ({ config }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Container>
      {config.teamMembers.map((item, index) => (
        <TeamMember key={index}>
          <img
            src={!!item.imageUrl ? item.imageUrl : config.imageFallback}
            alt={item.name}
          />
          <Profile>
            <h2>{item.name}</h2>
            <h3>{item.position}</h3>
            <Socials>
              {item.linkedin && (
                <a href={item.linkedin}>
                  <Icon
                    iconType='linkedin'
                    size={1}
                    color='linkBlue'
                    margin='0 0.5rem 0 0'
                  />
                </a>
              )}
              {item.twitter && (
                <a href={item.twitter}>
                  <Icon
                    iconType='twitter'
                    size={1}
                    color='linkBlue'
                    margin='0 0.5rem 0 0'
                  />
                </a>
              )}
              {item.facebook && (
                <a href={item.facebook}>
                  <Icon
                    iconType='facebook'
                    size={1}
                    color='linkBlue'
                    margin='0 0.5rem 0 0'
                  />
                </a>
              )}
              {item.email && (
                <a href={item.email}>
                  <Icon
                    iconType='mail'
                    size={1}
                    color='linkBlue'
                    margin='0 0.5rem 0 0'
                  />
                </a>
              )}
              {item.phone && (
                <a href={item.phone}>
                  <Icon
                    iconType='phone'
                    size={1}
                    color='linkBlue'
                    margin='0 0.5rem 0 0'
                  />
                </a>
              )}
            </Socials>
            <p>
              {showMore === index
                ? item.bio
                : item.bio.substring(0, 150) + '...'}
              <span
                onClick={() => {
                  showMore === index ? setShowMore(false) : setShowMore(index);
                }}
              >
                {showMore === index ? 'show less' : 'read more'}
              </span>
            </p>
          </Profile>
        </TeamMember>
      ))}
    </Container>
  );
};

export default TeamWithCirclePhoto;
