const stages = ['Active', 'Inactive'];
const outcomes = ['Current', 'Old'];
const schemesConfig = {
  title: 'Scheme Records',
  name: 'Scheme',
  addBtnLabel: 'Create Scheme Record',
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Scheme Name' },
  breadcrumbs: [
    { type: 'simpleLink', label: 'Corporate Clients', route: '/accounts' },
    {
      type: 'relatedRecord',
      dbRef: 'accounts',
      recordRefFromCurrent: 'accountssID',
    },
    { type: 'simpleLabel', label: 'Scheme Record' },
    { type: 'currentRecord' },
  ],
  stages: stages,
  outcomes: outcomes,
  manageTabConfig: {
    filterByLabel: 'Scheme Name',
    tableConfig: [
      { label: 'Scheme Name', ref: 'name' },
      { label: 'Policy Number', ref: 'policyNumber' },
      { label: 'Policy Type', ref: 'type' },
      { label: 'Provider', ref: 'provider' },
      { label: 'Start Date', ref: 'startDate' },
      { label: 'Renewal Date', ref: 'renewal' },
    ],
  },
  newDataSteps: [
    {
      title: 'Scheme Details',
      data: [
        { ref: 'name', mandatory: true },
        { ref: 'type', mandatory: true },
        { ref: 'provider' },
      ],
    },
    {
      title: 'Dates',
      data: [{ ref: 'startDate' }, { ref: 'renewal' }],
    },
    {
      title: 'Notes',
      guidance: 'Optional.',
      data: [{ ref: 'notes' }],
    },
  ],
  recordEditTabs: ['summary'],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Scheme Records' },
  },
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Corporate Record',
          data: ['accountsID'],
        },
        {
          title: 'Scheme Details',
          data: [
            'name',
            'policyNumber',
            'type',
            'provider',
            'startDate',
            'renewal',
          ],
        },

        {
          title: 'Notes',
          data: ['notes'],
        },
      ],
    },
  ],
  data: {
    name: {
      type: 'text',
      label: 'Scheme Name',
    },
    policyNumber: {
      type: 'text',
      label: 'Policy Number',
    },
    provider: {
      type: 'text',
      label: 'Provider',
    },
    startDate: {
      type: 'date',
      label: 'Start Date',
    },
    renewal: {
      type: 'date',
      label: 'Renewal',
    },
    type: {
      type: 'select',
      label: 'Policy Type',
      valueOptions: [
        { value: 'pension', label: 'Pension' },
        { value: 'groupLife', label: 'Group Life' },
        { value: 'groupDeathInService', label: 'Group Death In Service' },
        { value: 'groupIncomeProtection', label: 'Group Income Protection' },
        { value: 'groupCriticalIllness', label: 'Group Critical Illness' },
        { value: 'privateMedical', label: 'Private Medical' },
        { value: 'cashPlan', label: 'Cash Plan' },
        { value: 'dental', label: 'Dental' },
        { value: 'travel', label: 'Travel' },
      ],
    },
    notes: {
      type: 'textArea',
      label: 'Notes',
    },
    accountsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'accounts',
      label: 'Corporate Account',
    },
  },
};

export default schemesConfig;
