import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from './GlobalStyles';

import ConfigContext from '../../context/config/configContext';

const StylesWrapper = ({ children }) => {
  const configContext = useContext(ConfigContext);
  return (
    <ThemeProvider theme={configContext.config.styling}>
      <GlobalStyles />
      <main>{children}</main>
    </ThemeProvider>
  );
};

export default StylesWrapper;
