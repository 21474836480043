import HomeImg from '../assets/home.jpg';
import bgVideo from '../assets/bgvideo.mp4';
import largeLogo from '../assets/largeLogo.png';
import faceBrushes from '../assets/face-brushes.jpg';
import eyeBrushes from '../assets/eye-brushes.jpg';
import studioThin from '../assets/studiothin.jpg';

import aboutScrollover from '../assets/about-scrollover.jpg';
import aboutsection from '../assets/about-section.jpg';

import profilePick from '../assets/profile-pic.png';
import port1 from '../assets/port1.jpg';
import port2 from '../assets/port2.jpg';
import port3 from '../assets/port3.jpg';

const homeConfig = {
  route: '/',
  title: 'Laura Lane | Home',
  content: [
    {
      component: 'hero-with-video-background',
      img: HomeImg,
      video: bgVideo,
      logo: largeLogo,
      title: ['Stylist', 'Salon', 'Products'],
    },
    {
      component: 'features-with-title-text',
      title: 'what we do',
      content: [
        {
          title: 'Hair & Makeup',
          content: 'Information about the main focus of your services',
          img: aboutsection,
        },
        {
          title: 'Salon & Studio',
          content: 'some details about what goes on in Church Square',
          img: studioThin,
        },
        {
          title: 'Bridal Events Parties Film TV ',
          content: 'Elaborating on the events you work on',
          img: aboutScrollover,
        },
        {
          img: eyeBrushes,
          title: 'Brushes',
          content:
            'Now you can use what I use, with our newly launched makeup brushes',
        },
      ],
    },
    {
      component: 'scrollover-left-text-with-background-img',
      img: studioThin,
      title: 'Studio based in Market Harborough 🇬🇧',
      content: [
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough',
      ],
      ctaLabel: 'To The Studio',
      ctaRoute: '/studio',
    },
    {
      component: 'tiles-simple-side-by-side',
      background: false,
      title: '✨ New Arrivals ✨',
      subTitle: [
        'I’m so proud to announce the development of my own makeup brushes',
        'I will only be using my own brushes in the studio from now on and love love love the quality and versatility. These are pro brushes so for any makeup artists out there that want to update their kit or makeup lovers these are perfect.',
      ],
      hashTags: ['vegan', 'cruelty free', 'professional'],
      content: [
        {
          photo: faceBrushes,
          title: 'Face Brushes',
          route: '/products',
          paragraph:
            ' face brush set which consists of 8 brushes.ideally this section takes a few lines to provide a little information and get people to click through.',
        },
        {
          photo: eyeBrushes,
          title: 'Eye Brushes',
          route: '/products',
          paragraph:
            '11 piece eye brush set some more details about the product, ideally this section takes a few lines to provide a little information and get people to click through.',
        },
      ],
    },
    {
      component: 'banner-socials-with-mailinglist',
      background: true,
      title: "Let's get social",
      instagramLink: 'https://www.instagram.com/lauralanemakeup/',
      instagramLabel: '@lauralanemakeup',
      facebookLabel: 'Laura Lane Makeup Artist',
      facebookLink: 'https://www.facebook.com/lauralanemakeup/',
    },
    {
      component: 'features-images-title-only',
      title: 'Portfolio',
      background: true,
      ctaRoute: '/portfolio',
      ctaLabel: 'See More',
      content: [port1, port2, port3, profilePick],
    },
  ],
};

export default homeConfig;
