import React from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';

const StyledAppLayout = styled.div`
  width: 100vw;
  overflow-x: hidden;
`;

const AppContent = styled.div`
  margin: auto;
  margin-top: 4rem;
  height: 100%;
  min-height: 100vh;
  @media only screen and (min-width: 800px) {
    margin-left: 250px;
    margin-top: 0rem;
    background-color: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
  }
`;

const AppLayout = ({ children }) => {
  return (
    <StyledAppLayout>
      <Navigation />
      <AppContent>{children}</AppContent>
    </StyledAppLayout>
  );
};

export default AppLayout;
