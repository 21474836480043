import React from 'react';
import styled from 'styled-components';
import { Button } from '../Controls';
import Vertical from '../Steps/Vertical';

const Wrapper = styled.div`
  right: ${(props) => (props.active === 'true' ? 0 : '-550px')};
  position: fixed;
  min-height: 100vh;

  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  width: 550px;

  bottom: 0%;
  top: 0%;
  z-index: 99999999999999999;
  zoom: 1;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-top: ${(props) =>
    `0.25rem solid rgba(${props.theme.colors.success[0]}, 1)`};
  box-shadow: ${(props) => props.theme.boxShadow};
  @media only screen and (max-width: 600px) {
    width: 100vw;
    right: ${(props) => (props.active === 'true' ? 0 : '-100vw')};
  }
`;

const InnerWrapper = styled.div`
  padding: 2rem;
  overflow-y: auto;
  height: 100%;
  @media only screen and (max-width: 600px) {
    padding: 1rem 1rem 2rem 1rem;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 1.5rem;
  }
`;

const AddNewPopout = ({
  active,
  title,
  setToggleAdd,
  newDataSteps,
  dataConfig,
  handleSubmit,
}) => {
  return (
    <Wrapper active={active.toString()}>
      <InnerWrapper>
        <Header>
          <h1>{title}</h1>
          <Button
            size='small'
            color='light'
            onClick={() => {
              setToggleAdd(false);
            }}
          >
            Cancel
          </Button>
        </Header>
        <Vertical
          newDataSteps={newDataSteps}
          dataConfig={dataConfig}
          handleSubmit={handleSubmit}
        />
      </InnerWrapper>
    </Wrapper>
  );
};

export default AddNewPopout;
