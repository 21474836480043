import brushesThin from '../assets/brushes.jpg';
import eyeBrushes from '../assets/eye-brushes.jpg';
import faceBrushes from '../assets/face-brushes.jpg';
import giftCert from '../assets/giftcert.jpg';
import multibrush from '../assets/multibrush.png';

const aboutConfig = {
  route: '/products',
  title: 'Laura Lane | Products',
  content: [
    {
      component: 'hero-with-image-background',
      img: brushesThin,
      noTriangle: true,
      title: ['Eye and Face Brushes'],
      sub: 'I will only be using my own brushes in the studio from now on and love love love the quality and versatility. These are pro brushes so for any makeup artists out there that want to update their kit or makeup lovers these are perfect ✨ ',
    },
    {
      component: 'product-with-image',
      img: eyeBrushes,
      title: 'Eye Brushes',
      productCode: 'eye-brushes',
      cost: '45',
      content: [
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
      ],
      cta: { label: 'Add To Cart' },
    },
    {
      component: 'product-with-image',
      img: faceBrushes,
      title: 'Face Brushes',
      productCode: 'face-brushes',
      cost: '55',
      alignleft: true,
      cta: { label: 'Add To Cart' },
      content: [
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
      ],
    },
    {
      component: 'product-with-image',
      img: multibrush,
      title: 'Eye + Face Brushes',
      productCode: 'eye-face-brushes',
      cost: '95',
      cta: { label: 'Add To Cart' },
      content: [
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
      ],
    },
    {
      component: 'product-with-image',
      img: giftCert,
      title: 'Gift Certificate',
      alignleft: true,
      cta: { label: 'Add To Cart' },
      productCode: 'gift-cert',
      cost: '25',
      content: [
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
        'Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough. Laura Lane Makeup Artist is based in a beautiful newly renovated studio in the centre of Market Harborough.',
      ],
    },
  ],
};

export default aboutConfig;
