import React, { Fragment, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import styled from 'styled-components';

import LoadingSpinner from '../Feedback/LoadingSpinner';

const ChartWrapper = styled.div``;

const Pie = ({ chartData, seriesName }) => {
  // eslint-disable-next-line
  const [chartOptions, setChartOptions] = useState({
    chart: {
      plotBorderWidth: null,
      plotBackgroundColor: 'rgba(0, 0, 0, 0)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      plotShadow: false,
      type: 'pie',
      width: 250,
    },
    credits: {
      enabled: false,
    },
    legend: { enabled: false },
    title: {
      text: null,
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        innerSize: '80%',
        name: seriesName,
        colorByPoint: true,
        data: chartData,
      },
    ],
  });

  return (
    <Fragment>
      {!!chartData ? (
        <ChartWrapper>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </ChartWrapper>
      ) : (
        <LoadingSpinner />
      )}
    </Fragment>
  );
};

export default Pie;
