import familypic from './familyrain.jpg';
import Rachel from './rachellane.jpg';

const IncomeProtection = {
  title: 'Income protection - the simple safety net',
  date: '20/09/2021',
  readTime: 3,
  image: familypic,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Protection', 'Insurance'],
  intro:
    'One of the cornerstones of financial resilience is ensuring you have a financial safety net should things go wrong. This can be in the form of savings to cover unexpected bills, such as a new boiler, or a period of unemployment.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'One of the cornerstones of financial resilience is ensuring you have a financial safety net should things go wrong. This can be in the form of savings to cover unexpected bills, such as a new boiler, or a period of unemployment.',
      },
    ],
    [
      {
        content:
          'One of the cornerstones of financial resilience is ensuring you have a financial safety net should things go wrong. This can be in the form of savings to cover unexpected bills, such as a new boiler, or a period of unemployment.',
      },
    ],
    [
      {
        content:
          'The pandemic has also highlighted the relatively low levels of help available from employers and the state. There is certainly no fallback furlough scheme paying 80% of wages for those who are unable to work through injury or illness. Although some do pay more, employers are only obliged to meet Statutory Sick Pay requirements, paying £96.35 per week for 28 weeks.',
      },
    ],
    [
      {
        content:
          'After this period, those still unable to work have to apply for Universal Credit, currently just £411.51 a month for single claimants over 25 (including the £20 temporary uplift to the end of September).',
      },
    ],
    [
      {
        content:
          'As this is unlikely to cover even essential mortgage or rental payments and food and utility bills, income protection insurance could be a wise investment.',
      },
    ],
    [
      {
        type: 'bullet',
        boldtext: 'How does it work?',
        content:
          'As the name suggests, income protection insures a portion of your take-home salary and will pay out if you are unable to work through ill-health. This can be for any number of reasons and will include mental health conditions such as anxiety and depression — the most common cause of long-term work absence — as well as physical problems, such as slipped discs, sport injuries or more serious problems such as recovery from cancer, stroke or heart disease. Definitions and what is covered vary between product providers.',
      },
    ],
    [
      {
        content:
          'Typically payments start after a deferral period. The longer this period, the lower the premiums will be. Those who are employees may want to check their employment benefits to clarify if their employer offers more than just statutory sick pay. You can then set up your own insurance so it kicks in when support from your employer ends. Those who are self-employed clearly don\'t have this option.',
      },
    ],
    [
      {
        content:
          'If you are signed off sick from work you will need medical evidence to support your claim so the insurance can start to pay out within the terms of your policy. Once you return to work the payments stop, but you can still keep the policy and it will cover any future periods of ill-health.',
      },
    ],
    [
      {
        type: 'bullet',
        boldtext: 'Other types of insurance',
        content:
          'Income protection isn’t the only type of health insurance that can provide a safety net. Critical illness insurance pays out a one-off tax-free lump sum if you are diagnosed with one of the serious conditions listed in the policy. These will include most cancers, heart disease and stroke. The payment can be used to pay off an outstanding mortgage or any other debts, or simply to provide a financial buffer to give you time to recover from a serious illness.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Life Assurance plans typically have no cash in value at any time and cover will cease at the end of term. If premiums stop, then cover will lapse',
      },
    ],
  ],
};

export default IncomeProtection;
