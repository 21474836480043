import Image from './image.png';
import Newsletter from './newsletter.pdf';
import Rachel from './rachellane.jpeg';

const spring = {
  title: 'Financial Focus - Spring 2022 Newsletter',
  date: '22/03/2021',
  readTime: 14,
  image: Image,
  type: 'Newsletter',
  typeColor: 'tertiary',
  tags: ['spring 2021', 'Gifts', 'Investing'],
  intro:
    'Just when it seemed we were nearing a kind of resolution to one painful crisis, another has started. While the lifting of Covid-19 restrictions in the UK has been a huge relief for many, the financial cost of the pandemic is now really starting to bite. This is now likely to be exacerbated by the responses to Russia’s shocking attack on Ukraine as sanctions bite and inflationary effects on the cost of living rise. Spring is a good time to review and reflect, not least in these circumstances. In this edition of the newsletter we look specifically at the effects of inflation across your financial planning, from ensuring you’re making the most of your ISA allocation to thinking ahead with your estate planning. While reform to IHT is now off the table, existing gifting provisions mean you can still mitigate the increasing effect of the tax. If you are among those of us daydreaming about retiring early, recent research has identified 60 as the preferred age. But you’ll need sound planning to ensure you cover the potential funding gap before you qualify for your state pension.',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [{ type: 'newsletter', content: Newsletter }],
};

export default spring;
