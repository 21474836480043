import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Icon from '../UI/Icon';
import Hamburger from './Hamburger';

const MobileNav = styled.div`
  @media only screen and (min-width: 800px) {
    display: none;
  }
  position: fixed;
  width: 100vw;
  height: 4rem;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  border-top: ${(props) =>
    `0.25rem solid rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  img {
    height: 2.5rem;
    margin-top: 0.5rem;
  }
`;

const RightOption = styled(Link)`
  width: 60px;
`;

const RightOptionExternal = styled.a`
  width: 60px;
`;

const MobilePublicNavHeader = ({
  handleMobileNavToggle,
  showMobileNav,
  companyLogo,
  cta,
}) => {
  return (
    <MobileNav>
      <div
        onClick={() => {
          handleMobileNavToggle();
        }}
      >
        <Hamburger isActive={showMobileNav} />
      </div>
      <Link to='/'>
        <img src={companyLogo} alt='logo' />
      </Link>
      {cta.external && (
        <RightOptionExternal href={cta.route}>
          <Icon
            iconType={cta.icon}
            size={2}
            color='midGrey'
            margin='0 0.5rem'
          />
        </RightOptionExternal>
      )}
      {!cta.external && (
        <RightOption to={cta.route}>
          <Icon
            iconType={cta.icon}
            size={2}
            color='midGrey'
            margin='0 0.5rem'
          />
        </RightOption>
      )}
    </MobileNav>
  );
};

export default MobilePublicNavHeader;
