import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Lookup from '../Forms/Lookup';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
  * {
    text-transform: capitalize;
  }
  a {
    &:hover {
      color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]},1)`};
    }
  }
`;

const Breadcrumbs = ({ breadcrumbConfig, currentRecord }) => {
  return (
    <Wrapper>
      {breadcrumbConfig.map((item, index) => (
        <Fragment key={index}>
          <Icon
            iconType='chevron-right'
            size={1}
            color='midGrey'
            margin='0 0.25rem'
          />
          {item.type === 'simpleLabel' && <div>{item.label}</div>}
          {item.type === 'simpleLink' && (
            <Link to={item.route}>{item.label}</Link>
          )}
          {item.type === 'currentRecord' &&
            !!currentRecord &&
            !!currentRecord.name &&
            !!currentRecord.name.value && <div>{currentRecord.name.value}</div>}
          {item.type === 'relatedRecord' &&
            !!currentRecord &&
            !!currentRecord[item.recordRefFromCurrent] && (
              <Lookup
                data={currentRecord[item.recordRefFromCurrent]}
                dataItemConfig={item}
                removeStyling={true}
              />
            )}
        </Fragment>
      ))}
    </Wrapper>
  );
};

export default Breadcrumbs;
