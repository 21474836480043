import React from 'react';
import styled from 'styled-components';

import { LinkButton } from '../Controls';

const Wrapper = styled.div`
  width: 100vw;
  overflow-x: hidden;
  min-height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(
    305deg, rgba(${props.theme.colors.primary[0]}, 0.97), rgba(${props.theme.colors.neutral[0]}, 0.75)), url(${props.bg}) `};
`;

const InnerWrap = styled.div`
  max-width: 1100px;
  margin: auto;
`;

const Container = styled.div`
  float: ${(props) => props.alignRight && 'right'};
  text-align: ${(props) => props.alignRight && 'right'};
  max-width: 450px;
  padding: 4rem 1rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};

  svg {
    width: 100%;
  }

  p {
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 800px) {
    padding: 7rem 2rem 2rem 2rem;
  }
`;

const Title = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const HeroWithImageBackground = ({ config }) => {
  return (
    <Wrapper bg={config.img}>
      <InnerWrap>
        <Container>
          <Title>{config.title}</Title>
          {config.content.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          <LinkButton color='lightLink' to={config.ctaRoute}>
            {config.ctaLabel}
          </LinkButton>
        </Container>
      </InnerWrap>
    </Wrapper>
  );
};

export default HeroWithImageBackground;
