import React from 'react';
import styled from 'styled-components';
import formatCurrency from '../../utils/formatCurrency';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 1rem;
`;

const Tile = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]},1)`};
  margin-bottom: 1rem;
  margin-left: 1rem;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.boxShadow};
  p {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]},0.8)`};
  }
  h2 {
    color: ${(props) => `rgba(${props.theme.colors.neutral[1]},1)`};
    font-weight: bold;
    font-size: 1.8rem;
  }
`;

const MainCount = ({ data, config }) => {
  return (
    <Wrapper>
      {config.count && (
        <Tile>
          <p>{config.count}</p>
          <h2>{data.count ? data.count : '0'}</h2>
        </Tile>
      )}
      {config.amount && (
        <Tile>
          <p>{config.amount}</p>
          <h2>{data.amount ? formatCurrency(data.amount) : '0'}</h2>
        </Tile>
      )}
      {config.aua && (
        <Tile>
          <p>{config.aua}</p>
          <h2>{data.aua ? formatCurrency(data.aua) : '0'}</h2>
        </Tile>
      )}
      {config.initial && (
        <Tile>
          <p>{config.initial}</p>
          <h2>{data.initial ? formatCurrency(data.initial) : '0'}</h2>
        </Tile>
      )}
      {config.ongoing && (
        <Tile>
          <p>{config.ongoing}</p>
          <h2>{data.ongoing ? formatCurrency(data.ongoing) : '0'}</h2>
        </Tile>
      )}
    </Wrapper>
  );
};

export default MainCount;
