import React, { useContext, Fragment } from 'react';
import styled from 'styled-components';
import { functions } from '../../context/firebase';

import { Button } from '../Controls';

import ShoppingcartContext from '../../context/shoppingcart/shoppingcartContext';
import AddToCartWidget from './AddToCartWidget';

const Wrapper = styled.div`
  padding: 2rem;
  max-width: 1100px;
  margin: auto;
`;

const Title = styled.div`
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

const ProductItem = styled.div`
  margin-left: 4rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-bottom: 2rem;
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 2rem;
  }
`;
const Content = styled.div``;

const ContentTitle = styled.div`
  font-size: 1.5rem;
  letter-spacing: 0.4rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const Checkout = ({ config }) => {
  const shoppingcartContext = useContext(ShoppingcartContext);
  const handleNewCheckoutRequest = (data) => {
    const handleNewCheckoutRequestCallable = functions.httpsCallable(
      'handleNewCheckoutRequest'
    );
    return handleNewCheckoutRequestCallable({ data });
  };

  const processCheckout = () => {
    handleNewCheckoutRequest({ productCode: 'face-brush' })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.data && res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Wrapper>
      <Title>Checkout</Title>
      {config.products.map((product) => (
        <Fragment key={product.productCode}>
          {shoppingcartContext.cartItems[product.productCode] && (
            <ProductItem>
              <img src={product.img} alt={product.title} />
              <Content>
                <ContentTitle>{product.title}</ContentTitle>
                <AddToCartWidget config={product} checkout={true} />
              </Content>
            </ProductItem>
          )}
        </Fragment>
      ))}
      <Button
        onClick={() => {
          processCheckout();
        }}
      >
        Checkout
      </Button>
    </Wrapper>
  );
};

export default Checkout;
