import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  AiFillCompass,
  AiOutlineBank,
  AiOutlineTwitter,
  AiOutlineUser,
  AiFillPlusCircle,
  AiOutlineCheck,
  AiFillYoutube,
  AiOutlineVideoCamera,
  AiOutlineLike,
  AiOutlineLineChart,
  AiOutlinePlus,
  AiOutlineShoppingCart,
  AiOutlineShopping,
  AiOutlineMail,
  AiOutlineCaretUp,
  AiOutlineBarChart,
  AiOutlineShop,
  AiOutlinePlaySquare,
  AiOutlineTeam,
  AiOutlineFileAdd,
  AiOutlineBulb,
  AiOutlineFileSync,
  AiOutlineArrowUp,
  AiOutlinePlayCircle,
  AiOutlineSearch,
  AiOutlineClockCircle,
  AiOutlineCopy,
  AiOutlineUserAdd,
  AiOutlineClose,
  AiOutlineCaretDown,
  AiOutlineRightCircle,
  AiOutlineDashboard,
  AiOutlinePieChart,
  AiOutlineOrderedList,
  AiOutlineCloudDownload,
  AiOutlineLock,
  AiOutlineUsergroupAdd,
  AiOutlineFolder,
  AiOutlineMenu,
  AiOutlineCalendar,
  AiOutlineFileText,
  AiOutlineFolderOpen,
  AiOutlineOneToOne,
  AiOutlineAlert,
  AiOutlineStop,
  AiOutlineLogin,
  AiOutlineAppstoreAdd,
  AiOutlineExclamationCircle,
  AiOutlineCalculator,
  AiOutlineInfoCircle,
  AiOutlineHistory,
  AiOutlineFilePdf,
  AiOutlineFileImage,
  AiOutlineSetting,
  AiOutlineComment,
  AiOutlineReconciliation,
  AiOutlineSolution,
  AiOutlineHdd,
  AiOutlineGlobal,
  AiFillBell,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillCopy,
  AiOutlineFileDone,
  AiOutlineCluster,
  AiOutlineFileProtect,
  AiOutlineFund,
  AiOutlineArrowDown,
} from 'react-icons/ai';
import {
  FaLinkedinIn,
  FaRegCalendarTimes,
  FaInstagramSquare,
  FaFacebookF,
  FaUserAlt,
  FaPhone,
  FaRegAddressCard,
  FaFileInvoice,
  FaRegStickyNote,
  FaMapMarker,
  FaEllipsisV,
  FaCog,
  FaTimes,
  FaFax,
  FaAsterisk,
  FaFacebookSquare,
  FaRegEdit,
  FaRegUserCircle,
  FaRegComment,
} from 'react-icons/fa';
import {
  BsCheckCircle,
  BsCircle,
  BsBookmark,
  BsToggleOn,
  BsToggleOff,
  BsShield,
  BsTrash,
  BsChevronRight,
  BsChevronLeft,
  BsListNested,
  BsCardChecklist,
  BsKanban,
} from 'react-icons/bs';
import { BiUpvote } from 'react-icons/bi';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { IoTodayOutline, IoExtensionPuzzleOutline } from 'react-icons/io5';
import {
  RiRepeat2Line,
  RiRepeatOneLine,
  RiMailSendLine,
  RiBrushFill,
} from 'react-icons/ri';
import { MdInput, MdRssFeed } from 'react-icons/md';
import { ImQuotesLeft } from 'react-icons/im';
import {
  ImFileVideo,
  ImUserPlus,
  ImUserMinus,
  ImUserCheck,
} from 'react-icons/im';
import { GrDocumentMissing, GrMapLocation } from 'react-icons/gr';

const WrapperBackgroundStyles = ({ background }) => {
  if (background === 'light') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[5]}, 1)`};
    `;
  } else if (background === 'dark') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[1]}, 1)`};
    `;
  } else if (background === 'primary') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.primary[0]}, 1)`};
    `;
  } else if (background === 'alt') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.secondary[0]}, 1)`};
    `;
  } else if (background === 'lightGrey') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[4]}, 1)`};
    `;
  } else if (background === 'darkOverlay') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[2]}, 0.7)`};
    `;
  } else if (background === 'success') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
    `;
  } else if (background === 'danger') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.danger[0]}, 1)`};
    `;
  } else {
    return css`
      background-color: none;
    `;
  }
};

const IconColorStyles = ({ color }) => {
  if (color === 'light') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    `;
  } else if (color === 'darkOverlay') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
    `;
  } else if (color === 'primary') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
    `;
  } else if (color === 'linkBlue') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
    `;
  } else if (color === 'alt') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.secondary[0]}, 1)`};
    `;
  } else if (color === 'lightGrey') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
    `;
  } else if (color === 'midGrey') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
    `;
  } else if (color === 'success') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
    `;
  } else if (color === 'danger') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.danger[0]}, 1)`};
    `;
  } else if (color === 'lightGrey') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
    `;
  } else {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    `;
  }
};

const IconWrapper = styled.div`
  border-radius: ${(props) => (props.isCircle ? '50%' : '0')};
  border-radius: ${(props) => props.isTile && '5px'};
  font-size: ${(props) => (props.size ? `${props.size}rem` : '1rem')};
  padding: ${(props) => (props.background ? '0.5rem 0.5rem 0 0.5rem;' : '0')};
  padding-bottom: ${(props) => props.isCircle && '0.1rem'};
  padding-bottom: ${(props) => props.isTile && '0.3rem'};
  margin: ${(props) => (props.margin ? props.margin : '')};
  display: flex;
  ${WrapperBackgroundStyles}
  > * {
    ${IconColorStyles}
    align-self: center;
  }
`;

const Icon = ({
  iconType,
  size,
  color,
  background,
  isCircle,
  margin,
  isTile,
}) => {
  return (
    <IconWrapper
      background={background}
      size={size}
      color={color}
      isCircle={isCircle}
      margin={margin}
      isTile={isTile}
    >
      {iconType === 'twitter' && <AiOutlineTwitter />}
      {iconType === 'circle' && <BsCircle />}
      {iconType === 'facebook' && <FaFacebookF />}
      {iconType === 'instagram' && <FaInstagramSquare />}
      {iconType === 'plusSign' && <AiOutlinePlus />}
      {iconType === 'compass' && <AiFillCompass />}
      {iconType === 'linkedin' && <FaLinkedinIn />}{' '}
      {iconType === 'facebook-square' && <FaFacebookSquare />}
      {iconType === 'bank' && <AiOutlineBank />}
      {iconType === 'mail' && <AiOutlineMail />}
      {iconType === 'search' && <AiOutlineSearch />}
      {iconType === 'upArrow' && <AiOutlineArrowUp />}
      {iconType === 'puzzle' && <IoExtensionPuzzleOutline />}
      {iconType === 'fileSync' && <AiOutlineFileSync />}
      {iconType === 'downArrow' && <AiOutlineArrowDown />}
      {iconType === 'cardchecklist' && <BsCardChecklist />}
      {iconType === 'videocamera' && <AiOutlineVideoCamera />}
      {iconType === 'edit' && <FaRegEdit />}
      {iconType === 'repeat' && <RiRepeat2Line />}
      {iconType === 'repeatone' && <RiRepeatOneLine />}
      {iconType === 'fax' && <FaFax />}
      {iconType === 'brush' && <RiBrushFill />}
      {iconType === 'mapMarker' && <FaMapMarker />}
      {iconType === 'addItem' && <MdInput />}
      {iconType === 'nestedList' && <BsListNested />}
      {iconType === 'clock' && <AiOutlineClockCircle />}
      {iconType === 'user' && <FaUserAlt />}
      {iconType === 'file-add' && <AiOutlineFileAdd />}
      {iconType === 'download' && <AiOutlineCloudDownload />}
      {iconType === 'comment' && <FaRegComment />}
      {iconType === 'folderOutline' && <AiOutlineFolder />}
      {iconType === 'quote' && <ImQuotesLeft />}
      {iconType === 'phone' && <FaPhone />}
      {iconType === 'add' && <AiOutlineUserAdd />}
      {iconType === 'close' && <AiOutlineClose />}
      {iconType === 'downcaret' && <AiOutlineCaretDown />}
      {iconType === 'upcaret' && <AiOutlineCaretUp />}
      {iconType === 'bulletpoint' && <AiOutlineRightCircle />}
      {iconType === 'dashboard' && <AiOutlineDashboard />}
      {iconType === 'userlist' && <AiOutlineUsergroupAdd />}
      {iconType === 'like' && <AiOutlineLike />}
      {iconType === 'team' && <AiOutlineTeam />}
      {iconType === 'usersingle' && <AiOutlineUser />}
      {iconType === 'tasklist' && <AiOutlineOrderedList />}
      {iconType === 'mailSend' && <RiMailSendLine />}
      {iconType === 'kanban' && <BsKanban />}
      {iconType === 'profile' && <AiOutlineLock />}
      {iconType === 'calendar' && <AiOutlineCalendar />}
      {iconType === 'addresscard' && <FaRegAddressCard />}
      {iconType === 'fileupload' && <AiOutlineFileText />}
      {iconType === 'fileinvoice' && <FaFileInvoice />}
      {iconType === 'folderopen' && <AiOutlineFolderOpen />}
      {iconType === 'shoppingCart' && <AiOutlineShoppingCart />}
      {iconType === 'shopping' && <AiOutlineShopping />}
      {iconType === 'mapLocation' && <GrMapLocation />}
      {iconType === 'logout' && <AiOutlineLogin />}
      {iconType === 'exclamationTriangle' && <AiOutlineExclamationCircle />}
      {iconType === 'checkCircle' && <BsCheckCircle />}
      {iconType === 'check' && <AiOutlineCheck />}
      {iconType === 'stickyNote' && <FaRegStickyNote />}
      {iconType === 'calculator' && <AiOutlineCalculator />}
      {iconType === 'bookmark' && <BsBookmark />}
      {iconType === 'ellipsisV' && <FaEllipsisV />}
      {iconType === 'toggleOff' && <BsToggleOff />}
      {iconType === 'crossCal' && <FaRegCalendarTimes />}
      {iconType === 'toggleOn' && <BsToggleOn />}
      {iconType === 'plusfill' && <AiFillPlusCircle />}
      {iconType === 'infoCircle' && <AiOutlineInfoCircle />}
      {iconType === 'history' && <AiOutlineHistory />}
      {iconType === 'filePdf' && <AiOutlineFilePdf />}
      {iconType === 'fileImage' && <AiOutlineFileImage />}
      {iconType === 'alert' && <AiOutlineAlert />}
      {iconType === 'bell' && <AiFillBell />}
      {iconType === 'cog' && <FaCog />}
      {iconType === 'times' && <FaTimes />}
      {iconType === 'rssFeed' && <MdRssFeed />}
      {iconType === 'settings' && <AiOutlineSetting />}
      {iconType === 'reconcile' && <AiOutlineReconciliation />}
      {iconType === 'solution' && <AiOutlineSolution />}
      {iconType === 'hdd' && <AiOutlineHdd />}
      {iconType === 'line-chart' && <AiOutlineLineChart />}
      {iconType === 'global' && <AiOutlineGlobal />}
      {iconType === 'asterisk' && <FaAsterisk />}
      {iconType === 'copy' && <AiOutlineCopy />}
      {iconType === 'copied' && <AiFillCopy />}
      {iconType === 'next' && <AiOutlineArrowRight />}
      {iconType === 'back' && <AiOutlineArrowLeft />}
      {iconType === 'trash' && <BsTrash />}
      {iconType === 'plus' && <IoIosAddCircleOutline />}
      {iconType === 'file-done' && <AiOutlineFileDone />}
      {iconType === 'file-missing' && <GrDocumentMissing />}
      {iconType === 'connections' && <AiOutlineCluster />}
      {iconType === 'shop' && <AiOutlineShop />}
      {iconType === 'income' && <AiOutlineAppstoreAdd />}
      {iconType === 'pie-chart' && <AiOutlinePieChart />}
      {iconType === 'line-with-outline' && <AiOutlineFund />}
      {iconType === 'one-one' && <AiOutlineOneToOne />}
      {iconType === 'shield' && <BsShield />}
      {iconType === 'videofile' && <ImFileVideo />}
      {iconType === 'userCircle' && <FaRegUserCircle />}
      {iconType === 'barChart' && <AiOutlineBarChart />}
      {iconType === 'menu' && <AiOutlineMenu />}
      {iconType === 'comments' && <AiOutlineComment />}
      {iconType === 'play' && <AiOutlinePlayCircle />}
      {iconType === 'stop' && <AiOutlineStop />}
      {iconType === 'playSquare' && <AiOutlinePlaySquare />}
      {iconType === 'lightbulb' && <AiOutlineBulb />}
      {iconType === 'fileProtected' && <AiOutlineFileProtect />}
      {iconType === 'chevron-right' && <BsChevronRight />}
      {iconType === 'chevron-left' && <BsChevronLeft />}
      {iconType === 'youtube' && <AiFillYoutube />}
      {iconType === 'upvote' && <BiUpvote />}
      {iconType === 'ImUserPlus' && <ImUserPlus />}
      {iconType === 'ImUserMinus' && <ImUserMinus />}
      {iconType === 'ImUserCheck' && <ImUserCheck />}
      {iconType === 'todayCal' && <IoTodayOutline />}
    </IconWrapper>
  );
};
//impelment the icons instead of iconclass
Icon.propTypes = {
  margin: PropTypes.string,
  iconType: PropTypes.oneOf([
    'chevron-right',
    'upvote',
    'ImUserPlus',
    'ImUserMinus',
    'ImUserCheck',
    'chevron-left',
    'income',
    'mapMarker',
    'mapLocation',
    'youtube',
    'fax',
    'comments',
    'comment',
    'bank',
    'lightbulb',
    'fileProtected',
    'menu',
    'pie-chart',
    'twitter',
    'facebook',
    'facebook-square',
    'instagram',
    'linkedin',
    'mail',
    'search',
    'upArrow',
    'downArrow',
    'user',
    'clock',
    'quote',
    'folderOutline',
    'phone',
    'barChart',
    'add',
    'close',
    'playSquare',
    'bulletpoint',
    'downcaret',
    'mailSend',
    'dashboard',
    'userlist',
    'tasklist',
    'play',
    'stop',
    'profile',
    'calendar',
    'addresscard',
    'compass',
    'fileupload',
    'fileinvoice',
    'upcaret',
    'folderopen',
    'logout',
    'exclamationTriangle',
    'checkCircle',
    'asterisk',
    'stickyNote',
    'fileSync',
    'calculator',
    'bookmark',
    'shield',
    'brush',
    'shoppingCart',
    'shopping',
    'ellipsisV',
    'plusSign',
    'check',
    'crossCal',
    'toggleOff',
    'toggleOn',
    'usersingle',
    'circle',
    'infoCircle',
    'history',
    'todayCal',
    'filePdf',
    'fileImage',
    'cog',
    'times',
    'settings',
    'download',
    'reconcile',
    'solution',
    'hdd',
    'team',
    'global',
    'copy',
    'puzzle',
    'copied',
    'next',
    'plusfill',
    'back',
    'trash',
    'plus',
    'edit',
    'file-done',
    'file-add',
    'videocamera',
    'cardchecklist',
    'connections',
    'shop',
    'kanban',
    'line-with-outline',
    'alert',
    'file-missing',
    'videofile',
    'line-chart',
    'one-one',
    'repeat',
    'repeatone',
    'rssFeed',
    'addItem',
    'nestedList',
    'bell',
    'userCircle',
  ]),
  size: PropTypes.number.isRequired,
  isCircle: PropTypes.bool,
  color: PropTypes.oneOf([
    'light',
    'dark',
    'primary',
    'alt',
    'lightGrey',
    'midGrey',
    'success',
    'danger',
    'linkBlue',
  ]),
  background: PropTypes.oneOf([
    'light',
    'dark',
    'primary',
    'alt',
    'lightGrey',
    'darkOverlay',
  ]),
};

export default Icon;
