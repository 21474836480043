import React from 'react';
import styled from 'styled-components';
import BlogWithTileList from '../Blogs/BlogWithTileList';

const Wrapper = styled.div`
  width: 100vw;
  overflow-x: hidden;
  min-height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(
    125deg, rgba(${props.theme.colors.primary[0]}, 0.97), rgba(${props.theme.colors.neutral[0]}, 0.55)), url(${props.bg}) `};
`;

const InnerWrap = styled.div`
  max-width: 1100px;
  margin: auto;
`;

const Container = styled.div`
  float: ${(props) => props.alignRight && 'right'};
  text-align: ${(props) => props.alignRight && 'right'};
  max-width: 650px;
  padding: 4rem 2rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.2rem;
  }

  h2 {
    padding: 1rem 0;
    font-weight: 300;
    font-size: 1.1rem;
  }

  @media only screen and (max-width: 800px) {
    padding: 7rem 2rem 2rem 2rem;
  }
`;

const Triangle = styled.div`
  @media only screen and (max-width: 800px) {
    display: none;
  }
  height: 6rem;
  border: none;
  outline: none;
  background: ${(props) => `linear-gradient(
    to top right,
    rgba(${props.theme.colors.bgColor[0]}, 1) calc(50% - 1px),
    rgba(${props.theme.colors.primary[0]}, 0) calc(50% + 1px)
  )`};
`;

const HeroNewsPage = ({ config }) => {
  return (
    <Wrapper bg={config.img}>
      <InnerWrap>
        <Container>
          <h1>{config.content.title}</h1>
          <h2>{config.content.subtitle}</h2>
        </Container>
      </InnerWrap>
      <Triangle />
      <BlogWithTileList config={config.posts} />
    </Wrapper>
  );
};

export default HeroNewsPage;
