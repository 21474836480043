import Img from '../assets/home-min.jpg';
import homeScrollover from '../assets/home-scrollover.jpg';
import aboutPhoto from '../assets/about.jpg';
import corporatePhoto from '../assets/corporate.jpg';
import approachPhoto from '../assets/approach.jpg';
import privatePhoto from '../assets/private.jpg';

const homeConfig = {
  route: '/',
  title: 'Group Rapport | Home',
  content: [
    {
      component: 'hero-with-image-background',
      img: Img,
      title: ['Your Finances Simplified'],
      sub: 'We provide a coherent financial structure to help you achieve your objectives. We work with businesses & high net worth private clients; our specialist teams are focused on providing services tailored to the specific needs and circumstances of each.',
      primaryCTA: { route: '/contact', label: 'Get In Contact' },
      secondaryCTA: { route: '/about', label: 'Meet The Team' },
    },
    {
      component: 'features-two-by-two',
      features: [
        {
          icon: 'line-chart',
          title: 'Expert',
          text: 'Professionals in control of your investments, with over 100 years collective experience',
        },
        {
          icon: 'puzzle',
          title: 'Strategy',
          text: 'We have built thousands of financial plans and will construct a bespoke financial strategy for you',
        },
        {
          icon: 'global',
          title: 'Family',
          text: 'We are expert in inter-generational planning, considering the finances of your whole family',
        },
        {
          icon: 'shield',
          title: 'Confidence',
          text: 'Rest assured, with Team Rapport you have an entire team in your corner working tirelessly on your behalf',
        },
      ],
    },
    {
      component: 'testimonial-centered',
      contents: [
        {
          quote:
            'Although we’re good listeners, we always ask questions. We test assumptions.',
        },
      ],
    },
    {
      component: 'scrollover-text-with-background-img',
      img: homeScrollover,
      title: ['Our businesses and services are tailored'],
      sub: 'Specialist colleagues focus on business-related advice, whereas others work with high net worth private clients.',
    },
    {
      component: 'tiles-with-images',
      background: true,
      content: [
        {
          photo: aboutPhoto,
          title: 'Expert Team',
          bold: 'You wouldn’t ask your dentist to mend your car so why not have the right specialist when it comes to your money?',
          paragraph:
            'Specialist colleagues focus on business-related advice, whereas others work with high net worth private clients. As such, we can provide expert and qualified guidance on a wide and diverse range of financial subjects and issues.',
          cta: { route: '/about', label: 'Meet Our Team' },
        },
        {
          small: true,
          tiles: [
            {
              photo: corporatePhoto,
              title: 'Company Rapport',
              paragraph:
                'Our Corporate team will help you provide and promote the right benefits to your staff. Maximising the value to your team to give you value for your budget.',
              cta: { route: '/corporate', label: 'Details' },
            },
            {
              photo: approachPhoto,
              title: 'Our Approach',
              paragraph:
                'By working with you to build discipline to your financial affairs, we will help you to regain control of your financial future.',
              cta: { route: '/approach', label: 'Our Approach' },
            },
          ],
        },
        {
          photo: privatePhoto,
          title: 'Personal Advice',
          bold: 'The first step is to arrange an initial meeting to discuss your overall aims and objectives.',
          paragraph:
            'Our team offers a proactive and bespoke advice service, based on a long term relationship with our clients. We will start by analysing your circumstances and discussing your personal financial objectives. Then, once we have conducted a detailed evaluation of your options, we will provide a detailed recommendation.',
          cta: { route: '/personal', label: 'Learn More' },
        },
      ],
    },
  ],
};

export default homeConfig;
