import { useEffect, useState } from 'react';
import firebase from '../context/firebase';

const projectStorage = firebase.storage();

const useStorage = (clientID, file, tenant, folder) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const storageRef = projectStorage.ref(
      `${tenant}/${clientID}/${!!folder ? folder + '/' : ''}${
        new Date() + file.name + clientID
      }`
    );
    storageRef.put(file).on(
      'state_changed',
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
      },
      (err) => {
        setError(err);
      },
      async () => {
        const url = await storageRef.getDownloadURL();
        setUrl(url);
      }
    );
  }, [file, clientID, tenant, folder]);
  //added clientID untested

  return { progress, url, error };
};

export default useStorage;
