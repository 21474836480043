//remember - some core records names are protected, e.g. name, tenant, email, etc. as these are used directly - so we have nameEditable instead where we get nameEditable.name from, the others are only used on cloud functions
const stages = ['New', 'Invited', 'Active', 'Inactive'];
const outcomes = ['Current', 'Old'];
const usersConfig = {
  title: 'Team Records',
  name: 'Team',
  addBtnLabel: 'Add Team Member',
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Name' },
  stages: stages,
  outcomes: outcomes,
  manageTabConfig: {
    filterByLabel: 'Name',
    tableConfig: [
      { label: 'Name', ref: 'editableName' },
      { label: 'Role', ref: 'editableRole' },
      { label: 'Job Title', ref: 'jobTitle' },
      { label: 'tenant', ref: 'editableTenant' },
      { label: 'Logins', ref: 'logins' },
      { label: 'Status', ref: 'stage' },
    ],
  },
  newDataSteps: [
    {
      title: 'Team Member',
      guidance:
        'Role will govern the permissions this team member has within Compass, including the data & areas of the system they can access and the actions they can carry out.',
      data: [
        { ref: 'editableName', mandatory: true },
        { ref: 'jobTitle', mandatory: true },
        { ref: 'editableRole', mandatory: true },
      ],
    },
    {
      title: 'Contact Information',
      guidance:
        'Email address must be their work email, this will join their account with their company record in Compass.',
      data: [
        { ref: 'editableEmail', mandatory: true },
        { ref: 'location', mandatory: true },
        { ref: 'phone' },
      ],
    },
    {
      title: 'Notes',
      guidance: 'Optional.',
      data: [{ ref: 'notes' }],
    },
  ],
  recordEditTabs: ['summary'],
  analysisTabConfig: {
    aggregateConfig: { count: 'Total Team Members' },
  },
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Details',
          data: ['editableName', 'editableRole', 'jobTitle', 'editableTenant'],
        },
        {
          title: 'Contact Information',
          data: ['editableEmail', 'phone', 'location'],
        },
        {
          title: 'Portal',
          data: ['stage', 'token', 'logins', 'lastLoggedIn'],
        },
        {
          title: 'Notes',
          data: ['notes'],
        },
      ],
    },
  ],
  data: {
    editableName: {
      type: 'text',
      label: 'Name',
    },
    editableRole: {
      type: 'select',
      label: 'Role',
      valueOptions: [
        { value: 'client', label: 'Client' },
        { value: 'admin', label: 'Administrator' },
        { value: 'adviser', label: 'Adviser' },
        { value: 'manager', label: 'Manager' },
        { value: 'group', label: 'Group' },
      ],
    },
    stage: {
      type: 'select',
      label: 'Status',
      valueOptions: stages,
      readOnly: true,
    },
    token: {
      isComplex: true,
      type: 'token',
      isClient: false,
    },
    jobTitle: {
      type: 'text',
      label: 'Job Title',
    },
    location: {
      type: 'text',
      label: 'Location',
    },
    editableEmail: {
      type: 'email',
      label: 'Email',
      readOnly: true,
    },
    phone: {
      type: 'tel',
      label: 'Phone',
    },
    editableTenant: {
      type: 'text',
      label: 'Firm',
      readOnly: true,
    },
    lastLoggedIn: {
      type: 'date',
      label: 'Last Logged In',
      readOnly: true,
    },
    logins: {
      type: 'number',
      label: 'Logins',
      readOnly: true,
    },
    notes: {
      type: 'textArea',
      label: 'Notes',
    },
  },
};

export default usersConfig;
