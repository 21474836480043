import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../UI/Icon';

const ToggleGroup = styled.div`
  display: flex;
  align-items: center;
  visibility: ${(props) => (props.isVisible ? '' : 'hidden')};
  div {
    padding-right: 0.5rem;
  }
  i {
    padding-left: 0.2rem;
  }
  &:hover {
    cursor: pointer;
    color: ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
  }
`;

export const Toggle = ({ itemRef, value, change }) => {
  return (
    <ToggleGroup isVisible>
      <div
        className='clickable'
        onClick={() => {
          change(itemRef, 'value');
        }}
      >
        {!!value ? (
          <Icon iconType='toggleOn' size={2} color='primary' />
        ) : (
          <Icon iconType='toggleOff' size={2} color='lightGrey' />
        )}
      </div>
    </ToggleGroup>
  );
};

Toggle.propTypes = {
  itemRef: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
};
