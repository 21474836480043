const termsConfig = {
  route: '/terms',
  title: 'Terms, Conditions, Privacy & Cookies',
  content: [
    {
      component: 'termsconditions-termssections',
      content: [
        {
          title: 'Group Rapport | Terms and Conditions',
          id: 'terms',
          sections: [
            [
              {
                content:
                  'The information contained within this site is subject to the UK regulatory regime and is therefore meant for UK residents only. Users should be aware that the laws and regulations may be different outside the United Kingdom. This website does not constitute a solicitation and contains general information only.',
              },
            ],
            [
              {
                content:
                  'By accessing this website you agree to be bound by the conditions contained in this legal message and further agree that the Courts of England and Wales shall have exclusive jurisdiction in respect of any dispute arising from this legal message. Neither Stonewood Financial Planning nor its representatives can be held responsible for the accuracy of the contents/information contained within site(s) linked to this site. The copyright in the material contained in the web site belongs to Stonewood Financial Planning. All rights reserved.',
              },
            ],
            [
              {
                content:
                  'Group Rapport is a trading style of Stonewood Financial Planning who are authorised and regulated by the Financial Conduct Authority. Stonewood Financial Planning can be found on the FCA register ',
              },
              {
                content: 'www.fca.org.uk/register',
                type: 'external-link',
                href: 'http://www.fca.org.uk/register',
              },
              {
                content:
                  ' under the Registration Number 149889. Full details of the FOS can be found on its website at ',
              },
              {
                content: 'www.financial-ombudsman.org.uk.',
                type: 'external-link',
                href: 'http://www.financial-ombudsman.org.uk/',
              },
            ],
            [
              {
                content:
                  'If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern ‘Group Rapport’s relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.',
              },
            ],
            [
              {
                content:
                  'The term ‘Group Rapport or Stonewood Financial Planning or or ‘us’ or ‘we’ refers to the owner of the website whose registered office is Stonewood Financial Planning,  Marie House, 5 Baker Street, Weybridge, Surrey, KT13 8AE. Our company registration number is 02656267. The term ‘you’ refers to the user or viewer of our website.',
              },
            ],
            [
              {
                content:
                  'The guidance and/or advice contained in this website is subject to the UK regulatory regime and is therefore restricted to consumers based in the UK.',
              },
            ],
            [
              {
                content:
                  'The Financial Conduct Authority does not regulate Trust and Tax Advice or Estate Planning.',
              },
            ],
            [
              {
                content:
                  'The use of this website is subject to the following terms of use:',
              },
            ],
            [
              {
                type: 'unordered-list',
                listItems: [
                  [
                    {
                      content:
                        'The content of the pages of this website is for your general information and use only. It is subject to change without notice.',
                    },
                  ],
                  [
                    {
                      content:
                        'This website uses cookies to monitor browsing preferences. ',
                    },
                    {
                      type: 'onpage-link',
                      content: 'Cookie policy.',
                      linkLocation: '#cookies',
                    },
                  ],
                  [
                    {
                      content:
                        'Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.',
                    },
                  ],
                  [
                    {
                      content:
                        'Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.',
                    },
                  ],
                  [
                    {
                      content:
                        'This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.',
                    },
                  ],
                  [
                    {
                      content:
                        'All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.',
                    },
                  ],
                  [
                    {
                      content:
                        'Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.',
                    },
                  ],
                  [
                    {
                      content:
                        'From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).',
                    },
                  ],
                  [
                    {
                      content:
                        'You voluntarily choose to provide personal details to us via this website. Personal information will be treated as confidential by us and held in accordance with the Data Protection Act 1998. You agree that such personal information may be used to provide you with details of services and products in writing, by email or by telephone.',
                    },
                  ],
                ],
              },
            ],
            [
              {
                content:
                  'Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.',
              },
            ],
          ],
        },
        {
          title: 'Privacy Policy',
          id: 'privacy',
          sections: [
            [
              {
                content:
                  'This privacy notice explains how Group Rapport use any personal information we collect about you.',
              },
            ],
            [
              {
                type: 'title',
                content: 'WHAT INFORMATION DO WE COLLECT ABOUT YOU?',
              },
              {
                content:
                  'We collect information about you when you engage us for financial planning services. This information will relate to your personal and financial circumstances. It may also include special categories of personal data such as data about your health, if this is necessary for the provision of our services.',
              },
            ],
            [
              {
                content:
                  'We may also collect information when you voluntarily complete client surveys or provide feedback to us.',
              },
            ],
            [
              {
                content:
                  'Information relating to usage of our website and portal is collected using cookies. These are text files placed on your computer to collect standard internet log information and visitor behaviour information. We will use your information collected from the website and portal to personalise your repeat visits to the site.',
              },
            ],
            [
              {
                type: 'title',
                content: 'INFORMATION ABOUT CONNECTED INDIVIDUALS',
              },
              {
                content:
                  'We may need to gather personal information about your close family members and dependants in order to provide our service to you effectively. In such cases it will be your responsibility to ensure that you have the consent of the people concerned to pass their information on to us. We’ll provide a copy of this privacy notice for them or, where appropriate, ask you to pass the privacy information to them.',
              },
            ],
            [
              {
                type: 'title',
                content:
                  'WHY DO WE NEED TO COLLECT AND USE YOUR PERSONAL DATA?',
              },
              {
                content:
                  'The primary legal basis that we intend to use for the processing of your data is for the performance of our contract with you. The information that we collect about you is essential for us to be able to carry out the services that you require from us effectively. Without collecting your personal data we would also be unable to fulfil our legal and regulatory obligations.',
              },
            ],
            [
              {
                content:
                  'Where special category data is required we will obtain your explicit consent in order to collect and process this information.',
              },
            ],
            [
              {
                type: 'title',
                content: 'HOW WILL WE USE THE INFORMATION ABOUT YOU?',
              },
              {
                content:
                  'We collect information about you in order to provide you with the services for which you engage us. If you are a potential new client and do not engage our service within a calendar year we will delete your information from our records.',
              },
            ],
            [
              {
                type: 'title',
                content: 'WHO MIGHT WE SHARE YOUR INFORMATION WITH?',
              },
              {
                content:
                  'We will not share your information for marketing purposes with companies outside our group of companies.',
              },
            ],
            [
              {
                content:
                  'In order to deliver our services to you effectively we may send your details to third parties such as those that we engage for professional compliance, accountancy or legal services as well as product and platform providers that we use to arrange financial products for you.',
              },
            ],
            [
              {
                content:
                  'Where third parties are involved in processing your data we will have a contract in place with them to ensure that the nature and purpose of the processing is clear, that they are subject to a duty of confidence in processing your data and that they will only act in accordance with our written instructions.',
              },
            ],
            [
              {
                content:
                  'Where it is necessary for your personal data to be forwarded to a third party we will use appropriate security measures to protect your personal data in transit. This may include password protection and/or encryption of the data.',
              },
            ],
            [
              {
                content:
                  'To fulfil our obligations in respect of prevention of money-laundering and other financial crime we may send your details to third party agencies for identity verification purposes.',
              },
            ],
            [
              {
                type: 'title',
                content: 'HOW LONG DO WE KEEP HOLD OF YOUR INFORMATION?',
              },
              {
                content:
                  'During the course of our relationship with you we will retain personal data which is necessary to provide services to you. We will take all reasonable steps to keep your personal data up to date throughout our relationship.',
              },
            ],
            [
              {
                type: 'unordered-list',
                listItems: [
                  [
                    {
                      content:
                        'We are also subject to regulatory requirements to retain your data for specified minimum periods. These are, generally:',
                    },
                  ],
                  [
                    {
                      content: 'Five years for investment business.',
                    },
                  ],
                  [
                    {
                      content:
                        'Indefinitely for pension transfers and opt-out business.',
                    },
                  ],
                  [
                    {
                      content: 'Three years for insurance business.',
                    },
                  ],
                ],
              },
            ],
            [
              {
                content:
                  'These are minimum periods, during which we have a legal obligation to retain your records. We reserve the right to retain data for longer where we believe it is in our legitimate interests to do so. In any case, we will not keep your personal data for longer than seven years after our relationship with you has ended.',
              },
            ],
            [
              {
                content:
                  'You have the right to request deletion of your personal data. We will comply with this request, subject to the restrictions of our regulatory obligations and legitimate interests as noted above.',
              },
            ],
            [
              {
                type: 'title',
                content: 'HOW CAN I ACCESS THE INFORMATION YOU HOLD ABOUT ME?',
              },
              {
                content:
                  'You have the right to request a copy of the information that we hold about you. If you would like a copy of some or all of your personal information, please email or write to us using the contact details noted below.',
              },
            ],
            [
              {
                content:
                  'When your personal data is processed by automated means you have the right to ask us to move your personal data to another organisation for their use.',
              },
            ],
            [
              {
                content:
                  'We have an obligation to ensure that your personal information is accurate and up to date. Please ask us to correct or remove any information that you think is incorrect.',
              },
            ],
            [
              {
                type: 'title',
                content: 'AUTOMATED DECISION MAKING AND PROFILING',
              },
              {
                content:
                  'If you use any automated system made available by Stonewood Financial Planning (for example Stonewood Financial Planning money info) your personal information will be gathered through the information that you input onto the system. Your data is collected in order for us to carry out the service for which you engage us. If you are unsure about the outcome of the automated process you can contact us to discuss or to challenge the outcome.',
              },
            ],
            [
              {
                content:
                  'We take measures to ensure the security of your data and have our own Data Security Policy. We do not use any special category data (such as data about your health) in the automated process unless it is strictly necessary to deliver our service and we have obtained your explicit consent to do so.',
              },
            ],
            [
              {
                content:
                  'We regularly review our systems for accuracy and bias and feed any changes back into the design process.',
              },
            ],
            [
              {
                type: 'title',
                content: 'MARKETING',
              },
              {
                content:
                  'We would like to send you information about our products and services and those of other companies in our group which may be of interest to you. If you have agreed to receive marketing information, you may opt out at a later date.',
              },
            ],
            [
              {
                content:
                  'You have a right at any time to stop us from contacting you for marketing purposes or giving your information to other members of the group. If you no longer wish to be contacted for marketing purposes, please contact us by email or post.',
              },
            ],
            [
              {
                type: 'title',
                content: 'OTHER WEBSITES',
              },
              {
                content:
                  'Our website contains links to other websites. This privacy policy only applies to this website so when you link to other websites you should read their own privacy policies.',
              },
            ],
            [
              {
                type: 'title',
                content:
                  'WHAT CAN YOU DO IF YOU ARE UNHAPPY WITH HOW YOUR PERSONAL DATA IS PROCESSED?',
              },
              {
                content:
                  'You also have a right to lodge a complaint with the supervisory authority for data protection. In the UK this is:',
              },
            ],
            [
              {
                type: 'linebreak',
                content: 'Information Commissioner’s Office',
              },
              {
                type: 'linebreak',
                content: 'Wycliffe House',
              },
              {
                type: 'linebreak',
                content: 'Water Lane',
              },
              {
                type: 'linebreak',
                content: 'Wilmslow',
              },
              {
                type: 'linebreak',
                content: 'Cheshire',
              },
              {
                type: 'linebreak',
                content: 'SK9 5AF',
              },
            ],
            [
              {
                content: '0303 123 1113 (Local Rate)',
              },
            ],
            [
              {
                type: 'title',
                content: 'CHANGES TO OUR PRIVACY POLICY',
              },
              {
                content:
                  'We keep our privacy policy under regular review and we will inform you of any changes when they occur. This privacy policy was last updated on 28th July 2020.',
              },
            ],
            [
              {
                type: 'title',
                content: 'HOW TO CONTACT US',
              },
              {
                content:
                  'Please contact us if you have any questions about our privacy policy or information we hold about you: by email at ',
              },
              {
                type: 'external-link',
                href: 'mailto:admin@rapportteam.com',
                content: 'admin@rapportteam.com',
              },
            ],
            [
              {
                content: '',
              },
            ],
            [
              {
                type: 'title',
                content: '',
              },
              {
                content: '',
              },
            ],
            [
              {
                content: '',
              },
            ],
          ],
        },
        {
          title: 'Cookies',
          id: 'cookies',
          sections: [
            [
              {
                type: 'title',
                content: 'IP ADDRESSES, COOKIES AND WEB BEACONS',
              },
              {
                content:
                  'We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration, to improve the structure and content of our site and to report aggregate information. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual.',
              },
            ],
            [
              {
                content:
                  'For these reasons we reserve the right to use cookies as an anonymous method of recording your usage of our site and would ask that if you do not wish to have cookies recorded on your machine, you amend the settings on your browser to prevent them from being created. The “Help” section in your browser should provide you with the necessary information to adjust your settings and control the creation and storage of cookies on your hard drive.',
              },
            ],
            [
              {
                content:
                  'A cookie is a text-only string of information that a website transfers to the cookie file of the browser on your computer’s hard disk so that the website can remember who you are.',
              },
            ],
            [
              {
                content:
                  'A cookie will typically contain the name of the domain from which the cookie has come, the “lifetime” of the cookie, and a value, usually a randomly generated unique number.',
              },
            ],
            [
              {
                type: 'unordered-list',
                listItems: [
                  [
                    {
                      content:
                        'Two types of cookies are used by us on our site:',
                    },
                  ],
                  [
                    {
                      content:
                        'Session Cookies: which are temporary cookies that remain in the cookie file of your browser until you leave the site; and',
                    },
                  ],
                  [
                    {
                      content:
                        'Persistent cookies: which remain in the cookie file of your browser for much longer (though how long will depend on the lifetime of the specific cookie).',
                    },
                  ],
                ],
              },
            ],
            [
              {
                content:
                  'Cookies can help a website to arrange content to match your preferred interests more quickly. Most major websites use cookies. Cookies cannot be used by themselves to identify you.',
              },
            ],
            [
              {
                content:
                  'Session Cookies allow you to carry information across pages of our site and avoid having to re-enter information and to allow you to access stored information.',
              },
            ],
            [
              {
                content:
                  'Persistent Cookies help us recognise you as a unique visitor (just a number) when you return to our website and to allow us to tailor content or advertisements to match your preferred interests or to avoid showing you the same adverts repeatedly.',
              },
            ],
            [
              {
                content:
                  'If you alter your browser settings to refuse our cookies you may be unable to access certain parts of our site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to our site.',
              },
            ],
            [
              {
                content:
                  'Our website may contain electronic images known as web beacons which allows us to count users who have visited those pages. Web beacons are not used to access your personally identifiable information on our site. They are a technique to compile aggregated statistics about our website’s usage. Web beacons collect only a limited set of information including a cookie number, time and date of a page view and a description of the page on which the web beacon resides.',
              },
            ],
            [
              {
                content:
                  'Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit ',
              },
              {
                type: 'external-link',
                href: 'http://www.allaboutcookies.org/',
                content: 'www.allaboutcookies.org.',
              },
            ],

            [
              {
                content:
                  'To opt out of being tracked by Google Analytics across all websites visit ',
              },
              {
                type: 'external-link',
                href: 'http://tools.google.com/dlpage/gaoptout',
                content: 'http://tools.google.com/dlpage/gaoptout.',
              },
            ],
          ],
        },
      ],
    },
  ],
};

export default termsConfig;
