import React from 'react';
import styled from 'styled-components';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  height: calc(100vh - 7rem);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ComingSoon = () => {
  return (
    <Wrapper>
      <Icon iconType='compass' size={8} color='linkBlue' margin='2rem 0' />
      <h1>Coming Soon</h1>
    </Wrapper>
  );
};

export default ComingSoon;
