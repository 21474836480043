import Private from '../assets/private.jpg';
import privateScrollover from '../assets/private-scrollover.jpg';
import privateSection from '../assets/private-section.jpg';
import rachellane from '../assets/rachellane.jpg';

const personalConfig = {
  route: '/personal',
  title: 'Group Rapport | Personal',
  content: [
    {
      component: 'hero-with-image-background',
      img: Private,
      title: ['Personal Advice'],
      sub: 'Group Rapport’s personal advice business has changed name to Stonewood Financial Planning. To find out more please use the button below to access the website',
      primaryCTA: { route: '/contact', label: 'Get In Contact' },
      secondaryCTA: { route: '/about', label: 'Meet The Team' },
    },
    {
      component: 'features-with-bullets',
      title: "It’s Called 'Plain Speaking'",
      content: [
        'Fuzziness. There’s a lot of it about in financial services. Not to mention jargon, complexity and confusion.',
        "It’s hardly surprising, therefore, that financial planning is considered complicated, or that people don’t always grasp the significance of the financial decisions they make. All in all, we don’t feel comfortable with that! There is however, there is a way of dealing with that situation...it's called plain speaking.",
        'As financial advisers, you expect us to increase, manage and protect your wealth. But that’s not our only responsibility: we must also explain clearly and precisely how we intend doing that.',
        'For that reason, communication is a key element of our service.',
      ],
      bullets: [
        {
          content:
            'We work hard to avoid misunderstandings and strive for clarity. Aiming to keep explanations concise by taking things one step at a time.',
        },
        {
          content:
            'When we first meet, you’ll want to know about the scope of our service, the benefits you can expect, our qualifications, how we charge and the principles we subscribe to.',
          title: 'INTRODUCTIONS',
        },
        {
          content:
            'Whether you have a one-off immediate financial planning issue to address, or you require a long-term plan, we’ll help you understand what needs to be done and explain why.',
          title: 'FLEXIBILITY',
        },
      ],
    },
    {
      component: 'scrollover-text-with-background-img',
      img: privateScrollover,
      title: ['Children, Parents, Grandparents... we advise them all'],
      sub: 'Literally, for some of our clients ‘long term relationships’ means we work across all generations of the family.',
    },
    {
      component: 'features-with-image',
      img: privateSection,
      title: 'Long Term Relationships',
      content: [
        'With a broader view across the family and future generations it is possible to ensure the wealth of the ‘Grandparents’ is handled in the most tax efficient manner, preserving wealth for future generations, whilst making certain that our clients still enjoy the fruits of their forward planning and hard earned savings, now.',
        'In addition to working closely with your financial adviser, you will also have be in contact with our client services team who will assist you with the day to day issues.',
      ],
    },
    {
      component: 'testimonial-centered',
      backgroundColor: true,
      contents: [
        {
          quote:
            'We strive for clarity. We aim to keep explanations concise and take things one step at a time.',
          name: 'Rachel Lane',
          role: 'Sales & Marketing Director',
          photo: rachellane,
        },
      ],
    },
  ],
};

export default personalConfig;
