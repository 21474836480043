export const SET_LOADING = 'SET_LOADING';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SET_CONFIG = 'SET_CONFIG';
export const REMOVE_CONFIG = 'REMOVE_CONFIG';

export const UPDATE_CART = 'UPDATE_CART';
export const CLEAR_CART = 'CLEAR_CART';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_COMPLETE = 'VERIFY_EMAIL_COMPLETE';
export const AUTH_LOADING = 'AUTH_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_ERROR = 'USER_ERROR';
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const FILTER_USERS = 'FILTER_USERS';
export const CLEAR_USER_FILTER = 'CLEAR_USER_FILTER';
export const CLEAR_USERS = 'CLEAR_USERS';

export const GET_MAINRECORDS = 'GET_MAINRECORDS';
export const ADD_MAINRECORD = 'ADD_MAINRECORD';
export const DELETE_MAINRECORD = 'DELETE_MAINRECORD';
export const SET_CURRENT_MAINRECORD = 'SET_CURRENT_MAINRECORD';
export const CLEAR_CURRENT_MAINRECORD = 'CLEAR_CURRENT_MAINRECORD';
export const UPDATE_MAINRECORD = 'UPDATE_MAINRECORD';
export const FILTER_MAINRECORDS = 'FILTER_MAINRECORDS';
export const CLEAR_MAINRECORD_FILTER = 'CLEAR_MAINRECORD_FILTER';
export const MAINRECORD_ERROR = 'MAINRECORD_ERROR';
export const CLEAR_MAINRECORDS = 'CLEAR_MAINRECORDS';

export const GET_RELATEDRECORDS = 'GET_RELATEDRECORDS';
export const ADD_RELATEDRECORD = 'ADD_RELATEDRECORD';
export const DELETE_RELATEDRECORD = 'DELETE_RELATEDRECORD';
export const SET_CURRENT_RELATEDRECORD = 'SET_CURRENT_RELATEDRECORD';
export const CLEAR_CURRENT_RELATEDRECORD = 'CLEAR_CURRENT_RELATEDRECORD';
export const UPDATE_RELATEDRECORD = 'UPDATE_RELATEDRECORD';
export const FILTER_RELATEDRECORDS = 'FILTER_RELATEDRECORDS';
export const CLEAR_RELATEDRECORD_FILTER = 'CLEAR_RELATEDRECORD_FILTER';
export const RELATEDRECORD_ERROR = 'RELATEDRECORD_ERROR';
export const CLEAR_RELATEDRECORDS = 'CLEAR_RELATEDRECORDS';
