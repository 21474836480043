import contactImg from '../assets/contact.jpg';
import aboutPhoto from '../assets/about.jpg';
import corporatePhoto from '../assets/corporate.jpg';
import approachPhoto from '../assets/approach.jpg';
import privatePhoto from '../assets/private.jpg';

const contactConfig = {
  route: '/contact',
  title: 'Group Rapport | Contact',
  content: [
    {
      component: 'hero-contactform',
      img: contactImg,
      form: {
        title: 'Get In Touch',
        formName: 'website-contact-form',
        submitBtnLabel: 'Send Message',
        data: [
          {
            type: 'text',
            name: 'fullName',
            label: 'Name',
            isRequired: true,
          },
          {
            type: 'tel',
            name: 'telNumber',
            label: 'Contact Number',
          },
          {
            type: 'email',
            name: 'email',
            label: 'Email',
            isRequired: true,
          },
          {
            type: 'textarea',
            name: 'message',
            label: 'Your Message...',
            isRequired: true,
          },
        ],
      },
    },
    {
      component: 'tiles-with-images',
      background: true,
      content: [
        {
          photo: aboutPhoto,
          title: 'Expert Team',
          bold: 'You wouldn’t ask your dentist to mend your car so why not have the right specialist when it comes to your money?',
          paragraph:
            'Specialist colleagues focus on business-related advice, whereas others work with high net worth private clients. As such, we can provide expert and qualified guidance on a wide and diverse range of financial subjects and issues.',
          cta: { route: '/about', label: 'Meet Our Team' },
        },
        {
          small: true,
          tiles: [
            {
              photo: corporatePhoto,
              title: 'Company Rapport',
              paragraph:
                'Our Corporate team will help you provide and promote the right benefits to your staff. Maximising the value to your team to give you value for your budget.',
              cta: { route: '/corporate', label: 'Details' },
            },
            {
              photo: approachPhoto,
              title: 'Our Approach',
              paragraph:
                'By working with you to build discipline to your financial affairs, we will help you to regain control of your financial future.',
              cta: { route: '/approach', label: 'Our Approach' },
            },
          ],
        },
        {
          photo: privatePhoto,
          title: 'Personal Advice',
          bold: 'The first step is to arrange an initial meeting to discuss your overall aims and objectives.',
          paragraph:
            'Our team offers a proactive and bespoke advice service, based on a long term relationship with our clients. We will start by analysing your circumstances and discussing your personal financial objectives. Then, once we have conducted a detailed evaluation of your options, we will provide a detailed recommendation.',
          cta: { route: '/personal', label: 'Learn More' },
        },
      ],
    },
  ],
};

export default contactConfig;
