import inflationcover from './inflationcover.jpg';
import inline from './inflationgraph.jpg';
import Rachel from './rachellane.jpg';

const ReturnOfInflation = {
  title: 'The return of inflation?',
  date: '24/09/2021',
  readTime: 3,
  imagecover: inflationcover,
  imageinline: inline,
  type: 'Article',
  typeColor: 'linkBlue',
  tags: ['Investment', 'Inflation'],
  intro:
    'UK annual inflation was just 0.4% in February 2021, as measured by the Consumer Prices Index (CPI), or 1.4% measured by the Retail Prices Index (RPI). Five months later , after a blip downwards in July, CPI inflation was 2.0% and RPI inflation 3.8%. For once inflation was not just a British disease: the US inflation rate leapt from 1.7% to 5.4% over the same brief period. Even in the Eurozone, where annual inflation was negative for the last five months of 2020, by July 2021 it was 2.2%',
  author: {
    image: Rachel,
    name: 'Rachel Lane',
    role: 'Sales & Marketing Director @Group Rapport',
  },
  lineItems: [
    [
      {
        content:
          'UK annual inflation was just 0.4% in February 2021, as measured by the Consumer Prices Index (CPI), or 1.4% measured by the Retail Prices Index (RPI). Five months later , after a blip downwards in July, CPI inflation was 2.0% and RPI inflation 3.8%. For once inflation was not just a British disease: the US inflation rate leapt from 1.7% to 5.4% over the same brief period. Even in the Eurozone, where annual inflation was negative for the last five months of 2020, by July 2021 it was 2.2%',
      },
    ],
    [
      {
        content:
          'The sudden and widespread jump has been blamed on several factors, many of which relate to the developed world’s economic recovery from the pandemic. A significant jump in the cost of second-hand cars on both sides of the Atlantic, for example, is one sign of the economic fallout. This price rise has its roots in a shortage of new vehicles which in turn is due to reduced orders from manufacturers for microchips during the pandemic.',
      },
    ],
    [
      {
        content:
          'The big question now is whether inflation will abate along with the pandemic. Some economists predict that the inflationary spike will prove ‘transitory’ and the pandemic distortions will disappear over the coming year. This view is also shared by the central bankers in the UK, US and Eurozone',
      },
    ],
    [
      {
        content:
          'The Bank rate was held again in August and interest rates are not expected to rise in the near term. However, many economists fear that the price increases could lead to parallel wage rises – already evident in some sectors – creating a wage/price inflationary spiral, once a familiar feature of the UK economy',
      },
    ],
    [{type: 'image', content: inline}],
    [{ type: 'heading', content: 'Impact on investments' }],
    [
      {
        content:
          'If you are an investor, rising inflation can often be bad news. With interest rates close to zero, the buying power of any cash you hold is being steadily eroded. At the time of writing, there were no savings accounts that matched or beat the 2.5% inflation rate, other than a couple of specialist children’s accounts. If you have more cash than you need for your rainy day reserve, make sure you have a good reason, e.g. an impending tax bill.',
      },
    ],
    [
      {
        content:
          'Inflation also works against you if you hold fixed interest investments, such as bond funds, because the value of the future payments of interest and eventual return of capital are similarly eroded by inflation. On the other hand, the appeal – and value – of indexlinked bonds typically rises when the spectre of inflation looms and investors seek cover',
      },
    ],
    [
      {
        content:
          'In the long term, investment in shares is better protection against inflation than either bonds or cash deposits. However, in the short term, inflation can be a drag on some companies’ profits – think of those wage pressures – and can depress their share prices.',
      },
    ],
    [
      {
        content:
          'With inflation uncertainty set to remain for some while, it makes sense to review your investments now to make sure you are ready for whichever set of economists proves to be correct.',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'The value of your investment and any income from it can go down as well as up and you may not get back the full amount you invested. Past performance is not a reliable indicator of future performance',
      },
    ],
    [
      {
        type: 'footer',
        content:
          'Investing in shares should be regarded as a long-term investment and should fit in with your overall attitude to risk and financial circumstances',
      },
    ],
  ],
};

export default ReturnOfInflation;
