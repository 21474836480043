import React, { useReducer } from 'react';
import ShoppingcartContext from './shoppingcartContext';
import shoppingcartReducer from './shoppingcartReducer';
import { UPDATE_CART, CLEAR_CART } from '../types';

const ShoppingcartState = (props) => {
  const initalState = {
    cartItems: {},
  };

  const [state, dispatch] = useReducer(shoppingcartReducer, initalState);

  // Update Cart
  const updateCart = async (product, quantity) => {
    dispatch({
      type: UPDATE_CART,
      payload: { productCode: product, quantity: quantity },
    });
  };

  // Clear ShoppingCart
  const clearShoppingCart = () => {
    dispatch({ type: CLEAR_CART });
  };

  // New Order Complete

  return (
    <ShoppingcartContext.Provider
      value={{
        cartItems: state.cartItems,
        updateCart,
        clearShoppingCart,
      }}
    >
      {props.children}
    </ShoppingcartContext.Provider>
  );
};

export default ShoppingcartState;
