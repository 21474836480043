import React from 'react';
import styled from 'styled-components';

import { Button, LinkButton } from '../Controls';
import Icon from '../UI/Icon';
import NameJobPhoto from '../UI/NameJobPhoto';

const Wrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  padding: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 2fr;
`;

const LeftWrapper = styled.div``;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const Subtitle = styled.div`
  font-size: 1rem;
  font-weight: 300;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  margin: 1rem 0;
`;

const TestimonialWrapper = styled.div`
  background: ${(props) => `rgba(${props.theme.colors.bgColor[0]}, 1)`};
  margin: 2rem 0;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.boxShadow};
`;

const LogoWrapper = styled.div`
  img {
    width: 40px;
    height: 40x;
    margin-right: 1rem;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
`;
const Quote = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const RightWrapper = styled.div`
  img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    box-shadow: ${(props) => props.theme.boxShadow};
    border-radius: 10px;
  }
`;

const BulletsWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 0 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Bullet = styled.div`
  margin: 2rem 0;
  p {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const BulletTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const TestimonialWithVideo = ({ config, journeyIncrement }) => {
  return (
    <Wrapper>
      <LeftWrapper>
        <Title>{config.title}</Title>
        <Subtitle>{config.subTitle}</Subtitle>
        {config.ctaType === 'Journey-Increment' ? (
          <Button
            color='linkBlue'
            block='true'
            onClick={() => {
              journeyIncrement();
            }}
          >
            {config.cta}
            <Icon iconType='next' size={1} color='light' />
          </Button>
        ) : (
          <LinkButton color='linkBlue' to={config.ctaRoute} block='true'>
            {config.cta}
            <Icon iconType='next' size={1} color='light' />
          </LinkButton>
        )}
        <TestimonialWrapper>
          <LogoWrapper>
            <img src={config.testimonial.logo} alt={config.testimonial.name} />
            {config.testimonial.company}
          </LogoWrapper>
          <Quote>" {config.testimonial.quote} "</Quote>
          <NameJobPhoto config={config.testimonial} />
        </TestimonialWrapper>
      </LeftWrapper>
      <RightWrapper>
        <img src={config.mainImg} alt={config.title} />
        <BulletsWrapper>
          {config.bullets.map((item, index) => (
            <Bullet key={index}>
              <BulletTitle>{item.title}</BulletTitle>
              <p>{item.content}</p>
            </Bullet>
          ))}
        </BulletsWrapper>
      </RightWrapper>
    </Wrapper>
  );
};

export default TestimonialWithVideo;
