import calculateNext12Months from '../../../../utils/calculateNext12Months';

const stages = [
  'Enquiry',
  'Client Engaged',
  'Advice Issued',
  'Application Received',
  'Business Completed',
];

const outcomes = [
  'Pending',
  'Signed-Up',
  'Not Taken Up',
  'Deferred',
  'Lost to Competitor',
];

const productTypes = [
  { value: 'feeOnly', label: 'Fee Only' },
  { value: 'corporate', label: 'Corporate Fee Only' },
  { value: 'lifeAssurance', label: 'Life Assurance' },
  { value: 'mortgage', label: 'Mortgage' },
  { value: 'pension', label: 'Pension' },
  { value: 'investment', label: 'Investment' },
];

const valueGroups = [
  { LTE: 4999, label: '>£5,000' },
  { GT: 4999, LTE: 10000, label: '£5k - £10k' },
  { GT: 10000, LTE: 20000, label: '£10k - £20k' },
  { GT: 20000, label: '+£20k' },
];

const opportunitiesConfig = {
  title: 'Business Cases',
  name: 'Case',
  addBtnLabel: 'Create Case',
  disbaleDirectAddRecord: true,
  tabs: ['manage', 'analyse'],
  tableConfig: { filterByLabel: 'Client Name' },
  manageTabConfig: {
    tableConfig: [
      { label: 'Type', ref: 'productType' },
      { label: 'Adviser', ref: 'caseAdviserID' },
      { label: 'Firm', ref: 'tenant' },
      { label: 'Stage', ref: 'stage' },
      { label: 'Outcome', ref: 'outcome' },
      { label: 'Amount', ref: 'amount' },
      { label: 'Initial', ref: 'initial' },
      { label: 'Ongoing', ref: 'ongoing' },
    ],
  },
  analysisTabConfig: {
    aggregateConfig: {
      count: 'Total Cases',
      amount: 'Total Value',
      aua: 'Assets under Advice',
      initial: 'Initial',
      ongoing: 'Ongoing',
    },
  },
  newDataSteps: [
    {
      title: 'Introduction',
      guidance:
        'Add some basic details about this New Business Case. Once you create the new record you will be taken to the full details page, where additional details can be added; including Expectations & Product information.',
    },
    {
      title: 'Case Adviser Details',
      guidance:
        'Is the adviser for this case different to the usual client adviser, e.g. where the clients adviser has referred mortgage advice to a different adviser',
      data: [{ ref: 'differentCaseAdviser' }, { ref: 'caseAdviserID' }],
    },
    {
      title: 'Income Expectaction',
      guidance: 'Only add one product per business case.',
      data: [
        { ref: 'productType', mandatory: true },
        { ref: 'initial', mandatory: true },
        { ref: 'expectedMonth' },
      ],
    },
    {
      title: 'Notes',
      guidance:
        'Add any notes you want to record about this case and display on the case information to the rest of the team.',
      data: [{ ref: 'caseNotes' }],
    },
  ],
  keyFutureDataReference: 'expectedMonth',
  stages: stages,
  outcomes: outcomes,
  productTypes: productTypes,
  valueGroups: valueGroups,
  recordEditTabs: ['summary', 'case details', 'product info', 'documents'],
  recordEditConfig: [
    {
      reference: 'summary',
      tiles: [
        {
          title: 'Case Status',
          subtitle:
            'Track the case through the advice process and record the case outcome',
          data: ['stage', 'outcome', 'contactsID', 'accountsID'],
        },
        {
          title: 'Team',
          subtitle:
            'Case Adviser & Client Adviser can be the same, select a different Case Adviser where the clients usual Adviser is not giving the advice in this case.',
          data: [
            'clientAdviserID',
            'adminID',
            'caseAdviserID',
            'differentCaseAdviser',
          ],
        },
        {
          title: 'Case Notes',
          data: ['caseNotes'],
        },
      ],
    },
    {
      reference: 'case details',
      tiles: [
        {
          title: 'Income Expectation',
          data: ['initial', 'ongoing', 'expectedMonth'],
        },
      ],
    },
    {
      reference: 'product info',
      tiles: [
        {
          title: 'Product details',
          data: ['productType', 'product', 'provider', 'amount', 'frequency'],
        },
      ],
    },
    {
      reference: 'documents',
      tiles: [
        {
          namedComponent: {
            component: 'document',
            recordRef: 'letterOfAuthority',
          },
        },
        {
          namedComponent: {
            component: 'document',
            recordRef: 'general',
          },
        },
      ],
    },
  ],
  data: {
    stage: {
      type: 'select',
      group: 'status',
      label: 'Pipeline Stage',
      valueOptions: stages,
    },
    outcome: {
      type: 'select',
      group: 'status',
      label: 'Case Outcome',
      valueOptions: outcomes,
    },

    productType: {
      type: 'select',
      group: 'productInfoSection',
      label: 'Product Type',
      valueOptions: productTypes,
    },
    product: {
      type: 'text',
      group: 'productInfoSection',
      label: 'Product',
    },
    provider: {
      type: 'text',
      group: 'productInfoSection',
      label: 'Provider',
    },
    amount: {
      type: 'number',
      group: 'productInfoSection',
      label: 'Amount / Premium',
    },
    frequency: {
      type: 'select',
      label: 'Frequency',
      group: 'productInfoSection',
      valueOptions: [
        { value: 'monthly', label: 'Monthly' },
        { value: 'quarterly', label: 'Quarterly' },
        { value: 'biannual', label: 'Bi-Annual' },
        { value: 'annually', label: 'Annually' },
        { value: 'lumpsum', label: 'Lumpsum / One-off' },
      ],
    },
    initial: {
      type: 'number',
      group: 'expectationSection',
      label: 'Initial Fee / Commission (£)',
    },
    adminID: {
      isComplex: true,
      type: 'teamlookup',
      icon: 'usersingle',
      dbRef: 'users',
      label: 'Administrator',
      roleTypes: ['admin', 'manager'],
    },
    clientAdviserID: {
      isComplex: true,
      type: 'teamlookup',
      icon: 'usersingle',
      dbRef: 'users',
      label: 'Client Adviser',
      roleTypes: ['adviser'],
    },
    differentCaseAdviser: {
      type: 'boolean',
      label: 'Case adviser is different to usual client adviser',
    },
    caseAdviserID: {
      isComplex: true,
      type: 'teamlookup',
      icon: 'usersingle',
      dbRef: 'users',
      label: 'Case Adviser',
      fullGroup: true,
      roleTypes: ['adviser'],
      visibilityRules: [
        {
          target: 'differentCaseAdviser',
          comparisionOperator: 'isEqualTo',
          valueTest: true,
        },
      ],
    },
    contactsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'contacts',
      label: 'Private Client',
    },
    accountsID: {
      isComplex: true,
      type: 'lookup',
      icon: 'userlist',
      dbRef: 'accounts',
      label: 'Corporate Account',
    },
    ongoing: {
      type: 'number',
      group: 'expectationSection',
      label: 'Ongoing Annual Fee / Commission (£)',
    },
    expectedMonth: {
      type: 'select',
      label: 'Expected Month',
      group: 'expectationSection',
      valueOptions: calculateNext12Months(),
    },
    caseNotes: {
      type: 'textArea',
      label: 'Case Notes',
    },
  },
  documents: {
    letterOfAuthority: {
      type: 'document',
      label: 'Letter(s) of Authority Agreement',
      guidance: 'Optional. Upload a signed LOA.',
      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'provider', label: 'Provider' },
      ],
      saveFolder: 'clientdocuments',
    },
    general: {
      type: 'document',
      label: 'Other Documents',

      meta: [
        { ref: 'documentDate', label: 'Document Date' },
        { ref: 'type', label: 'Document Type' },
      ],
      saveFolder: 'clientdocuments',
    },
  },
};

export default opportunitiesConfig;
