import React, { Fragment } from 'react';
import styled from 'styled-components';

import Icon from '../UI/Icon';

const Wrapper = styled.div`
  padding: 2rem;
  max-width: 1100px;
  margin: auto;
`;

const ContentItem = styled.div`
  margin-bottom: 2rem;
  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const Section = styled.div`
  margin-bottom: 1rem;
  * {
    display: inline;
  }
  a {
    color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  }
  h3 {
    display: block;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1rem;
  }
  ul {
    display: block;
    li {
      display: flex;
      margin-bottom: 0.25rem;
    }
  }
`;

const TermsSection = ({ config }) => {
  return (
    <Wrapper>
      {config.content.map((contentItem, contentItemIndex) => (
        <ContentItem key={contentItemIndex} id={contentItem.id}>
          <h2>{contentItem.title}</h2>
          {contentItem.sections.map((section, sectionIndex) => (
            <Section key={sectionIndex}>
              {section.map((lineItem, itemIndex) => (
                <Fragment key={itemIndex}>
                  {!lineItem.type && <p>{lineItem.content}</p>}
                  {lineItem.type === 'linebreak' && (
                    <p>
                      {lineItem.content}
                      <br />
                    </p>
                  )}
                  {lineItem.type === 'title' && <h3>{lineItem.content}</h3>}
                  {lineItem.type === 'external-link' && (
                    <a href={lineItem.href}>{lineItem.content}</a>
                  )}
                  {lineItem.type === 'unordered-list' && (
                    <ul>
                      {lineItem.listItems.map((listItem, listItemIndex) => (
                        <li key={listItemIndex}>
                          <div>
                            <Icon
                              iconType='circle'
                              size={0.5}
                              color='dark'
                              margin='0 0.5rem 0.5rem 0.25rem'
                            />
                          </div>
                          <div>
                            {listItem.map(
                              (listItemContent, listItemContentIndex) => (
                                <Fragment key={listItemContentIndex}>
                                  {listItemContent.type === 'external-link' && (
                                    <a href={listItemContent.href}>
                                      {listItemContent.content}
                                    </a>
                                  )}
                                  {listItemContent.type === 'onpage-link' && (
                                    <a href={listItemContent.linkLocation}>
                                      {listItemContent.content}
                                    </a>
                                  )}
                                  {!listItemContent.type && (
                                    <Fragment>
                                      {listItemContent.content}
                                    </Fragment>
                                  )}
                                </Fragment>
                              )
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </Fragment>
              ))}
            </Section>
          ))}
        </ContentItem>
      ))}
    </Wrapper>
  );
};

export default TermsSection;
